import { atom } from 'recoil'

export type ApplicationsAtomType = {
  visitor: number
  fixed: number
}

export const applicationsAtom = atom<ApplicationsAtomType>({
  key: 'applicationsState',
  default: {
    visitor: 0,
    fixed: 0,
  },
})
