import React from 'react'
import {
  Navigation,
  requiresPermission,
  requiresTenant,
  requiresOneOfPermissions,
  requiresAllOfPermissions,
} from '@griegconnect/krakentools-react-kraken-app'
import { withActiveTenant } from '@app/routes/WithActiveTenant'
import { withServices } from '@app/routes/withServices'
import { AccessDependencies } from '@app/Content'
import { PATH_TENANT_BASE, Permissions, PERMISSIONS } from '@app/constants'
import { withParam } from '@app/routes/withParam'
import { isPort } from '@app/lib/access'
import { Trans } from 'react-i18next'

const FACILITIES_PATH = PATH_TENANT_BASE + '/settings/facilities'

export const facilitiesModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="settings.labels.facilities" />,
    path: FACILITIES_PATH + '/',
    page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./Facilities'))))),
    exact: true,
    menu: true,
    access: (d) =>
      isPort(d) &&
      requiresTenant(d) &&
      requiresOneOfPermissions([PERMISSIONS.facilities.create, PERMISSIONS.facilities.update], true)(d),
    subNavs: [
      {
        name: 'New Facility',
        path: FACILITIES_PATH + '/new',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Facility/Settings/New')),
                'facilityId'
              )
            )
          )
        ),
        exact: true,
        access: (d) => isPort(d) && requiresPermission(PERMISSIONS.facilities.create, true)(d),
      },
      {
        name: 'Facility',
        path: FACILITIES_PATH + '/:facilityId',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Facility')),
                'facilityId'
              )
            )
          )
        ),
        exact: true,
        access: (d) => isPort(d) && requiresPermission(PERMISSIONS.facilities.update, true)(d),
      },
      {
        name: 'Edit security level',
        path: FACILITIES_PATH + '/:facilityId/securitylevels/:securityLevelId',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Facility/SecurityLevels/EditSecurityLevel')),
                'securityLevelId'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          requiresTenant(d) &&
          isPort(d) &&
          requiresOneOfPermissions(Permissions.all(PERMISSIONS.security_level), true)(d),
      },
      {
        name: 'Edit quay',
        path: FACILITIES_PATH + '/:facilityId/quays/:quayId',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Facility/Quays/EditQuay')),
                'quayId'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          requiresTenant(d) && isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.quays))(d),
      },
      {
        name: 'New checkpoint',
        path: FACILITIES_PATH + '/:facilityId/checkpoints/new',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Facility/Checkpoints/New')),
                'facilityId'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          requiresTenant(d) && isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.checkpoints))(d),
      },
      {
        name: 'Edit checkpoint',
        path: FACILITIES_PATH + '/:facilityId/checkpoints/:checkpointId',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Facility/Checkpoints/Edit')),
                'checkpointId'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          requiresTenant(d) && isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.checkpoints))(d),
      },
    ],
  },
]
