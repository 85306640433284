import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
import { FormValues } from 'src/common/ServicesWrapper/apis/Application2'
import { UserApi } from 'src/common/ServicesWrapper/apis/UserApi'
import { WithPortDetails } from 'src/routes/withPortDetails'
import { ApplicationFormData, emptyApplicationForm } from '../schema'
import StepConfirmations from './StepConfirmations'
import StepSubmit from './StepSubmit'
import StepVisiting from './StepVisiting'
import StepTime from './StepTime'
import StepVehicle from './StepVehicle'
import StepVisitor from './StepVisitor'
import { SecurityIdentity } from 'src/common/ServicesWrapper/apis/dtos/IdentityAttributes'
import StepSuccess from './StepSuccess'
import { useUser } from '@griegconnect/krakentools-react-kraken-app'
import { Close } from '@griegconnect/krakentools-react-icons'
import StepVisitType from './StepVisitType'
import StepWalkingOrDriving from './StepWalkingOrDriving'

export type VisitType = 'company' | 'vessel'
export type WalkingOrDrivingType = 'walking' | 'driving'

export interface NextState {
  formType: 'new' | 'edit'
  currentStep: number
  visitBy?: WalkingOrDrivingType
  status: 'ongoing' | 'done'
  visitType?: VisitType
  applicationForm?: UserApi.Application.Form
}

export const defaultNextState: NextState = {
  formType: 'new',
  currentStep: 0,
  visitBy: undefined,
  visitType: undefined,
  status: 'ongoing',
}

export const nextStateAtom = atom<NextState>({
  key: 'nextState',
  default: defaultNextState,
})

export const applicationState = atom<ApplicationFormData>({
  key: 'applicationState',
  default: emptyApplicationForm(null),
})

export interface StepProps extends WithPortDetails {
  formType: 'new' | 'edit'
  formValues: FormValues
  application?: UserApi.Application.Details
  children?: React.ReactNode
  setModalOpen?: Dispatch<SetStateAction<boolean>>
  userDetails?: SecurityIdentity
  resetForm?: () => void
  onBack?: () => void
}

export interface Props extends WithPortDetails {
  isDone: boolean
  formValues: FormValues
  application?: UserApi.Application.Details
  userDetails: SecurityIdentity
}

const Flow: React.FC<Props> = ({ formValues, application, isDone, portDetails, userDetails }) => {
  const navigate = useNavigate()
  const [applicationForm, setApplicationForm] = useRecoilState<ApplicationFormData>(applicationState)
  const [state, setState] = useRecoilState<NextState>(nextStateAtom)
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(true)
  const theme = useTheme()
  const user = useUser()

  const onBack = () =>
    state.currentStep === 0 ? navigate('/user') : setState((p) => ({ ...p, currentStep: p.currentStep - 1 }))

  const onNext = () =>
    state.currentStep === steps.length - 1
      ? navigate('/user')
      : setState((p) => ({ ...p, currentStep: p.currentStep + 1 }))

  const status = application?.current.status
  const disabled = status === 'approved' || status === 'declined'

  const navButtons = (last?: boolean) => (
    <Box display="flex" justifyContent="flex-end" mt={4}>
      {!isDone && (
        <Button variant="outlined" onClick={onBack} style={{ marginRight: 10 }}>
          {state.currentStep === 0 ? t('common.actions.cancel') : t('common.actions.back')}
        </Button>
      )}
      {!isDone && (!disabled || state.currentStep < steps.length - 1) && (
        <Button variant="contained" type="submit" onClick={onNext}>
          {last ? t('common.actions.submit') : t('common.actions.next')}
        </Button>
      )}
      {!!isDone && (
        <Button variant="contained" onClick={() => navigate('/user')}>
          {t('common.actions.done')}
        </Button>
      )}
    </Box>
  )

  const resetForm = () => {
    setApplicationForm({
      ...emptyApplicationForm(user, userDetails),
      visitorPerson: {
        ...emptyApplicationForm(user, userDetails).visitorPerson,
        name: applicationForm.visitorPerson ? applicationForm.visitorPerson.name : '',
        email: applicationForm.visitorPerson ? applicationForm.visitorPerson.email : '',
        mobile: applicationForm.visitorPerson ? applicationForm.visitorPerson.mobile : '',
        company: applicationForm.visitorPerson ? applicationForm.visitorPerson.company : '',
        locale: applicationForm.visitorPerson ? applicationForm.visitorPerson.locale : '',
        files: applicationForm.visitorPerson ? applicationForm.visitorPerson.files : [],
      },
    })
    setState(defaultNextState)
  }

  const onClose = () => {
    navigate('/user')
    resetForm()
    setModalOpen(false)
  }

  const steps = () => [
    {
      name: 'Visitor',
      edit: state.formType === 'edit' || state.formType === 'new',
      component: () => (
        <StepVisitor
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
          resetForm={resetForm}
        >
          {navButtons()}
        </StepVisitor>
      ),
    },
    {
      name: 'Visit type',
      edit: false,
      component: () => (
        <StepVisitType
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
          resetForm={resetForm}
        />
      ),
    },
    {
      name: 'Walking or driving',
      edit: false,
      component: () => (
        <StepWalkingOrDriving
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
          resetForm={resetForm}
        />
      ),
    },
    {
      name: 'Vehicle',
      edit: false,
      component: () => (
        <StepVehicle
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
          resetForm={resetForm}
        />
      ),
    },
    {
      name: 'Target',
      edit: false,
      component: () => (
        <StepVisiting
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
          resetForm={resetForm}
        />
      ),
    },
    {
      name: 'Time',
      edit: false,
      component: () => (
        <StepTime
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
          resetForm={resetForm}
        />
      ),
    },
    {
      name: 'Confirmations',
      edit: false,
      component: () => (
        <StepConfirmations
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
          resetForm={resetForm}
        />
      ),
    },
    {
      name: 'Submit',
      edit: state.formType === 'edit' || state.formType === 'new',
      component: () => (
        <StepSubmit
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
          setModalOpen={(open) => setModalOpen(open)}
          resetForm={resetForm}
          onBack={onBack}
          userDetails={userDetails}
        />
      ),
    },
    {
      name: 'Success',
      edit: false,
      component: () => (
        <StepSuccess
          application={application}
          formValues={formValues}
          formType={state.formType}
          portDetails={portDetails}
        />
      ),
    },
  ]

  const schemaView = () => {
    return (
      <Box p={2} paddingLeft={0}>
        {steps()
          .filter((_s, i) => i === state.currentStep)
          .map((s, _i) => {
            return <Box key={s.name.toLowerCase()}>{s.component()}</Box>
          })}
      </Box>
    )
  }

  const editSchemaView = () => {
    return (
      <Box p={2} paddingLeft={0}>
        {steps()
          .filter((s) => s.edit !== false)
          .filter((_s, i) => i === state.currentStep)
          .map((s, _i) => {
            return <Box key={s.name.toLowerCase()}>{s.component()}</Box>
          })}
      </Box>
    )
  }

  const maestro = () => {
    switch (state.formType) {
      case 'new':
        return schemaView()
      case 'edit':
        return editSchemaView()
    }
  }

  const calculateProgress = (formType: string) => {
    const editSteps = steps().filter((s) => s.edit !== false)
    if (formType === 'new') {
      let l = ((state.currentStep + 1) / steps().length) * 100
      return l
    } else if (formType === 'edit') {
      let l = ((state.currentStep + 1) / editSteps.length) * 100
      return l
    }
  }

  return (
    <Modal open={modalOpen}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <AppBar sx={{ color: '#000' }}>
          <Toolbar variant="dense" sx={{ bgcolor: theme.palette.background.default }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: (theme) => theme.palette.text.primary }}>
              {state.formType === 'new' ? t('applications.subTitle.requestPortAccess') : status === 'declined' ? t('applications.status.applicationDeclined') : t('applications.module.edit')}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Toolbar>
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={calculateProgress(state.formType)} />
          </Box>
        </AppBar>
        <Box height={'65px'} />
        <Grid container sx={{ flexGrow: 1, overflow: 'auto', paddingLeft: '1em', justifyContent: 'center' }}>
          <Grid item xs={12} sm={12} md={7} lg={6} xl={5} maxWidth={'1000px !important'}>
            {maestro()}
          </Grid>
        </Grid>
        <Box height={'60px'} />
      </Paper>
    </Modal>
  )
}

export default Flow
