import { Page } from '@griegconnect/krakentools-react-ui'
import { Box, Skeleton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const Icon = () => {
  const theme = useTheme()
  return <Skeleton variant="circular" width={theme.spacing(3)} height={theme.spacing(3)} />
}

export const Progress = () => (
  <Page.Paper padding={2} style={{ height: '100%' }}>
    <Box display="flex" alignItems="center">
      <Icon />
      <Box ml={2} display="flex" flexDirection="column" width="100%">
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="50%" />
      </Box>
    </Box>
    <Box pt={1} />
    <Skeleton variant="rectangular" height="60%" />
    <Box pt={1} />
    <Skeleton variant="text" width="30%" />
  </Page.Paper>
)

export const Value = () => (
  <Page.Paper padding={2} style={{ height: '100%' }}>
    <Box display="flex" alignItems="center">
      <Icon />
      <Box ml={2} display="flex" flexDirection="column" width="100%">
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="50%" />
      </Box>
    </Box>
    <Box pt={2} />
    <Skeleton variant="rectangular" height="60%" />
  </Page.Paper>
)

export const Trend = () => (
  <Page.Paper padding={2} style={{ height: '100%' }}>
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Box display="flex" alignItems="center">
        <Icon />
        <Box ml={2} display="flex" flexDirection="column" width="100%">
          <Skeleton variant="text" width="30%" />
          <Skeleton variant="text" width="50%" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Skeleton variant="text" width="10%" />
        <Skeleton variant="text" width="20%" />
      </Box>
    </Box>
  </Page.Paper>
)

export const LineChart = () => (
  <Page.Paper padding={2} style={{ height: '100%' }}>
    <Box display="flex" alignItems="center">
      <Icon />
      <Box ml={2} display="flex" flexDirection="column" width="100%">
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="50%" />
      </Box>
    </Box>
    <Box pt={4} />
    <Skeleton variant="rectangular" height="60%" />
  </Page.Paper>
)
