import { Box, Typography } from '@mui/material'
import React from 'react'
import 'react-international-phone/style.css'

interface PartProps {
  heading: string
  children: React.ReactNode
  chip?: React.ReactNode
  hidden?: boolean
}

export const Part: React.FunctionComponent<React.PropsWithChildren<PartProps>> = ({
  heading,
  children,
  chip,
  hidden,
}) => {
  return (
    <Box pt={2} hidden={hidden}>
      <Box pb={1}>
        <Typography variant={'h5'} component="h2">
          {heading}
        </Typography>
        {chip}
      </Box>
      {children}
    </Box>
  )
}
