export type LanguageCode = {
  iso: IsoCode
  iso639_2: string
  iso639_3: string
}

export type IsoCode = 'da' | 'en' | 'fi' | 'no' | 'sv'

export type LanguageCodes = LanguageCode[]

const languageCodes: LanguageCodes = [
  {
    iso: 'da',
    iso639_2: 'dan',
    iso639_3: 'dan',
  },
  {
    iso: 'en',
    iso639_2: 'eng',
    iso639_3: 'eng',
  },
  {
    iso: 'fi',
    iso639_2: 'fin',
    iso639_3: 'fin',
  },
  {
    iso: 'sv',
    iso639_2: 'swe',
    iso639_3: 'swe',
  },
  {
    iso: 'no',
    iso639_2: 'nor',
    iso639_3: 'nor',
  },
]

export const getLanguageCodes = (): LanguageCodes => languageCodes

export const getIsoCodes = (): IsoCode[] => languageCodes.map(l => l.iso)

export const getLanguageCodeFromIso639_2 = (code: string): IsoCode | undefined =>
  languageCodes.find((l) => l.iso639_2 === code.toLowerCase())?.iso

export const getLanguageCodeFromIso639_3 = (code: string): IsoCode | undefined =>
  languageCodes.find((l) => l.iso639_3 === code.toLowerCase())?.iso
