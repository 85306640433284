import { Typography, Box } from '@mui/material'
import { IWidget, WidgetCard } from '@griegconnect/krakentools-react-ui'
import { Stats } from '@app/common/ServicesWrapper/apis/Stats'
import { PermitApplication } from '@griegconnect/krakentools-react-icons'
import { getChipStatus } from '@app/lib/contracts'
import { ContractApplications } from '@app/common/ServicesWrapper/apis/ContractsApi'
import { Widgets } from '../Types'
import * as SkeletonLoader from '../SkeletonLoader'
import { useTranslation } from 'react-i18next'

export const Widget: React.VFC<Widgets.InfoWithData<Stats.ContractStatusStats>> = ({
  widgetConstructor,
  data,
  onRemove,
  onDetails,
  onSettings,
  editing,
}) => {
  const { t } = useTranslation()
  return data && widgetConstructor.subVariant ? (
    <WidgetCard
      title={widgetConstructor.name || t('components.dashboard.labels.cardApplications')}
      description={
        widgetConstructor.subVariant
          ? `${getChipStatus(widgetConstructor.subVariant as ContractApplications.Status, t).label}`
          : t('components.dashboard.labels.all')
      }
      colorDescription={data[widgetConstructor.subVariant] > 0 ? widgetConstructor.color : undefined}
      icon={<PermitApplication />}
      onDetails={onDetails}
      detailsDisabled={data[widgetConstructor.subVariant] === undefined}
      editing={editing}
      onRemove={onRemove}
      onSettings={onSettings}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          fontSize={70}
          style={
            widgetConstructor.color && data[widgetConstructor.subVariant] > 0 ? { color: widgetConstructor.color } : {}
          }
        >
          {data[widgetConstructor.subVariant] || 0}
        </Typography>
      </Box>
    </WidgetCard>
  ) : (
    <SkeletonLoader.Value />
  )
}

export const contractsStatusWidget = (props: Widgets.InfoWithData<Stats.ContractStatusStats>): IWidget[] => {
  const { widgetConstructor } = props
  return widgetConstructor.subVariant
    ? [
        {
          id: widgetConstructor.id,
          isResizable: true,
          exactWidth: widgetConstructor.width,
          position: widgetConstructor.position,
          component: <Widget {...props} />,
        },
      ]
    : []
}

export default contractsStatusWidget
