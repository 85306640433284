import { Box, Stack, Typography } from '@mui/material'
import VisitorAvatarGroup from '@app/common/VisitorAvatarGroup'
import { UserApi } from '@app/common/ServicesWrapper/apis/UserApi'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import UserApplicationStatus from '@app/common/applications/ApplicationStatus'
import { Page } from '@griegconnect/krakentools-react-ui'
interface IPermitProps {
  application: UserApi.Application
}

const ApplicationCard = ({ application }: IPermitProps) => {
  const navigate = useNavigate()
  const status = {
    invited: 'invited' as const,
    approved: 'closed' as const,
    declined: 'closed' as const,
    request_more_info: 'suspended' as const,
    submitted: 'submitted' as const,
  }
  const fromDate = application.visiting.timeIn && DateTime.fromISO(application.visiting.timeIn)
  return (
    <Page.Paper style={{ height: '100%' }}>
      <Stack
        onClick={() => navigate(`/user/application/edit/${application.meta.id}`)}
        style={{ cursor: 'pointer' }}
        direction="row"
        width="100%"
        height="100%"
      >
        <Box flexDirection="column" flex={1} style={{ textOverflow: 'ellipsis' }}>
          <Typography variant="h5">{application.visiting.port.name}</Typography>
          <Typography variant="subtitle2" noWrap>
            {[application.visiting.facility.name].reduce((acc, a) => `${acc}, ${a}`)}
          </Typography>
          {application.status !== 'invited' && fromDate && (
            <Typography variant="body1" mt={1}>
              {fromDate.toLocaleString(DateTime.DATETIME_SHORT)}
            </Typography>
          )}
          {application.visitor.vehicle && (
            <Box display="flex" flexDirection="row" my={2} alignItems="center">
              <Box mr={2}>
                <VisitorAvatarGroup users={[]} vehicles={[[application.visitor.vehicle.vehicle]]} />
              </Box>
              <Typography variant="h6">{application.visitor.vehicle.vehicle}</Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="flex-start" justifyContent="center" pl={2}>
          <UserApplicationStatus status={status[application.status]} />
        </Box>
      </Stack>
    </Page.Paper>
  )
}
export default ApplicationCard
