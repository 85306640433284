import React from 'react'

import { UseQueryResult } from '@tanstack/react-query'
import { FailedToRetrieveData } from './StatusTexts'
import { AppLoader } from '@griegconnect/krakentools-react-ui'

type Data<A extends { [K in keyof A]: UseQueryResult<any> }> = {
  [K in keyof A]: A[K] extends UseQueryResult<infer T> ? T : never
}

interface Props<A extends { [K in keyof A]: UseQueryResult<any> }> {
  queries: A
  children: (data: Data<A>) => React.ReactElement | null
  error?: React.ReactElement
  loading?: React.ReactElement
}

function UseQueries<A extends { [K in keyof A]: UseQueryResult<any> }>({
  queries,
  children,
  error = <FailedToRetrieveData />,
  loading = <AppLoader />,
}: Props<A>) {
  const entries = Object.entries(queries) as [string, UseQueryResult<any>][]
  if (entries.some(([_, value]) => value.isError)) {
    return error
  }
  if (entries.some(([_, value]) => value.isLoading)) {
    return loading
  }
  const data = Object.fromEntries(entries.map(([key, value]) => [key, value.data])) as Data<A>
  return children(data)
}

export default UseQueries
