import { Box, FormControl, FormHelperText, InputLabel, SelectChangeEvent, Select as SelectMUI } from '@mui/material'
import React from 'react'
import { Control, Controller, get, useFormState } from 'react-hook-form'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'

interface SelectProps {
  control: Control<any>
  className?: string | undefined
  label: string
  name: string
  variant?: 'standard' | 'filled' | 'outlined'
  defaultValue?: string
  fullWidth?: boolean
  errorsPath?: string
  required?: boolean
  disabled?: boolean
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
  helperText?: string
}
export const Select: React.FC<React.PropsWithChildren<SelectProps>> = ({
  control,
  className,
  label,
  name,
  variant,
  defaultValue,
  fullWidth,
  children,
  errorsPath,
  required,
  disabled,
  onChange,
  helperText,
}) => {
  const { errors } = useFormState()
  const error = get(errors, name)
  return (
    <Box pb={1}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        render={({ field }) => (
          <>
            <FormControl style={{ width: '100%' }} variant={variant ?? 'filled'}>
              <InputLabel htmlFor={`${name}-select`} required={required ?? false}>
                {label}
              </InputLabel>
              <SelectMUI
                id={`${name}-select`}
                disabled={disabled ?? false}
                {...field}
                label={label}
                onChange={onChange ? onChange : field.onChange}
                variant={variant ?? 'filled'}
                className={className}
                fullWidth={fullWidth ?? true}
              >
                {children}
              </SelectMUI>
            </FormControl>
          </>
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && error.message && <ErrorHelperText error={error.message} />}
    </Box>
  )
}

interface SelectMultiProps {
  control: Control<any>
  className?: string | undefined
  label: string
  name: string
  variant?: 'standard' | 'filled' | 'outlined'
  defaultValue?: string[]
  fullWidth?: boolean
  errorsPath?: string
  required?: boolean
  disabled?: boolean
}
export const SelectMulti: React.FC<React.PropsWithChildren<SelectMultiProps>> = ({
  control,
  className,
  label,
  name,
  variant,
  defaultValue,
  fullWidth,
  children,
  errorsPath,
  required,
  disabled,
}) => {
  const { errors } = useFormState()
  const error = get(errors, name)
  return (
    <Box pb={2}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        render={({ field }) => (
          <>
            <InputLabel required={required ?? false}>{label}</InputLabel>
            <SelectMUI
              disabled={disabled ?? false}
              {...field}
              onChange={(change) => {
                field.onChange(change.target.value)
              }}
              variant={variant ?? 'filled'}
              className={className}
              fullWidth={fullWidth ?? true}
              multiple
            >
              {children}
            </SelectMUI>
          </>
        )}
      />
      {error && error.message && <ErrorHelperText error={error.message} />}
    </Box>
  )
}
