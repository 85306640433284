import { Button, Box } from '@mui/material'
import React from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { Page as P } from '@griegconnect/krakentools-react-ui'
import { Services } from '@app/common/ServicesWrapper/types'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import * as Form from '@griegconnect/krakentools-form'
import { ProfileAttributesForm } from '.'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'

interface UserPINProps {
  services: Services
  form: UseFormReturn<ProfileAttributesForm.Inputs>
  refresh: () => void
  pinCodeRequired?: boolean
}

export const UserPin: React.FC<React.PropsWithChildren<UserPINProps>> = ({
  services,
  form,
  pinCodeRequired,
  refresh,
}) => {
  const { enqueue } = useAlerts()

  const updateProfile = async () => {
    const inputForm = ProfileAttributesForm.toForm(form.getValues())
    try {
      await services.userApi.updateProfile(
        inputForm.idDocument,
        inputForm.company,
        inputForm.symmetryBlue?.toString() || undefined,
        inputForm.pin,
        inputForm.cardNumbers
      )
      enqueue('Pin number updated!', 'success')
      refresh()
    } catch (err) {
      enqueue('Error updating profile', 'error')
    }
  }

  return (
    <FormProvider {...form}>
      <P.Wrapper title="Pin code">
        <P.Paper>
          <Box m={1} />
          <Form.TextInput
            name="pin"
            label="User PIN"
            control={form.control}
            type="number"
            required={pinCodeRequired || false}
          />
          <Box m={2} />
          <Box display="flex" flex="1" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={updateProfile} endIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        </P.Paper>
      </P.Wrapper>
    </FormProvider>
  )
}

export default UserPin
