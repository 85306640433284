import { AxiosInstance } from 'axios'
import qs from 'qs'
import Page from './Page'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { AreaLink } from './dtos/areaDto'
import { Checkpoints } from './CheckpointApi'
import { Infer, Search as S } from '@app/lib/hooks/useSearch'
import { Contracts } from './ContractsApi'
import { ChipProps } from '@mui/material'

export namespace Openpath {
  export interface Credentials {
    username: string
    password: string
    namespace: string
    organization: number
    region: string
    apiKey?: string
  }

  export interface Settings{
    mobileCredentialType?: number
    pinCredentialType?: number
    pinLength?: number
    webhookId?: number
    cardholderSync: string | null
  }

  export interface Installation {
    id: string
    port: ITenant
    credentials: Credentials | null
    settings: Settings
  }

  export interface Group {
    id: number
    name: string
    description: string | null
  }

  export interface GroupForm {
    name: string
    description?: string
    badgeConfigId?: string
  }

  export interface CredentialType{
    id: number
    name: string
    description: string
    modelName: string
  }

  export interface Identity {
    name: string | null
    email: string
    emailVerified: boolean
  }

  export interface User {
    id: number
    identity: Identity
    groups: number[]
    createdAt: string
    imgUrl: string | null
    startDate?: string
    endDate?: string
    status: UserStatus.Types
    data: {
      hasRemoteUnlock: boolean
      isOverrideAllowed: boolean
    }
  }

  export namespace UserStatus {
    export type Types = 'A' | 'I' | 'S'

    export interface ValueType {
      id: UserStatus.Types
      label: string
      color: ChipProps['color']
    }

    export interface OptionType {
      id: UserStatus.Types
      label: string
    }
  }
  export const StatusValues: UserStatus.ValueType[] = [
    {
      id: 'A',
      label: 'Active',
      color: 'success',
    },
    {
      id: 'S',
      label: 'Inactive',
      color: 'warning',
    },
    {
      id: 'I',
      label: 'Deleted',
      color: 'default',
    },
  ]

  export const StatusOptions: UserStatus.OptionType[] = [
    {
      id: 'A',
      label: 'Activate',
    },
    {
      id: 'S',
      label: 'Inactivate',
    },
    {
      id: 'I',
      label: 'Delete',
    },
  ]

  export interface Stored {
    user: User
    contract: Contracts.Link | null
    installation: string
  }

  export type Status = 'active' | 'inactive' | 'deleted'

  export interface UserForm {
    identity: UserFormIdentity
    startDate?: string
    endDate?: string
    status?: Status
    hasRemoteUnlock?: boolean
    contract?: string
  }
  export interface UserFormIdentity {
    email: string
    password?: string
    firstName?: string | null
    middleName?: string | null
    lastName?: string | null
  }

  export interface Entry {
    id: number
    name: string
  }

  export interface Facility {
    accessGroups: number[]
    facility: AreaLink
  }

  export interface Checkpoint {
    id: string
    installation: string
    entries: number[]
    checkpoint: Checkpoints.CheckpointLink
  }

  export interface Data {
    entries: Entry[]
    groups: Group[]
    facilities: Facility[]
    checkpoints: Checkpoint[]
    credentialTypes: CredentialType[]
  }

  export interface CheckpointForm {
    checkpoint: string
    entries: number[]
  }

  export interface FacilityAccessForm {
    accessGroups: number[]
  }

  export interface SetCardHolderContractBulk {
    contract: string
    users: string[]
  }

  export interface SetCardHolderContract {
    contract: string | null
  }

  export interface CardHolderContract {
    contract: Contracts.Link | null
  }
}

export const ParamsSchema = {
  ...Page.schema,
  name: S.array(S.string),
  accessGroup: S.array(S.number),
  active: S.undefine(S.boolean),
  contract: S.array(S.string),
  status: S.array(S.string),
  contractId: S.array(S.string),
}
export type Params = Infer<typeof ParamsSchema>

export class OpenpathApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async installation(tenantRef: string): Promise<Openpath.Installation | null> {
    try {
      const response = await this.http.get(`/ports/${tenantRef}/openpath`)
      return response.data
    } catch (error) {
      if (error.response.status === 404) {
        return null
      } else {
        throw error
      }
    }
  }

  async getData(tenantRef: string): Promise<Openpath.Data | null> {
    try {
      const response = await this.http.get(`/ports/${tenantRef}/openpath/data`)
      return response.data
    } catch (error) {
      if (error.response.status === 404) {
        return null
      } else {
        throw error
      }
    }
  }

  async setCheckpointMapping(tenantRef: string, form: Openpath.CheckpointForm): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/openpath/checkpoints`, form)
  }

  async updateFacility(
    tenantRef: string,
    facility: string,
    updateFacility: Openpath.FacilityAccessForm
  ): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/facilities/${facility}`, updateFacility)
  }

  async updateCredentials(tenantRef: string, form: Openpath.Credentials): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath`, form)
  }

  async updateSettings(tenantRef: string, form: Openpath.Settings): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/settings`, form)
  }

  async getSettings(tenantRef: string): Promise<Openpath.Settings> {
    const response =  await this.http.get(`/ports/${tenantRef}/openpath/settings`)
    return response.data
  }

  async getGroups(tenantRef: string): Promise<Openpath.Group[]> {
    const response = await this.http.get(`/ports/${tenantRef}/openpath/groups`)
    return response.data
  }

  async getGroup(tenantRef: string, groupId: string): Promise<Openpath.Group> {
    const response = await this.http.get(`/ports/${tenantRef}/openpath/groups/${groupId}`)
    return response.data
  }

  async createGroup(tenantRef: string, form: Openpath.GroupForm): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/openpath/groups`, form)
  }

  async updateGroup(tenantRef: string, id: string, form: Openpath.GroupForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/groups/${id}`, form)
  }

  async addUserToGroup(tenantRef: string, groupId: string, cardholderId: string): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/groups/${groupId}/addCardholder/${cardholderId}`)
  }

  async getUsers(tenantRef: string, params: Params, signal?: AbortSignal): Promise<Page<Openpath.User>> {
    const response = await this.http.get(`/ports/${tenantRef}/openpath/cardholders`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
      signal,
    })
    return response.data
  }

  async getUserDetails(tenantRef: string, cardholderId: string): Promise<Openpath.Stored> {
    const response = await this.http.get(`/ports/${tenantRef}/openpath/cardholders/${cardholderId}`)
    return response.data
  }

  async createUser(tenantRef: string, form: Openpath.UserForm): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/openpath/cardholders`, form)
  }

  async updateUser(tenantRef: string, userId: string, form: Openpath.UserForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/cardholders/${userId}`, form)
  }
  async refreshUser(tenantRef: string, userId: number): Promise<void> {
    await this.http.get(`/ports/${tenantRef}/openpath/cardholders/${userId}/refresh`)
  }

  async updateUserGroups(tenantRef: string, userId, groupIds: number[]): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/cardholders/${userId}/groups`, {groups: groupIds})
  }

  async updateUserStatus(tenantRef: string, userId: string, status: Openpath.UserStatus.Types): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/cardholders/${userId}/status`, { status: status })
  }

  async setContract(tenantRef: string, id: string, form: Openpath.SetCardHolderContract): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/cardholders/${id}/contract`, form)
  }

  async setContractBulk(tenantRef: string, form: Openpath.SetCardHolderContractBulk): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/openpath/cardholders/setContract`, form)
  }

  async getContract(tenantRef: string, id: string): Promise<Openpath.CardHolderContract> {
    const response = await this.http.get(`/ports/${tenantRef}/openpath/cardholders/${id}/contract`)
    return response.data
  }
}
