import { Autocomplete, Box, MenuItem, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { UiDialog } from '@app/common/UiDialog'
import { Widgets, WidgetOptions } from './Types'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import { DateTime } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as z from 'zod'
import { zDateTime } from '@app/lib/ZodForm'
import { zodResolver } from '@hookform/resolvers/zod'
import * as Form from '@griegconnect/krakentools-form'
import * as TimePeriod from '../components/TimePeriod'
import { Quays } from '@app/common/ServicesWrapper/apis/QuayApi'
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  submit: {
    position: 'absolute',
    left: '-9999px',
    width: '1px',
    height: '1px',
  },
}))

const defaultLabelTransform = (name: string) => {
  const transformed = name
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(/[-_]+/)
    .join(' ')
  const capitalized = transformed.charAt(0).toUpperCase() + transformed.slice(1).toLowerCase()
  return capitalized
}

const getTypeLabel = (typeName: string) => {
  switch (typeName) {
    case 'controls':
      return 'components.dashboard.labels.controlPercentage'
    case 'areaControls':
      return 'components.dashboard.labels.checksCompleted'
    case 'dos':
      return 'components.dashboard.labels.cardApplications'
    case 'quay':
      return 'components.dashboard.labels.quayStatus'
    case 'deviations':
      return 'components.dashboard.labels.deviations'
    case 'map':
      return 'components.dashboard.module.map'
    case 'passings':
      return 'components.dashboard.labels.passings'
    case 'turnaround':
      return 'components.dashboard.labels.turnaround'
    default:
      return defaultLabelTransform(typeName)
  }
}

const getVariantLabel = (variantName: string) => {
  switch (variantName) {
    case 'value':
      return 'components.dashboard.module.value'
    case 'progress':
      return 'components.dashboard.module.progress'
    case 'linechart':
      return 'components.dashboard.module.linechart'
    case 'trend':
      return 'components.dashboard.module.trend'
    case 'map':
      return 'components.dashboard.module.map'
    default:
      return defaultLabelTransform(variantName)
  }
}

const getSubVariantLabel = (subVariantName: string) => {
  switch (subVariantName) {
    case 'persons':
      return 'security.labels.persons'
    case 'vehicles':
      return 'security.labels.vehicles'
    case 'quays':
      return 'security.labels.quays'
    case 'facilities':
      return 'security.labels.facilities'
    case 'personChecks':
      return 'checks.module.personChecks'
    case 'vehicleChecks':
      return 'checks.module.vehicleChecks'
    case 'quayChecks':
      return 'checks.module.quayChecks'
    case 'facilityChecks':
      return 'checks.module.facilityChecks'
    case 'vesselChecks':
      return 'checks.module.vesselChecks'
    case 'created':
      return 'applications.status.created'
    case 'invited':
      return 'applications.status.invited'
    case 'awaiting_cso':
      return  'applications.status.awaitingCSO'
    case 'in_review':
      return 'applications.status.inReview'
    case 'require_more_information':
      return 'applications.status.requestingMoreInfo'
    case 'ready_for_pickup':
      return  'applications.actions.readyForPickup'
    case 'in_production':
      return 'applications.status.inProduction'
    case 'completed':
      return 'applications.status.completed'
    case 'rejected':
      return 'applications.status.rejected'
    default:
      return defaultLabelTransform(subVariantName)
  }
}

namespace WidgetForm {
  export const Schema = z.object({
    type: z.string(),
    variant: z.string(),
    subVariant: z.string(),
    groupings: z.array(z.string().optional()),
    facilities: z.array(z.string()),
    quay: z.string(),
    from: zDateTime.nullable(),
    to: zDateTime.nullable(),
    currentPeriod: z.boolean(),
    trendPositiveValue: z.string(),
    name: z.string(),
  })

  export type Inputs = z.infer<typeof Schema>

  export const defaultValues = (): Inputs => ({
    type: '',
    variant: '',
    subVariant: '',
    groupings: [],
    facilities: [],
    quay: '',
    from: null,
    to: null,
    currentPeriod: false,
    trendPositiveValue: '',
    name: '',
  })

  export const fromConstructor = (
    constructor: Widgets.Constructor,
    typeOptions: WidgetOptions.Type[],
    trendPositiveValues: WidgetOptions.Select[],
    quays: Quays.Quay[]
  ): Inputs => {
    const tpe = typeOptions.find((t) => t.name === constructor.type)
    const variant = tpe?.variants.find((v) => v.name === constructor.variant)
    const subVariant = variant?.subVariants.find((v) => v.name === constructor.subVariant)
    const dates = constructor.timeFrom
      ? TimePeriod.getConstructorDates(constructor.timeFrom, constructor.currentPeriod, constructor.timeTo)
      : undefined
    const trendPositiveValue = trendPositiveValues.find((v) => v.name.toLowerCase() === constructor.positiveValue)
    const quay = constructor.quay ? quays.find((q) => q.name === constructor.quay) : undefined
    return {
      type: tpe?.id || '',
      variant: variant?.id || '',
      subVariant: subVariant?.id || '',
      groupings: constructor.grouping || [],
      facilities: constructor.facilities || [],
      quay: quay?.id || '',
      from: dates?.fromDate || null,
      to: dates?.toDate || null,
      currentPeriod: constructor.currentPeriod || false,
      trendPositiveValue: trendPositiveValue?.id ? `${trendPositiveValue.id}` : '',
      name: constructor.name || '',
    }
  }

  export const toForm = async (
    inputs: Inputs,
    typeOptions: WidgetOptions.Type[],
    trendPositiveValues: WidgetOptions.Select[],
    areas: IAreaDto[],
    quays: Quays.Quay[]
  ): Promise<Widgets.Constructor> => {
    const positiveValue = inputs.trendPositiveValue
      ? trendPositiveValues.find((v) => v.id === +inputs.trendPositiveValue)!.name.toLowerCase()
      : undefined
    const alignedDates =
      inputs.from && inputs.currentPeriod ? TimePeriod.alignDates(inputs.from, inputs.to || undefined) : undefined
    const type = typeOptions.find((t) => t.id === inputs.type)
    const variant = type?.variants.find((v) => v.id === inputs.variant)
    const subVariant = variant?.subVariants.find((v) => v.id === inputs.subVariant)
    const quay = inputs.quay.length > 0 ? quays.find((q) => q.id === inputs.quay)?.name : undefined

    return {
      id: v4(),
      type: type!.name,
      variant: variant!.name,
      subVariant: subVariant ? subVariant.name : undefined,
      grouping:
        inputs.groupings.length === 0 || !!inputs.groupings.find((g) => g === 'All available')
          ? undefined
          : (inputs.groupings.filter((g) => !!g) as string[]),
      facilities:
        inputs.facilities.length === areas.length || inputs.facilities.length === 0 ? undefined : inputs.facilities,
      quay,
      timeFrom: alignedDates
        ? alignedDates.from.diffNow().negate()
        : !!inputs.from
        ? inputs.from.diffNow().negate()
        : undefined,
      timeTo: alignedDates
        ? alignedDates.to.diffNow().negate()
        : !!inputs.to
        ? inputs.to.diffNow().negate()
        : undefined,
      currentPeriod: inputs.currentPeriod,
      positiveValue,
      name: inputs.name.length > 0 ? inputs.name : undefined,
    }
  }
}

interface IWidgetDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (data: Widgets.Constructor, id?: string) => void
  typeOptions: WidgetOptions.Type[]
  areas: IAreaDto[]
  quays: Quays.Quay[]
  widgetConstructor?: Widgets.Constructor
}

export const WidgetDialog: React.FC<React.PropsWithChildren<IWidgetDialogProps>> = ({
  open,
  onClose,
  onConfirm,
  typeOptions,
  areas,
  quays,
  widgetConstructor,
}) => {
  const classes = useStyles()
  const now = DateTime.now()
  const { t } = useTranslation()

  const [options, setOptions] = useState<WidgetOptions.Type[]>([])

  const trendPositiveValues: WidgetOptions.Select[] = [
    {
      id: 1,
      name: t('common.labels.up'),
    },
    {
      id: 2,
      name: t('common.labels.down'),
    },
  ]

  const form = useForm({
    defaultValues: widgetConstructor
      ? WidgetForm.fromConstructor(widgetConstructor, typeOptions, trendPositiveValues, quays)
      : WidgetForm.defaultValues(),
    resolver: zodResolver(WidgetForm.Schema),
  })

  const type = form.watch('type')
  const variant = form.watch('variant')
  const subVariant = form.watch('subVariant')
  const groupings = form.watch('groupings')
  const facilities = form.watch('facilities')
  const quay = form.watch('quay')
  const from = form.watch('from')
  const to = form.watch('to')
  const currentPeriod = form.watch('currentPeriod')
  const trendPositiveValue = form.watch('trendPositiveValue')
  const name = form.watch('name')


  const selectedType = type ? options.find((t) => t.id === type) : undefined
  const selectedVariant =
    selectedType?.variants?.length === 1
      ? selectedType.variants[0]
      : variant && selectedType
      ? selectedType.variants.find((v) => v.id === variant)
      : undefined
  const selectedSubVariant =
    selectedVariant?.subVariants?.length === 1
      ? selectedVariant.subVariants[0]
      : subVariant && selectedVariant
      ? selectedVariant.subVariants.find((v) => v.id === subVariant)
      : undefined

  useEffect(() => {
    !options.length && setOptions(typeOptions)
  }, [typeOptions]) // eslint-disable-line

  useEffect(() => {
    const defaultVariant = selectedType?.variants?.length === 1 ? selectedType.variants[0].id : undefined
    defaultVariant && form.setValue('variant', defaultVariant)
  }, [type, form.setValue]) // eslint-disable-line

  useEffect(() => {
    const defaultSubVariant = selectedVariant?.subVariants?.length === 1 ? selectedVariant.subVariants[0].id : undefined
    defaultSubVariant && form.setValue('subVariant', defaultSubVariant)
  }, [variant, form.setValue]) // eslint-disable-line

  useEffect(() => {
    if (currentPeriod && from) {
      const newDates = TimePeriod.currentPeriod(from, to || undefined)
      form.setValue('from', newDates.from)
      form.setValue('to', newDates.to)
    }
  }, [currentPeriod, form.setValue]) // eslint-disable-line

  const onGroupingsChange = (newValue: Widgets.Grouping[]) => {
    if (newValue.length > 0) {
      const lastValue = newValue[newValue.length - 1]
      const isAll = lastValue.name === 'All available'
      const newGroupings = (newValue || [])
        .filter((g) => (isAll && g.name === 'All available') || (!isAll && g.name !== 'All available'))
        .map((g) => g.name)
      return newGroupings
    }
    return newValue
  }

  const onFacilityOptionsChange = (newValue: IAreaDto[]) => {
    const newFacilities = (newValue || []).filter((f) => f.name.length > 0).map((f) => f.name)
    return newFacilities
  }

  const onSubmit = async () => {
    const formData: WidgetForm.Inputs = {
      type,
      variant,
      subVariant,
      groupings,
      facilities,
      quay,
      from,
      to,
      currentPeriod,
      trendPositiveValue,
      name,
    }
    const form = await WidgetForm.toForm(formData, options, trendPositiveValues, areas, quays)
    onConfirm(form, widgetConstructor?.id)
  }

  const getPeriodLabel = () => {
    if (from) {
      const diff = from
        .diff(to || now)
        .negate()
        .as('days')
      const periodLabel =
        diff > 32
          ? t('components.dashboard.paragraphs.lockToPeriodYear')
          : diff > 8
            ? t('components.dashboard.paragraphs.lockToPeriodMonth')
            : t('components.dashboard.paragraphs.lockToPeriodWeek')
      return periodLabel
    }
    return t('components.dashboard.paragraphs.lockToPeriodGeneric')
  }

  return (
    <FormProvider {...form}>
      <form id="widgetForm" onSubmit={() => onSubmit()}>
        <UiDialog
          title={widgetConstructor ? t('components.dashboard.actions.editWidget') : t('components.dashboard.actions.addWidget') }
          text={t('components.dashboard.paragraphs.widgetDialogfillOutDetails')}
          onCancel={onClose}
          onConfirm={() => onSubmit()}
          open={open}
          confirmButton="Confirm"
          disabled={
            !selectedType ||
            !selectedVariant ||
            ((selectedVariant?.subVariants || []).length > 0 && !selectedSubVariant) ||
            (!!selectedVariant?.quay && quay.length === 0)
          }
        >
          <Box m={2} />
          <Form.Select name="type" control={form.control} label={t('components.dashboard.module.type')} required disabled={!!widgetConstructor}>
            {options
              .filter((o) => !o.disabled)
              .map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {t(getTypeLabel(o.name))}
                </MenuItem>
              ))}
          </Form.Select>
          <Box m={2} />
          <Form.Select
            name="variant"
            control={form.control}
            label={t('components.dashboard.module.variant')}
            required
            disabled={!!widgetConstructor || !type || (selectedType?.variants || []).length < 2}
          >
            {(selectedType?.variants || []).map((v) => (
              <MenuItem key={v.id} value={v.id}>
                {t(getVariantLabel(v.name))}
              </MenuItem>
            ))}
          </Form.Select>
          <Box m={2} />
          {(selectedVariant?.subVariants || []).length > 0 && (
            <>
              <Form.Select
                name="subVariant"
                control={form.control}
                label={t('components.dashboard.module.subVariant')}
                required
                disabled={(selectedVariant?.subVariants || []).length < 2}
              >
                {(selectedVariant?.subVariants || []).map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    {t(getSubVariantLabel(v.name))}
                  </MenuItem>
                ))}
              </Form.Select>
              <Box m={2} />
            </>
          )}
          {(selectedVariant?.groupings || []).length > 0 && (
            <>
              <Controller
                name="groupings"
                control={form.control}
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    id="groupings"
                    options={(selectedVariant?.groupings || []).filter(
                      (g) => groupings.find((wg) => wg === g.name) === undefined
                    )}
                    value={(selectedVariant?.groupings || []).filter(
                      (g) => groupings.find((wg) => wg === g.name) !== undefined
                    )}
                    getOptionLabel={(option: Widgets.Grouping) => option.name}
                    onChange={(e, value) => field.onChange(onGroupingsChange(value))}
                    renderInput={(params) => <TextField {...params} required variant="standard" label="Groups" />}
                  />
                )}
              />
              <Box m={2} />
            </>
          )}
          {selectedVariant?.facilities && areas.length > 0 && (
            <>
              <Controller
                name="facilities"
                control={form.control}
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    id="facilities"
                    options={areas.filter((f) => facilities.find((wf) => wf === f.name) === undefined)}
                    value={areas.filter((f) => facilities.find((wf) => wf === f.name) !== undefined)}
                    getOptionLabel={(option: IAreaDto) => option.name}
                    onChange={(e, value) => field.onChange(onFacilityOptionsChange(value))}
                    renderInput={(params) => <TextField {...params} required variant="standard" label={t('security.labels.facilities')}/>}
                  />
                )}
              />
              <Box m={2} />
            </>
          )}
          {selectedVariant?.quay && quays.length > 0 && (
            <>
              <Form.Select name="quay" control={form.control} label="Quay" required>
                {(quays || []).map((q) => (
                  <MenuItem key={q.id} value={q.id}>
                    {defaultLabelTransform(q.name)}
                  </MenuItem>
                ))}
              </Form.Select>
              <Box m={2} />
            </>
          )}
          {selectedVariant?.time && (
            <>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Controller
                  name="from"
                  control={form.control}
                  render={({ field }) => (
                    <DatePicker
                      disabled={currentPeriod}
                      onChange={() => {}}
                      onAccept={field.onChange}
                      format="dd.MM.yyyy"
                      value={field.value}
                      disableFuture={true}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                          label: t('common.labels.timeFrom'),
                          fullWidth: true,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Box m={2} />
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Controller
                  name="to"
                  control={form.control}
                  render={({ field }) => (
                    <DatePicker
                      disabled={!from || currentPeriod}
                      onChange={() => {}}
                      onAccept={field.onChange}
                      format="dd.MM.yyyy"
                      value={field.value && field.value > now ? now : field.value}
                      disableFuture={true}
                      slotProps={{
                        textField: {
                          variant: 'filled',
                          label: t('common.labels.timeTo'),
                          fullWidth: true,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Box m={1} />
              {!!from && <Form.Checkbox name="currentPeriod" control={form.control} label={getPeriodLabel()} />}
              <Box pb={1} />
            </>
          )}
          {selectedVariant?.positiveValue && (
            <>
              <Form.Select name="trendPositiveValue" control={form.control} label="Positive change">
                {trendPositiveValues.map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Form.Select>
              <Box m={2} />
            </>
          )}
          <Form.TextInput name="name" control={form.control} label={t('components.dashboard.module.customName')} />
          <input type="submit" tabIndex={-1} className={classes.submit} />
        </UiDialog>
      </form>
    </FormProvider>
  )
}

export default WidgetDialog
