import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPersonAdd = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      fillRule="evenodd"
      d="M11 8c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4Zm6 0c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2ZM15.029 13h-.058c-.978 0-1.954 0-2.844.082-.899.084-1.79.256-2.574.649a4.148 4.148 0 0 0-1.92 1.94C7.199 16.556 7 17.66 7 19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1c0-1.34-.2-2.443-.633-3.33a4.147 4.147 0 0 0-1.92-1.94c-.784-.392-1.675-.564-2.574-.648C16.983 13 16.007 13 15.03 13Zm5.541 3.549c.173.353.31.822.38 1.451H9.05c.07-.629.207-1.098.38-1.451a2.15 2.15 0 0 1 1.017-1.03c.466-.232 1.075-.372 1.864-.445C13.1 15 13.988 15 15 15s1.9 0 2.69.074c.788.073 1.397.213 1.863.445.44.22.775.534 1.017 1.03Z"
      clipRule="evenodd"
    />,
    <path d="M5 7a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2H6v2a1 1 0 1 1-2 0v-2H2a1 1 0 1 1 0-2h2V8a1 1 0 0 1 1-1Z" />
  )
export default SvgPersonAdd
