import React from 'react'
import {
  Navigation,
  requiresTenant,
  requiresAllOfPermissions,
  requiresPermission,
} from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, PERMISSIONS, Permissions } from '@app/constants'
import { withServices } from '../withServices'
import { withActiveTenant } from '../WithActiveTenant'
import { Settings } from '@griegconnect/krakentools-react-icons'
import { stanleyModuleNavs } from './stanley/stanleyModuleNavs'
import { saltoModuleNavs } from './salto/saltoModuleNavs'
import { visyModuleNavs } from './visy/visyModuleNavs'
import { westecModuleNavs } from './westec/westecModuleNavs'
import { withParam } from '../withParam'
import { AccessDependencies } from '@app/Content'
import { administratorsModuleNavs } from './Administrators/administratorsModuleNavs'
import { coursesModuleNavs } from './courses/coursesModuleNavs'
import { isPort } from '@app/lib/access'
import { openpathModuleNavs } from './Openpath/openpathModuleNavs'
import { facilitiesModuleNavs } from './Facilities/facilitiesModuleNavs'
import { CheckLists } from './Checklist/List'
import { NewCheckList } from './Checklist/New'
import { ViewCheckList } from './Checklist/View'
import { avaModuleNavs } from './Ava/avaModuleNavs'
import { bewatorModuleNavs } from './Bewator/bewatorModuleNavs'
import { stripeModuleNavs } from './Stripe/stripeModuleNavs'
import { Trans } from 'react-i18next'

const PATH = PATH_TENANT_BASE + '/settings'
export const settingsModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.global.settings" />,
    path: PATH,
    exact: true,
    menu: {
      icon: <Settings />,
    },
    access: (d) =>
      requiresTenant(d) &&
      ((isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.notifications), true)(d)) ||
        (isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.security_check_result), true)(d)) ||
        (isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.security_confirmation), true)(d)) ||
        (isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.purposes), true)(d)) ||
        (isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.facilities), true)(d)) ||
        (isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.checkpoints), true)(d)) ||
        (isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.port), true)(d)) ||
        (isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.vessels), true)(d)) ||
        (isPort(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.checklists), true)(d))),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.settings.configuration" />,
        path: PATH + '/configuration',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./Configuration/index'))))),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.port), true)(d),
      },
      ...facilitiesModuleNavs,
      {
        name: <Trans i18nKey="navigation.settings.notifications" />,
        path: PATH + '/notifications',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./CompanyNotifications'))))),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) &&
          requiresTenant(d) &&
          isPort(d) &&
          requiresAllOfPermissions(Permissions.all(PERMISSIONS.notifications), true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.checkResults" />,
        path: PATH + '/results',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./security/SecurityCheckResult'))))
        ),
        menu: true,
        access: (d) =>
          isPort(d) &&
          requiresTenant(d) &&
          isPort(d) &&
          requiresAllOfPermissions(Permissions.all(PERMISSIONS.security_check_result), true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.securityConfirmations" />,
        path: PATH + '/confirmations',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./security/SecurityConfirmationList'))))
        ),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) &&
          requiresTenant(d) &&
          isPort(d) &&
          requiresAllOfPermissions(Permissions.all(PERMISSIONS.security_confirmation), true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.purposes" />,
        path: PATH + '/purposes',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./Purposes/ListPurposes'))))),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.purposes), true)(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.settings.purpose" />,
            path: PATH + '/purposes/edit/:id',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./Purposes/EditPurpose')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              isPort(d) &&
              requiresTenant(d) &&
              requiresAllOfPermissions(Permissions.all(PERMISSIONS.purposes), true)(d),
          },
          {
            name: <Trans i18nKey="navigation.settings.purpose" />,
            path: PATH + '/purposes/new',
            page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./Purposes/New'))))),
            exact: true,
            access: (d) =>
              isPort(d) &&
              requiresTenant(d) &&
              requiresAllOfPermissions(Permissions.all(PERMISSIONS.purposes), true)(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.settings.companiesOnSite" />,
        path: PATH + '/companies-on-site',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./CompaniesOnSite/List'))))),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.frontend_companies.read, true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.addCompany" />,
        path: PATH + '/companies-on-site/add',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./CompaniesOnSite/Add'))))),
        exact: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.frontend_companies.read, true)(d),
      },
      ...administratorsModuleNavs,
      {
        name: <Trans i18nKey="navigation.settings.checklists" />,
        path: PATH + '/checklists',
        page: React.createElement(withServices(withActiveTenant(CheckLists))),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.checklists), true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.newChecklist" />,
        path: PATH + '/checklists/new',
        page: React.createElement(withServices(withActiveTenant(NewCheckList))),
        exact: true,
        access: (d) =>
          isPort(d) &&
          requiresTenant(d) &&
          requiresAllOfPermissions([PERMISSIONS.checklists.read, PERMISSIONS.checklists.create], true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.editChecklist" />,
        path: PATH + '/checklists/:id',
        page: React.createElement(withServices(withParam(withActiveTenant(ViewCheckList), 'id'))),
        exact: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.checklists), true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.vehicleCategories" />,
        path: PATH + '/vehicle-categories',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./VehicleCategories'))))),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.port), true)(d),
      },
      ...coursesModuleNavs,
      ...stanleyModuleNavs,
      ...saltoModuleNavs,
      ...visyModuleNavs,
      ...westecModuleNavs,
      ...openpathModuleNavs,
      ...avaModuleNavs,
      ...bewatorModuleNavs,
      ...stripeModuleNavs,
    ],
  },
]
