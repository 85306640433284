import { AxiosInstance } from 'axios'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { Checkpoints } from './CheckpointApi'
import { IAreaDto } from './dtos/areaDto'
import { PortCompany } from './dtos/companyDto'
import { IPurposeDto } from './dtos/purposeDto'
import { ISecurityCheckResultsDto } from './dtos/securityCheckResultsDto'
import { ISecurityConfirmationDto } from './dtos/securityConfirmationsDto'
import { ResolvedUser } from './dtos/userDto'
import { DateTime } from 'luxon'

export interface IPortDetailsDto {
  port: ITenant
  attributes: PortAttributes
  areas: IAreaDto[]
  integrations: Integrations
  checkpoints: Checkpoints.Checkpoint[]
  port_companies: PortCompany[]
  purposes: IPurposeDto[]
  securityCheckResults: ISecurityCheckResultsDto
  securityConfirmations: ISecurityConfirmationDto[]
}

export interface Integrations {
  salto: boolean
  stanley: boolean
  visy: boolean
  westec: boolean
  openpath: boolean
  vehicles: boolean
  ava: boolean
  bewator: boolean
  stripe: boolean
}

export interface PortData {
  tenant: ITenant
  attributes: PortAttributes
}

export interface PortAttributes {
  approvedCompanies?: boolean
  courses?: boolean
  enableOneTime?: boolean
  enforceOneTime?: boolean
  disableVessel?: boolean
  idDocumentRequired?: boolean
  applicationTrailerInput?: boolean
  applicationSubcontractorInput?: boolean
  physicalCards?: boolean
  cardManufacturerEmail?: string
  showPinInPassings?: boolean
  pinFormat?: string
  showPinInPermits?: boolean
  maxVisitTimeInHours?: number
  invoicePeriod?: 'monthly' | 'quarterly' | 'yearly'
  invoiceDate: DateTime | null
  checkpointForAccess?: boolean
  nextGenVisitorApplication?: boolean
  advancedContractScheduling?: boolean
  nextGenUserPhotoOverId?: boolean
  contractsFromPortcall?: boolean
  notifyUserOnApplicationSubmission?: boolean
}

export interface PortConfig {
  passingsRetention: number | null
  applications: boolean
  attributes: PortAttributes | null
  isPort: boolean
  visy: boolean
  stanley: boolean
  salto: boolean
  westec: boolean
  openpath: boolean
  vehicles: boolean
  ava: boolean
  bewator: boolean
  stripe: boolean
  vehicleCategories: VehicleCategory[]
}

export interface VehicleCategory {
  id: string
  name: string
  from: number
  to: number
}

export interface VehicleCategoryForm {
  name: string
  from: number
  to: number
}

export interface PortParams {
  applications?: boolean
  id?: string | null
}

export interface LookupParams {
  email?: string
  phoneNumber?: string
}

export class SharedDataApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async getPorts(params: PortParams): Promise<PortData[]> {
    const response = await this.httpClient.get('/ports', { params })
    return response.data.ports
  }

  async getPortDetails(tenantRef: string): Promise<IPortDetailsDto> {
    const response = await this.httpClient.get(`/ports/${tenantRef}`)
    return response.data
  }

  async getPortTimeZone(tenantRef: string): Promise<string> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/attributes/timezone`)
    return response.data
  }

  async updateConfig(tenantRef: string, form: PortConfig): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/config`, form)
  }

  async getVehicleCategories(tenantRef: string): Promise<VehicleCategory[]> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/vehicle_categories`)
    return response.data
  }

  async upsertVehicleCategories(tenantRef: string, form: VehicleCategoryForm[]): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/vehicle_categories`, form)
  }

  async updateVehicleCategory(tenantRef: string, id: string, form: VehicleCategoryForm): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/vehicle_categories/${id}`, form)
  }

  async deleteVehicleCategory(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.delete(`/ports/${tenantRef}/vehicle_categories/${id}`)
  }

  async getConfig(tenantRef: string): Promise<PortConfig> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/config`)
    return response.data
  }

  async lookup(tenantRef: string, params: LookupParams): Promise<ResolvedUser[]> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/lookup`, { params })
    return response.data
  }
}
