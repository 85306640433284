import { AxiosInstance, AxiosResponse } from 'axios'
import { Checkpoints } from './CheckpointApi'
import { IAreaDto } from "./dtos/areaDto"
import { Infer, Search as S } from '@app/lib/hooks/useSearch'

interface IMobileAccess {
  zoneId: string;
  timezoneTableId: number;
  enabled: boolean;
}

interface IShipForm {
  mobileAccess?: IMobileAccess;
}

interface IShip {
  credentials: ISaltoCredentials;
}

interface IShipZone {
  extId: string;
  name: string;
  description?: string;
}

export interface IShipTimeZoneTable {
  id: number;
  name: string;
  description?: string;
}

export interface ISaltoInstallationForm {
  timezone: string;
  companyNameKey?: string;
  ship?: IShipForm;
  autoCreateUser: boolean;
}

export interface SaltoInstallationDetails {
  installation: ISaltoInstallation
  readers: ISaltoCheckpoint[]
  facilities: SaltoFacilityAccess[]
}

export interface ISaltoInstallation {
  timezone: string;
  companyNameKey?: string;
  ship: IShip | null;
  autoCreateUser: boolean;
}

export interface ISaltoCredentials {
  uri: string;
  username: string;
  password: string;
}

export interface ISaltoCheckpointForm {
  id: string;
  name: string;
  checkpoint: string;
  operation: number;
}

export interface ISaltoCheckpoint {
  id: string;
  name: string;
  checkpoint: Checkpoints.CheckpointLink;
  operation: number;
}

export interface ISaltoInfo {
  protocolId: string
  protocolVersion: string
}

export interface ISaltoDoor {
  id: string
  name: string
  description: string | null
  GPF1: string | null
  GPF2: string | null
}

export interface ISaltoDoorLink {
  id: string,
  timezoneTableId: number,
  usePeriod: boolean,
  period: ISaltoPeriod
}

export interface ISaltoZones {
  extId: string
  name: string
  description: string | null
}

export interface SaltoFacilityAccess {
  facility: IAreaDto
  accessGroups: string[]
}

export interface SaltoFacilityAccessForm {
  accessGroups: string[]
}

type GPF = string | null

export interface ISaltoUserDetails {
  extId: string
  title?: string
  firstName: string
  lastName: string
  GPF1?: GPF
  GPF2?: GPF
  GPF3?: GPF
  GPF4?: GPF
  GPF5?: GPF
  currentKeyExists: boolean
  banned: boolean
  mobileAppType: number
  mobile?: string
  companyName?: string
  access?: ISaltoGroupLink[] | []
}

export interface ISaltoUser {
  id: string
  underlying: ISaltoUserDetails
}

export interface ISaltoUserForm {
  id?: string
  extId?: string
  title?: string
  firstName: string
  lastName: string
  GPF1?: GPF
  GPF2?: GPF
  GPF3?: GPF
  GPF4?: GPF
  GPF5?: GPF
  currentKeyExists: boolean
  banned: boolean
  mobileAppType: number
  mobile?: string
  companyName?: string
  access?: ISaltoGroupLink[] | []
}

export interface ISaltoMember {
  id: string
  usePeriod: boolean
  period: ISaltoPeriod
}

export interface ISaltoPeriod {
  start: string
  end: string
}

export interface ISaltoGroup {
  id: string,
  name: string,
  description: string | null,
  zones: ISaltoZones[],
  doors: ISaltoDoorLink[],
  members: ISaltoMember[]
}

export interface ISaltoGroupLink {
  group: string,
  period: string
}

export interface ISaltoData {
  zones: IShipZone[]
  timezoneTables: IShipTimeZoneTable[]
  doors: ISaltoDoor[]
}

export const ParamsSchema = {
  name: S.array(S.string),
  justinKey: S.undefine(S.boolean)
}
export type Params = Infer<typeof ParamsSchema>

export interface SetUserContractBulk {
  contract: string
  users: string[]
}

export class SaltoApi {
  private http: AxiosInstance;

  constructor(http: any) {
    this.http = http;
  }

  async installation(tenantRef: string): Promise<SaltoInstallationDetails | null> {
    try {
      const response = await this.http.get(`/ports/${tenantRef}/salto`)
      return response.data
    } catch (error) {
      if (error.response.status === 404) {
        return null
      } else {
        throw error
      }
    }
  }

  async updateInstallation(tenantRef: string, form: ISaltoInstallationForm): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/salto`, form);
    return response.data.id;
  }

  async updateCredentials(tenantRef: string, form: ISaltoCredentials): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/salto/set-credentials`, form);
    return response.data.id;
  }

  async deleteInstallation(tenantRef: string) {
    await this.http.delete(`/ports/${tenantRef}/salto`);
  }

  async readers(tenantRef: string): Promise<ISaltoCheckpoint[]> {
    const response = await this.http.get(`/ports/${tenantRef}/salto/readers`);
    return response.data.readers;
  }

  async addReader(tenantRef: string, form: ISaltoCheckpointForm): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/salto/readers`, form);
    return response.data.id;
  }

  async deleteReader(tenantRef: string, id: string) {
    await this.http.delete(`/ports/${tenantRef}/salto/readers/${id}`);
  }

  async getInfo(tenantRef: string): Promise<ISaltoInfo> {
    const response: AxiosResponse<ISaltoInfo> = await this.http.get(`/ports/${tenantRef}/salto/ship/info`);
    return response.data;
  }

  async getUsers(tenantRef: string, appType?: number, contract?: string): Promise<ISaltoUser[]> {
    const response: AxiosResponse<{ "users": ISaltoUser[] }> = await this.http.get(`/ports/${tenantRef}/salto/ship/users`, { params: { appType, contract } });
    return response.data.users;
  }

  async setContractBulk(tenantRef: string, form: SetUserContractBulk): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/salto/ship/users/set-contract`, form)
  }

  async getUser(tenantRef: string, userId: string): Promise<ISaltoUser> {
    const response: AxiosResponse<ISaltoUser> = await this.http.get(`/ports/${tenantRef}/salto/ship/users/${userId}`);
    return response.data;
  }

  async deleteUser(tenantRef: string, userId: string): Promise<ISaltoUser> {
    const response: AxiosResponse<ISaltoUser> = await this.http.delete(`/ports/${tenantRef}/salto/ship/users/${userId}`);
    return response.data;
  }

  async getUserDetails(tenantRef: string, userId: string): Promise<ISaltoUserDetails> {
    const response: AxiosResponse<ISaltoUserDetails> = await this.http.get(`/ports/${tenantRef}/salto/ship/users/edit/${userId}`);
    return response.data;
  }

  async updateUser(tenantRef: string, userId: string, form: ISaltoUserForm): Promise<ISaltoUser> {
    const response: AxiosResponse<ISaltoUser> = await this.http.put(`/ports/${tenantRef}/salto/ship/users/edit/${userId}`, form);
    return response.data;
  }

  async createUser(tenantRef: string, form: ISaltoUserForm): Promise<ISaltoUser> {
    const response: AxiosResponse<ISaltoUser> = await this.http.post(`/ports/${tenantRef}/salto/ship/users`, form);
    return response.data;
  }

  async getGroups(tenantRef: string): Promise<ISaltoGroup[]> {
    const response: AxiosResponse<{ "groups": ISaltoGroup[] }> = await this.http.get(`/ports/${tenantRef}/salto/ship/groups`);
    return response.data.groups;
  }

  async getGroup(tenantRef: string, groupId: string): Promise<ISaltoGroup> {
    const response: AxiosResponse<ISaltoGroup> = await this.http.get(`/ports/${tenantRef}/salto/ship/groups/${groupId}`);
    return response.data;
  }

  async createGroup(tenantRef: string, form: ISaltoGroup): Promise<ISaltoGroup> {
    const response: AxiosResponse<ISaltoGroup> = await this.http.post(`/ports/${tenantRef}/salto/ship/groups`, form);
    return response.data;
  }

  async updateGroup(tenantRef: string, id: string, form: ISaltoGroup): Promise<ISaltoGroup> {
    const response: AxiosResponse<ISaltoGroup> = await this.http.post(`/ports/${tenantRef}/salto/ship/groups/${id}`, form);
    return response.data;
  }

  async getData(tenantRef: string): Promise<ISaltoData> {
    const response = await this.http.get(`/ports/${tenantRef}/salto/ship/data`);
    return response.data;
  }

  async updateFacility(tenantRef: string, facility: string, updateFacility: SaltoFacilityAccessForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/salto/facilities/${facility}`, updateFacility)
  }
}
