import { Fab, Fade, Grid, Modal, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Close from '@mui/icons-material/Close'
import RotateLeft from '@mui/icons-material/RotateLeft'
import RotateRight from '@mui/icons-material/RotateRight'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import ZoomIn from '@mui/icons-material/ZoomIn'
import ZoomOut from '@mui/icons-material/ZoomOut'
import React, { useCallback, useEffect, useState } from 'react'

interface IImageFullscreenProps {
  imgSrc: string
  hideEyeIcon?: boolean
  maxHeight?: number
  maxWidth?: number
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      position: 'fixed',
      top: 32,
      right: 32,
      backgroundColor: 'rgba(104, 104, 104, 0.5)',
      color: 'white',
      zIndex: 10,
    },
    image: {
      width: '100%',
      willChange: 'transform',
    },
    imageContainer: {
      willChange: 'translate',
      position: 'relative',
      top: 80,
    },
    icon: {
      color: '#fff',
      cursor: 'pointer',
    },
  })
)

export const ImageFullScreen: React.FC<React.PropsWithChildren<IImageFullscreenProps>> = (props) => {
  const classes = useStyles()

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [rotation, setRotation] = useState<number>(0)
  const [scale, setScale] = useState<number>(0)
  const [mousePositionX, setMousePositionX] = useState<number>(0)
  const [mousePositionY, setMousePositionY] = useState<number>(0)
  const [imageOffsetX, setImageOffsetX] = useState<number>(0)
  const [imageOffsetY, setImageOffsetY] = useState<number>(0)
  const [prevImageOffsetX, setPrevImageOffsetX] = useState<number>(0)
  const [prevImageOffsetY, setPrevImageOffsetY] = useState<number>(0)
  const [mouseDown, setMouseDown] = useState<boolean>(false)
  const [imageWidth, setImageWidth] = useState<number>(0)
  const [imageHeight, setImageHeight] = useState<number>(0)
  const [imageWidthScaled, setImageWidthScaled] = useState<number>(0)
  const [imageHeightScaled, setImageHeightScaled] = useState<number>(0)
  const [imageRotated, setImageRotated] = useState<boolean>(false)

  const addEventListners = useCallback(() => {
    window.addEventListener('resize', onImgLoadorWindowResize)
    window.addEventListener('keydown', onKeyDown)
  }, [window]) // eslint-disable-line react-hooks/exhaustive-deps
  const removeEventListners = useCallback(() => {
    window.removeEventListener('resize', onImgLoadorWindowResize)
    window.removeEventListener('keydown', onKeyDown)
  }, [window]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    addEventListners()
    return () => {
      removeEventListners()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalToggle = () => {
    setModalIsOpen(!modalIsOpen)
    setScale(1)
    setRotation(0)
    setImageOffsetX(0)
    setImageOffsetY(0)
  }

  const rotateLeft = () => {
    setRotation(rotation - 90)
    setImageRotated(!imageRotated)
    checkPosition()
  }

  const rotateRight = () => {
    setRotation(rotation + 90)
    setImageRotated(!imageRotated)
    checkPosition()
  }

  const scaleUp = () => {
    const newScale = scale * 1.25
    calcImageSize(newScale)
  }

  const scaleDown = () => {
    const newScale = scale * 0.75
    calcImageSize(newScale)
  }

  const onMouseWheel = (evt: any) => {
    const newScale = evt.deltaY < 0 ? scale * 0.95 : scale * 1.05
    calcImageSize(newScale)
  }

  const onMouseDown = (evt: any) => {
    setMousePositionX(evt.clientX)
    setMousePositionY(evt.clientY)
    setMouseDown(true)
  }

  const onMouseMove = (evt: any) => {
    const event = evt
    if (mouseDown && event.buttons > 0) {
      // console.debug('Image: onMouseMove', event.buttons);
      const newPositionX = event.clientX - mousePositionX + prevImageOffsetX
      const newPositionY = event.clientY - mousePositionY + prevImageOffsetY
      setImageOffsetX(newPositionX)
      setImageOffsetY(newPositionY)
    } else {
      setMouseDown(false)
    }
  }

  const onMouseUp = (evt: any) => {
    if (mouseDown) {
      setMouseDown(false)
      setPrevImageOffsetX(imageOffsetX)
      setPrevImageOffsetY(imageOffsetY)
      checkPosition()
    }
  }

  const onKeyDown = (evt: any) => {
    const speed = 25
    if ((evt.key === 'r' && evt.shiftKey) || (evt.key === 'R' && evt.shiftKey)) {
      rotateLeft()
    } else if (evt.key === 'r' || evt.key === 'R') {
      rotateRight()
    } else if (evt.key === '+') {
      scaleUp()
    } else if (evt.key === '-') {
      scaleDown()
    } else if (evt.key === 'ArrowUp') {
      setImageOffsetY(imageOffsetY - speed)
    } else if (evt.key === 'ArrowDown') {
      setImageOffsetY(imageOffsetY + speed)
    } else if (evt.key === 'ArrowLeft') {
      setImageOffsetX(imageOffsetX - speed)
    } else if (evt.key === 'ArrowRight') {
      setImageOffsetX(imageOffsetX + speed)
    }
    checkPosition()
  }

  const checkPosition = () => {
    const posX = imageOffsetX
    const posY = imageOffsetY
    if (!imageRotated) {
      const imageCutH = (imageWidthScaled - imageWidth) / 2
      const imageCutV = (imageHeightScaled - imageHeight) / 2
      if (posX > imageWidthScaled - 20 - imageCutH) {
        setImageOffsetX(imageWidthScaled - 20 - imageCutH)
        setPrevImageOffsetX(imageWidthScaled - 20 - imageCutH)
      }
      if (posX < -imageWidthScaled + 20 + imageCutH) {
        setImageOffsetX(-imageWidthScaled + 20 + imageCutH)
        setPrevImageOffsetX(-imageWidthScaled + 20 + imageCutH)
      }
      if (posY < -imageHeightScaled - 60 + imageCutV) {
        setImageOffsetY(imageWidthScaled - 60 + imageCutH)
        setPrevImageOffsetY(imageWidthScaled - 60 + imageCutH)
      }
      if (posY > window.innerHeight * 0.9 - 100 + imageCutV) {
        setImageOffsetY(window.innerHeight * 0.9 - 100 + imageCutV)
        setPrevImageOffsetY(window.innerHeight * 0.9 - 100 + imageCutV)
      }
    } else {
      // TODO: Må gå igjennom denne
      const imageCutH = (imageHeightScaled - imageHeight) / 2
      // const imageCutH = (imageWidthScaled -imageWidth) / 2;
      const imageCutV = (imageHeightScaled - imageHeight) / 2
      // const windowCutH = (window.innerWidth - imageWidthScaled) / 2;
      if (posX > imageWidthScaled - 20 - imageCutH) {
        setImageOffsetX(imageWidthScaled - 20 - imageCutH)
        setPrevImageOffsetX(imageWidthScaled - 20 - imageCutH)
      }
      if (posX < -imageWidthScaled + 20 + imageCutH) {
        setImageOffsetX(-imageWidthScaled + 20 + imageCutH)
        setPrevImageOffsetX(-imageWidthScaled + 20 + imageCutH)
      }
      if (posY < -imageHeightScaled - 60 + imageCutV) {
        setImageOffsetY(-imageHeightScaled - 60 + imageCutV)
        setPrevImageOffsetY(-imageHeightScaled - 60 + imageCutV)
      }
      if (posY > window.innerHeight * 0.9 - 100 + imageCutV) {
        setImageOffsetY(window.innerHeight * 0.9 - 100 + imageCutV)
        setPrevImageOffsetY(window.innerHeight * 0.9 - 100 + imageCutV)
      }
    }
  }

  const calcImageSize = (newScale) => {
    if (isValidScale(newScale)) {
      if (!imageRotated) {
        setImageWidthScaled(imageWidth * newScale)
        setImageHeightScaled(imageHeight * newScale)
        setScale(newScale)
      } else {
        setImageWidthScaled(imageHeight * newScale)
        setImageHeightScaled(imageWidth * newScale)
        setScale(newScale)
      }
    }
    setTimeout(() => {
      checkPosition()
    }, 250)
  }

  const isValidScale = (newScale: number) => {
    if (newScale < 0.3 || newScale > 5) {
      return false
    } else {
      return true
    }
  }

  const onImgLoadorWindowResize = (evt: any) => {
    const img = document.getElementById('mainImg') === null ? evt.target : document.getElementById('mainImg')
    if (!imageRotated) {
      setImageWidth(img.offsetWidth)
      setImageHeight(img.offsetHeight)
      setImageWidthScaled(img.offsetWidth * scale)
      setImageHeightScaled(img.offsetHeight * scale)
    } else {
      setImageWidth(img.offsetHeight)
      setImageHeight(img.offsetWidth)
      setImageWidthScaled(img.offsetHeight * scale)
      setImageHeightScaled(img.offsetWidth * scale)
    }
  }

  return (
    <div
      style={{ width: '100%', justifyContent: props.justifyContent ?? 'center', display: 'flex', position: 'relative' }}
    >
      <img
        src={props.imgSrc}
        alt="Pass port"
        onClick={handleModalToggle}
        style={{ height: props.maxHeight, width: props.maxWidth }}
      />
      {props.hideEyeIcon !== true && (
        <VisibilityOutlined
          className={classes.icon}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
            cursor: 'pointer',
          }}
          onClick={handleModalToggle}
        />
      )}
      <Modal
        open={modalIsOpen}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.98)',
          zIndex: 1302,
          userSelect: 'none',
        }}
      >
        <div style={{ outline: 'none' }}>
          <Fab
            id="closeModal"
            color="default"
            aria-label="Add"
            size="small"
            className={classes.close}
            onClick={handleModalToggle}
          >
            <Close />
          </Fab>
          <div
            style={{
              backgroundColor: 'rgba(104, 104, 104, 0.5)',
              height: '90vh',
              overflow: 'hidden',
              cursor: 'move',
              touchAction: 'none',
            }}
            id="mainImgContainer"
            onPointerDown={onMouseDown}
            onPointerMove={onMouseMove}
            onPointerUp={onMouseUp}
            onPointerOut={onMouseUp}
            onWheel={onMouseWheel}
          >
            <div
              style={{
                transform: `translate(${imageOffsetX}px, ${imageOffsetY}px) scale(${scale}, ${scale})`,
              }}
              className={classes.imageContainer}
            >
              <img
                id="mainImg"
                src={props.imgSrc}
                alt="Pass port"
                className={classes.image}
                style={{
                  transform: `rotate(${rotation}deg)`,
                  outline: 'none',
                }}
                draggable={false}
                onLoad={onImgLoadorWindowResize}
              />
            </div>
          </div>
          <Grid container={true} justifyContent="center" style={{ position: 'fixed', bottom: '3vh' }}>
            <Grid item={true} xs={12}>
              <Grid container={true} justifyContent="center" style={{ textAlign: 'center' }}>
                <Grid item={true} xs={8}>
                  <Grid container={true} justifyContent="center">
                    <Grid item={true} xs={4}>
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Rotate left (shift + R)"
                        aria-label="Rotate left (shift + R)"
                      >
                        <RotateLeft id="rotateLeft" className={classes.icon} onClick={rotateLeft} />
                      </Tooltip>
                    </Grid>
                    <Grid item={true} xs={4}>
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Zoom in (+)"
                        aria-label="Zoom in (+)"
                      >
                        <ZoomIn id="zoomIn" className={classes.icon} onClick={scaleUp} />
                      </Tooltip>
                    </Grid>
                    <Grid item={true} xs={4}>
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Zoom out (-)"
                        aria-label="Zoom out (-)"
                      >
                        <ZoomOut id="zoomOut" className={classes.icon} onClick={scaleDown} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} xs={2}>
                  <Grid container={true}>
                    <Grid item={true} xs={12}>
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Rotate right (R)"
                        aria-label="Rotate right (R)"
                      >
                        <RotateRight id="rotateRight" className={classes.icon} onClick={rotateRight} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}

export default ImageFullScreen

ImageFullScreen.defaultProps = {
  maxHeight: 200,
  maxWidth: 200,
}
