import { AxiosInstance } from 'axios'
import { By } from './By'
import qs from 'qs'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import Page from './Page'

export namespace Courses {
  export interface Params {
    id?: string[]
    company?: string[]
    name?: string[]
    description?: string[]
  }

  export interface Form {
    company: string
    name: string
    description: string
    data: Object
    validDuration: string
    external?: External.Form
  }

  export interface Summary {
    id: string
    company: ITenant
    name: string
    description: string
    data: Object
    validDuration: string
    modified: By
    external?: External.Summary
  }
}

export namespace External {
  export interface Form {
    id: string
    url: string
    apiKey: string
  }

  export interface Summary {
    id: string
    url: string
    apiKey?: string
  }
}

export class CoursesApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async list(tenantRef: string, params: Courses.Params, page: Page.Search): Promise<Page<Courses.Summary>> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/courses`, {
      params: { ...params, ...page },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async get(tenantRef: string, id: string): Promise<Courses.Summary> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/courses/${id}`)
    return response.data
  }

  async create(tenantRef: string, form: Courses.Form): Promise<void> {
    await this.httpClient.post(`/ports/${tenantRef}/courses`, form)
  }

  async update(tenantRef: string, id: string, form: Courses.Form): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/courses/${id}`, form)
  }

  async delete(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.delete(`/ports/${tenantRef}/courses/${id}`)
  }
}
