import { useServices } from '@app/common/ServicesWrapper'
import { IPortDetailsDto } from '@app/common/ServicesWrapper/apis/SharedDataApi'
import { UserApi } from '@app/common/ServicesWrapper/apis/UserApi'
import { useQuery } from '@tanstack/react-query'
import EditDefaultApplication from './Default/EditDefaultApplication'
import EditNextApplication from './Next/EditNextApplication'
import { EditProps } from './types'
import { FormValues } from 'src/common/ServicesWrapper/apis/Application2'
import { SecurityIdentity } from 'src/common/ServicesWrapper/apis/dtos/IdentityAttributes'

const EditSelector: React.FC<EditProps> = ({ id }) => {
  const { services } = useServices()
  const fetch = useQuery<
    {
      application: UserApi.Application.Details
      formValues: FormValues
      portDetails: IPortDetailsDto
      userDetails: SecurityIdentity
    },
    any
  >(
    ['editPortDetails'],
    async () => {
      const application = await services.userApi.getApplication(id)
      const formValues = await services.applications2Api.getApplicationValues({
        tenant: application.current.visiting.port.id,
      })
      const portDetails = await services.sharedDataApi.getPortDetails(application.current.visiting.port.id)
      const userDetails = await services.userApi.me()
      return { application, formValues, portDetails, userDetails }
    },
    {
      retry: (count, error) => error?.response?.status !== 404 && count < 3,
    }
  )

  if (fetch.isError || fetch.isLoading || !fetch.isFetched) {
    return <></>
  }

  if (fetch.data?.portDetails.attributes?.nextGenVisitorApplication) {
    return (
      <EditNextApplication
        id={id}
        services={services}
        portDetails={fetch.data.portDetails}
        application={fetch.data.application}
        formValues={fetch.data.formValues}
        userDetails={fetch.data.userDetails}
      />
    )
  } else {
    return (
      <EditDefaultApplication
        id={id}
        services={services}
        portDetails={fetch.data.portDetails}
        application={fetch.data.application}
      />
    )
  }
}

export default EditSelector
