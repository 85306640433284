import { Avatar, Badge, Box, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { SecurityIdentity } from './ServicesWrapper/apis/dtos/IdentityAttributes'
import { ResolvedUser } from './ServicesWrapper/apis/dtos/userDto'
import { initials } from '@app/lib/Initials'

interface IVisitorAvatarProps {
  src?: string | null
  name?: string
  company?: string
  extraInfo?: string
}

export const VisitorAvatar = ({ src, name, company, extraInfo }: IVisitorAvatarProps) => {
  const avatar = (
    <Avatar alt={name} src={src ?? undefined} style={{ color: 'rgba(255, 255, 255, 0.87)' }}>
      {name ? initials(name) : ''}
    </Avatar>
  )

  return name ? (
    <Grid container={true} justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item={true}>{avatar}</Grid>
      <Grid item={true}>
        <Grid container={true} direction="column">
          <Grid item={true}>
            <Typography>{name}</Typography>
          </Grid>
          {extraInfo && extraInfo.length > 0 && (
            <Grid item={true}>
              <Typography variant="body2">{extraInfo}</Typography>
            </Grid>
          )}
          {company && (
            <Grid item={true}>
              <Typography variant="body2" style={{ opacity: 0.6 }}>
                {company}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    avatar
  )
}

interface IUserAvatarProps {
  user: ResolvedUser
  displayName?: boolean
  displayCompany?: boolean
  displayBadge?: boolean
  extraInfo?: string
}

export default function UserAvatar({
  user,
  displayName = false,
  displayCompany = false,
  displayBadge = false,
  extraInfo,
}: IUserAvatarProps) {
  const src = user.picture
  const userName = user.name ? user.name : user.email ? user.email : user.id
  const company = displayCompany && user.profile.companyName ? `${user.profile.companyName}` : ''
  const withBadge = (child: React.ReactElement) =>
    displayBadge && user.profile.type ? (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        badgeContent={user.profile.type}
      >
        {child}
      </Badge>
    ) : (
      child
    )

  const avatar = withBadge(
    <Avatar alt={userName} src={src ?? undefined} style={{ color: 'rgba(255, 255, 255, 0.87)' }}>
      {initials(userName)}
    </Avatar>
  )

  return displayName ? (
    <Box display="flex" flexDirection="row" alignItems="center" mb={0.5}>
      {avatar}
      <Box display="flex" flexDirection="column" ml={1}>
        <Typography>{userName}</Typography>
        {extraInfo && extraInfo.length > 0 && <Typography variant="body2">{extraInfo}</Typography>}
        {company && (
          <Typography variant="body2" style={{ opacity: 0.6 }}>
            {company}
          </Typography>
        )}
      </Box>
    </Box>
  ) : (
    avatar
  )
}

interface SecurityIdentityAvatarProps {
  identity: SecurityIdentity
  displayName?: boolean
  displayCompany?: boolean
  displayEmail?: boolean
}

export function SecurityIdentityAvatar({
  identity,
  displayName = false,
  displayCompany = false,
  displayEmail = false,
}: SecurityIdentityAvatarProps) {
  const theme = useTheme()

  const src = identity.identity.picture
  const userName = identity.identity.name
    ? identity.identity.name
    : identity.identity.email
    ? identity.identity.email
    : identity.identity.id
  const company = displayCompany && identity.attributes.company ? identity.attributes.company : ''
  const email = displayEmail && identity.identity.email ? identity.identity.email : ''
  const avatar = (
    <Avatar alt={userName} src={src ?? undefined} style={{ border: `thin ${theme.palette.text.primary} solid` }}>
      {initials(userName)}
    </Avatar>
  )

  return displayName ? (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Box mr={2} alignItems="center" justifyItems="center">
        {avatar}
      </Box>
      <Grid container>
        {displayName && (
          <Grid item xs={12}>
            <Typography variant="h6">{userName}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            {displayCompany ? company : ' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            {displayEmail ? email : ' '}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  ) : (
    avatar
  )
}
