import { Box, Button } from '@mui/material'
import { Page as P } from '@griegconnect/krakentools-react-ui'
import React from 'react'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import { Services } from '@app/common/ServicesWrapper/types'
import SaveIcon from '@mui/icons-material/Save'
import * as Form from '@griegconnect/krakentools-form'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { ProfileAttributesForm } from '.'

export interface CompanyProps {
  company?: string
  form: UseFormReturn<ProfileAttributesForm.Inputs>
  refresh: () => void
  services: Services
}

export const Company: React.FC<React.PropsWithChildren<CompanyProps>> = ({ company, form, services, refresh }) => {
  const { enqueue } = useAlerts()

  const updateProfile = async () => {
    const inputForm = ProfileAttributesForm.toForm(form.getValues())
    try {
      await services.userApi.updateProfile(
        inputForm.idDocument,
        inputForm.company,
        inputForm.symmetryBlue?.toString() || undefined,
        inputForm.pin,
        inputForm.cardNumbers
      )
      enqueue('Company name changed!', 'success')
      refresh()
    } catch (err) {
      enqueue('Error company name', 'error')
    }
  }

  return (
    <FormProvider {...form}>
      <P.Wrapper title="Company">
        <P.Paper>
          <Box m={1} />
          <Form.TextInput name="company" label="Company" control={form.control} />
          <Box m={2} />
          <Box display="flex" flex="1" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={updateProfile} endIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        </P.Paper>
      </P.Wrapper>
    </FormProvider>
  )
}
export default Company
