import { Courses } from '@app/common/ServicesWrapper/apis/CoursesApi'
import { IUserDto } from '@app/common/ServicesWrapper/apis/dtos/userDto'
import { IFileRefDto } from '@app/common/ServicesWrapper/apis/dtos/fileRefDto'
import { AxiosInstance } from 'axios'
import { By } from './By'
import qs from 'qs'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import Page from './Page'

export declare namespace CourseAttendees {
  export interface Params {
    id?: string[]
    course?: string[]
    attendee?: string[]
  }

  export interface Form {
    course: string
    attendee: string
    files: string[]
    data: Object
    completedAt: string
  }

  export interface UserForm {
    course: string
    attendee?: string
    files: string[]
    data: Object
    completedAt: string
  }

  export interface Summary {
    id: string
    reported_by?: ITenant
    course: Courses.Summary
    attendee: IUserDto
    files: IFileRefDto[]
    data: Object
    completedAt: string
    modified: By
  }

  export interface Created {
    id: string
  }
}

export class CourseAttendeesApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async list(
    tenantRef: string,
    params: CourseAttendees.Params,
    page: Page.Search
  ): Promise<Page<CourseAttendees.Summary>> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/course-attendees`, {
      params: { ...params, ...page },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async get(tenantRef: string, id: string): Promise<CourseAttendees.Summary> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/course-attendees/${id}`)
    return response.data
  }

  async create(tenantRef: string, form: CourseAttendees.Form): Promise<CourseAttendees.Created> {
    const id = await this.httpClient.post(`/ports/${tenantRef}/course-attendees`, form)
    return id.data
  }

  async update(tenantRef: string, id: string, form: CourseAttendees.Form): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/course-attendees/${id}`, form)
  }

  async delete(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.delete(`/ports/${tenantRef}/course-attendees/${id}`)
  }

  async show(tenantRef: string, id: string): Promise<CourseAttendees.Summary[]> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/course-attendees/show/${id}`)
    return response.data
  }
}
