import React from 'react'
import { useParams } from 'react-router-dom'

type Strings<T> = { [k in keyof T]: T[k] extends string ? k : never }[keyof T]

export function withParam<P extends object, F extends Strings<P>>(Component: React.ComponentType<React.PropsWithChildren<P>>, field: F) {
  const Wrapped = (props: Omit<P, F> & { children?: React.ReactNode }) => {
    const params = useParams<{ [key in F]: string }>()
    const value = { [field]: params[field as unknown as string] }
    // kan vi få med typesystemet på dette ?
    const all = { ...props, ...value } as P

    return <Component {...all} />
  }
  return Wrapped
}
