import { Tenant } from '@griegconnect/krakentools-react-kraken-app'

export const PATH_TENANT_BASE = '/companies/:tenantRef'

export namespace Permissions {
  export const all = (p: { [k: string]: Tenant.Permission }): Tenant.Permission[] => Object.values(p)

  type Create = { create: Tenant.Permission }
  type Read = { read: Tenant.Permission }
  type Update = { update: Tenant.Permission }
  type Delete = { delete: Tenant.Permission }
  type Allow = { allow: Tenant.Permission }
  type Approve = { approve: Tenant.Permission }
  type Status = { status: Tenant.Permission }
  type Revoke = { revoke: Tenant.Permission }

  export const create = (name: string): Create => ({ create: { domain: 'security', name: `${name}:create` } })
  export const read = (name: string): Read => ({ read: { domain: 'security', name: `${name}:read` } })
  export const update = (name: string): Update => ({ update: { domain: 'security', name: `${name}:update` } })
  export const del = (name: string): Delete => ({ delete: { domain: 'security', name: `${name}:delete` } })
  export const allow = (name: string): Allow => ({ allow: { domain: 'security', name: `${name}:allow_entry` } })
  export const status = (name: string): Status => ({ status: { domain: 'security', name: `${name}:status_update` } })
  export const approve = (name: string): Approve => ({ approve: { domain: 'security', name: `${name}:approve` } })
  export const revoke = (name: string): Revoke => ({ revoke: { domain: 'security', name: `${name}:revoke` } })
  export const crud = (name: string) =>
    Object.assign(Permissions.create(name), Permissions.read(name), Permissions.update(name), Permissions.del(name))
  export const cru = (name: string) =>
    Object.assign(Permissions.create(name), Permissions.read(name), Permissions.update(name))
  export const monitorRead = (name: string): Read => ({ read: { domain: 'monitor', name: `${name}:read` } })
  export const globalRead = (name: string): Read => ({ read: { domain: 'global', name: `${name}:read` } })
  export const globalUpdate = (name: string): Update => ({ update: { domain: 'global', name: `${name}:update` } })
  export const global = (name: string) => Object.assign(globalRead(name), globalUpdate(name))
}

export const PERMISSIONS = {
  applications: Object.assign(
    Permissions.read('application'),
    Permissions.update('application'),
    Permissions.del('application'),
    Permissions.approve('application')
  ),
  checklists: Permissions.crud('checklist'),
  checkpoints: Object.assign(Permissions.crud('checkpoint'), Permissions.allow('checkpoint')),
  quays: Object.assign(Permissions.crud('quay'), Permissions.status('quay')),
  checks_vehicle: Permissions.crud('check_vehicle'),
  checks_facility: Permissions.crud('check_facility'),
  checks_person: Permissions.crud('check_person'),
  checks_vessel: Permissions.crud('check_vessel'),
  checks_quay: Permissions.crud('check_quay'),
  course: Permissions.crud('course'),
  course_attendance: Permissions.crud('course_attendance'),
  facilities: Object.assign(
    Permissions.create('facility'),
    Permissions.update('facility'),
    Permissions.del('facility')
  ),
  notifications: Object.assign(Permissions.read('notification'), Permissions.create('notification')),
  permits: Object.assign(Permissions.crud('permit'), Permissions.revoke('permit')),
  passings: Object.assign(Permissions.read('passing'), Permissions.create('passing')),
  passing_stats: Permissions.read('passing_stats'),
  identities: Object.assign(Permissions.read('identities'), Permissions.update('identities')),
  purposes: Object.assign(Permissions.create('purpose'), Permissions.update('purpose'), Permissions.del('purpose')),
  port: Object.assign(Permissions.create('port'), Permissions.update('port')),
  security_level: Object.assign(Permissions.read('securitylevel'), Permissions.create('securitylevel')),
  security_confirmation: Object.assign(
    Permissions.create('security_confirmation'),
    Permissions.update('security_confirmation'),
    Permissions.del('security_confirmation')
  ),
  security_check_result: Permissions.crud('security_check_result'),
  vessels: Object.assign(Permissions.update('vessel'), Permissions.del('vessel')),
  dos: Object.assign(Permissions.update('dos'), Permissions.read('dos')),
  visitor_stats: Permissions.crud('visitor_stats'),
  global_access_system: Permissions.global('access_system'),
  access_system_checkpoint_event: Permissions.create('access_system_checkpoint'),
  access_system_checkpoint: Permissions.crud('access_system_checkpoint'),
  access_system_data: Permissions.crud('access_system_data'),
  frontend_users: Permissions.read('frontend_users'),
  frontend_companies: Permissions.read('frontend_companies'),
  dos_contract: Permissions.crud('dos_contract'),
  dos_application: Object.assign(Permissions.crud('dos_application'), Permissions.approve('dos_application')),
  invoicing: Object.assign(
    Permissions.create('invoicing'),
    Permissions.read('invoicing'),
    Permissions.update('invoicing')
  ),
  maps: Object.assign(
    Permissions.monitorRead('maps'),
    Permissions.monitorRead('layers'),
  ),
}
export const ALL_PERMISSIONS = Object.values(PERMISSIONS).flatMap((p) => Object.values(p))
