import * as Url from '@app/lib/util';
import { AxiosResponse } from 'axios';
import { AxiosInstance } from 'axios';
import { SortArrayOfObjectsNested } from '@app/lib/Sort';
import { SecurityCheck, SecurityChecks, SecurityChecksForm } from './dtos/api';

export class SecurityCheckApi {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async list(tenantRef: string, filterParams?: {}): Promise<SecurityCheck[]> {
    const baseApiPath = `/ports/${tenantRef}/securitychecks`;
    const urlWithParams = filterParams ? Url.addQueryParms(filterParams, true, baseApiPath) : baseApiPath;
    const securityChecksResponse: AxiosResponse<SecurityChecks> = await this.httpClient.get(urlWithParams);
    const data: SecurityCheck[] = securityChecksResponse.data.securityChecks;
    return data.sort(SortArrayOfObjectsNested('createdAt', 'desc'));
  }

  async create(tenantRef: string, data: SecurityChecksForm) {
    await this.httpClient.post(`/ports/${tenantRef}/securitychecks`, data);
  }
}
