import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSettings = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M11.995 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm4 2a4 4 0 1 0-8 0 4 4 0 0 0 8 0Z" />,
    <path d="M13.815 2a1 1 0 0 1 .98.804l.37 1.85c.573.247 1.111.559 1.604.926l1.784-.603a1 1 0 0 1 1.186.447l1.82 3.152a1 1 0 0 1-.206 1.251l-1.412 1.241a8.064 8.064 0 0 1 0 1.863l1.411 1.24a1 1 0 0 1 .206 1.25l-1.82 3.153a1 1 0 0 1-1.186.447l-1.78-.603a7.995 7.995 0 0 1-1.61.93l-.37 1.843a1 1 0 0 1-.98.804h-3.64a1 1 0 0 1-.98-.804l-.37-1.845a7.995 7.995 0 0 1-1.606-.93l-1.786.604a1 1 0 0 1-1.186-.447l-1.82-3.152a1 1 0 0 1 .206-1.25l1.418-1.247a8.065 8.065 0 0 1 0-1.851L2.631 9.827a1 1 0 0 1-.206-1.251l1.82-3.152a1 1 0 0 1 1.186-.447l1.788.605a7.994 7.994 0 0 1 1.606-.929l.37-1.85a1 1 0 0 1 .98-.803h3.64ZM10.56 6.173a5.993 5.993 0 0 0-2.894 1.671l-2.1-.71-1 1.732 1.664 1.463A6.002 6.002 0 0 0 5.995 12c0 .579.082 1.139.235 1.669L4.566 15.13l1 1.732 2.099-.71a5.994 5.994 0 0 0 2.894 1.674l.433 2.168h2l.434-2.167a5.993 5.993 0 0 0 2.897-1.672l2.093.708 1-1.732-1.658-1.457A6.002 6.002 0 0 0 17.995 12c0-.582-.083-1.144-.237-1.676l1.66-1.458-1-1.732-2.096.71a5.993 5.993 0 0 0-2.892-1.671L12.995 4h-2l-.434 2.173Z" />
  )
export default SvgSettings
