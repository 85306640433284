import { Page } from '@griegconnect/krakentools-react-ui'
import { Dashboard, IWidget } from '@griegconnect/krakentools-react-ui'
import { AppLoader } from '@griegconnect/krakentools-react-ui'
import { Box, Button, useMediaQuery, Stack } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import React, { useEffect, useState } from 'react'
import { Stats } from '@app/common/ServicesWrapper/apis/Stats'
import contractsStatusWidget from './Widgets/ContractsStatusWidget'
import { WidgetOptions, Widgets } from './Types'
import * as Fullscreen from '../components/Fullscreen'
import { UiDialog } from '@app/common/UiDialog'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import mapWidget from './Widgets/MapWidget'
import passingsWidget from './Widgets/PassingsWidget'
import { PassingIn } from '@app/common/ServicesWrapper/apis/PassingsApi'
import { useNavigate } from 'react-router-dom'
import { Turnaround as ITurnaround } from '@app/common/ServicesWrapper/apis/PassingsApi'
import controlReportVisitorWidget from './Widgets/ControlReportVisitorWidget'
import { VisitorStats } from '@app/common/ServicesWrapper/apis/VisitorStatsApi'
import { Add as AddIcon } from '@griegconnect/krakentools-react-icons'
import WidgetDialog from './WidgetDialog'
import { v4 } from 'uuid'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import { chartColors } from '@app/common/dashboard/Utils'
import turnaroundWidget from './Widgets/TurnaroundWidget'
import { Duration } from 'luxon'
import { useAuth } from '@griegconnect/krakentools-react-kraken-app'
import md5 from 'blueimp-md5'
import { useRecoilValue } from 'recoil'
import { portConfigStore } from '@app/stores/portStore'
import deviationsStatusWidget from './Widgets/DeviationsStatusWidget'
import controlReportAreaWidget from './Widgets/ControlReportAreaWidget'
import quayStatusWidget from './Widgets/QuayStatusWidget'
import { Quays } from '@app/common/ServicesWrapper/apis/QuayApi'
import {useTranslation} from "react-i18next";
import { PermitGroup } from 'src/common/ServicesWrapper/apis/VisyApi'

const useStyles = makeStyles((theme: Theme) => ({
  editButton: {
    marginLeft: theme.spacing(2),
  },
  switchButton: {
    marginRight: theme.spacing(2),
  },
}))

interface StorageKeys {
  layout: string
  widgets: string
}

interface StatsData {
  isFetching: boolean
  dos?: Stats.ContractStatusStats
  quays?: Quays.Quay[]
  deviations?: Stats.DeviationsRangeStats[]
  passings?: PassingIn
  turnaround?: ITurnaround
  controls?: VisitorStats[]
  areaControls?: Stats.CompletedRangeStats[]
  areas?: IAreaDto[]
  visyGroups?: PermitGroup[]
}

interface ICustomDashboardProps extends WithActiveTenant {
  storageKeys: StorageKeys
  defaultWidgetConstructors: Widgets.Constructor[]
  data: StatsData
  disableMap?: boolean
}

const CustomDashboard: React.FC<React.PropsWithChildren<ICustomDashboardProps>> = ({
  activeTenant,
  storageKeys,
  defaultWidgetConstructors,
  data,
  disableMap,
  children,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const user = useAuth()
  const hash = md5(user.user!.email)
  const portConfig = useRecoilValue(portConfigStore)
  const theme = useTheme()
  const isSM = useMediaQuery(theme.breakpoints.down('lg'))
  const { enqueue, close } = useAlerts()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [widgetConstructors, setWidgetConstructors] = useState<Widgets.Constructor[]>([])
  const [selectedWidgetConstructor, setSelectedWidgetConstructor] = useState<Widgets.Constructor | undefined>(undefined)
  const [widgets, setWidgets] = useState<IWidget[]>([])

  const [isLocked, setIsLocked] = React.useState(true)
  const [isFullscreen, setIsFullscren] = React.useState<boolean>(false)
  const [resetDialogOpen, setResetDialogOpen] = React.useState<boolean>(false)
  const [WidgetDialogOpen, setWidgetDialogOpen] = React.useState<boolean>(false)
  const { t } = useTranslation()

  const groupingNames: string[] = Array.from(
    new Set([...(data.passings?.entries || []).map((e) => e.classified ?? 'Andre'), ...(data.visyGroups || []).map((g) => g.name)])
  )

  const widgetGroupingsPassings: Widgets.Grouping[] = [
    {
      id: 1,
      name: t('components.dashboard.labels.allAvailable'),
    },
    ...groupingNames.map((name, idx) => ({ id: idx + 2, name, color: chartColors.dark[idx] })),
  ]

  useEffect(() => {
    setIsLoading(true)
  }, [storageKeys.layout, widgetConstructors])

  useEffect(() => {
    setIsLoading(false)
  }, [widgets])

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem(storageKeys.widgets) ?? '{}')
    const storedOld = JSON.parse(
      localStorage.getItem(`${activeTenant.ref}-${hash}-dashboard-widget-constructors`) ?? '{}'
    )
    if (
      storageKeys.widgets === `${activeTenant.ref}-${hash}-dashboard-widget-constructors-general` &&
      Object.entries(storedOld).length > 0
    ) {
      // runs once to preserve existing layouts
      const oldConstructors: Widgets.OldConstructor[] = storedOld?.widgetConstructors || []
      const newConstructors: Widgets.Constructor[] = oldConstructors.map((c) => ({
        id: c.id,
        type: (c.type === 'map'
          ? 'map'
          : c.type === 'progress'
          ? 'controls'
          : c.data
          ? c.data
          : 'passings') as Widgets.Type,
        variant: c.type as Widgets.Variant,
        subVariant:
          c.grouping && c.grouping?.length > 0
            ? c.grouping[0] === 'persons'
              ? 'persons'
              : c.grouping[0] === 'vehicles'
              ? 'vehicles'
              : undefined
            : undefined,
        name: c.name,
        grouping:
          c.grouping && c.grouping.length > 0
            ? c.grouping[0] !== 'persons' && c.grouping[0] !== 'vehicles'
              ? c.grouping
              : undefined
            : undefined,
        facilities: c.facilities,
        timeFrom:
          c.data !== 'passings' && c.data !== 'turnaround' && c.time
            ? Duration.fromDurationLike({
                day: c.time === 'last 24 hours' ? 1 : c.time === 'last 7 days' ? 7 : c.time === 'last 30 days' ? 30 : 0,
              })
            : undefined,
        timeTo:
          c.time === 'current week'
            ? Duration.fromDurationLike({ day: 7 }).negate()
            : c.time === 'current month'
            ? Duration.fromDurationLike({ day: 30 }).negate()
            : c.time === 'current year'
            ? Duration.fromDurationLike({ day: 365 }).negate()
            : undefined,
        currentPeriod: c.time === 'current week' || c.time === 'current month' || c.time === 'current year',
        position: c.position,
        positiveValue: c.positiveValue,
      }))
      setWidgetConstructors(newConstructors)
      localStorage.setItem(storageKeys.widgets, JSON.stringify(newConstructors))
      localStorage.removeItem(`${activeTenant.ref}-${hash}-dashboard-widget-constructors`)
      const oldLayout = JSON.parse(localStorage.getItem(`${activeTenant.ref}-${hash}-dashboard-layout`) ?? '{}')
      localStorage.setItem(storageKeys.layout, JSON.stringify(oldLayout))
      localStorage.removeItem(`${activeTenant.ref}-${hash}-dashboard-layout`)
    } else if (stored.length > 0) {
      if (stored.find((c) => c.subVariant === 'submitted')) {
        // runs once to update old statuses
        const mapped = stored.map((c) => (c.subVariant === 'submitted' ? { ...c, subVariant: 'in_review' } : c))
        setWidgetConstructors(mapped)
        localStorage.setItem(storageKeys.widgets, JSON.stringify(mapped))
      } else {
        setWidgetConstructors(stored)
      }
    } else if (defaultWidgetConstructors.length > 0) {
      setWidgetConstructors(defaultWidgetConstructors)
      localStorage.setItem(storageKeys.widgets, JSON.stringify(defaultWidgetConstructors))
    }
    return () => close('editMode')
  }, [defaultWidgetConstructors, storageKeys.widgets, storageKeys.layout, activeTenant.ref, hash, close])

  const onRemove = (constructor: Widgets.Constructor) => {
    const newWidgetConstructors = [...widgetConstructors.filter((c) => c.id !== constructor.id)]
    setWidgetConstructors(newWidgetConstructors)
    localStorage.setItem(storageKeys.widgets, JSON.stringify(newWidgetConstructors))
    enqueue(t('components.dashboard.statusUpdates.widgetRemoved'), 'info')
  }

  const onSettings = (constructor: Widgets.Constructor) => {
    const foundWidgetConstructor = widgetConstructors.find((c) => c.id === constructor.id)
    setSelectedWidgetConstructor(foundWidgetConstructor)
    setWidgetDialogOpen(true)
  }

  const onDetails = (
    path: string,
    filters?: {
      timeFrom?: Duration
      timeTo?: Duration
      currentPeriod?: boolean
      facilities?: string[]
      grouping?: string[]
      status?: string[]
      checkType?: string[]
    }
  ) => {
    const params = filters
      ? Object.keys(filters)
          .map((key) => (!!filters[key] ? `${key}=${filters[key]}` : ''))
          .filter((p) => p.length > 0)
      : []
    const mappedParams =
      params.length > 0
        ? params.reduce((old, current, index) => (index === 0 ? old.concat(current) : old.concat(`&&${current}`)), '?')
        : ''
    return isLocked && !isFullscreen
      ? () => navigate(`/companies/${activeTenant.ref}${path}${mappedParams}`)
      : undefined
  }

  /***************************

  dialog widget options config

  ***************************/

  const typeOptions: WidgetOptions.Type[] = [
    {
      id: v4(),
      name: 'controls',
      disabled: !data.controls,
      variants: [
        {
          id: v4(),
          name: 'progress',
          subVariants: [
            {
              id: v4(),
              name: 'persons',
            },
            {
              id: v4(),
              name: 'vehicles',
            },
          ],
          facilities: true,
          time: true,
        },
      ],
    },
    {
      id: v4(),
      name: 'areaControls',
      disabled: !data.areaControls,
      variants: [
        {
          id: v4(),
          name: 'value',
          subVariants: [
            {
              id: v4(),
              name: 'facilities',
            },
            {
              id: v4(),
              name: 'quays',
            },
          ],
          facilities: true,
          time: true,
        },
      ],
    },
    {
      id: v4(),
      name: 'dos',
      disabled: !data.dos || (portConfig.isPort && !(portConfig.attributes && portConfig.attributes.approvedCompanies)),
      variants: [
        {
          id: v4(),
          name: 'value',
          subVariants: [
            {
              id: v4(),
              name: 'created',
            },
            {
              id: v4(),
              name: 'invited',
            },
            {
              id: v4(),
              name: 'awaiting_cso',
            },
            {
              id: v4(),
              name: 'in_review',
            },
            {
              id: v4(),
              name: 'require_more_information',
            },
            {
              id: v4(),
              name: 'in_production',
            },
            {
              id: v4(),
              name: 'completed',
            },
            {
              id: v4(),
              name: 'rejected',
            },
          ],
        },
      ],
    },
    {
      id: v4(),
      name: 'quay',
      disabled: !data.quays,
      variants: [
        {
          id: v4(),
          name: 'value',
          subVariants: [],
          quay: true,
        },
      ],
    },
    {
      id: v4(),
      name: 'deviations',
      disabled: !data.deviations,
      variants: [
        {
          id: v4(),
          name: 'value',
          subVariants: [
            {
              id: v4(),
              name: 'facilityChecks',
            },
            {
              id: v4(),
              name: 'personChecks',
            },
            {
              id: v4(),
              name: 'quayChecks',
            },
            {
              id: v4(),
              name: 'vehicleChecks',
            },
            {
              id: v4(),
              name: 'vesselChecks',
            },
          ],
          facilities: true,
          time: true,
        },
      ],
    },
    {
      id: v4(),
      name: 'map',
      disabled: !!disableMap || !!widgetConstructors.find(c => c.type === 'map'),
      variants: [
        {
          id: v4(),
          name: 'map',
          subVariants: [],
        },
      ],
    },
    {
      id: v4(),
      name: 'passings',
      disabled: !data.passings,
      variants: [
        {
          id: v4(),
          name: 'trend',
          subVariants: [],
          facilities: true,
          positiveValue: true,
          groupings: widgetGroupingsPassings,
        },
        {
          id: v4(),
          name: 'linechart',
          subVariants: [],
          facilities: true,
          groupings: widgetGroupingsPassings,
        },
      ],
    },
    {
      id: v4(),
      name: 'turnaround',
      disabled: !data.turnaround,
      variants: [
        {
          id: v4(),
          name: 'trend',
          subVariants: [],
          facilities: true,
          positiveValue: true,
          groupings: widgetGroupingsPassings,
        },
        {
          id: v4(),
          name: 'linechart',
          subVariants: [],
          facilities: true,
          groupings: widgetGroupingsPassings,
        },
      ],
    },
  ]

  /********************

  layout widgets config

  ********************/

  useEffect(() => {
    if (widgetConstructors.length > 0) {
      const w: IWidget[] = widgetConstructors.flatMap((constructor, idx) => {
        const commonData: Widgets.Info = {
          widgetConstructor: constructor,
          onRemove: () => onRemove(constructor),
          onSettings: () => onSettings(constructor),
          editing: !isLocked,
        }
        switch (constructor.type) {
          case 'dos':
            !data.isFetching && !data.dos && console.error('missing dos data')
            return contractsStatusWidget({
              ...commonData,
              data: data.dos,
              onDetails: onDetails(portConfig.isPort ? '/applications/fixed' : 'fixed-applications', {
                status: constructor.subVariant ? [constructor.subVariant] : undefined,
              }),
            })
          case 'quay':
            !data.isFetching && !data.quays && console.error('missing quays data')
            const quay =
              constructor.quay && !!data.quays ? data.quays.find((q) => q.name === constructor.quay) : undefined
            return quayStatusWidget({
              ...commonData,
              data: data.quays,
              onDetails: quay ? onDetails(`/settings/facilities/${quay.facility.id}/quays/${quay.id}`) : undefined,
            })
          case 'deviations':
            !data.isFetching && !data.deviations && console.error('missing deviations data')
            return deviationsStatusWidget({
              ...commonData,
              data: data.deviations,
              onDetails: onDetails(`/${constructor.subVariant || ''}`),
              activeTenant,
            })
          case 'map':
            return !disableMap && widgetConstructors.findIndex(c => c.type === 'map') >= idx ? mapWidget({ ...commonData, activeTenant, data: {}, onDetails: onDetails('/map') }) : []
          case 'passings':
            !data.isFetching && !data.passings && console.error('missing passings data')
            return passingsWidget({
              ...commonData,
              activeTenant,
              data: data.passings,
              onDetails: onDetails('/passingStats', {
                timeFrom: constructor.timeFrom,
                timeTo: constructor.timeTo,
                currentPeriod: constructor.currentPeriod,
                facilities: constructor.facilities,
                grouping: constructor.grouping,
              }),
              isSM,
              groupings: widgetGroupingsPassings,
            })
          case 'turnaround':
            !data.isFetching && !data.turnaround && console.error('missing turnaround data')
            return turnaroundWidget({
              ...commonData,
              activeTenant,
              data: data.turnaround,
              onDetails: onDetails('/turnaroundStats', {
                timeFrom: constructor.timeFrom,
                timeTo: constructor.timeTo,
                currentPeriod: constructor.currentPeriod,
                facilities: constructor.facilities,
                grouping: constructor.grouping,
              }),
              groupings: widgetGroupingsPassings,
              isSM: true,
            })
          case 'controls':
            !data.isFetching && !data.controls && console.error('missing controls data')
            return controlReportVisitorWidget({
              ...commonData,
              data: data.controls,
              onDetails: onDetails('/controlStats', {
                timeFrom: constructor.timeFrom,
                timeTo: constructor.timeTo,
                currentPeriod: constructor.currentPeriod,
                checkType: ['visitor'],
              }),
              activeTenant,
            })
          case 'areaControls':
            !data.isFetching && !data.areaControls && console.error('missing areaControls data')
            return controlReportAreaWidget({
              ...commonData,
              data: data.areaControls,
              facilities: data.areas || [],
              onDetails: onDetails('/controlStats', {
                timeFrom: constructor.timeFrom,
                timeTo: constructor.timeTo,
                currentPeriod: constructor.currentPeriod,
                checkType: ['area'],
              }),
              activeTenant,
            })
          default:
            return []
        }
      })
      setWidgets(w)
    }
  }, [setWidgets, data, widgetConstructors, isLocked, isFullscreen]) // eslint-disable-line

  const toggleEditMode = () => {
    const message = t('components.dashboard.paragraphs.editModeDescription')
    isLocked ? enqueue(message, 'info', { key: 'editMode' }) : close('editMode')
    setIsLocked(!isLocked)
  }

  const onFullscreenChange = (fullscreen: boolean) => {
    setIsFullscren(fullscreen)
  }

  const resetLayout = () => {
    localStorage.removeItem(storageKeys.layout)
    localStorage.removeItem(storageKeys.widgets)
    setWidgetConstructors(defaultWidgetConstructors)
    setResetDialogOpen(false)
  }

  const onCancelWidget = () => {
    setWidgetDialogOpen(false)
    selectedWidgetConstructor && setSelectedWidgetConstructor(undefined)
  }

  const onConfirmWidget = async (data: Widgets.Constructor, id?: string) => {
    const newWidgetConstructors = [...widgetConstructors]
    if (id) {
      const editedConstructor = newWidgetConstructors.findIndex((c) => c.id === id)
      editedConstructor > -1 && (newWidgetConstructors[editedConstructor] = data)
    } else {
      newWidgetConstructors.push(data)
    }
    setWidgetConstructors(newWidgetConstructors)
    localStorage.setItem(storageKeys.widgets, JSON.stringify(newWidgetConstructors))
    setWidgetDialogOpen(false)
    selectedWidgetConstructor && setSelectedWidgetConstructor(undefined)
    enqueue(id ? t('components.dashboard.statusUpdates.widgetEdited') : t('components.dashboard.statusUpdates.widgetAdded'), 'success')
  }

  const dashboard = () => <Dashboard widgets={widgets} locked={isLocked} storageKey={storageKeys.layout} />

  return (
    <Page.Wrapper
      title= {t('components.dashboard.labels.title')}
      buttons={
        <Stack direction="row" justifyContent={'flex-end'} flex={1}>
          <Button
            onClick={toggleEditMode}
            variant="contained"
            color="primary"
            aria-label={isLocked ? 'Edit' : 'Lock'}
            className={!!isLocked ? classes.switchButton : undefined}
          >
            {isLocked ? t('common.actions.edit') : t('common.actions.lock') }
          </Button>
          {isLocked ? (
            <>
              <Fullscreen.Button onChange={onFullscreenChange} />
              <Box display="flex" alignItems="center" pl={2} style={{ width: '140px', height: '24px' }}>
                {children}
              </Box>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                aria-label="New widget"
                startIcon={<AddIcon />}
                disabled={isLocked}
                className={classes.editButton}
                onClick={() => setWidgetDialogOpen(true)}
              >
                {t('components.dashboard.labels.newWidget')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                aria-label="Reset layout"
                className={classes.editButton}
                onClick={() => setResetDialogOpen(true)}
              >
                {t('components.dashboard.labels.resetLayout')}
              </Button>
            </>
          )}
        </Stack>
      }
    >
      {isLoading || widgets.length === 0 ? (
        <AppLoader />
      ) : (
        <>{isFullscreen ? <Fullscreen.Modal open={isFullscreen}>{dashboard()}</Fullscreen.Modal> : dashboard()}</>
      )}
      <UiDialog
        title={t('components.dashboard.labels.resetLayoutQuestion')}
        text={t('components.dashboard.paragraphs.resetLayoutDescription')}
        open={resetDialogOpen}
        onCancel={() => setResetDialogOpen(false)}
        onConfirm={resetLayout}
      />
      {WidgetDialogOpen && (
        <WidgetDialog
          open={WidgetDialogOpen}
          onClose={onCancelWidget}
          onConfirm={onConfirmWidget}
          typeOptions={typeOptions}
          areas={data.areas || []}
          quays={data.quays || []}
          widgetConstructor={selectedWidgetConstructor}
        />
      )}
    </Page.Wrapper>
  )
}

export default CustomDashboard
