import { FormLabel, RadioGroup as RadioGroupMUI } from '@mui/material'
import React from 'react'
import { Control, Controller, get, useFormState } from 'react-hook-form'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'

interface RadioGroupProps {
  control: Control<any>
  name: string
  label?: string
  required?: boolean
  row?: boolean
}
export const RadioGroup: React.FC<React.PropsWithChildren<RadioGroupProps>> = ({
  control,
  name,
  label,
  children,
  row,
  required,
}) => {
  const { errors } = useFormState()
  const error = get(errors, name)
  return (
    <>
      {label && <FormLabel required={required ?? false}>{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <RadioGroupMUI {...rest} row={row ?? false}>
            {children}
          </RadioGroupMUI>
        )}
      />
      {error && error.message && <ErrorHelperText error={error.message} />}
    </>
  )
}
