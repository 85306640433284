import React from 'react'
import { Navigation, requiresTenant, requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { withActiveTenant } from '../WithActiveTenant'
import { withParam } from '../withParam'
import { Permit as IconPermit } from '@griegconnect/krakentools-react-icons'
import { AccessDependencies } from '@app/Content'
import { isPort } from '@app/lib/access'
import { withUserTenant } from '../withUserTenant'
import { Trans } from 'react-i18next'

const PATH = PATH_TENANT_BASE + '/permits'

export const permitsModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.permits.permits" />,
    path: PATH + '/',
    page: React.createElement(withActiveTenant(React.lazy(() => import('.')))),
    exact: true,
    menu: {
      icon: <IconPermit />,
    },
    access: (d) => isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.permits.read, true)(d),
  },
  {
    name: <Trans i18nKey="navigation.permits.newPermit" />,
    path: PATH + '/new',
    page: React.createElement(withUserTenant(React.lazy(() => import('./NewPermit')))),
    exact: true,
  },
  {
    name: <Trans i18nKey="navigation.permits.editPermit" />,
    path: PATH + '/edit/:id',
    page: React.createElement(
      withUserTenant(
        withParam(
          React.lazy(() => import('./EditPermit')),
          'id'
        )
      )
    ),
    exact: true,
  },
]
