import { AxiosInstance } from 'axios'
import { By } from './By'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import Page from '@app/common/ServicesWrapper/apis/Page'
import qs from 'qs'
import { IUserDto } from './dtos/userDto'
import { IFileRefDto } from './dtos/fileRefDto'
import { AreaLink } from './dtos/areaDto'
import { Integrations } from './SharedDataApi'
import { CourseAttendees } from './CourseAttendeesApi'
import { DateTime, Interval } from 'luxon'
import { Scheduling, convertToScheduling, convertToTScheduling } from './dtos/schedulingDto'
import { DefaultAccess } from './dtos/defaultAccessDto'

export interface ContractAreaLink {
  id: string
  facility: AreaLink
}

export declare namespace Contracts {
  export type InvoicePeriod = 'monthly' | 'quarterly' | 'yearly'

  export interface Data {
    invoiceManufacturing?: boolean
    invoicePassings?: boolean
    invoiceVehicles?: boolean
    invoicePersons?: boolean
    visitHandlers?: boolean
    invoicePeriods?: InvoicePeriods
    permitDuration?: Interval
  }

  export interface Params {
    page?: number
    pageSize?: number
    id?: string[]
    port?: string[]
    company?: string[]
    companySearch?: string[]
    status?: string[]
    allowFixed?: boolean
    type?: string[]
  }

  export interface Link {
    id: string
    name: string
  }

  export interface Form {
    name: string
    portContact: string | null
    kind: ContractKind
    vesselId?: number
    csos: string[]
    portCustomerId: number | null
    validFrom: string
    validTo: string | null
    files: string[]
    data: Data
  }

  export interface ClientForm {
    csos: string[]
  }

  interface InvoicePeriods {
    vehicles?: InvoicePeriod
    access?: InvoicePeriod
  }

  export type Status = 'active' | 'revoked' | 'deleted' | 'invalidated' | 'expired' | 'inactive'

  export interface Contract {
    id: string
    name: string
    port: ITenant
    kind: ContractKind
    vesselId?: number
    revoked: Boolean
    portContact: IUserDto | null
    csos: IUserDto[]
    portCustomerId?: number
    validFrom: string
    validTo: string | null
    files: IFileRefDto[]
    data: Data
    by: By
    applications: number
    invalidatedAt: DateTime | null
    deletedBy: By | null
    status: Status
  }

  export interface Detailed {
    contract: Contract
    applications: ContractApplications.Summary[]
    facilities: ContractFacilities.Summary[]
    visitHandlers: ContractVisitHandlers.Details[]
    integrations: Integrations
  }

  export interface DetailedWithDefaultAccess {
    contract: ContractWithDefaultAccess
    applications: ContractApplications.Summary[]
    facilities: ContractFacilities.Summary[]
    visitHandlers: ContractVisitHandlers.Details[]
    integrations: Integrations
  }

  export interface ContractWithDefaultAccess extends Contract {
    defaultAccesses: DefaultAccess.IFacilityVisitorAccesses
  }

  export interface FacilityContractScheduleResponse {
    facilityId: string
    userId?: string
    schedule: Scheduling
  }
}

export declare namespace ContractFacilities {
  export interface Form {
    contract: string
    facility: string
    files: string[]
    data: Object
  }

  export interface Summary {
    id: string
    modified: By
    revoked: boolean
    allowVisitors: boolean
    allowFixed: boolean
    approvedCompany: Contracts.Link
    facility: AreaLink
    files: IFileRefDto[]
    data: Object
  }

  export interface Params {
    id?: string[]
    contract?: string[]
    facility?: string[]
  }

  export interface Form {
    contract: string
    facility: string
    allowVisitors: boolean
    allowFixed: boolean
  }

  export interface Facility {
    id: string
    modified: By
    revoked: boolean
    allowVisitors: boolean
    allowFixed: boolean
    contract: Contracts.Link
    facility: AreaLink
  }

  export interface Details {
    summary: Facility
  }

  export interface DetailsWithDefaultAccess {
    summary: Facility
    defaultAccesses: DefaultAccess.IDefaultAccesses
  }
}

export declare namespace ContractVisitHandlers {
  export interface Params {
    id?: string[]
    contract?: string[]
    person?: string[]
  }

  export interface Form {
    contract: string
    person: string
  }

  export interface Details {
    id: string
    contract: By
    person: IUserDto
    created: By
  }
}

export declare namespace ContractApplications {
  export type Kind = 'person' | 'vehicle' | 'personvehicle'
  export type Status =
    | 'created'
    | 'invited'
    | 'awaiting_cso'
    | 'in_review'
    | 'require_more_information'
    | 'ready_for_pickup'
    | 'in_production'
    | 'completed'
    | 'rejected'

  export interface Params {
    page?: number
    kind?: Kind
    pageSize?: number
    id?: string[]
    person?: string[]
    contract?: string[]
    company?: string[]
    contractSearch?: string[]
    numberPlate?: string[]
    status?: string[]
  }

  export interface Form {
    contract: string
    firstName: string
    lastName: string
    email: string
    cardNumber?: string
    pin?: number
    numberPlate?: string
    ivrCode?: string
    status: Status
    facilities: string[]
    data: Object
    portrait: string[]
    validTo?: string
    files: string[]
    courseAttendances: string[]
    kind: Kind
  }

  export interface Patch {
    courses?: string
  }

  export interface ApplicationData {
    phone?: string
    birthDate?: string
    requiresMoreInformation?: string
    pickupDescription?: string
    color?: string
    manufacturer?: string
    model?: string
    invoiceDetails?: string
  }
  export interface Application {
    id: string
    port: ITenant
    contract: Contracts.Link
    person: IUserDto
    firstName: string
    lastName: string
    email: string
    kind: Kind
    cardNumber?: string
    pin?: number
    numberPlate?: string
    ivrCode?: string
    application?: By
    approved?: By
    completed?: By
    status: Status
    validTo?: string
    consent: boolean
    portrait: IFileRefDto[]
    files: IFileRefDto[]
    courseAttendances: string[]
    data: ApplicationData
  }

  export interface Summary {
    application: Application
    facilities: ContractAreaLink[]
  }

  export interface Details {
    contract: Contracts.Detailed
    courses: CourseAttendees.Summary[]
    summary: Summary
  }
}
export type ContractKind = 'company' | 'vessel'

export class ContractsSchedulesApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async getSchedules(tenantRef: string, id: string): Promise<Contracts.FacilityContractScheduleResponse[]> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/${id}/schedules`)
    return response.data.map((e) => {
      return { facilityId: e.area.id, userId: e.handler ?? undefined, schedule: convertToScheduling(e.schedule) }
    })
  }

  async getSchedulesContractFacility(tenantRef: string, id: string): Promise<Scheduling> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/facilities/${id}/schedules`)
    return convertToScheduling(response.data)
  }

  async updateContractFacilitySchedules(tenantRef: string, id: string, form: Scheduling): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/facilities/${id}/schedules`, convertToTScheduling(form))
  }

  async updateFacilitySchedules(tenantRef: string, id: string, userId: string, form: Scheduling): Promise<void> {
    await this.httpClient.put(
      `/ports/${tenantRef}/contracts/facilities/${id}/schedules?handler=${encodeURI(userId)}`,
      convertToTScheduling(form)
    )
  }
}

export class ContractsApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async listApplications(
    tenantRef: string,
    params: ContractApplications.Params
  ): Promise<Page<ContractApplications.Summary>> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/applications`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async getApplication(tenantRef: string, id: string): Promise<ContractApplications.Details> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/applications/${id}`)
    return response.data
  }

  async createApplication(tenantRef: string, form: ContractApplications.Form): Promise<{ id: string }> {
    const res = await this.httpClient.post(`/ports/${tenantRef}/contracts/applications`, form)
    return res.data
  }

  async updateApplication(tenantRef: string, id: string, form: ContractApplications.Form): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/applications/${id}`, form)
  }
  async approveApplication(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/applications/${id}/approve`)
  }
  async readyForPickup(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/applications/${id}/ready_for_pickup`)
  }
  async rejectApplication(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/applications/${id}/reject`)
  }
  async completeApplication(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/applications/${id}/complete`)
  }
  async changeStatus(tenantRef: string, id: string, status: ContractApplications.Status): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/applications/${id}/status`, status, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  async patchApplication(
    tenantRef: string,
    application: string,
    patch: ContractApplications.Patch
  ): Promise<CourseAttendees.Created> {
    const id = await this.httpClient.patch(`/ports/${tenantRef}/contracts/applications/${application}`, patch)
    return id.data
  }

  async listContracts(tenantRef: string, params: Contracts.Params): Promise<Page<Contracts.Contract>> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async getContract(tenantRef: string, id: string): Promise<Contracts.Detailed> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/${id}`)
    return response.data
  }

  async getContractWithDefaultAccess(tenantRef: string, id: string): Promise<Contracts.DetailedWithDefaultAccess> {
    try {
      const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/${id}`)
      const accesses = await this.httpClient.get(`/ports/${tenantRef}/contracts/${id}/default-accesses`)
      if (response.data && accesses.data) {
        return {
          ...response.data,
          contract: {
            ...response.data.contract,
            defaultAccesses: accesses.data,
          } as Contracts.ContractWithDefaultAccess,
        }
      } else {
        throw new Error('Failed to fetch area with default accesses')
      }
    } catch (error) {
      console.error('ERROR', error)
      return error
    }
  }

  async createContract(tenantRef: string, form: Contracts.Form): Promise<{ id: string }> {
    const res = await this.httpClient.post(`/ports/${tenantRef}/contracts`, form)
    return res.data
  }

  async updateContract(tenantRef: string, id: string, form: Contracts.Form): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/${id}`, form)
  }

  async updateContractAsClient(tenantRef: string, id: string, form: Contracts.ClientForm): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/${id}/client`, form)
  }

  async revoke(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/${id}/revoke`)
  }

  async delete(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.delete(`/ports/${tenantRef}/contracts/${id}`)
  }

  async listFacility(
    tenantRef: string,
    filter: ContractFacilities.Params,
    paging: Page.Search
  ): Promise<Page<ContractFacilities.Facility>> {
    const params = {
      ...filter,
      ...paging,
    }
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async getFacility(tenantRef: string, id: string): Promise<ContractFacilities.Details> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/facilities/${id}`)
    return response.data
  }

  async getFacilityWithDefaultAccesses(
    tenantRef: string,
    id: string
  ): Promise<ContractFacilities.DetailsWithDefaultAccess> {
    try {
      const facility = await this.getFacility(tenantRef, id)
      const accesses = await this.httpClient.get(`/ports/${tenantRef}/contracts/facilities/${id}/default-accesses`)
      if (facility && accesses.data) {
        return { ...facility, defaultAccesses: accesses.data } as ContractFacilities.DetailsWithDefaultAccess
      } else {
        throw new Error('Failed to fetch area with default accesses')
      }
    } catch (error) {
      console.error('ERROR', error)
      return error
    }
  }

  async updateFacilityDefaultAccesses(
    tenantRef: string,
    id: string,
    form: DefaultAccess.IDefaultAccesses
  ): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/facilities/${id}/default-accesses`, form)
  }

  async createFacility(tenantRef: string, form: ContractFacilities.Form): Promise<string> {
    const res = await this.httpClient.post(`/ports/${tenantRef}/contracts/facilities`, form)
    return res?.data?.id
  }

  async updateFacility(tenantRef: string, id: string, form: ContractFacilities.Form): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/contracts/facilities/${id}`, form)
  }

  async revokeFacility(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.delete(`/ports/${tenantRef}/contracts/facilities/${id}`)
  }

  async listVisitHandlers(
    tenantRef: string,
    filter: ContractVisitHandlers.Params,
    paging: Page.Search
  ): Promise<Page<ContractVisitHandlers.Details>> {
    const params = {
      ...filter,
      ...paging,
    }
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/visit-handlers`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async getVisitHandler(tenantRef: string, id: string): Promise<ContractFacilities.Details> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/contracts/visit-handlers/${id}`)
    return response.data
  }

  async addVisitHandler(tenantRef: string, form: ContractVisitHandlers.Form): Promise<void> {
    await this.httpClient.post(`/ports/${tenantRef}/contracts/visit-handlers`, form)
  }

  async removeAccessApprover(tenantRef: string, id: string): Promise<void> {
    await this.httpClient.delete(`/ports/${tenantRef}/contracts/visit-handlers/${id}`)
  }
}

export default ContractsApi
