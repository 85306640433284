import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@griegconnect/krakentools-react-icons'
import { Hidden } from '@mui/material'
import { useFullscreenContext } from '../FullscreenContext'

import MapButton from '../shared/MapButton'

export const FullscreenControl = () => {
  const { fullscreenToggle, isFullscreen } = useFullscreenContext()

  return (
    <Hidden xlDown={true}>
      <MapButton onClick={fullscreenToggle} icon={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} />
    </Hidden>
  )
}

export default FullscreenControl
