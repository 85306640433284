import ServicesWrapper from '@app/common/ServicesWrapper'
import LocaleWrapper from '@app/common/locale/LocaleWrapper'
import { SignInType, TenantContextWrapper } from '@griegconnect/krakentools-react-kraken-app'
import Content from './Content'
import { useEffect } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

/**
 * This is a temporary hack for supporting two "apps" within the same codebase. Later we should introduce a new visitor app separately.
 * NB: There are also a hack introduced inside src/routes/SelectContext/index.tsx
 *
 * To test with ports on local machine, start the app two times with different ports:
 * - 'pnpm run isps' and 'pnpm run visit-isps'
 */

const hostname = window.location.hostname
export const inProduction = hostname.includes('griegconnect.com') || hostname.includes('krakentools.com')
export const isVisitDomain = hostname.startsWith('visit') || window.location.port === '3001'
const searchParams = new URLSearchParams(window.location.search)
export const isStavanger = searchParams.get('ref') === 'SVG' || searchParams.get('ref') === 'griegconnect'
export const userType = searchParams.get('usertype') ?? 'username-password'

export const isFullDev = window.location.port === '3000' // To allow developers to work on /users withiout having to start the application twice
// end of site hack

export const selectSigninMethods = (): SignInType[] => {
  if (isVisitDomain) {
    return userType === 'visitor' ? ['sms'] : ['email']
  }
  return ['username-password']
}

export const infoText = (): string => {
  const prefix = isStavanger ? 'Stavanger Havn relies on security services from Grieg Connect. ' : ''
  if (isVisitDomain) {
    return prefix + 'Please click the sign-in button to proceed.'
  } else {
    return ''
  }
}

export const title = () => {
  if (isStavanger && userType === 'visitor') {
    return 'Request access'
  }
  return 'Login'
}

export const getFrontendConfigPath = () => {
  if (inProduction) {
    return isVisitDomain ? '/config/config-visit.json' : '/config/config-security.json'
  } else {
    return '/config/config.json'
  }
}

export const passwordlessDescriptionText = (): string => {
  if (isVisitDomain && userType !== 'visitor') {
    return 'Click on the button to login with email. You will recieve a one-time code in your inbox.'
  }
  return ''
}

export const redirect = (navigate: NavigateFunction) => {
  if (!isFullDev && isVisitDomain && window.location.pathname === '/') {
    navigate('/user')
  }
}

export const AutoAppSwitcher = () => {
  const navigate = useNavigate()

  useEffect(() => {
    redirect(navigate)
  }, [navigate])

  return (
    <ServicesWrapper>
      <TenantContextWrapper autoSelect={false} selectTenantType="page">
        <LocaleWrapper>
          <Content isVisitDomain={isVisitDomain} isFullDev={isFullDev} />
        </LocaleWrapper>
      </TenantContextWrapper>
    </ServicesWrapper>
  )
}
