import { AxiosInstance } from 'axios'
import { SecurityIdentity, IdentityAttributes } from './dtos/IdentityAttributes'
import Page from '@app/common/ServicesWrapper/apis/Page'
import PortApplication from './Application2'
import PortPermit from './Permits2'
import { PersistedCard as StanleyCard } from './Stanley'
import { LocalIdentity } from './dtos/identitiesDto'
import { ContractApplications, Contracts } from './ContractsApi'
import { CourseAttendees } from './CourseAttendeesApi'
import { Westec } from './WestecApi'
import { Tenant } from '@griegconnect/krakentools-react-kraken-app'
import { Bewator } from './BewatorApi'
import Application2 from './Application2'
import qs from 'qs'
import { By } from './By'
import Permits2 from './Permits2'

export declare namespace UserApi {
  export namespace Application {
    export type VisitingTargetForm = { type: 'company' | 'customer' | 'vessel'; value: { id: string } }

    export interface VisitingForm {
      facility: string
      purpose: string
      timeIn: string
      timeOut: string | null
      target: VisitingTargetForm | null
      visitHandler: string | null
    }

    export interface VisitorForm {
      company: string
      person: PersonForm | null
      vehicle: UserApi.Application.VehicleForm | null
    }

    export interface PersonForm {
      mobile: string
      files: string[]
    }

    export interface VehicleForm {
      vehicle: string
      trailer: string | null
      subcontractor: string | null
      files: string[]
    }

    export interface Fields {
      email: string
    }

    export interface Form {
      visiting: VisitingForm
      visitor: VisitorForm
      message: string
      securityConfirmations: string[]
      integrations: Integrations
      fields: Fields
    }

    export interface CreateForm {
      form: Form
      port: string
      stripeInvoice?: string
    }

    export type Integrations = PortApplication.Integrations

    export interface Details {
      current: Application
      history: Application[]
    }
  }
  export interface Application {
    meta: PortApplication.Meta
    modified: string
    invitedBy: By
    visiting: PortApplication.Visiting
    securityConfirmations: string[]
    message: string
    integrations: Application.Integrations
    status: PortApplication.Status
    statusMessage: string
    visitor: PortPermit.Visitor
    fields: Application.Fields
  }

  export interface Permit {
    meta: PortPermit.Meta
    group: string | null
    application: string | null
    modified: string
    pin: string | null
    visiting: PortPermit.Visiting
    visitor: PortPermit.Visitor
  }
  export interface AccessCards {
    port: Tenant.Type
    stanley: StanleyCard[]
    bewator: Bewator.User[]
    westec: Westec.Card[]
  }
  export interface Details {
    visitHandler: Contracts.Contract[]
    contractOwner: Contracts.Contract[]
    identities: LocalIdentity[]
  }
}

export class UserApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async updateProfile(
    idDocument?: string,
    company?: string,
    symmetryBlue?: string,
    pin?: number,
    cardNumbers?: number[]
  ): Promise<void> {
    const payload = {
      idDocument,
      company,
      symmetryBlue,
      pin,
      cardNumbers,
    }
    await this.httpClient.put('/user/profile', payload)
  }

  async profile(): Promise<IdentityAttributes> {
    const response = await this.httpClient.get('/user/profile')
    return response.data
  }

  async details(port?: string): Promise<UserApi.Details> {
    const response = await this.httpClient.get(`/user/details`, { params: { port } })
    return response.data
  }

  async me(): Promise<SecurityIdentity> {
    const response = await this.httpClient.get(`/user/profile/me`)
    return response.data
  }

  async myPermits(): Promise<Page<UserApi.Permit>> {
    const response = await this.httpClient.get(`/user/my/permits`)
    return response.data
  }

  async applications(): Promise<Page<UserApi.Application>> {
    const response = await this.httpClient.get(`/user/applications2`)
    return response.data
  }

  async getApplication(id: string): Promise<UserApi.Application.Details> {
    const response = await this.httpClient.get(`/user/applications2/${id}`)
    return response.data
  }

  async createApplication(form: UserApi.Application.CreateForm): Promise<string> {
    const response = await this.httpClient.post(`/user/applications2`, form)
    return response.data.id
  }

  async updateApplication(id: string, form: UserApi.Application.Form): Promise<string> {
    const response = await this.httpClient.put(`/user/applications2/${id}`, form)
    return response.data.revision
  }

  async myFixedApplication(id: string): Promise<ContractApplications.Details> {
    const response = await this.httpClient.get(`/user/fixed-applications/${id}`)
    return response.data
  }

  async myFixedApplications(): Promise<ContractApplications.Summary[]> {
    const response = await this.httpClient.get(`/user/fixed-applications`)
    return response.data
  }

  async updateFixedApplication(id: string, form: ContractApplications.Form): Promise<void> {
    await this.httpClient.put(`/user/fixed-applications/${id}`, form)
  }

  async myAccessCards(): Promise<UserApi.AccessCards[]> {
    const res = await this.httpClient.get(`/user/access-cards`)
    return res.data
  }

  async myCourses(): Promise<CourseAttendees.Summary[]> {
    const res = await this.httpClient.get(`/user/courses`)
    return res.data
  }

  async addCourse(form: CourseAttendees.UserForm): Promise<CourseAttendees.Created> {
    const id = await this.httpClient.post(`/user/courses`, form)
    return id.data
  }

  async visitRequests(params: Application2.Params): Promise<Page<Application2>> {
    try {
      const res = await this.httpClient.get(`/user/visitor-requests`, {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      return res.data
    } catch (e) {
      return Page.empty
    }
  }

  async contracts(params: Contracts.Params): Promise<Page<Contracts.Contract>> {
    const res = await this.httpClient.get(`/user/contracts`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return res.data
  }

  async contract(id: string): Promise<Contracts.Detailed> {
    const res = await this.httpClient.get(`/user/contracts/${id}`)
    return res.data
  }

  async contractWithDefaultAccess(id: string): Promise<Contracts.DetailedWithDefaultAccess> {
    const res = await this.httpClient.get(`/user/contracts/${id}`)
    return {
      ...res.data,
      contract: { ...res.data.contract, defaultAccesses: {} },
    } as Contracts.DetailedWithDefaultAccess
  }

  async permits(filter: Permits2.Params, paging: Page.Search): Promise<Page<Permits2.Private>> {
    const params = {
      ...filter,
      ...paging,
      valid: filter.valid?.map((i) => i.toISO()),
      invalid: filter.invalid?.map((i) => i.toISO()),
    }
    const response = await this.httpClient.get(`/user/permits`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async accessRequests(params: ContractApplications.Params): Promise<Page<ContractApplications.Summary>> {
    const res = await this.httpClient.get(`/user/access-requests`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return res.data
  }
}
