import { IFilterSectionValuesKeyValue } from '@griegconnect/krakentools-react-ui'
import humanizeDuration from 'humanize-duration'
import { DateTime, Duration } from 'luxon'

export const options: IFilterSectionValuesKeyValue[] = [
  {
    label: 'Current year',
    value: '3000',
  },
  {
    label: 'Last 30 days',
    value: '3001',
  },
  {
    label: 'Current month',
    value: '3002',
  },
  {
    label: 'Last 7 days',
    value: '3003',
  },
  {
    label: 'Current week',
    value: '3004',
  },
  {
    label: 'Today',
    value: '3005',
  },
]

export const getDates = (period: string) => {
  let fromDate = DateTime.now().startOf('day')
  let toDate = DateTime.now().endOf('day')
  switch (period) {
    case '':
      fromDate.set({ year: 2000 }).startOf('year')
      break
    case '3000':
      fromDate = fromDate.startOf('year')
      break
    case '3001':
      fromDate = fromDate.minus({ day: 30 })
      break
    case '3002':
      fromDate = fromDate.startOf('month')
      break
    case '3003':
      fromDate = fromDate.minus({ day: 7 })
      break
    case '3004':
      fromDate = fromDate.startOf('week')
      break
    case '3005':
      fromDate = DateTime.now().minus({ day: 1 })
      toDate = DateTime.now()
      break
    default:
      break
  }
  return { fromDate, toDate }
}

export const alignDates = (fromDate: DateTime, toDate: DateTime = DateTime.now()) => {
  const now = DateTime.now()
  const diff = fromDate.diff(toDate).negate()
  if (diff.as('days') > 32) {
    fromDate = fromDate.set({ month: now.month, day: now.day })
  } else if (diff.as('days') > 8) {
    fromDate = fromDate.set({ day: now.day })
  } else {
    fromDate = fromDate.set({ weekday: now.weekday })
  }
  const to = fromDate.plus(diff)
  return { from: fromDate, to }
}

export const currentPeriod = (from: DateTime, to: DateTime = DateTime.now()) => {
  const diff = from.diff(to).negate().as('days')
  const newFrom = diff > 32 ? from.startOf('year') : diff > 8 ? from.startOf('month') : from.startOf('week')
  const newTo = diff > 32 ? from.endOf('year') : diff > 8 ? from.endOf('month') : from.endOf('week')
  return { from: newFrom, to: newTo }
}

export const getConstructorDates = (
  timeFrom: string | Duration,
  isCurrentPeriod: boolean = false,
  timeTo: string | Duration = ''
) => {
  const now = DateTime.now()
  const durationFrom = Duration.fromISO(timeFrom as unknown as string)
  const durationTo = Duration.fromISO(timeTo as unknown as string)
  const startDate = now.minus({ days: durationFrom.as('days') })
  const endDate = timeTo ? now.minus({ days: durationTo.as('days') }) : now
  const period = currentPeriod(startDate, endDate)
  const fromDate = isCurrentPeriod ? period.from : startDate
  const toDate = isCurrentPeriod ? period.to : endDate
  return { fromDate, toDate }
}

export const getFilterText = (defaultText: string, timeFrom?: Duration, timeTo?: Duration, currentPeriod?: boolean) => {
  if (timeFrom) {
    const now = DateTime.now()
    const dates = getConstructorDates(timeFrom, currentPeriod, timeTo)
    dates.toDate > now && (dates.toDate = now)
    const fromYear = dates.fromDate.year !== dates.toDate.year ? ` ${dates.fromDate.year}` : ''
    const from = `from ${dates.fromDate
      .setLocale('en-gb')
      .toLocaleString({ month: 'long', day: '2-digit' })}${fromYear}`
    const toYear = dates.fromDate.year !== now.year ? ` ${dates.toDate.year}` : ''
    const to = `to ${dates.toDate.setLocale('en-gb').toLocaleString({ month: 'long', day: '2-digit' })}${toYear}`
    return timeTo || currentPeriod
      ? `${from} ${to}`
      : `last ${humanizeDuration(timeFrom.toMillis(), {
          largest: 1,
          round: true,
          delimiter: ', ',
        })}`
  }
  return defaultText
}

export const getChartLabels = (time: string | undefined, isSM?: boolean) => {
  const weeksCurrentMonth = DateTime.now().endOf('month').weekNumber - DateTime.now().startOf('month').weekNumber + 1
  const weeksLastMonth = DateTime.now().weekday > 2 ? 5 : 6
  const numItems =
    time === 'last 24 hours'
      ? 24
      : time === 'current week' || time === 'last 7 days'
      ? 7
      : time === 'current month'
      ? weeksCurrentMonth
      : time === 'last 30 days'
      ? weeksLastMonth
      : 12
  let times: string[] = []
  for (let i = 0; i < numItems; i++) {
    time === 'last 24 hours'
      ? times.unshift(
          DateTime.now()
            .set({ minute: 0 })
            .minus({ hour: i })
            .toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })
        )
      : time === 'current week'
      ? times.unshift(`${DateTime.now().endOf('week').minus({ day: i }).weekdayShort}`)
      : time === 'last 7 days'
      ? times.unshift(`${DateTime.now().minus({ day: i }).weekdayShort}`)
      : time === 'current month'
      ? times.unshift(`week ${DateTime.now().endOf('month').minus({ week: i }).weekNumber}`)
      : time === 'last 30 days'
      ? times.unshift(`week ${DateTime.now().minus({ week: i }).weekNumber}`)
      : times.unshift(DateTime.now().endOf('year').minus({ month: i }).toFormat('LLL'))
  }
  if (isSM && time === 'last 24 hours') {
    times = times.map((l, i) => (i % 2 !== 0 ? l : ''))
  }
  return times
}
