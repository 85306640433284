import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPurpose = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M10 5a7 7 0 1 0 6.53 9.53l1.513 1.513a9 9 0 1 1 0-8.086l-1.514 1.514A7.002 7.002 0 0 0 10 5Z" />,
    <path d="M14.584 10A5.001 5.001 0 0 0 5 12a5 5 0 0 0 9.584 2h-2.348a3 3 0 1 1 0-4h2.348Z" />,
    <path d="m23 10-2 2 2 2h-5l-1-1h-7a1 1 0 1 1 0-2h7l1-1h5Z" />
  )
export default SvgPurpose
