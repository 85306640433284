import React from 'react'
import { Navigation, requiresPermission, requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { withActiveTenant } from '@app/routes/WithActiveTenant'
import { withServices } from '@app/routes/withServices'
import { AccessDependencies } from '@app/Content'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { withParam } from '@app/routes/withParam'
import { usesCourses } from '@app/lib/access'
import { Trans } from 'react-i18next'

const COURSES_PATH = PATH_TENANT_BASE + '/settings/courses'

export const coursesModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.courses.courses" />,
    path: COURSES_PATH + '/',
    page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./Courses'))))),
    exact: true,
    menu: true,
    access: (d) => usesCourses(d) && requiresPermission(PERMISSIONS.course.read, true)(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.courses.newCourse" />,
        path: COURSES_PATH + '/course/new',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Course')),
                'courseId'
              )
            )
          )
        ),
        exact: true,
        access: (d) => usesCourses(d) && requiresPermission(PERMISSIONS.course.create, true)(d),
      },
      {
        name: <Trans i18nKey="navigation.courses.course" />,
        path: COURSES_PATH + '/course/edit/:courseId',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Course')),
                'courseId'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          usesCourses(d) && requiresAllOfPermissions([PERMISSIONS.course.read, PERMISSIONS.course.update], true)(d),
      },
    ],
  },
]
