import { AxiosInstance } from 'axios'
import { IGCFile } from '../../UiFileUpload'
import { ResolvedFile } from './dtos/fileRefDto'

export class FileApi {
  private fileHttpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.fileHttpClient = httpClient
  }

  async uploadUserFile(file: File, tag: string): Promise<ResolvedFile> {
    const formData = new FormData()
    formData.append('category', tag)
    formData.append('file', file)

    const response = await this.fileHttpClient.post(`/user/files`, formData)
    return response.data
  }

  async uploadManyCompanyFiles(files: IGCFile[], tenantRef: string, tag: string): Promise<ResolvedFile[]> {
    const one = (file: IGCFile) =>
      file.file && file.status === 'upload'
        ? this.uploadCompanyFile(file.file, tenantRef, tag)
        : Promise.resolve<ResolvedFile | undefined>(undefined)
    const all = await Promise.all(files.map(one))
    return all.reduce<ResolvedFile[]>((p, current) => (current ? [...p, current] : p), [])
  }

  async uploadCompanyFile(file: File, tenantRef: string, tag: string): Promise<ResolvedFile> {
    const formData = new FormData()
    formData.append('category', tag)
    formData.append('file', file)

    const response = await this.fileHttpClient.post(`/ports/${tenantRef}/files`, formData)
    return response.data
  }
}
