import React from 'react'
import * as z from 'zod'
import { IFileRefDtoSchema } from '@app/common/ServicesWrapper/apis/dtos/fileRefDto'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import * as Form from '@griegconnect/krakentools-form'
import { IPortDetailsDto } from '@app/common/ServicesWrapper/apis/SharedDataApi'
import { zodResolver } from '@hookform/resolvers/zod'
import SymmetryBlueForm from '@app/common/SymmetryBlueForm'
import { SecurityIdentity } from '@app/common/ServicesWrapper/apis/dtos/IdentityAttributes'
import { useTranslation } from 'react-i18next'
import useSearch, { Search as S } from '@app/lib/hooks/useSearch'
import { LocalIdentity } from '@app/common/ServicesWrapper/apis/dtos/identitiesDto'
import { FileUpload } from '@app/common/Form'
import { Visitor } from '../../common/types'

export const checkSymmetryBlue = (details: IPortDetailsDto, facility: string) =>
  details.integrations.stanley && Boolean(details.areas.find((a) => a.id === facility)?.flags.symmetryBlue)

const _Schema = z
  .object({
    company: z.string().min(1, { message: 'common.validation.required' }),
    integrations: z.object({
      symmetryBlue: z.string().optional(),
    }),
    isPerson: z.boolean(),
    mobile: z.string().default(''),
    personFile: z.array(IFileRefDtoSchema),
    isVehicle: z.boolean(),
    vehicle: z.string(),
    trailer: z.string(),
    subcontractor: z.string(),
    vehicleFile: z.array(IFileRefDtoSchema),
    save: z.object({
      company: z.boolean(),
      mobile: z.boolean(),
      personFile: z.boolean(),
      symmetryBlue: z.boolean(),
    }),
  })
  .refine((data) => data.isPerson || data.isVehicle, {
    message: 'applications.validation.isVehicle',
    path: ['isVehicle'],
  })
  .refine((data) => !data.isVehicle || data.vehicle.trim().length > 0, {
    message: 'applications.validation.vehicle',
    path: ['vehicle'],
  })
  .refine((data) => !data.isPerson || data.mobile.length > 0, {
    message: 'common.validation.required',
    path: ['mobile'],
  })
  .refine((data) => !data.isPerson || Form.validatePhone(data.mobile), {
    message: 'common.errors.phoneNumberInvalid',
    path: ['mobile'],
  })

export const Schema = (details: IPortDetailsDto, identities: LocalIdentity[], facility: string) => {
  const personFileRequired = (details && details.attributes && details.attributes.idDocumentRequired) || true
  return _Schema
    .refine((data) => !data.isPerson || !personFileRequired || data.personFile.length > 0, {
      message: 'applications.validation.personFile',
      path: ['personFile'],
    })
    .refine(
      (data) =>
        Boolean(data.integrations.symmetryBlue) ||
        identities.some((i) => i.local.type === 'stanley-cardholder') ||
        !checkSymmetryBlue(details, facility) ||
        !data.isPerson,
      {
        message: 'Required',
        path: ['integrations.symmetryBlue'],
      }
    )
}

export type Input = z.infer<typeof _Schema>

export const defaultValues = ({ applicationForm, me, prefill }: IDefaultValuesProps): Input => {
  return {
    integrations: applicationForm?.integrations || {
      symmetryBlue:
        me.attributes?.symmetryBlue && me.attributes?.symmetryBlue !== null
          ? me.attributes.symmetryBlue.toString()
          : '',
    },
    company: applicationForm?.visitor?.company || me.attributes?.company || '',
    isPerson:
      applicationForm?.isPerson !== undefined
        ? applicationForm.isPerson
        : prefill.isPerson !== null
        ? prefill.isPerson
        : true,
    mobile: applicationForm?.visitor?.person?.mobile || me.identity.mobile || '',
    personFile:
      applicationForm?.visitor?.person?.files || (me.attributes?.idDocument ? [me.attributes.idDocument] : []),
    isVehicle:
      applicationForm?.isVehicle !== undefined
        ? applicationForm?.isVehicle
        : prefill.isVehicle !== null
        ? prefill.isVehicle
        : true,
    vehicle: applicationForm?.visitor?.vehicle?.vehicle || prefill.vehicle || '',
    trailer: applicationForm?.visitor?.vehicle?.trailer || prefill.trailer || '',
    subcontractor: applicationForm?.visitor?.vehicle?.subcontractor || prefill.subcontractor || '',
    vehicleFile: applicationForm?.visitor?.vehicle?.files || [],
    save: applicationForm?.save || { company: false, mobile: false, personFile: false, symmetryBlue: false },
  }
}

export const toForm = async (input: Input): Promise<Visitor> => {
  return {
    visitor: {
      company: input.company,
      person: input.isPerson ? { mobile: input.mobile, files: input.personFile } : null,
      vehicle: input.isVehicle
        ? {
            vehicle: input.vehicle,
            trailer: input.trailer || null,
            subcontractor: input.subcontractor || null,
            files: input.vehicleFile,
          }
        : null,
    },
    integrations: input.integrations,
    save: input.save,
    isPerson: input.isPerson,
    isVehicle: input.isVehicle,
  }
}

interface IPrefill {
  isPerson: boolean | null
  isVehicle: boolean | null
  vehicle: string | null
  trailer: string | null
  subcontractor: string | null
}

interface IDefaultValuesProps {
  applicationForm?: Visitor
  me: SecurityIdentity
  prefill: IPrefill
}

interface Props {
  applicationForm?: Visitor
  me: SecurityIdentity
  identities: LocalIdentity[]
  details: IPortDetailsDto
  facility: string
  disabled: boolean
  onUpdate: (form: Visitor, part: 'visitor') => void
  onNext: () => void
  updateErrors: (errors: boolean) => void
  children?: React.ReactNode
}

function StepVisitor({
  applicationForm,
  me,
  identities,
  details,
  facility,
  disabled,
  onUpdate,
  onNext,
  updateErrors,
  children,
}: Props) {
  const [search] = useSearch({
    isPerson: S.boolean,
    isVehicle: S.boolean,
    vehicle: S.string,
    trailer: S.string,
    subcontractor: S.string,
  })

  const form = useForm({
    defaultValues: defaultValues({
      applicationForm,
      me,
      prefill: search,
    }),
    resolver: zodResolver(Schema(details, identities, facility)),
  })
  const { t } = useTranslation()

  const isPerson = form.watch('isPerson')
  const isVehicle = form.watch('isVehicle')
  const company = form.watch('company')
  const mobile = form.watch('mobile')
  const personFile = form.watch('personFile')
  const integrations = form.watch('integrations')
  const vehicle = form.watch('vehicle')
  const trailer = form.watch('trailer')
  const subcontractor = form.watch('subcontractor')
  const vehicleFile = form.watch('vehicleFile')
  const save = form.watch('save')

  const personFileArray = useFieldArray({
    control: form.control,
    name: 'personFile',
  })

  const vehicleFileArray = useFieldArray({
    control: form.control,
    name: 'vehicleFile',
  })

  const hasSymmetryBlue = checkSymmetryBlue(details, facility)
  const applicationTrailerInput = details && details.attributes && details.attributes.applicationTrailerInput
  const applicationSubcontractorInput =
    details && details.attributes && details.attributes.applicationSubcontractorInput

  const errors = form.formState.errors

  React.useEffect(() => {
    if (!!Object.keys(errors).length) {
      form.trigger()
    }
  }, [isPerson, isVehicle]) // eslint-disable-line

  React.useEffect(() => {
    if (personFileArray.fields.length > 0) {
      form.clearErrors('personFile')
    }
  }, [personFileArray.fields.length]) // eslint-disable-line

  React.useEffect(() => {
    updateErrors(!!Object.keys(errors).length)
    // eslint-disable-next-line
  }, [
    errors.company,
    errors.isVehicle,
    errors.vehicle,
    errors.mobile,
    errors.personFile,
    errors.integrations?.symmetryBlue,
  ])

  /* eslint-disable */
  React.useEffect(() => {
    const updateParentForm = async () => {
      const input: Input = {
        company,
        integrations,
        isPerson,
        mobile,
        personFile,
        isVehicle,
        vehicle,
        trailer,
        subcontractor,
        vehicleFile,
        save,
      }
      onUpdate(await toForm(input), 'visitor')
    }
    updateParentForm()
  }, [
    company,
    integrations,
    isPerson,
    mobile,
    personFile,
    isVehicle,
    vehicle,
    trailer,
    subcontractor,
    vehicleFile,
    save,
  ])
  /* eslint-enable */

  const onSubmit = async (_data: Input) => onNext()

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Typography>{t('applications.subTitle.visitor')} *</Typography>
        <Box mb={1} />
        <Form.Checkbox disabled={disabled} name="isPerson" control={form.control} label={t('security.labels.person')} />
        <Form.Checkbox
          disabled={disabled}
          name="isVehicle"
          control={form.control}
          label={t('security.labels.vehicle')}
        />
        <Box mb={2} />
        <Typography>{t('security.labels.person')}</Typography>
        <Box mb={1} />
        <Form.TextInput
          disabled={disabled}
          name={'company'}
          label={t('components.applications.fields.labels.visitorCompany')}
          control={form.control}
          required
        />
        {company && company !== me.attributes.company && (
          <Form.Checkbox
            disabled={disabled}
            name={'save.company'}
            label={t('applications.actions.saveProfile')}
            control={form.control}
          />
        )}
        {isPerson && (
          <>
            <Form.TextInput
              disabled={disabled}
              name={'mobile'}
              label={t('security.labels.mobile')}
              control={form.control}
              required
            />
            {mobile && mobile !== me.identity.mobile && (
              <Form.Checkbox
                disabled={disabled}
                name={'save.mobile'}
                label={t('applications.actions.saveProfile')}
                control={form.control}
              />
            )}
            {hasSymmetryBlue && (
              <>
                <SymmetryBlueForm
                  identities={identities}
                  disabled={disabled}
                  me={me}
                  facility={facility}
                  name={'integrations.symmetryBlue'}
                  port={details.port.ref}
                />
                {integrations.symmetryBlue &&
                  integrations.symmetryBlue !==
                    (me.attributes.symmetryBlue ? me.attributes.symmetryBlue.toString() : null) && (
                    <Form.Checkbox
                      disabled={disabled}
                      name={'save.symmetryBlue'}
                      label={t('applications.actions.saveProfile')}
                      control={form.control}
                    />
                  )}
              </>
            )}
            <FileUpload
              disabled={disabled}
              arrayMethods={personFileArray}
              control={form.control}
              name={`personFile`}
              kind="photos"
              title={`${t('components.applications.files.personId.title')} *`}
              uploadLabel={t('components.applications.files.personId.upload')}
              modifyLabel={t('components.applications.files.personId.change')}
            />
            {personFile.length > 0 && personFile[0].id !== me.attributes.idDocument?.id && (
              <Form.Checkbox
                disabled={disabled}
                name={'save.personFile'}
                label={t('applications.actions.saveProfile')}
                control={form.control}
              />
            )}
            <Box pb={2} />
          </>
        )}

        {isVehicle && (
          <>
            <Box mb={1} />
            <Typography>{t('security.labels.vehicle')}</Typography>
            <Box mb={1} />
            <Form.NumberPlate
              disabled={disabled}
              name={'vehicle'}
              label={t('security.labels.vehicle')}
              control={form.control}
              required={true}
            />
            <Box m={1} />
            {applicationTrailerInput && (
              <>
                <Form.NumberPlate
                  disabled={disabled}
                  name={'trailer'}
                  label={t('components.applications.fields.labels.trailerPlate')}
                  control={form.control}
                />
                <Box m={1} />
              </>
            )}
            {applicationSubcontractorInput && (
              <>
                <Form.TextInput
                  disabled={disabled}
                  name={'subcontractor'}
                  label={t('components.applications.fields.labels.subContractor')}
                  control={form.control}
                />
              </>
            )}
            <FileUpload
              disabled={disabled}
              arrayMethods={vehicleFileArray}
              control={form.control}
              name={`vehicleFile`}
              kind="files"
              title={t('components.applications.files.vehicle.title')}
              uploadLabel={t('components.applications.files.vehicle.upload')}
              modifyLabel={t('components.applications.files.vehicle.change')}
            />
          </>
        )}
        {children}
      </form>
    </FormProvider>
  )
}

export default StepVisitor
