import { useBreakpointDown } from '@griegconnect/krakentools-react-kraken-app'
import { Box, useTheme } from '@mui/material'

export interface FlowNavigationProps {}

const FlowNavigation: React.FC<React.PropsWithChildren<FlowNavigationProps>> = ({ children }) => {
  const isSmall = useBreakpointDown('sm')
  const theme = useTheme()

  if (isSmall) {
    return (
      <Box
        sx={{
          position: 'fixed',
          padding: '1em',
          bottom: 0,
          height: '70px',
          left: 0,
          right: 0,
          justifyContent: 'flex-end',
          display: 'flex',
          bgcolor: theme.palette.background.default,
        }}
      >
        {children}
      </Box>
    )
  }

  return (
    <Box display="flex" justifyContent="flex-end" mt={4}>
      {children}
    </Box>
  )
}

export default FlowNavigation
