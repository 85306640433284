import { Typography, useMediaQuery, Box } from '@mui/material'
import { useTheme, Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { Button, Grid, Link } from '@mui/material'
import CloudUpload from '@mui/icons-material/CloudUpload'
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline'
import React from 'react'
import MiddleEllipsis from 'react-middle-ellipsis'
import { FileDropZone } from '@griegconnect/krakentools-react-ui'
import { useTranslation } from 'react-i18next'

interface IUiFileUploadProps {
  classes: any
  id?: string
  title?: string
  uploadButtonlabel?: string
  multiple?: boolean
  value?: IGCFile[]
  onChange: (files: IGCFile[]) => void
  showLinks: boolean
  disabled?: boolean
}

export interface IGCFile {
  file?: File
  id?: string
  name: string
  status: IGFileAction
  href?: string
  filename?: string
  resolved?: boolean
}

type IGFileAction = 'upload' | 'delete' | 'persisting'

const styles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(),
      width: '90%',
    },
    input: {
      display: 'none',
    },
    title: {
      marginTop: 0,
    },
    fileItem: {
      height: '24px',
      lineHeight: '24px',
      overflow: 'hidden',
      marginBottom: theme.spacing(2),
      '& div': {
        display: 'inline-block',
      },
    },
    deleteFileItemIcon: {
      marginRight: 4,
      verticalAlign: 'middle',
      opacity: 0.2,
      cursor: 'pointer',
      '&:hover': {
        opacity: 1,
      },
    },
  })

const UiFileUpload: React.FunctionComponent<React.PropsWithChildren<IUiFileUploadProps>> = (props) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isSm = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  const { classes } = props
  const allowMultipleFiles: boolean = props.multiple || false
  const fileOrFiles: string = allowMultipleFiles ? 'files' : 'file'
  const label: string = props.uploadButtonlabel ? props.uploadButtonlabel : `Upload ${fileOrFiles}`
  const title: string = props.title ? props.title : `${fileOrFiles.toLocaleUpperCase()} to upload`
  const files: IGCFile[] | undefined = props.value

  const fileUploadElement: React.RefObject<HTMLInputElement> = React.createRef()

  const updateFiles = (newFiles: File[]) => {
    const currentfiles: IGCFile[] = props.value ? [...props.value] : []
    for (const newFile of newFiles as File[]) {
      (!!allowMultipleFiles || currentfiles.length === 0) && currentfiles.push({
        file: newFile,
        id: undefined,
        name: newFile.name,
        status: 'upload',
      })
    }
    props.onChange(currentfiles)
  }

  function onChangeHandler(evt: any) {
    const newFiles = evt.target.files
    updateFiles(newFiles)
    // Clear the input
    fileUploadElement.current!.value = ''
  }

  const onDropHandler = (files: FileList) => updateFiles(files as any as File[])

  const removeFileHandler = (fileToRemove: IGCFile) => (evt: any) => {
    const files: IGCFile[] = []
    for (const file of props.value!) {
      if (file.name === fileToRemove.name && file.id === fileToRemove.id) {
        files.push({
          file: file.file,
          id: file.id,
          name: file.name,
          status: 'delete',
        })
      } else {
        files.push(file)
      }
    }
    props.onChange(files)
  }

  function triggerFileUploadDialog() {
    fileUploadElement.current!.click()
  }

  interface FileLinkProps {
    file: IGCFile
  }
  const FileLink = ({ file }: FileLinkProps) => {
    if (file.href) {
      return (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={file.href}
          title={t('common.paragraphs.downloadDocument')}
          key={file.id}
        >
          {file.name}
        </Link>
      )
    } else {
      return <span>{file.name}</span>
    }
  }

  return (
    <Grid container={true} alignItems="flex-start">
      {files && files.length > 0 && (
        <Grid container={true} item={true}>
          <Typography component="h2" variant="overline" gutterBottom={true}>
            {title}
          </Typography>
          {props.showLinks ? (
            files.map((fileElement: IGCFile, index: number) => {
              return (
                <Typography className={classes.fileItem} key={index} style={{ whiteSpace: 'nowrap' }} component="div">
                  <RemoveCircleOutline
                    className={classes.deleteFileItemIcon}
                    onClick={removeFileHandler(fileElement)}
                  />
                  <MiddleEllipsis style={{ display: 'inline-block' }}>
                    <FileLink file={fileElement} />
                  </MiddleEllipsis>
                </Typography>
              )
            })
          ) : (
            <></>
          )}
        </Grid>
      )}
      <Grid container={true} item={true} justifyContent={isXs ? 'center' : 'flex-start'} xs={12}>
        <input
          accept="image/*,application/pdf"
          id="contained-button-file"
          multiple={allowMultipleFiles}
          type="file"
          onChange={onChangeHandler}
          className={classes.input}
          ref={fileUploadElement}
        />
        {isSm ? (
          <Button
            variant="outlined"
            disabled={(files && files.length === 1 && !allowMultipleFiles) || props.disabled}
            endIcon={<CloudUpload />}
            onClick={triggerFileUploadDialog}
            fullWidth={true}
          >
            {label}
          </Button>
        ) : (
          <FileDropZone fullWidth={true} onFilesDropped={onDropHandler} dropText={t('common.paragraphs.dropFiles')}>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ p: 2, border: '1px dashed grey' }}>
              <Button
                variant="text"
                disabled={(files && files.length === 1 && !allowMultipleFiles) || props.disabled}
                endIcon={<CloudUpload />}
                onClick={triggerFileUploadDialog}
                fullWidth={true}
              >
                {label}
              </Button>
              <Typography variant="body1">
                ({t('common.paragraphs.dragAndDropFiles')})
              </Typography>
            </Box>
          </FileDropZone>
        )}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(UiFileUpload)
