import { useServices } from '@app/common/ServicesWrapper'
import { Tenant } from '@griegconnect/krakentools-react-kraken-app'
import { AppLoader } from '@griegconnect/krakentools-react-ui'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

export interface WithUserTenantProps {
  activeTenant: Tenant.Type
}

export function withUserTenant<P extends WithUserTenantProps>(
  Component: React.ComponentType<React.PropsWithChildren<P>>
): React.ComponentType<React.PropsWithChildren<Omit<P, keyof WithUserTenantProps>>> {
  const Wrapped = (props: Omit<P, keyof WithUserTenantProps>) => {
    const { services } = useServices()
    const { tenantRef } = useParams<{ tenantRef: string }>()
    const [activeTenant, setActiveTenant] = useState<Tenant.Type>()
    useEffect(() => {
      const go = async () => {
        const ports = await services.sharedDataApi.getPorts({})
        const tenant = ports.find((p) => p.tenant.ref === tenantRef)?.tenant
        if (tenant) {
          const tenantType = Object.assign({}, tenant as any, {
            companies: [],
            permissions: [],
          }) as Tenant.Type
          setActiveTenant(tenantType)
        }
      }
      go()
    }, [services.sharedDataApi, tenantRef])
    const all = { ...props, activeTenant } as P
    return activeTenant ? <Component {...all} /> : <AppLoader />
  }
  return Wrapped
}
