import { useBreakpointUp } from '@griegconnect/krakentools-react-kraken-app'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface FlowHeaderProps {
  i18n: string
}

const FlowHeader: React.FC<FlowHeaderProps> = ({ i18n }) => {
  const { t } = useTranslation()
  const isDesktop = useBreakpointUp('sm')

  return (
    <Box pb={1} display={'flex'} justifyContent={isDesktop ? 'flex-start' : 'center'}>
      <Typography variant="body1" sx={{ fontWeight: 450 }}>
        {t(i18n)}
      </Typography>
    </Box>
  )
}
export default FlowHeader
