import { Stack, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Paragraph } from 'src/common/Paragraph'
import { StepProps } from '.'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const StepSuccess: React.FC<StepProps> = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Stack spacing={2} alignItems="center">
      <CheckCircleOutlineIcon sx={{ fontSize: 100, color: theme.palette.primary.main, alignItems: 'center' }} />
      <Paragraph variant="subtitle1" align="center">
        {t('applications.paragraphs.successText')}
      </Paragraph>
    </Stack>
  )
}

export default StepSuccess
