import React from 'react'
import { ISecurityConfirmationDto } from '@app/common/ServicesWrapper/apis/dtos/securityConfirmationsDto'
import { ApplicantSecurityConfirmation } from './applications/ApplicantSecurityConfirmation'

interface Props {
  securityConfirmations: ISecurityConfirmationDto[]
  value: string[]
  onChange?: (confirmations: string[]) => void
  translate?: boolean // temporary avoid translation on pages without it
}

const SecurityConfirmations: React.VFC<Props> = ({ securityConfirmations, value, onChange, translate }) => {
  const toggle = (id: string) => {
    const updated = value.includes(id) ? value.filter((c) => c !== id) : [...value, id]
    if (onChange) {
      onChange(updated)
    }
  }

  return (
    <ApplicantSecurityConfirmation
      portSecurityConfirmations={securityConfirmations}
      currentUserSecurityConfirmations={value}
      onConfirmationToggle={onChange ? toggle : undefined}
      translate={translate}
    />
  )
}

export default SecurityConfirmations
