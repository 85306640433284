import { AxiosInstance } from 'axios'
import qs from 'qs'
import { AreaCheckListDto, AreaCheckLists, AreaLink, AreaTargets } from './dtos/areaDto'

export interface SecurityLevel {
  id: string
  securityLevel: number
  facility: AreaLink
  checklists: AreaCheckLists
  targets: AreaTargets
}

export interface SecurityLevelForm {
  facility: string
  securityLevel: number
  checklists: AreaCheckListDto
  targets: AreaTargets
}

export interface SecurityLevelParams {
  facility?: string[]
}

export class SecurityLevelApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async list(tenantRef: string, search: SecurityLevelParams): Promise<SecurityLevel[]> {
    const params = { ...search }
    const response = await this.http.get(`/ports/${tenantRef}/securityLevels`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    return response.data.securityLevels
  }

  async upsert(tenantRef: string, form: SecurityLevelForm): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/securityLevels`, form)
  }

  async get(tenantRef: string, id: string): Promise<SecurityLevel> {
    const response = await this.http.get(`/ports/${tenantRef}/securityLevels/${id}`)
    return response.data
  }
}
