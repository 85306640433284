import TranslateIcon from '@mui/icons-material/Translate'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  FormControlProps,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { IsoCode, IsoCodeTranslation, translationsFor } from './lang2lang'
import { useTranslatable } from './TranslatableContext'
import { Translations } from './lang2lang/lang'

export type TranslatableChangeEvent =
  | (Event & { target: { value: Translations; name: string } })
  | React.ChangeEvent<HTMLInputElement>

export interface TranslatableTextFieldProps {
  showISOCode?: boolean
  // TextField props
  id: FormControlProps['id']
  sx?: FormControlProps['sx']
  variant?: FormControlProps['variant']
  placeholder?: FormControlProps['placeholder']
  onChange?: (event: TranslatableChangeEvent, child: React.ReactNode) => void
  fullWidth?: boolean
  className?: string
  label: string
  multiline?: boolean
  disabled?: boolean
  error?: boolean
  helperText?: string
  userLocale?: IsoCode
  value?: Translations
}

export const toTranslations = (value: string, lang: IsoCode, i18n: Translations | null): Translations =>
  Object.assign(i18n || {}, {
    [lang]: value,
  })

export const fromTranslations = (lang: string, translations?: Translations | null): string | undefined => {
  if (translations !== null && translations) {
    return translations[lang as IsoCode]
  } else {
    return undefined
  }
}

const translationSanitizer = (value: Translations | undefined, defaultLocale: IsoCode): Translations =>
  value ? { ...value, [defaultLocale]: value[defaultLocale] || '' } : { [defaultLocale]: '' }

export const TranslatableTextField = ({
  showISOCode = true,
  id,
  sx,
  variant,
  placeholder,
  onChange,
  fullWidth,
  className,
  label,
  multiline,
  disabled,
  error,
  helperText,
  userLocale,
  value,
}: TranslatableTextFieldProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [uxTranslations, setUxTranslations] = useState<IsoCodeTranslation>(translationsFor(userLocale || 'en'))
  const ctx = useTranslatable()
  const defaultLocale = ctx.getDefaultLocale()
  const [inputValue, setInputValue] = useState<Translations>(translationSanitizer(value, defaultLocale))

  const defaultLang = uxTranslations ? uxTranslations.language[defaultLocale] : 'unknown'
  const defaultValue = useMemo(() => inputValue[defaultLocale] || '', [inputValue, defaultLocale])

  // In case user locale are changed on the outside
  useEffect(() => {
    setUxTranslations(translationsFor(userLocale || defaultLocale || 'en'))
  }, [setUxTranslations])

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const openDialog = () => {
    setDialogOpen(true)
  }

  const handleValueChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const _value = { ...inputValue, [defaultLocale]: e.target.value }
    handleOnChange(_value)
  }

  const handleTranslationChange = (_code: IsoCode, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const _value = { ...inputValue, [_code]: e.target.value }
    handleOnChange(_value)
  }
  const handleOnChange = (_value: Translations) => {
    setInputValue(_value)
    onChange &&
      onChange(
        {
          target: {
            value: _value,
            name: 'id',
          },
        } as TranslatableChangeEvent,
        null
      )
  }

  const renderFields = () => {
    return ctx
      .getSupportedLocales()
      .filter((iso) => iso !== defaultLocale)
      .map((iso) => {
        const element = uxTranslations ? uxTranslations.language[iso] : 'unknown'
        return (
          <TextField
            key={'trans-dialog-field-' + id + '-' + iso}
            variant={variant}
            label={element}
            fullWidth={fullWidth}
            multiline={multiline}
            value={inputValue[iso] || ''}
            onChange={(e) => handleTranslationChange(iso, e)}
          />
        )
      })
  }

  const Adornment = (
    <InputAdornment position="end">
      {showISOCode ? (
        <Button variant="text" startIcon={<TranslateIcon />} onClick={openDialog}>
          {defaultLocale}
        </Button>
      ) : (
        <Tooltip title="Translate">
          <IconButton onClick={openDialog}>
            <TranslateIcon />
          </IconButton>
        </Tooltip>
      )}
    </InputAdornment>
  )
  const filled = () => (
    <FilledInput
      id={'trans-field-' + id}
      type={'text'}
      disabled={disabled}
      error={error}
      value={defaultValue}
      placeholder={placeholder}
      fullWidth={fullWidth}
      multiline={multiline}
      onChange={handleValueChange}
      endAdornment={Adornment}
    />
  )

  const outlined = () => (
    <OutlinedInput
      id={'trans-field-' + id}
      type={'text'}
      disabled={disabled}
      label={label}
      error={error}
      value={defaultValue}
      placeholder={placeholder}
      fullWidth={fullWidth}
      multiline={multiline}
      onChange={handleValueChange}
      endAdornment={Adornment}
    />
  )

  const standard = () => (
    <Input
      id={'trans-field-' + id}
      type={'text'}
      disabled={disabled}
      error={error}
      value={defaultValue}
      placeholder={placeholder}
      fullWidth={fullWidth}
      multiline={multiline}
      onChange={handleValueChange}
      endAdornment={Adornment}
    />
  )

  return (
    <>
      <FormControl sx={sx} fullWidth={fullWidth} variant={variant || 'standard'} className={className}>
        <InputLabel>{label || ''}</InputLabel>
        {variant === 'filled' && filled()}
        {variant === 'outlined' && outlined()}
        {(variant === 'standard' || variant === undefined) && standard()}

        {!!helperText && (
          <FormHelperText error id={'trans-form-helper-text-' + id}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
      <Dialog open={dialogOpen} onClose={handleCloseDialog} scroll="paper" fullWidth={true} maxWidth="sm">
        <DialogTitle id={'trans-dialog-' + id} sx={{ display: 'flex', alignItems: 'center' }}>
          <TranslateIcon color="inherit" fontSize="large" sx={{ mr: 2 }} /> {label || uxTranslations.translation}
        </DialogTitle>
        <DialogContent dividers={false}>
          <TextField
            type={'text'}
            label={defaultLang}
            placeholder={defaultLang}
            variant={variant}
            sx={{ mt: 2, mb: 4 }}
            value={inputValue[defaultLocale]}
            disabled={disabled}
            fullWidth={true}
            onChange={handleValueChange}
            multiline={multiline}
          />
          <Stack spacing={2} direction="column">
            {renderFields()}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{uxTranslations.close}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
