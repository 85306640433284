/**
 * Example: this.myArray.sort(SortArrayOfObjectsMultiple('firstname', '-surname'));
 *
 * @export
 * @returns
 */
export function SortArrayOfObjectsMultiple() {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  const props = arguments
  return (obj1, obj2) => {
    const numberOfProperties = props.length
    let i = 0
    let result = 0
    /* try getting a different result from 0 (equal)
     * as long as we have extra properties to compare
     */
    while (result === 0 && i < numberOfProperties) {
      result = SortArrayOfObjects(props[i])(obj1, obj2)
      i++
    }
    return result
  }
}

/**
 * Example: this.myArray.sort(SortArrayOfObjects('name'));
 *
 * @export
 * @param {string} property
 * @returns
 */
export function SortArrayOfObjects(property: string) {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return (a, b) => {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

/**
 * Example: console.log(byString(someObjext, 'property.vehicle.name'));
 *
 * @param {*} object
 * @param {string} propString
 * @returns
 */
const propByString = (object, propString: string) => {
  // access object properties by string
  propString = propString.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  propString = propString.replace(/^\./, '') // strip a leading dot
  const keys = propString.split('.')
  let value: string | number = ''
  for (const key of keys) {
    if (key in object && object[key] !== undefined) {
      const index = keys.indexOf(key)
      if (index < keys.length - 1) {
        object = object[key]
      } else {
        value = object[key]
      }
    } else {
      return
    }
  }
  return value
}

/**
 * Example: this.myArray.sort(SortArrayOfObjectsNested('vehicle.name', 'desc'));
 *
 * @export
 * @param {string} property
 * @param {('asc' | 'desc')} [direction]
 * @returns
 */
export function SortArrayOfObjectsNested(property: string, direction?: 'asc' | 'desc') {
  let sortOrder = 1
  if (direction && direction === 'desc') {
    sortOrder = -1
  }
  return (a, b) => {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const propA = propByString(a, property)
    const propB = propByString(b, property)
    let result = 0
    if (propA === undefined || propB === undefined) {
      result = propA && !propB ? -1 : !propA && propB ? 1 : 0
    } else if (propA === 0 || propB === 0) {
      result = propA === 0 && propB !== 0 ? -1 : propA !== 0 && propB === 0 ? 1 : 0
    } else {
      result = propA < propB ? -1 : propA > propB ? 1 : 0
    }
    return result * sortOrder
  }
}
