import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Map from './Map'
import { WithActiveTenant } from '../WithActiveTenant'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapwrapper: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: 'calc(100vh - 65px)',
        backgroundColor: 'rgb(0,0,0)',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        height: 'calc(100vh - 65px)',
        backgroundColor: 'rgb(0,0,0)',
      },
    },
  })
)

export const Module: React.VFC<WithActiveTenant> = ({ activeTenant }) => {
  const classes = useStyles()
  return (
    <div className={classes.mapwrapper}>
      <Map activeTenant={activeTenant} />
    </div>
  )
}

export default Module
