import { DialogWithContent } from '@griegconnect/krakentools-react-ui'
import { Stack, TextField } from '@mui/material'
import { Duration } from 'luxon'
import React, { useState } from 'react'
import { Control, Controller, get, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'

interface DurationPickerComponentProps {
  label: string
  value?: Duration
  onChange: (value: Duration) => void
  showMinutes?: boolean
  showHours?: boolean
  showDays?: boolean
  showYears?: boolean
}

const DurationPickerComponent: React.FC<DurationPickerComponentProps> = ({
  label,
  value,
  onChange,
  showMinutes = true,
  showHours = true,
  showDays = true,
  showYears = true,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [minutes, setMinutes] = useState<number>(value?.minutes || 0)
  const [hours, setHours] = useState<number>(value?.hours || 0)
  const [days, setDays] = useState<number>(value?.days || 0)
  const [years, setYears] = useState<number>(value?.years || 0)

  const getStringValue = (): string => {
    let valueString = ''
    if (!minutes && !hours && !days && !years) {
      valueString = t('common.labels.setDuration')
    } else {
      valueString = `${!!years ? `${years} ${t('common.labels.years')} ` : ''}${
        !!days ? `${days} ${t('common.labels.days')} ` : ''
      }${!!hours ? `${hours} ${t('common.labels.hours')} ` : ''}${
        !!minutes ? `${minutes} ${t('common.labels.minutes')}` : ''
      }`
    }
    return valueString
  }

  const initialValue = getStringValue()
  const [selected, setSelected] = useState<string | undefined>(initialValue)

  const handleSubmit = () => {
    const v = getStringValue()
    setSelected(v)
    setOpen(false)
    const duration = Duration.fromObject({
      minutes: !minutes ? 0 : minutes,
      hours: !hours ? 0 : hours,
      days: !days ? 0 : days,
      years: !years ? 0 : years,
    })
    onChange(duration)
  }

  return (
    <>
      <TextField
        style={{ width: 300 }}
        label={label}
        required={showMinutes}
        variant="filled"
        type="string"
        value={selected || ''}
        onClick={() => setOpen(true)}
        InputLabelProps={{
          shrink: open || undefined,
        }}
        inputProps={{
          readOnly: true,
        }}
      />
      <DialogWithContent
        title={getStringValue()}
        open={open}
        onNext={handleSubmit}
        onClose={() => setOpen(false)}
        nextButtonName={t('common.actions.ok')}
        nextButtonType="submit"
        closeButtonName={t('common.actions.cancel')}
      >
        <Stack direction="row" spacing={2}>
          {showYears && (
            <TextField
              style={{ width: 100 }}
              label={t('common.labels.years')}
              value={years || ''}
              variant="filled"
              type="number"
              inputProps={{ min: 0 }}
              onChange={(event) => {
                event.stopPropagation()
                setYears(parseInt(event.target.value))
              }}
            />
          )}
          {showDays && (
            <TextField
              style={{ width: 100 }}
              value={days || ''}
              label={t('common.labels.days')}
              variant="filled"
              type="number"
              inputProps={{ min: 0 }}
              onChange={(event) => {
                event.stopPropagation()
                setDays(parseInt(event.target.value))
              }}
            />
          )}
          {showHours && (
            <TextField
              style={{ width: 100 }}
              label={t('common.labels.hours')}
              value={hours || ''}
              variant="filled"
              type="number"
              inputProps={{ min: 0 }}
              onChange={(event) => {
                event.stopPropagation()
                setHours(parseInt(event.target.value))
              }}
            />
          )}
          {showMinutes && (
            <TextField
              style={{ width: 100 }}
              label={t('common.labels.minutes')}
              value={minutes || ''}
              variant="filled"
              type="number"
              inputProps={{ min: 0 }}
              onChange={(event) => {
                event.stopPropagation()
                setMinutes(parseInt(event.target.value))
              }}
            />
          )}
        </Stack>
      </DialogWithContent>
    </>
  )
}

interface DurationPickerProps {
  control: Control<any>
  label: string
  name: string
}
export const DurationPicker: React.FC<React.PropsWithChildren<DurationPickerProps>> = ({ control, label, name }) => {
  const { errors } = useFormState()
  const error = get(errors, name)

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const handleSubmit = (value: Duration) => {
            const v = value.toMillis() === 0 ? null : value
            field.onChange(v)
          }
          const duration = Duration.fromISO(field.value)
          return <DurationPickerComponent label={label} value={duration} onChange={handleSubmit} />
        }}
      />
      {error && error.message && <ErrorHelperText error={error.message} />}
    </>
  )
}
