import React from 'react'
import * as z from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, FormControlLabel, MenuItem, Radio, Typography } from '@mui/material'
import * as Form from '@griegconnect/krakentools-form'
import { IPortDetailsDto } from '@app/common/ServicesWrapper/apis/SharedDataApi'
import { useServices } from '@app/common/ServicesWrapper/index'
import { UserApi } from '@app/common/ServicesWrapper/apis/UserApi'
import { zodResolver } from '@hookform/resolvers/zod'
import { toResolved } from '@app/common/ServicesWrapper/apis/dtos/userDto'
import { Services } from '@app/common/ServicesWrapper/types'
import { useTranslation } from 'react-i18next'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import useSearch, { Search as S } from '@app/lib/hooks/useSearch'
import { OnSite } from '@app/common/ServicesWrapper/apis/CompaniesApi'
import { PortCompany } from '@app/common/ServicesWrapper/apis/dtos/companyDto'
import { Vessel } from '@app/common/ServicesWrapper/apis/VesselsApi'
import { VesselInput, zVesselSchema } from '@app/common/Form'
import { Visiting } from '../../common/types'
import { useQuery } from '@tanstack/react-query'

const _Schema = z
  .object({
    visiting: z.union([z.literal('company'), z.literal('vessel')]).nullable(),
    visitingCompany: z.string(),
    visitingVessel: zVesselSchema.nullable(),
    facility: z.string(),
    visitHandler: z.string().optional(),
    shoudChooseVisitHandler: z.boolean().default(false),
  })
  .refine((data) => data.visiting !== null, {
    message: 'common.validation.required',
    path: ['visiting'],
  })
  .refine((data) => data.visiting === null || data.facility.length > 0, {
    message: 'applications.validation.facility',
    path: ['facility'],
  })
  .refine((data) => data.visiting !== 'vessel' || data.visitingVessel !== null, {
    message: 'common.validation.required',
    path: ['visitingVessel'],
  })

export const Schema = () => _Schema

export type Input = z.infer<typeof _Schema>

interface DefaultValuesProps {
  applicationForm?: Visiting
  prefill: IPrefill
  portCompanies: PortCompany[]
  facilities: IAreaDto[]
  customers: OnSite.Details[]
  vessels?: Vessel[]
}

interface IPrefill {
  visiting: string | null
  company: string | null
  visitHandler: string | null
  facility: string | null
}

export const defaultValues = ({
  applicationForm,
  prefill,
  portCompanies,
  facilities,
  customers,
  vessels,
}: DefaultValuesProps): Input => {
  const companies = [
    ...portCompanies.map((c) => ({ id: c.company.id, name: c.company.name })),
    ...customers.map((c) => ({ id: c.contract, name: c.name })),
  ]
  const company = companies.find((c) => c.name === prefill.company)?.id
  const facility = facilities.find((f) => f.name === prefill.facility)?.id

  const visiting =
    applicationForm?.visiting?.target.type === 'customer' ? 'company' : applicationForm?.visiting?.target.type

  return {
    visiting:
      visiting || (prefill.visiting === 'vessel' ? 'vessel' : prefill.visiting === 'company' ? 'company' : null),
    visitingCompany: applicationForm?.visiting?.target.value.id || company || '',
    visitingVessel:
      applicationForm?.visiting?.target.type === 'vessel' && !!applicationForm.visiting.target.value.name
        ? {
            type: 'vessel',
            id: applicationForm.visiting.target.value.id,
            name: applicationForm.visiting.target.value.name,
          }
        : vessels && vessels.length === 1
        ? { type: 'vessel', ...vessels[0] }
        : null,
    facility: applicationForm?.visiting?.facility || facility || '',
    visitHandler: applicationForm?.visiting.visitHandler || undefined,
    shoudChooseVisitHandler: !!applicationForm?.visiting?.visitHandler,
  }
}

const visitingTarget = async (input: Input, port: string, services: Services): Promise<VisitingTargetForm> => {
  if (input.visiting === 'company') {
    return { type: 'company', value: { id: input.visitingCompany } }
  } else if (input.visitingVessel?.type === 'vessel') {
    return { type: 'vessel', value: { id: input.visitingVessel.id, name: input.visitingVessel.name } }
  } else {
    const newVessel = await services.vesselApi.create(port, { name: input.visitingVessel!.name })
    return { type: 'vessel', value: { id: newVessel.id, name: input.visitingVessel!.name } }
  }
}

export const toForm = async (input: Input, port: string, services: Services): Promise<Visiting> => {
  const target = await visitingTarget(input, port, services)

  return {
    visiting: {
      facility: input.facility,
      target,
      visitHandler:
        target.type === 'company' && input.visitHandler && input.visitHandler.length > 0 ? input.visitHandler : null,
    },
  }
}

interface VisitingTargetForm extends UserApi.Application.VisitingTargetForm {
  value: {
    id: string
    name?: string
  }
}

interface Props {
  applicationForm?: Visiting
  details: IPortDetailsDto
  customers: OnSite.Details[]
  vessels?: Vessel[]
  edit: boolean
  disabled: boolean
  onNext: (form: Visiting, part: 'visiting') => void
  updateErrors: (errors: boolean) => void
  children?: React.ReactNode
}

function StepVisiting({
  applicationForm,
  details,
  customers,
  vessels,
  edit,
  disabled,
  onNext,
  updateErrors,
  children,
}: Props) {
  const [search] = useSearch({
    visiting: S.string,
    company: S.string,
    visitHandler: S.string,
    facility: S.string,
  })

  const form = useForm({
    defaultValues: defaultValues({
      applicationForm,
      prefill: search,
      portCompanies: details.port_companies,
      facilities: details.areas,
      customers,
      vessels,
    }),
    resolver: zodResolver(Schema()),
  })

  const setValue = form.setValue
  const { services } = useServices()
  const { t } = useTranslation()

  const visiting = form.watch('visiting')
  const visitingCompany = form.watch('visitingCompany')
  const facility = form.watch('facility')
  const visitHandler = form.watch('visitHandler')

  const visitingVessel = form.watch('visitingVessel')
  const vesselId = visitingVessel && visitingVessel.type === 'vessel' && visitingVessel.id
  const vesselFacilities = useQuery(['target-facilities', vesselId ?? ''], async () => {
    const contractFacilities =
      vesselId && (await services.applications2Api.getApplicationValues({ tenant: details.port.id, target: vesselId }))

    const retval =
      contractFacilities &&
      contractFacilities.targets.find((t) => t.id === visitingVessel.id)?.facilities.map((f) => f.area)

    return retval
  })

  const facilities = React.useMemo(() => {
    const visitCompanies =
      customers.find((c) => c.contract === visitingCompany)?.facilities ||
      details.port_companies.find((pc) => pc.company.id === visitingCompany)?.facilities ||
      []
    return visiting === 'vessel' ? (vesselFacilities.data ? vesselFacilities.data : details.areas) : visitCompanies
  }, [details, visitingCompany, vesselFacilities, visiting, customers])

  const visitHandlers = React.useMemo(
    () =>
      customers.length > 0
        ? (customers.find((c) => c.contract === visitingCompany)?.visitHandlers || []).map((vh) => ({
            id: vh.id,
            name: toResolved(vh).name,
          }))
        : [],
    [customers, visitingCompany]
  )

  const vesselsDisabled = details && details.attributes && details.attributes.disableVessel
  const errors = form.formState.errors

  React.useEffect(() => {
    if (vesselsDisabled) {
      setValue('visiting', 'company')
    }
  }, [vesselsDisabled, setValue])

  React.useEffect(() => {
    if (
      (visitingCompany === '' || details.port_companies.find((c) => c.company.id === visitingCompany) === undefined) &&
      details.port_companies.length === 1
    ) {
      setValue('visitingCompany', details.port_companies[0].company.id)
    }
    visitHandlers.length > 0 ? setValue('shoudChooseVisitHandler', true) : setValue('shoudChooseVisitHandler', false)
  }, [visiting, visitHandlers, visitingCompany, details.port_companies, setValue])

  React.useEffect(() => {
    if (facility === '' && facilities.length === 1) {
      setValue('facility', facilities[0].id)
      errors.facility && form.clearErrors('facility')
    } else if (facility !== '' && !facilities.some((f) => f.id === facility)) {
      setValue('facility', '')
    }
  }, [visitingCompany, facility, facilities, setValue]) // eslint-disable-line

  React.useEffect(() => {
    if (visiting !== 'vessel') {
      form.clearErrors('visitingVessel')
    }
  }, [visiting]) // eslint-disable-line

  React.useEffect(() => {
    if (!edit) {
      if (visitHandler === '' && visitHandlers.length === 1) {
        setValue('visitHandler', visitHandlers[0].id)
      }
    }
  }, [customers, visitingCompany, setValue]) // eslint-disable-line

  React.useEffect(() => {
    updateErrors(!!Object.keys(errors).length)
  }, [errors.visiting, errors.visitingVessel, errors.visitHandler, errors.facility]) // eslint-disable-line

  const onSubmit = async (data: Input) => onNext(await toForm(data, details.port.ref, services), 'visiting')

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Typography>{t('applications.subTitle.visitingWhat')} *</Typography>
        <Box mb={1} />
        {!vesselsDisabled && (
          <Form.RadioGroup name="visiting" control={form.control} required={true} row={true}>
            <FormControlLabel
              disabled={disabled}
              key="company"
              value="company"
              control={<Radio autoFocus />}
              label={t('security.labels.visitingCompany') as string}
            />
            <FormControlLabel
              disabled={disabled}
              key="vessel"
              value="vessel"
              control={<Radio />}
              label={t('settings.labels.vessel') as string}
            />
          </Form.RadioGroup>
        )}
        <Box pb={2} />
        <Box display={visiting !== 'company' ? 'none' : undefined}>
          <Form.Select
            disabled={disabled}
            name={`visitingCompany`}
            control={form.control}
            label={t('security.labels.visitingCompany')}
          >
            {details.port_companies.map(({ company }) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
            {customers.map(({ contract, name }) => (
              <MenuItem key={contract} value={contract}>
                {name}
              </MenuItem>
            ))}
          </Form.Select>
        </Box>
        <Box display={visiting !== 'vessel' ? 'none' : undefined} py={2}>
          <VesselInput
            control={form.control}
            name="visitingVessel"
            label={t('settings.labels.vessel')}
            tenant={details.port.id}
          />
        </Box>
        <Box pb={2} />
        {visiting === 'company' && visitHandlers.length > 0 && (
          <>
            <Form.Select
              disabled={disabled}
              name={`visitHandler`}
              control={form.control}
              label={t('components.applications.fields.labels.visitHandler')}
            >
              {visitHandlers.map((vh) => (
                <MenuItem key={vh.id} value={vh.id}>
                  {vh.name}
                </MenuItem>
              ))}
            </Form.Select>
            <Box pb={2} />
          </>
        )}
        {visiting && details.areas.length > 1 && (
          <Box pb={2}>
            <Form.RadioGroup
              name="facility"
              label={t('settings.labels.facility')}
              control={form.control}
              required={true}
            >
              {details.areas.map((facility) => (
                <FormControlLabel
                  key={facility.id}
                  value={facility.id}
                  control={<Radio />}
                  label={facility.name}
                  disabled={disabled || !facilities.find((f) => f.id === facility.id)}
                />
              ))}
            </Form.RadioGroup>
          </Box>
        )}
        {children}
      </form>
    </FormProvider>
  )
}

export default StepVisiting
