import {
  ApplicationProvider,
  AxiosInstance,
  ConfigType,
  ITenantAccess,
  Tenant,
} from '@griegconnect/krakentools-react-kraken-app'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { ReactComponent as Slide1 } from './assets/slides/Slide1.svg'
import { ReactComponent as Slide2 } from './assets/slides/Slide2.svg'
import { ReactComponent as Slide3 } from './assets/slides/Slide3.svg'
import './config/i18n'
import {
  AutoAppSwitcher,
  isStavanger,
  isVisitDomain,
  selectSigninMethods,
  passwordlessDescriptionText,
  infoText,
  title,
  getFrontendConfigPath,
  isFullDev,
} from './AutoAppSwitcher'
import { LicenseInfo } from '@mui/x-license-pro'
import { SharedDataApi } from '@app/common/ServicesWrapper/apis/SharedDataApi'

LicenseInfo.setLicenseKey(
  '3c92ac2a13f79038b2fca55b538af70bTz03MzQ0MyxFPTE3MjU3NTAwMDAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
})
const container = document.getElementById('root')
const root = createRoot(container!)

if (!isVisitDomain && !isFullDev && window.location.pathname.startsWith('/user')) {
  window.location.port === '3002'
    ? (window.location.port = '3001')
    : (window.location.hostname = window.location.hostname.replace('security', 'visit'))
}

const filterUserAccess = async (
  accesses: ITenantAccess[],
  config: ConfigType,
  httpClient: (baseUrl: string, useBearerToken?: boolean) => AxiosInstance
): Promise<Tenant.Type[]> => {
  const sharedDataApi = new SharedDataApi(httpClient(config.api.securityApi, true))
  const ports = await sharedDataApi.getPorts({})
  return accesses
    .filter((a) => ports.some((p) => p.tenant.id === a.tenant.id))
    .map((a) => {
      return {
        ...a.tenant,
        permissions: a.permissions || [],
        companies:
          a.companies.map((c) => {
            return { ...c.company, permissions: c.permissions || [] }
          }) || [],
      }
    })
}

root.render(
  <QueryClientProvider client={queryClient}>
    <ApplicationProvider
      name="Security"
      disableProfileCompletion
      profileMenuButtons={['Settings']}
      tool="security"
      confirmInvitation
      filterUserAccess={{ callback: filterUserAccess, type: 'http-client' }}
      tenantsUrlMatchPathPrefix="/companies/:tenantRef"
      reloadOnTenantsChange={true}
      configurationFilePath={getFrontendConfigPath()}
      appBar={{ hideApplicationSwitcher: isVisitDomain }}
      i18n={{ languages: ['en-GB', 'nb-NO', 'sv-SE', 'fi-FI'] }}
      onboarding={{
        signInMethods: selectSigninMethods(),
        loginRedirectOnSingleSignInMethod: isVisitDomain,
        passwordlessDescription: passwordlessDescriptionText(),
        carouselElements:
          isVisitDomain && isStavanger
            ? [
                {
                  component: <Slide1 />,
                  title: title(),
                  description: infoText(),
                },
              ]
            : [
                {
                  component: <Slide1 />,
                  title: 'Self-service',
                  description: 'Apply for access permit in advance of any planned visits.',
                },
                {
                  component: <Slide2 />,
                  title: 'Access',
                  description: 'Use your personal mobile device as an electronic access permit.',
                },
                {
                  component: <Slide3 />,
                  title: 'Availability',
                  description: 'Available wherever you are, via web and app – on mobile, tablet and pc.',
                },
              ],
      }}
    >
      <AutoAppSwitcher />
    </ApplicationProvider>
  </QueryClientProvider>
)
