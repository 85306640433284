import { Box, Button, Chip, Fab, IconButton, TextField, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { ArrowDown as ArrowDownIcon } from '@griegconnect/krakentools-react-icons'
import { ArrowUp as ArrowUpIcon } from '@griegconnect/krakentools-react-icons'
import { TrashCan as IconTrash } from '@griegconnect/krakentools-react-icons'
import AddIcon from '@mui/icons-material/Add'
import { Autocomplete } from '@mui/material'
import React from 'react'
import { v4 } from 'uuid'
import { CheckListForm as ICheckListForm, CheckListItem } from '@app/common/ServicesWrapper/apis/CheckListsApi'
import { Page } from '@griegconnect/krakentools-react-ui'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { FormProvider, useForm, useFieldArray, useFormContext, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as Form from '@griegconnect/krakentools-form'

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    marginRight: theme.spacing(-1),
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
}))

namespace CheckListForm {
  const _Schema = z
    .object({
      name: z.string().min(1, 'common.validation.required'),
      description: z.string().default(''),
      minControlled: z.number().or(z.nan()).default(0),
      items: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          description: z.string().default(''),
          tags: z.array(z.string()).default([]),
          required: z.boolean().default(false),
        })
      ),
    })
    .refine((data) => !!isNaN(data.minControlled) || data.minControlled <= data.items.length, {
      message: 'checks.validations.minControlled',
      path: ['minControlled'],
    })
    .refine((data) => data.items.length > 0, {
      message: 'checks.validations.noListItems',
      path: ['items'],
    })
    .superRefine((data, ctx) => {
      const isMissingNames = data.items.find((i) => !i.name) !== undefined
      if (isMissingNames) {
        data.items.forEach((item, idx) => {
          if (!item.name) {
            ctx.addIssue({
              message: 'common.validation.required',
              path: [`items.${idx}.name`],
              code: z.ZodIssueCode.custom,
            })
          }
        })
      }
    })

  export const Schema = () => _Schema

  export type Inputs = z.infer<typeof _Schema>

  export const defaultValues = (): Inputs => ({
    name: '',
    description: '',
    minControlled: 0,
    items: [{ id: v4(), name: '', description: '', required: false, tags: [] }],
  })

  export const fromCheckList = (checkList: ICheckListForm): Inputs => {
    return {
      ...checkList,
      minControlled: !checkList.minControlled ? 0 : checkList.minControlled,
    }
  }

  export const toForm = (inputs: Inputs): ICheckListForm => {
    return {
      ...inputs,
      minControlled: !!isNaN(inputs.minControlled) || inputs.minControlled === 0 ? undefined : inputs.minControlled,
    }
  }
}

interface EditItemProps {
  item: CheckListItem
  index: number
  tags: string[]
  onDelete: () => void
  onMove: (oldIndex: number, newIndex: number) => void
  moveUp: boolean
  moveDown: boolean
}

const EditItem: React.VFC<EditItemProps> = ({ item, index, tags, onDelete, onMove, moveUp, moveDown }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { control } = useFormContext()

  const options = tags.filter(
    (tag, index) => !item.tags.includes(tag) && tags.indexOf(tag) === index // removes duplicates
  )

  const moveItem = (direction: 'up' | 'down') => (evt: any) => {
    if (direction === 'up') {
      onMove(index, index - 1)
    } else {
      onMove(index, index + 1)
    }
  }

  return (
    <Box key={item.id}>
      <Box display="flex" justifyContent="center" mb={2}>
        <IconButton onClick={moveItem('up')} disabled={!moveUp} size="large">
          <ArrowUpIcon />
        </IconButton>
      </Box>
      <Form.TextInput control={control} label={t('common.labels.name')} name={`items.${index}.name`} required={true} />
      <Box m={1} />
      <Form.TextInput
        control={control}
        label={t('settings.labels.description')}
        name={`items.${index}.description`}
        multiline={true}
      />
      <Box m={1} />
      <Controller
        name={`items.${index}.tags`}
        control={control}
        render={({ field }) => (
          <Autocomplete
            multiple={true}
            options={options}
            freeSolo={true}
            renderTags={(values: string[], tagProps) =>
              values.map((value, index) => <Chip variant="outlined" label={value} {...tagProps({ index })} />)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label={t('settings.labels.tags')}
                helperText={t('checks.paragraphs.tags')}
              />
            )}
            onChange={(evt, tags: string[]) => field.onChange(tags)}
          />
        )}
      />
      <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
        <Form.Switch name={`items.${index}.required`} control={control} label={t('common.validation.required')} />
        <IconButton onClick={onDelete} className={classes.iconBtn} size="large">
          <IconTrash />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <IconButton onClick={moveItem('down')} disabled={!moveDown} size="large">
          <ArrowDownIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

interface EditCheckListProps {
  checkList?: ICheckListForm
  onSubmit: (list: ICheckListForm) => void
}

export const EditCheckList: React.VFC<EditCheckListProps> = ({ checkList, onSubmit }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const form = useForm<CheckListForm.Inputs>({
    defaultValues: checkList ? CheckListForm.fromCheckList(checkList) : CheckListForm.defaultValues(),
    resolver: zodResolver(CheckListForm.Schema()),
  })
  const errors = form.formState.errors

  const itemsArray = useFieldArray({
    control: form.control,
    name: 'items',
  })

  const tags = itemsArray.fields.reduce<string[]>((p, c) => [...p, ...c.tags], [])

  React.useEffect(() => {
    !!Object.keys(errors).find((k) => k === 'items') && form.trigger('items')
  }, [itemsArray.fields]) // eslint-disable-line

  React.useEffect(() => {
    !!Object.keys(errors).find((k) => k === 'minControlled') && form.trigger('minControlled')
  }, [itemsArray.fields.length]) // eslint-disable-line

  const addListItem = () => {
    const id = v4()
    itemsArray.append({
      id,
      name: '',
      description: '',
      required: false,
      tags: [],
    })
  }

  const submit = async (inputs: CheckListForm.Inputs) => {
    const form = await CheckListForm.toForm(inputs)
    onSubmit(form)
  }

  return (
    <>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(submit)}>
          <Page.Paper>
            <Form.TextInput control={form.control} label={t('common.labels.title')} name="name" required />
            <Box m={1} />
            <Form.TextInput
              control={form.control}
              label={t('settings.labels.description')}
              name="description"
              multiline
            />
            <Box m={1} />
            <Form.TextInput
              control={form.control}
              label={t('settings.labels.minControlled')}
              name="minControlled"
              type="number"
              inputProps={{ min: 0 }}
              helperText={t('checks.paragraphs.minControlled')}
            />
          </Page.Paper>
          <Box m={3} />
          {itemsArray.fields.map((item, idx) => (
            <Box key={item.id}>
              <Page.Paper style={{ position: 'relative' }}>
                <EditItem
                  item={item}
                  index={idx}
                  tags={tags}
                  onDelete={() => itemsArray.remove(idx)}
                  onMove={(oldIndex, newIndex) => itemsArray.move(oldIndex, newIndex)}
                  moveUp={idx !== 0}
                  moveDown={idx !== itemsArray.fields.length - 1}
                />
              </Page.Paper>
              <Box m={3} />
            </Box>
          ))}
          {errors.items?.message && <Form.ErrorHelperText error={errors.items.message} />}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Fab size="small" aria-label="add_list_item" onClick={addListItem} className={classes.addIcon}>
              <AddIcon />
            </Fab>
            <Typography variant="body1" component="div">
              {t('settings.actions.addListItem')}
            </Typography>
          </Box>
          <Box mt={2} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
            <Button variant="outlined" onClick={() => navigate(-1)}>
              {t('common.actions.cancel')}
            </Button>
            <Box ml={2} />
            <Button variant="contained" type="submit">
              {t('common.actions.save')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  )
}
