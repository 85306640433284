import React, { useCallback, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import * as z from 'zod'
import { zDateTime } from '@app/lib/ZodForm'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, MenuItem, Typography } from '@mui/material'
import * as Form from '@griegconnect/krakentools-form'
import { IPortDetailsDto } from '@app/common/ServicesWrapper/apis/SharedDataApi'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { IPurposeDto } from '@app/common/ServicesWrapper/apis/dtos/purposeDto'
import useSearch, { Search as S } from '@app/lib/hooks/useSearch'
import { useServices } from '@app/common/ServicesWrapper'
import { ApplicationForm, VisitDetails } from '../../common/types'
import { fromTranslations, useTranslatable } from '@griegconnect/krakentools-form'

export const Schema = z
  .object({
    purpose: z.string().min(1, { message: 'applications.validation.purpose' }),
    from: zDateTime,
    to: zDateTime,
  })
  .refine((data) => !data.to || !data.from || !!data.from.invalidReason || data.from < data.to, {
    message: 'applications.validation.to',
    path: ['to'],
  })

export type Input = z.infer<typeof Schema>

export const defaultValues = ({ applicationForm, prefill, purposes }: IDefaultValuesProps): Input => {
  const purpose = purposes.find((p) => p.name === prefill.purpose)?.id
  const from = !!applicationForm?.visiting?.timeIn
    ? DateTime.fromISO(applicationForm.visiting.timeIn)
    : prefill.from
    ? DateTime.fromISO(prefill.from)
    : DateTime.now()
  return {
    purpose: applicationForm?.visiting?.purpose || purpose || '',
    from,
    to: from.endOf('day'),
  }
}

export const toForm = async (input: Input): Promise<VisitDetails> => {
  return {
    visiting: {
      purpose: input.purpose,
      timeIn: input.from.toUTC().toISO(),
      timeOut: input.to?.toUTC()?.toISO() || null,
    },
  }
}

interface IPrefill {
  purpose: string | null
  from: string | null
  to: string | null
}

interface IDefaultValuesProps {
  applicationForm?: VisitDetails
  prefill: IPrefill
  purposes: IPurposeDto[]
}

interface Props {
  applicationForm?: ApplicationForm
  details: IPortDetailsDto
  facility: string
  disabled: boolean
  onUpdate: (form: VisitDetails, part: 'visitDetails') => void
  onNext: () => void
  updateErrors: (errors: boolean) => void
  children?: React.ReactNode
}

function StepVisitDetails({ applicationForm, details, facility, disabled, onUpdate, onNext, updateErrors, children }: Props) {
  const [maxDate, setMaxDate] = useState<DateTime | null>(null)
  const { services } = useServices()
  const [search] = useSearch({
    purpose: S.string,
    from: S.string,
    to: S.string,
  })

  const form = useForm({
    defaultValues: defaultValues({
      applicationForm: applicationForm?.visitDetails || undefined,
      prefill: search,
      purposes: details.purposes,
    }),
    resolver: zodResolver(Schema),
  })
  const { t } = useTranslation()

  const purpose = form.watch('purpose')
  const from = form.watch('from')
  const to = form.watch('to')

  const errors = form.formState.errors

  useEffect(() => {
    const setMaxTime = async (startTime: DateTime) => {
      const isCompany = applicationForm?.visiting?.visiting.target.type !== 'vessel'
      const restricted = await services.permits2Api.maxDuration(details.port.ref, {
        startTime: startTime.toISO(),
        target: isCompany && applicationForm?.visiting ? applicationForm.visiting.visiting.target.value.id : undefined,
        purpose: purpose && purpose.length > 0 ? purpose : undefined,
        facility,
      })
      const maxTime = restricted.maxDuration ? DateTime.fromISO(restricted.maxDuration) : null
      const defaultMax = restricted.defaultDuration ? DateTime.fromISO(restricted.defaultDuration) : null
      const defaultTime = defaultMax && defaultMax < startTime.endOf('day') ? defaultMax : startTime.endOf('day')

      setMaxDate(maxTime)
      form.setValue('to', defaultTime)
    }

    if (!!from && !from.invalidReason) {
      setMaxTime(from)
    } else {
      setMaxDate(null)
    }
  }, [applicationForm, form, details, facility, purpose, from, services.permits2Api]) // eslint-disable-line

  React.useEffect(() => {
    updateErrors(!!Object.keys(errors).length)
  }, [errors.purpose, errors.from, errors.to]) // eslint-disable-line

  React.useEffect(() => {
    !!Object.keys(errors).find((k) => k === 'to') && form.trigger('to')
  }, [to]) // eslint-disable-line

  React.useEffect(() => {
    const updateParentForm = async () => {
      onUpdate(await toForm({ purpose, from, to }), 'visitDetails')
    }
    updateParentForm()
  }, [purpose, from, to]) // eslint-disable-line

  const onSubmit = async (_data: Input) => onNext()

  //Logic requested for Åhus Hamn!
  const updateFromDateTime = useCallback(
    (view) => {
      const _from = form.getValues('from')
      if (view === 'hours' && _from.startOf('day') > DateTime.now().startOf('day')) {
        form.setValue('from', _from.startOf('day'))
        form.setValue('to', _from.endOf('day'))
      }
    },
    [form]
  )

  //Logic requested for Åhus Hamn!
  const updateToDateTime = useCallback(
    (view) => {
      const _to = form.getValues('to')
      if (view === 'hours' && _to.endOf('day') > DateTime.now().endOf('day')) {
        form.setValue('to', _to.endOf('day'))
      }
    },
    [form]
  )
  const translatable = useTranslatable()
  const transLang = translatable.getDefaultLocale()
  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Typography>{t('applications.subTitle.visiting')}</Typography>
        <Box mb={1} />
        {details.purposes.length > 0 && (
          <Form.Select
            disabled={disabled}
            name={`purpose`}
            control={form.control}
            label={t('settings.labels.purpose')}
            required
          >
            {details.purposes.map((purpose) => (
              <MenuItem key={purpose.id} value={purpose.id}>
                {fromTranslations(transLang, purpose.i18n) || purpose.name}
              </MenuItem>
            ))}
          </Form.Select>
        )}
        <Box mb={1} />
        <Form.DateTimePicker
          disabled={disabled}
          name="from"
          control={form.control}
          label={t('common.labels.timeFrom')}
          onViewChange={updateFromDateTime}
          required
        />
        {!!errors.from && <Box mb={1} />}
        <Form.DateTimePicker
          disabled={disabled}
          name="to"
          control={form.control}
          label={t('common.labels.timeTo')}
          onViewChange={updateToDateTime}
          maxDateTime={maxDate}
          required
        />
        {children}
      </form>
    </FormProvider>
  )
}

export default StepVisitDetails
