import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { UserApi } from '@app/common/ServicesWrapper/apis/UserApi'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import { AppLoader } from '@griegconnect/krakentools-react-ui'
import { FailedToRetrieveData } from '@app/common/StatusTexts'
import { SecurityIdentity } from '@app/common/ServicesWrapper/apis/dtos/IdentityAttributes'
import { useTranslation } from 'react-i18next'
import { OnSite } from '@app/common/ServicesWrapper/apis/CompaniesApi'
import ApplicationFlow from './Flow'
import { EditPropsWithDetails } from '../types'

const EditDefaultApplication: React.FC<EditPropsWithDetails> = ({ id, portDetails, application, services }) => {
  const { enqueue } = useAlerts()
  const { t } = useTranslation()
  const [formLoading, setFormLoading] = useState<boolean>(false)

  const [isDone, setIsDone] = React.useState<boolean>(false)

  const fetch = useQuery<
    {
      details: UserApi.Details
      me: SecurityIdentity
      customers: OnSite.Details[]
    },
    any
  >(
    ['application', id],
    async () => {
      const [details, me] = await Promise.all([
        services.userApi.details(application.current.visiting.port.id),
        services.userApi.me(),
      ])
      const customers =
        portDetails.attributes && portDetails.attributes.approvedCompanies
          ? await services.companiesApi.companiesOnSite(portDetails.port.id)
          : []
      return { details, me, customers }
    },
    {
      retry: (count, error) => error?.response?.status !== 404 && count < 3,
    }
  )

  if (fetch.isError) {
    return <FailedToRetrieveData />
  }

  if (fetch.isLoading) {
    return <AppLoader />
  }

  const onSubmit = async (form: UserApi.Application.Form) => {
    setFormLoading(true)
    try {
      await services.userApi.updateApplication(id, form)
      fetch.refetch()
      enqueue(t('applications.success.edit'), 'success')
      setIsDone(true)
    } catch (error) {
      enqueue(t('applications.errors.edit'), 'error')
    }
    setFormLoading(false)
  }

  return (
    <>
      <ApplicationFlow
        services={services}
        me={fetch.data.me}
        identities={fetch.data.details.identities}
        details={portDetails}
        customers={fetch.data.customers}
        onSubmit={(form) => onSubmit(form)}
        application={application}
        isDone={isDone}
        formLoading={formLoading}
        setFormLoading={setFormLoading}
        mode="edit"
      />
    </>
  )
}

export default EditDefaultApplication
