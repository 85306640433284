import React from 'react'
import { Navigation, requiresTenant, requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import { withActiveTenant } from '@app/routes/WithActiveTenant'
import { withServices } from '@app/routes/withServices'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { AccessDependencies } from '@app/Content'
import { Trans } from 'react-i18next'

const PATH = PATH_TENANT_BASE + '/settings'

export const administratorsModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.settings.administrators" />,
    path: PATH + '/administrators',
    page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./External'))))),
    exact: true,
    menu: true,
    access: (d) => requiresTenant(d) && requiresPermission(PERMISSIONS.frontend_users.read, true)(d),
  },
]
