import { List } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { availableLayersSelector, useMapContext, LayerControlCategory } from '@griegconnect/krakentools-react-kmap'
import { LayerListItem } from './LayerListItem'

type LayerListProps = {
  title?: string
  layers?: string[]
}

export const LayerList: React.FC<React.PropsWithChildren<LayerListProps>> = (props) => {
  const { kmapInstance: instance } = useMapContext()
  const layers = useRecoilValue(availableLayersSelector(instance.mapElementId))

  return (
    <LayerControlCategory title="Security objects">
      <List>
        {(!props.layers ? layers : layers.filter((l) => props.layers?.find((pl) => pl === l.name) !== undefined)).map(
          (layer) => {
            return <LayerListItem key={layer.key} layer={layer} />
          }
        )}
      </List>
    </LayerControlCategory>
  )
}

export default LayerList
