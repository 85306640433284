import { useUser } from '@griegconnect/krakentools-react-kraken-app'
import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import Flow, { NextState, applicationState, defaultNextState, nextStateAtom } from './Flow'
import { ApplicationFormData, toForm } from './schema'
import { EditPropsWithValues } from '../types'

const EditNextPortApplication: React.FC<EditPropsWithValues> = ({
  application,
  portDetails,
  formValues,
  userDetails,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const setApplicationFormData = useSetRecoilState<ApplicationFormData>(applicationState)
  const setState = useSetRecoilState<NextState>(nextStateAtom)
  const user = useUser()

  useEffect(() => {
    setApplicationFormData(toForm(application, user, userDetails))
    setState({
      ...defaultNextState,
      formType: 'edit',
      visitBy:
        application.current.visitor.vehicle !== null && application.current.visitor.vehicle.vehicle.length > 0
          ? 'driving'
          : 'walking',
      visitType: application.current.visiting.target.type,
    })
    setIsLoading(false)
  }, [setApplicationFormData, setState, setIsLoading, userDetails, user, application])

  return isLoading ? null : (
    <Flow
      formValues={formValues}
      isDone={false}
      portDetails={portDetails}
      application={application}
      userDetails={userDetails}
    />
  )
}

export default EditNextPortApplication
