import React from 'react'
import * as z from 'zod'
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form'
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import * as Form from '@griegconnect/krakentools-form'
import { IPortDetailsDto } from '@app/common/ServicesWrapper/apis/SharedDataApi'
import { UserApi } from '@app/common/ServicesWrapper/apis/UserApi'
import { zodResolver } from '@hookform/resolvers/zod'
import { toResolved } from '@app/common/ServicesWrapper/apis/dtos/userDto'
import SecurityConfirmations from '@app/common/SecurityConfirmations'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { initials } from '@app/lib/Initials'
import { Confirmations } from '../../common/types'
import { getMessages } from '../../common/utils'

const _Schema = z.object({
  message: z.string(),
  securityConfirmations: z.array(z.string()).default([]),
})

export const Schema = (details: IPortDetailsDto) => {
  return _Schema.refine(
    (data) => details.securityConfirmations.every((s) => data.securityConfirmations.includes(s.id)),
    {
      message: 'applications.validation.securityConfirmations',
      path: ['securityConfirmations'],
    }
  )
}

export type Input = z.infer<typeof _Schema>

export const defaultValues = ({ applicationForm }: IDefaultValuesProps): Input => {
  return {
    message: applicationForm?.message || '',
    securityConfirmations: applicationForm?.securityConfirmations || [],
  }
}

export const toForm = async (input: Input): Promise<Confirmations> => {
  return {
    message: input.message,
    securityConfirmations: input.securityConfirmations,
  }
}

interface IDefaultValuesProps {
  applicationForm?: Confirmations
}

interface Props {
  applicationForm?: Confirmations
  details: IPortDetailsDto
  application?: UserApi.Application.Details
  disabled: boolean
  onUpdate: (form: Confirmations, part: 'confirmations') => void
  onNext: () => void
  updateErrors: (errors: boolean) => void
  children?: React.ReactNode
}

function StepConfirmations({ applicationForm, details, application, disabled, onUpdate, onNext, updateErrors, children }: Props) {
  const form = useForm({
    defaultValues: defaultValues({ applicationForm }),
    resolver: zodResolver(Schema(details)),
  })
  const { t } = useTranslation()

  const message = form.watch('message')
  const securityConfirmations = form.watch('securityConfirmations')

  const messages = application ? getMessages(application) : []

  const errors = form.formState.errors

  React.useEffect(() => {
    updateErrors(!!Object.keys(errors).length)
  }, [errors.securityConfirmations]) // eslint-disable-line

  React.useEffect(() => {
    const updateParentForm = async () => {
      onUpdate(await toForm({ message, securityConfirmations }), 'confirmations')
    }
    updateParentForm()
  }, [message, securityConfirmations]) // eslint-disable-line

  const onSubmit = async (_data: Input) => onNext()

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        {details.securityConfirmations.length > 0 && (
          <>
            <Typography>{t('applications.subTitle.securityConfirmations')} *</Typography>
            <Box mb={1} />
            <Controller
              name="securityConfirmations"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <SecurityConfirmations
                  securityConfirmations={details.securityConfirmations}
                  value={value}
                  onChange={disabled ? undefined : onChange}
                  translate={true}
                />
              )}
            />
            {form.formState.errors.securityConfirmations && (
              <Form.ErrorHelperText
                error={t(((form.formState.errors.securityConfirmations as unknown) as FieldError).message ?? '')}
              />
            )}
          </>
        )}
        <Box mb={2} />
        {messages.length > 0 && (
          <>
            <List>
              {messages.map((msg) => {
                const avatar = () => {
                  if (msg.side === 'reviewer') {
                    return <Avatar>{initials(details.port.name)}</Avatar>
                  } else {
                    const user = toResolved(msg.by)
                    return user.picture ? <Avatar src={user.picture} /> : <Avatar>{initials(user.name)}</Avatar>
                  }
                }

                return (
                  <ListItem key={msg.at}>
                    <ListItemAvatar>{avatar()}</ListItemAvatar>
                    <ListItemText
                      primary={msg.message}
                      secondary={DateTime.fromISO(msg.at).toLocaleString(DateTime.DATETIME_SHORT)}
                    />
                  </ListItem>
                )
              })}
            </List>
            <Box m={1} />
          </>
        )}
        <Form.TextInput
          disabled={disabled}
          multiline
          name="message"
          control={form.control}
          label={t('common.labels.message')}
        />
        {children}
      </form>
    </FormProvider>
  )
}

export default StepConfirmations
