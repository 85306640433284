import { Theme, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import Chart from 'react-apexcharts'
import utils from './Utils'

const useStyles = makeStyles((theme: Theme) => ({
  chartContainer: {
    height: '100%',
    '& svg': {
      background: 'none !important',
    },
  },
}))

export interface IChartData {
  name: string
  data: number[]
}

interface ILineChartProps {
  data: IChartData[]
  xAxisLabels: (string | number)[]
  dataLabel?: string
  colors?: string[]
}

export const LineChart: React.FC<React.PropsWithChildren<ILineChartProps>> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { data, dataLabel } = props
  let { xAxisLabels } = props

  React.useEffect(() => {
    // resizes width
    window.dispatchEvent(new Event('resize'))
  }, []) // eslint-disable-line

  const strokeColors = props.colors ?? utils.colors.dark
  const fillColors = strokeColors.map((c) => `${c}52`) // adds 32 % opacity as hex alpha

  const overrideColor = (opacityInHex: string) =>
    theme.palette.mode === 'light' ? `#000000${opacityInHex}` : `#FFFFFF${opacityInHex}`

  const options = {
    chart: {
      id: 'line-chart',
      toolbar: {
        show: false,
      },
      animations: {
        speed: 700,
        animateGradually: {
          enabled: true,
          delay: 40,
        },
      },
    },
    stroke: {
      width: 2,
      colors: strokeColors,
    },
    fill: {
      type: 'solid',
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: xAxisLabels,
      axisTicks: {
        color: overrideColor('61'), // with 38 % opacity as hex alpha
      },
      labels: {
        style: {
          colors: overrideColor('99'), // with 60 % opacity as hex alpha
        },
      },
    },
    yaxis: {
      labels: {
        style: { colors: overrideColor('99') }, // with 60 % opacity as hex alpha
      },
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      y: {
        formatter: (value) => `${value} ${!dataLabel ? '' : value === 1 ? `${dataLabel}` : `${dataLabel}s`}`,
      },
    },
    legend: {
      markers: {
        fillColors: strokeColors,
      },
    },
    grid: {
      borderColor: overrideColor('29'), // with 16 % opacity as hex alpha
    },
    colors: fillColors,
  }

  return (
    <div className={`mixed-chart ${classes.chartContainer}`}>
      <Chart options={options} series={data} type="area" height="100%" />
    </div>
  )
}

export default LineChart
