import { IWidget, WidgetCard } from '@griegconnect/krakentools-react-ui'
import { Widgets } from '../Types'
import { Port as PortIcon } from '@griegconnect/krakentools-react-icons'
import { HostCompany as FacilityIcon } from '@griegconnect/krakentools-react-icons'
import { useEffect, useState } from 'react'
import { useServices } from '@app/common/ServicesWrapper'
import { useQuery } from '@tanstack/react-query'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { Duration } from 'luxon'
import * as TimePeriod from '../../components/TimePeriod'
import * as SkeletonLoader from '../SkeletonLoader'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { Stats } from '@app/common/ServicesWrapper/apis/Stats'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import { useTranslation } from "react-i18next"

interface IProgressData {
  controls: number
  facilities: number
  totalFacilities: number
}

interface IControlReportAreaWidget extends Widgets.InfoWithData<Stats.CompletedRangeStats[]>, WithActiveTenant {
  facilities: IAreaDto[]
}

export const Widget: React.VFC<IControlReportAreaWidget> = ({
  widgetConstructor,
  data,
  onRemove,
  onSettings,
  onDetails,
  editing,
  activeTenant,
  facilities,
}) => {
  const { services } = useServices()
  const { t } = useTranslation()

  const [progressData, setProgressData] = useState<IProgressData | undefined>(undefined)

  const timeFrom = widgetConstructor.timeFrom
    ? Duration.fromISO((widgetConstructor.timeFrom as unknown) as string)
    : undefined
  const timeTo = widgetConstructor.timeTo
    ? Duration.fromISO((widgetConstructor.timeTo as unknown) as string)
    : undefined

  const { data: fetchedData } = useQuery<Stats.CompletedRangeStats[] | undefined>(
    ['fetchedDataAreaControls', activeTenant.ref, timeFrom, data],
    async () => {
      if (timeFrom) {
        const period = TimePeriod.getConstructorDates(timeFrom, widgetConstructor.currentPeriod, timeTo)
        const result = await services.statsApi.completedChecksRange(activeTenant.ref, {
          from: period.fromDate.startOf('day').toJSDate(),
          to: period.toDate.endOf('day').toJSDate(),
        })
        return result
      } else {
        return data
      }
    }
  )

  useEffect(() => {
    if (fetchedData) {
      const filteredData = fetchedData
        .filter((d) =>
          widgetConstructor?.facilities ? widgetConstructor.facilities.find((f) => f === d.facility) : true
        )
        .reduce(
          (obj, data) => ({
            controls:
              widgetConstructor.subVariant === 'facilities' && !!data.facilityChecks
                ? obj.controls + data.facilityChecks.completedChecks
                : widgetConstructor.subVariant === 'quays' && !!data.quayChecks
                ? obj.controls + data.quayChecks.completedChecks
                : obj.controls,
            facilities:
              widgetConstructor.subVariant === 'facilities' && !!data.facilityChecks
                ? obj.facilities + 1
                : widgetConstructor.subVariant === 'quays' && !!data.quayChecks
                ? obj.facilities + 1
                : obj.facilities,
          }),
          { controls: 0, facilities: 0 }
        )
      setProgressData({ ...filteredData, totalFacilities: widgetConstructor.facilities?.length || facilities.length })
    }
  }, [widgetConstructor, fetchedData, facilities.length])

  const info = {
    facilities: {
      title: t('checks.module.facilityChecks'),
      icon: <FacilityIcon />,
    },
    quays: {
      title: t('checks.module.quayChecks'),
      icon: <PortIcon />,
    },
  }

  return progressData && widgetConstructor.subVariant ? (
    <WidgetCard
      title={widgetConstructor.name || info[widgetConstructor.subVariant].title}
      description={`${
        !widgetConstructor.facilities
          ? t('components.dashboard.labels.allFacilities')
          : widgetConstructor.facilities && widgetConstructor.facilities?.length > 3
          ? `${widgetConstructor.facilities?.length} ${t('security.labels.facilities').toLowerCase()}`
          : widgetConstructor.facilities?.map((f, i) => `${i !== 0 ? ', ' : ''}${f}`).join('')
      } - ${TimePeriod.getFilterText(t('components.dashboard.labels.last1Day'), timeFrom, timeTo, widgetConstructor.currentPeriod)}`}
      icon={info[widgetConstructor.subVariant].icon}
      onDetails={onDetails}
      detailsDisabled={!progressData.controls}
      editing={editing}
      onRemove={onRemove}
      onSettings={onSettings}
    >
      <Box display="flex" flex={1} alignItems="flex-end" justifyContent="flex-start" height="100%">
        <Typography variant="h1" component="h1">
          {progressData.controls}
        </Typography>
      </Box>
    </WidgetCard>
  ) : (
    <SkeletonLoader.Progress />
  )
}

export const controlReportAreaWidget = (props: IControlReportAreaWidget): IWidget[] => {
  const { widgetConstructor } = props
  return widgetConstructor.subVariant
    ? [
        {
          height: [3],
          position: widgetConstructor.position,
          id: widgetConstructor.id,
          component: <Widget {...props} />,
        },
      ]
    : []
}

export default controlReportAreaWidget
