import { AxiosInstance } from 'axios'
import { By } from './By'
import { CheckListField, CheckListFieldForm } from './CheckListsApi'
import { IAreaDto } from './dtos/areaDto'
import { ISecurityCheckResultDto } from './dtos/securityCheckResultsDto'
import { IUserDto } from './dtos/userDto'
import Page from './Page'
import qs from 'qs'
import { DateTime } from 'luxon'
import { Search as S, Infer } from '@app/lib/hooks/useSearch'

export type Status = 'completed' | 'in_progress' | 'todo'

export declare namespace PersonCheck {
  export interface Params {
    facility?: string[]
    comment?: string[]
    deviations?: boolean
  }
  export interface Details {
    personCheck: PersonCheck
    fields: CheckListField[]
  }
  export interface Form {
    facility: string
    time: DateTime
    person: string
    company: string
    vehicle: string
    vehicleRole: string
    result: string
    comment: string
    fields: CheckListFieldForm[]
    status: 'completed' | 'todo'
    assignee?: string
    addPassing: boolean
    checkpoint?: string
  }
}
export interface Completion {
  status: Status
  assignee?: IUserDto
}
export interface PersonCheck {
  id: string
  facility: IAreaDto
  person: IUserDto
  company: string
  vehicle: string
  vehicleRole: string
  result: ISecurityCheckResultDto
  comment: string
  modified: By
  negative: number
  time: string
  completion: Completion
}

export const ParamsSchema = {
  ...Page.schema,
  facility: S.array(S.string),
  comment: S.array(S.string),
  deviations: S.undefine(S.boolean),
}
export type Params = Infer<typeof ParamsSchema>

export class PersonCheckApi {
  private http: AxiosInstance
  constructor(http: any) {
    this.http = http
  }

  async list(tenantRef: string, search: PersonCheck.Params, pagination: Page.Search): Promise<Page<PersonCheck>> {
    const params = { ...search, ...pagination }
    const response = await this.http.get(`/ports/${tenantRef}/personChecks`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    return response.data
  }
  async get(tenantRef: string, id: string): Promise<PersonCheck.Details> {
    const response = await this.http.get(`/ports/${tenantRef}/personChecks/${encodeURIComponent(id)}`)
    return response.data
  }
  async create(tenantRef: string, form: PersonCheck.Form): Promise<string> {
    const postData = { ...form, time: form.time.toUTC() }
    const response = await this.http.post(`/ports/${tenantRef}/personChecks`, postData)
    return response.data.id
  }

  async delete(tenantRef: string, id: string): Promise<void> {
    await this.http.delete(`/ports/${tenantRef}/personChecks/${encodeURIComponent(id)}`)
  }
}
