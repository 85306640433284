import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgHostCompany = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M12 7h5V5h-5v2ZM17 10h-5V8h5v2ZM12 13h5v-2h-5v2ZM17 16h-5v-2h5v2ZM6 11h2v3H6v-3ZM8 15H6v3h2v-3Z" />,
    <path
      fillRule="evenodd"
      d="M10 2a1 1 0 0 0-1 1v5H4a1 1 0 0 0-1 1v11H2a1 1 0 1 0 0 2h19a1 1 0 1 0 0-2h-1V3a1 1 0 0 0-1-1h-9Zm6 18v-3h-3v3h-2V4h7v16h-2ZM5 10h4v10H5V10Z"
      clipRule="evenodd"
    />
  )
export default SvgHostCompany
