import { Box, BoxProps, Tooltip } from '@mui/material'
import { getAisCountry } from '../utils/aisCountryMap'

type CountryFlagProps = {
  mmsi: number
  /**
   * The size of the flag
   * @default 'tiny'
   *
   *```markdown
   * - tiny: `20x13px` (`40x27px` for retina)
   * - small: `40x27px` (`80x53px` for retina)
   * - medium: `80x53px` (`160x107px` for retina)
   * - large: `160x107px` (`320x213px` for retina)
   * - huge: `320x213px` (`640x427px` for retina)
   * ```
   */
  size?: 'huge' | 'large' | 'medium' | 'small' | 'tiny'
  tooltip?: boolean
  sx?: BoxProps['sx']
}

// 20 40 80 160 320 640 1280 2560

const sizes = {
  tiny: { width: 20, height: 13, retina: { width: 40, height: 27 } },
  small: { width: 40, height: 27, retina: { width: 80, height: 53 } },
  medium: { width: 80, height: 53, retina: { width: 160, height: 107 } },
  large: { width: 160, height: 107, retina: { width: 320, height: 213 } },
  huge: { width: 320, height: 213, retina: { width: 640, height: 427 } },
} as const

const CountryFlag = (props: CountryFlagProps) => {
  // const ratio = 1.4997070885
  const country = getAisCountry(props.mmsi)

  if (country) {
    const flag = (
      <Box
        component="img"
        loading="lazy"
        width={sizes[props.size ?? 'tiny'].width}
        height={sizes[props.size ?? 'tiny'].height}
        srcSet={`https://flagcdn.com/w${sizes[
          props.size ?? 'tiny'
        ].retina.width.toString()}/${country.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w${sizes[props.size ?? 'tiny'].width.toString()}/${country.code.toLowerCase()}.png`}
        alt={country.name}
        sx={{ backgroundColor: 'rgba(130,130,130,0.1)', ...props.sx }}
      />
    )

    if (props.tooltip) {
      return <Tooltip title={country.name}>{flag}</Tooltip>
    }
    return flag
  }

  return null
}

export default CountryFlag
