import * as Url from '@app/lib/util'
import { IFilterSelections } from '@griegconnect/krakentools-react-ui'
import { AxiosInstance } from 'axios'
import { Checkpoints } from './CheckpointApi'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { IUserDto } from './dtos/userDto'
import { Vessel } from './VesselsApi'
import { AreaLink } from './dtos/areaDto'
import { Interval } from 'luxon'
import Page from './Page'
import qs from 'qs'
import { IFileRefDto } from './dtos/fileRefDto'
import { VehicleCategory } from './SharedDataApi'
import { Search as S, Infer } from '@app/lib/hooks/useSearch'

export type VisitorType = 'person' | 'vehicle'

// eslint-disable-next-line
export interface PassingsQuery {
  id?: string
  area?: string
  type?: VisitorType
  user?: string
  from?: Date
  to?: Date
  licencePlate?: string
  permit?: string
}

export interface PassingsSearch {
  facility?: string[]
  checkpoint?: string[]
  time?: Interval[]
  vehicle?: string[]
  from?: string[]
  timeFrom?: string[]
  timeTo?: string[]
  visiting?: string[]
  vessel?: string[]
  person?: string[]
  pin?: string[]
  inside?: boolean
}

export interface VehicleVisitorForm {
  vehicle: string[]
  user?: string
}

export interface Visiting {
  permit: string
  company?: ITenant
  vessel?: Vessel
}

export interface PassingForm {
  checkpoint: string
  time?: Date
  users: string[]
  vehicles: VehicleVisitorForm[]
  permit?: string
  files: string[]
  oneTime?: boolean
}

export interface IPassingDto {
  id: string
  checkpoint: Checkpoints.CheckpointLink
  facility: AreaLink
  createdAt: string
  users: IUserDto[]
  vehicles: string[][]
  visiting: Visiting[]
}

export interface PassingPerson {
  person: IUserDto
  company: string | null
}

export interface PassingVehicle {
  vehicle: string[]
  company: string | null
}

export interface PassingCore {
  id: string
  port: ITenant
  facility: AreaLink
  checkpoint: Checkpoints.CheckpointLink
  createdAt: string
  data: {
    visy_gname?: string
    visy_pname?: string
    visy_pcompany?: string
    vehicleCategory?: VehicleCategory
  }
  files: IFileRefDto[]
  pin: string | null
}

export interface PassingDetails {
  passing: PassingCore
  people: PassingPerson[]
  vehicles: PassingVehicle[]
  permits: PermitLink[]
}

export interface PassingPermit {
  permit: string
  company?: string
  visiting: ITenant
}

export type Precision =
  | 'microseconds'
  | 'milliseconds'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year'
  | 'decade'
  | 'century'
  | 'millennium'

export interface PassingInFilter {
  precision?: Precision
  interval?: Interval
}

export interface PassingInEntry {
  facility: AreaLink
  classified: string | null
  time: string
  count: number
}

export interface PassingIn {
  precision: Precision
  interval: string
  entries: PassingInEntry[]
  excel: string
}

export interface TurnaroundEntry {
  facility: AreaLink
  time: string
  classified: string | null
  count: number
  min: string | null
  max: string | null
  avg: string | null
  median: string | null
}

export interface Turnaround {
  precision: Precision
  interval: string
  buckets: TurnaroundEntry[]
}

export interface TurnaroundStatsFilter {
  precision?: Precision
  interval?: Interval
}

export interface PermitLink {
  id: string
  visiting: VisitingVessel | VisitingCompany
}

export interface VisitingCompany {
  type: 'company'
  value: ITenant
}

export interface VisitingVessel {
  type: 'vessel'
  value: Vessel
}

export const ParamsSchema = {
  ...Page.schema,
  facility: S.array(S.string),
  checkpoint: S.array(S.string),
  vehicle: S.array(S.string),
  from: S.array(S.string),
  to: S.array(S.string),
  timeFrom: S.array(S.string),
  timeTo: S.array(S.string),
  visiting: S.array(S.string),
  vessel: S.array(S.string),
  person: S.array(S.string),
  pin: S.array(S.string),
  inside: S.undefine(S.boolean),
}
export type Params = Infer<typeof ParamsSchema>

export class PassingsApi {
  private httpClient: AxiosInstance
  private eventClient: (path: string) => EventSource

  constructor(httpClient: AxiosInstance, eventClient: (path: string) => EventSource) {
    this.httpClient = httpClient
    this.eventClient = eventClient
  }

  async search(tenantRef: string, criteria: PassingsSearch, page: Page.Search): Promise<Page<PassingDetails>> {
    const time = criteria.time?.map((i) => i.toISO())
    const params = { ...criteria, time, ...page }
    const response = await this.httpClient.get(`/ports/${tenantRef}/passings2`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async export(tenantRef: string, criteria: PassingsSearch, page: Page.Search): Promise<PassingDetails[]> {
    const time = criteria.time?.map((i) => i.toISO())
    const params = { ...criteria, time, ...page }
    const response = await this.httpClient.get(`/ports/${tenantRef}/passings2/export`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  onPassing(tenantRef: string, criteria: PassingsSearch, on: (passing: PassingDetails) => void): () => void {
    const time = criteria.time?.map((i) => i.toISO())
    const params = { ...criteria, time }
    const q = qs.stringify(params, { arrayFormat: 'repeat' })
    const client = this.eventClient(`/ports/${tenantRef}/passings2?${q}`)
    client.addEventListener('passing', (event: Event) => {
      const message = event as MessageEvent
      const passing = JSON.parse(message.data) as PassingDetails
      on(passing)
    })
    return () => client.close()
  }

  async list(tenantRef: string, filterParams?: {}): Promise<IPassingDto[]> {
    const baseApiPath = `/ports/${tenantRef}/passings`
    const urlWithParams = filterParams ? Url.addQueryParms(filterParams, true, baseApiPath) : baseApiPath
    const response = await this.httpClient.get(urlWithParams)
    return response.data.passings
  }

  async get(tenantRef: string, id: string): Promise<IPassingDto> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/passings/${id}`)
    return response.data
  }

  eventSource(tenantRef: string, filterParams?: IFilterSelections): EventSource {
    const url = `/ports/${tenantRef}/passings`
    return this.eventClient(filterParams ? Url.addQueryParms(filterParams, true, url) : url)
  }

  async create(tenantRef: string, form: PassingForm): Promise<void> {
    await this.httpClient.post(`/ports/${tenantRef}/passings`, form)
  }

  async passingIn(tenantRef: string, filter: PassingInFilter): Promise<PassingIn> {
    const params = { ...filter, interval: filter.interval?.toISO() }
    const response = await this.httpClient.get(`/ports/${tenantRef}/passing-stats/in`, { params })
    return response.data
  }

  async turnaroundStats(tenantRef: string, filter: TurnaroundStatsFilter): Promise<Turnaround> {
    const params = { ...filter, interval: filter.interval?.toISO() }
    const response = await this.httpClient.get(`/ports/${tenantRef}/passing-stats/turnaround2`, { params })
    return response.data
  }
}
