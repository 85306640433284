import React from 'react'
import { Navigation, requiresPermission, requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { withActiveTenant } from '@app/routes/WithActiveTenant'
import { withServices } from '@app/routes/withServices'
import { AccessDependencies } from '@app/Content'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { withParam } from '@app/routes/withParam'
import { isPort, usesContracts } from '@app/lib/access'
import { Trans } from 'react-i18next'

const COURSE_ATTENDEES_PATH = PATH_TENANT_BASE + '/course-attendees'

export const courseAttendeesModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.courses.newCourseAttendee" />,
    path: COURSE_ATTENDEES_PATH + '/attendee/new',
    page: React.createElement(
      withServices(
        withActiveTenant(
          withParam(
            React.lazy(() => import('./CourseAttendee')),
            'attendeeId'
          )
        )
      )
    ),
    exact: true,
    access: (d) =>
      (!isPort(d) || usesContracts(d)) && requiresPermission(PERMISSIONS.course_attendance.create, true)(d),
  },
  {
    name: <Trans i18nKey="navigation.courses.courseAttendee" />,
    path: COURSE_ATTENDEES_PATH + '/attendee/edit/:attendeeId',
    page: React.createElement(
      withServices(
        withActiveTenant(
          withParam(
            React.lazy(() => import('./CourseAttendee')),
            'attendeeId'
          )
        )
      )
    ),
    exact: true,
    access: (d) =>
      (!isPort(d) || usesContracts(d)) &&
      requiresAllOfPermissions([PERMISSIONS.course_attendance.read, PERMISSIONS.course_attendance.update], true)(d),
  },
]
