import { useBreakpointDown } from '@griegconnect/krakentools-react-kraken-app'
import { Box, useTheme } from '@mui/material'
import { Person as PersonIcon } from '@griegconnect/krakentools-react-icons'

const DummyUploadImage = () => {
  const isDevice = useBreakpointDown('md')
  const theme = useTheme()

  return (
    <Box display="flex" width={'100%'} alignItems="center" justifyContent="center" mt={2} mb={2}>
      <Box
        display="flex"
        height={isDevice ? 70 : 200}
        width={isDevice ? '40%' : 200}
        bgcolor={theme.palette.action.hover}
        alignItems="center"
        justifyContent="center"
        borderRadius={2}
      >
        <PersonIcon color="action" sx={{ fontSize: 50 }} />
      </Box>
    </Box>
  )
}

export default DummyUploadImage
