import { Tabs, Tab, Container, Box, useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { Services } from '@app/common/ServicesWrapper/types'
import Company from './Company'
import SymmetryBlueId from './SymmetryBlueId'
import { HostCompany as CompanyIcon } from '@griegconnect/krakentools-react-icons'
import { Person as PersonIcon } from '@griegconnect/krakentools-react-icons'
import { PortCall as PortCallIcon } from '@griegconnect/krakentools-react-icons'
import CardIcon from '@mui/icons-material/CreditCard'
import { AppLoader } from '@griegconnect/krakentools-react-ui'
import PasswordIcon from '@mui/icons-material/Password'
import UserPin from './UserPIN'
import CardNumbers from './CardNumbers'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import { useQuery } from '@tanstack/react-query'
import {
  IdentityAttributes,
  IdentityAttributesForm,
  SecurityIdentity,
} from '../ServicesWrapper/apis/dtos/IdentityAttributes'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { IFileRefDtoSchema } from '../ServicesWrapper/apis/dtos/fileRefDto'
import { FormProvider, useForm } from 'react-hook-form'
import IdDocuments from './IdDocuments'

export namespace ProfileAttributesForm {
  export const Schema = z.object({
    company: z.string(),
    idDocument: z.array(IFileRefDtoSchema),
    symmetryBlue: z.number().nullable(),
    pin: z.number().nullable(),
    cardNumbers: z.array(z.object({ cardNumber: z.number().nullable() })),
  })
  export type Inputs = z.infer<typeof Schema>

  export const defaultValues = (): Inputs => ({
    company: '',
    idDocument: [],
    symmetryBlue: null,
    pin: null,
    cardNumbers: [],
  })

  export const toCardNumberList = (inputs: Inputs) => {
    return inputs.cardNumbers
      .map((item, index) => {
        return item.cardNumber
      })
      .flatMap((n) => (n ? [n] : []))
  }

  export const fromAttributes = (attributes: IdentityAttributes): Inputs => {
    return {
      company: attributes.company || '',
      idDocument: attributes.idDocument ? [attributes.idDocument] : [],
      symmetryBlue: attributes.symmetryBlue || null,
      pin: attributes.pin || null,
      cardNumbers: attributes.cardNumbers?.map((n) => {
        return {
          cardNumber: n,
        }
      }) || [{ cardNumber: 9999999 }],
    }
  }

  export const toForm = (inputs: Inputs): IdentityAttributesForm => {
    return {
      company: inputs.company,
      idDocument: inputs.idDocument.at(0)?.id,
      symmetryBlue: inputs.symmetryBlue || undefined,
      pin: inputs.pin || undefined,
      cardNumbers: toCardNumberList(inputs),
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flex: 1,
  },
  tabs: {
    width: 64,
    transition: 'width cubic-bezier(0.4, 0, 0.6, 1) 225ms',
    borderRight: `1px solid ${theme.palette.divider}`,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      width: 260,
    },
    '& button': {
      margin: '0px',
      padding: `${theme.spacing()} ${theme.spacing(0)}`,
      width: '100%',
      minHeight: '48px',
      paddingRight: 25,
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
      '& > span': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        fontSize: '1rem',
        fontWeight: '400',
      },
    },
    '& svg': {
      marginBottom: '0 !important',
    },
  },
  tabPanel: {},
}))

interface IProps {
  children?: React.ReactNode
  index: any
  value: any
}

export const TabPanel: React.FC<React.PropsWithChildren<IProps>> = ({ children, value, index, ...other }) => {
  const classes = useStyles()
  const theme = useTheme()

  const isXS = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div
      role="tabpanel"
      style={{ width: '100%', marginLeft: isXS ? '64px' : '260px' }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div className={classes.tabPanel}>{children}</div>}
    </div>
  )
}

interface ITabsProps {
  services: Services
  company?: string
}

export const VerticalTabs = (props: ITabsProps) => {
  const { services } = props

  const { data: securityIdentity, isLoading, refetch } = useQuery<SecurityIdentity>(
    ['attributes', services.userApi],
    async () => {
      const data = await services.userApi.me()
      return data
    }
  )

  const onRefresh = () => {
    refetch()
  }

  return (
    <Container maxWidth="md" style={{ height: '100%' }}>
      <Box display="flex" flex="1" alignItems="flex-start" justifyContent="flex-start" height="100%">
        {isLoading ? (
          <AppLoader />
        ) : (
          <ProfileFormTabs services={services} securityIdentity={securityIdentity} refresh={onRefresh} />
        )}
      </Box>
    </Container>
  )
}

interface IProfileFormTabs {
  services: Services
  securityIdentity?: SecurityIdentity
  refresh: () => void
}

const ProfileFormTabs: React.FC<React.PropsWithChildren<IProfileFormTabs>> = ({
  services,
  securityIdentity,
  refresh,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const { enqueue } = useAlerts()

  const handleChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const isXS = useMediaQuery(theme.breakpoints.down('md'))

  const form = useForm({
    defaultValues: securityIdentity
      ? ProfileAttributesForm.fromAttributes(securityIdentity.attributes)
      : ProfileAttributesForm.defaultValues(),
    resolver: zodResolver(ProfileAttributesForm.Schema),
  })

  const updateProfile = async () => {
    const inputForm = ProfileAttributesForm.toForm(form.getValues())
    try {
      await services.userApi.updateProfile(
        inputForm.idDocument,
        inputForm.company,
        inputForm.symmetryBlue?.toString() || undefined,
        inputForm.pin,
        inputForm.cardNumbers
      )
      enqueue('Profile updated!', 'success')
    } catch (err) {
      enqueue('Error updating profile', 'error')
    }
  }
  return (
    <>
      <div className={classes.root}>
        <FormProvider {...form}>
          <form id="profileAttributesFormId" onSubmit={form.handleSubmit(updateProfile)}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab label={!isXS ? `ID document` : ``} icon={<PersonIcon />} />
              <Tab label={!isXS ? `Company` : ``} icon={<CompanyIcon />} />
              <Tab label={!isXS ? `Symmmetry Blue` : ``} icon={<PortCallIcon />} />
              <Tab label={!isXS ? `PIN` : ``} icon={<PasswordIcon />} />
              <Tab label={!isXS ? `Card Numbers` : ``} icon={<CardIcon />} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <IdDocuments services={services} form={form} refresh={refresh} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Company services={services} form={form} refresh={refresh} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <SymmetryBlueId services={services} form={form} refresh={refresh} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <UserPin services={services} form={form} refresh={refresh} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CardNumbers
                services={services}
                form={form}
                refresh={refresh}
                attributes={securityIdentity?.attributes}
              />
            </TabPanel>
          </form>
        </FormProvider>
      </div>
    </>
  )
}

export default VerticalTabs
