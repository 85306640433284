import { IFileRefDtoSchema } from './fileRefDto'
import * as z from 'zod'

export const ProfileSchema = z.object({
  companyName: z.string().optional(),
  idPhoto: IFileRefDtoSchema.optional(),
  type: z.string().optional(),
  symmetryBlue: z.number().optional(),
  firstname: z.string().optional(),
  lastname: z.string().optional(),
})

export const ResolvedUserSchema = z.object({
  resolved: z.literal(true),
  id: z.string(),
  name: z.string(),
  email: z.string(),
  email_verified: z.boolean(),
  picture: z.string().nullable(),
  mobile: z.string().optional().nullable(),
  mobile_verified: z.boolean(),
  profile: ProfileSchema,
})

export const UnresolvedUserSchema = z.object({
  resolved: z.literal(false),
  id: z.string(),
})

export const IUserSchema = z.union([ResolvedUserSchema, UnresolvedUserSchema])

export type Profile = z.infer<typeof ProfileSchema>
export type ResolvedUser = z.infer<typeof ResolvedUserSchema>
export type UnresolvedUser = z.infer<typeof UnresolvedUserSchema>
export type IUserDto = z.infer<typeof IUserSchema>

export const toResolved = (user: IUserDto): ResolvedUser =>
  user.resolved
    ? Object.assign(user, {
        name: user.name.length > 0 ? user.name : user.mobile && user.mobile.length > 0 ? user.mobile : 'Unknown',
      })
    : user.id.endsWith('port')
    ? {
        resolved: true,
        id: user.id,
        name: 'Port',
        email: 'uknown',
        email_verified: false,
        mobile_verified: false,
        profile: {},
        picture: null,
      }
    : {
        resolved: true,
        id: user.id,
        name: 'Unknown',
        email: 'uknown',
        email_verified: false,
        mobile_verified: false,
        profile: {},
        picture: null,
      }
