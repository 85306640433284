import { AxiosInstance } from 'axios'
import { ResolvedUser } from './dtos/userDto'
import { ITenant } from '@griegconnect/krakentools-react-tenants'

export class SearchApi {
  private readonly http: AxiosInstance
  constructor(http: AxiosInstance) {
    this.http = http
  }

  async searchCompany(tenantRef: String, name: string): Promise<ITenant[]> {
    const response = await this.http.get(`/ports/${tenantRef}/search/tenants?name=${encodeURIComponent(name)}`)
    return response.data
  }

  async getUserByEmail(tenantRef: String, email: string): Promise<ResolvedUser | null> {
    try {
      const response = await this.http.get(`/ports/${tenantRef}/search/user?email=${encodeURIComponent(email)}`)
      return { ...response.data, resolved: true }
    } catch (e) {
      return null
    }
  }
}
