import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { ArrowLeft, Close } from '@griegconnect/krakentools-react-icons'
import { PaperProps } from '@mui/material'

type DialogWithContentProps = Pick<DialogProps, 'disablePortal' | 'fullScreen' | 'className'> & {
  title: string | JSX.Element
  closeButtonName?: string
  nextButtonName?: string
  /**
   * @deprecated please stop using this prop, secondary button should use inherit (default)
   * https://www.figma.com/file/8DOl7xbXo12GOyptxed0Dm/Sub-components?node-id=33%3A144
   */
  closeButtonColor?: ButtonProps['color']
  /**
   * @deprecated primary button should only use theme color and error
   * please use error prop if you want to use the new api
   * https://www.figma.com/file/8DOl7xbXo12GOyptxed0Dm/Sub-components?node-id=33%3A144
   */
  nextButtonColor?: ButtonProps['color']
  nextButtonType?: ButtonProps['type']
  error?: boolean
  loading?: boolean
  disabled?: DialogWithContentDisabledTypes
  /**
   * Defaults to `true`
   */
  centered?: boolean
  children: React.ReactNode
  open: boolean
  autoFocus?: boolean
  onClose?: (event: {}, reason: DialogWithContentCloseReasons) => void
  onNext: () => void
  headerBackButton?: boolean
  onHeaderBackClicked?: () => void
  headerCloseButton?: boolean
  elevation?: PaperProps['elevation']
}

let warnDeprecated = false

export type DialogWithContentDisabledTypes = boolean | 'nextButton' | 'closeButton'

export type DialogWithContentCloseReasons =
  | 'backdropClick'
  | 'escapeKeyDown'
  | 'closeButtonClick'
  | 'closeIconButtonClick'

const DialogWithContent = (props: DialogWithContentProps) => {
  const {
    children,
    closeButtonColor,
    closeButtonName,
    error,
    nextButtonColor,
    nextButtonName = 'OK',
    nextButtonType,
    onClose,
    onNext: onComplete,
    open,
    autoFocus,
    title,
    disabled,
    centered = true,
    loading,
    headerBackButton,
    onHeaderBackClicked,
    headerCloseButton,
    disablePortal,
    elevation,
  } = props

  if (!warnDeprecated && (closeButtonColor !== undefined || nextButtonColor !== undefined)) {
    console.warn(
      `[react-ui]: DialogWithContent's closeButtonColor and nextButtonColor is deprecated. Please remove nextButtonColor and use error prop if you want a red button`
    )
    warnDeprecated = true
  }

  const handleClose = (event: {}, reason: DialogWithContentCloseReasons) => {
    if (onClose) {
      onClose(event, reason)
    }
  }

  const handleComplete = () => {
    onComplete()
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ elevation }}
      onClose={onClose}
      fullWidth
      className={props.className}
      fullScreen={props.fullScreen}
      disablePortal={disablePortal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={centered ? { '.MuiDialog-container': { alignItems: 'center', paddingTop: 0 } } : undefined}
    >
      <DialogTitle id="dialog-title">
        {headerBackButton && (
          <IconButton
            aria-label="go back"
            size="small"
            sx={{ p: 0, mr: 1, mt: -0.5, color: (theme) => theme.palette.text.secondary }}
            onClick={() => (onHeaderBackClicked ? onHeaderBackClicked() : undefined)}
          >
            <ArrowLeft fontSize="medium" />
          </IconButton>
        )}
        {title}
        {headerCloseButton && (
          <IconButton
            aria-label="close"
            size="small"
            sx={{
              position: 'absolute',
              right: 16,
              marginTop: -0.5,
              color: (theme) => theme.palette.text.secondary,
            }}
            onClick={(e) => handleClose(e, 'closeIconButtonClick')}
          >
            <Close fontSize="medium" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ paddingTop: 0, paddingRight: 3, paddingBottom: 3, paddingLeft: 3 }}>
        {closeButtonName && (
          <Button
            onClick={(e) => handleClose(e, 'closeButtonClick')}
            variant="outlined"
            color={closeButtonColor}
            disabled={disabled === true || disabled === 'closeButton'}
          >
            {closeButtonName}
          </Button>
        )}
        <Button
          onClick={handleComplete}
          color={error ? 'error' : undefined}
          variant="contained"
          type={nextButtonType}
          disabled={disabled === true || disabled === 'nextButton'}
          autoFocus={autoFocus}
          endIcon={loading ? <CircularProgress size={20} color="inherit" /> : undefined}
        >
          {nextButtonName}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogWithContent
