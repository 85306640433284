import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { useAuth } from '@griegconnect/krakentools-react-kraken-app'
import md5 from 'blueimp-md5'
import { useQuery } from '@tanstack/react-query'
import { useServices } from '@app/common/ServicesWrapper'
import { Stats, Check } from '@app/common/ServicesWrapper/apis/Stats'
import { v4 } from 'uuid'
import { ContractApplications } from '@app/common/ServicesWrapper/apis/ContractsApi'
import { Widgets } from '../../CustomDashboard/Types'
import CustomDashboard from '../../CustomDashboard'
import { DateTime } from 'luxon'
import { VisitorStats } from '@app/common/ServicesWrapper/apis/VisitorStatsApi'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import { useTheme } from '@mui/material/styles'
import { useRecoilValue } from 'recoil'
import { portConfigStore } from '@app/stores/portStore'
import { useEffect, useState } from 'react'
import { Quays } from '@app/common/ServicesWrapper/apis/QuayApi'
import { useTranslation } from "react-i18next"

const PfsoDashboard: React.FC<React.PropsWithChildren<WithActiveTenant>> = ({ activeTenant, children }) => {
  const { services } = useServices()
  const user = useAuth()
  const hash = md5(user.user!.email)
  const portConfig = useRecoilValue(portConfigStore)
  const theme = useTheme()
  const warning = theme.palette.warning.main
  const { t } = useTranslation()

  const dosConstructors: Widgets.Constructor[] = [
    {
      id: v4(),
      type: 'dos',
      variant: 'value',
      name: t('components.dashboard.labels.cardApplications'),
      subVariant: 'in_review' as ContractApplications.Status,
      position: [undefined, undefined, undefined, { x: 0, y: 0 }],
      width: [3, 3],
      color: warning,
    },
    {
      id: v4(),
      type: 'dos',
      variant: 'value',
      name: t('components.dashboard.labels.cardApplications'),
      subVariant: 'in_production' as ContractApplications.Status,
      position: [undefined, undefined, undefined, { x: 3, y: 0 }],
      width: [3, 3],
    },
    {
      id: v4(),
      type: 'dos',
      variant: 'value',
      name: t('components.dashboard.labels.cardApplications'),
      subVariant: 'require_more_information' as ContractApplications.Status,
      position: [undefined, undefined, undefined, { x: 6, y: 0 }],
      width: [3, 3],
    },
    {
      id: v4(),
      type: 'dos',
      variant: 'value',
      name: t('components.dashboard.labels.cardApplications'),
      subVariant: 'completed' as ContractApplications.Status,
      position: [undefined, undefined, undefined, { x: 9, y: 0 }],
      width: [3, 3],
    },
  ]

  const defaultConstructors: Widgets.Constructor[] = [
    {
      id: v4(),
      type:'map',
      variant: 'map',
      name: t('components.dashboard.module.map'),
      position: [undefined, undefined, undefined, { x: 8, y: 4 }],
    },
    {
      id: v4(),
      type: 'controls',
      variant: 'progress',
      subVariant: 'persons',
      position: [undefined, undefined, undefined, { x: 0, y: 4 }],
    },
    {
      id: v4(),
      type: 'controls',
      variant: 'progress',
      subVariant: 'vehicles',
      position: [undefined, undefined, undefined, { x: 4, y: 4 }],
    },
    {
      id: v4(),
      type: 'deviations',
      variant: 'value',
      name: t('components.dashboard.labels.deviations'),
      subVariant: 'personChecks' as Check,
      position: [undefined, undefined, undefined, { x: 0, y: 8 }],
      width: [3, 4],
    },
    {
      id: v4(),
      type: 'deviations',
      variant: 'value',
      name:  t('components.dashboard.labels.deviations'),
      subVariant: 'vehicleChecks' as Check,
      position: [undefined, undefined, undefined, { x: 4, y: 8 }],
      width: [3, 4],
    },
    {
      id: v4(),
      type: 'areaControls',
      variant: 'value',
      subVariant: 'quays',
      position: [undefined, undefined, undefined, { x: 0, y: 12 }],
    },
    {
      id: v4(),
      type: 'areaControls',
      variant: 'value',
      subVariant: 'facilities',
      position: [undefined, undefined, undefined, { x: 4, y: 12 }],
    },
  ]

  const [defaultWidgetConstructors, setDefaultWidgetConstructors] = useState<Widgets.Constructor[]>([])

  useEffect(() => {
    const totalConstructors =
      portConfig.attributes && portConfig.attributes.approvedCompanies
        ? [...dosConstructors, ...defaultConstructors]
        : defaultConstructors
    totalConstructors.length > defaultWidgetConstructors.length && setDefaultWidgetConstructors(totalConstructors)
  }, [portConfig.attributes]) // eslint-disable-line

  const { data, isLoading: isFetching, refetch } = useQuery<{
    dos: Stats.ContractStatusStats | undefined
    deviations: Stats.DeviationsRangeStats[] | undefined
    controls: VisitorStats[] | undefined
    areaControls: Stats.CompletedRangeStats[] | undefined
    areas: IAreaDto[] | undefined
    quays: Quays.Quay[] | undefined
  }>(['', activeTenant.ref], async () => {
    const now = DateTime.now()
    const [dos, deviations, controls, areaControls, areas, quays] = await Promise.allSettled([
      services.statsApi.contractApplicationStatuses(activeTenant.ref),
      services.statsApi.deviationsRange(activeTenant.ref, {}),
      services.visitorStatsApi.get(
        activeTenant.ref,
        now.startOf('year').startOf('day').toJSDate(),
        now.endOf('day').toJSDate()
      ),
      services.statsApi.completedChecksRange(activeTenant.ref, {
        from: now.startOf('day').toJSDate(),
        to: now.endOf('day').toJSDate(),
      }),
      services.areasApi.getAreas(activeTenant.ref),
      services.quayApi.list(activeTenant.ref, {}),
    ])
    return {
      dos: dos.status === 'fulfilled' ? dos.value : undefined,
      deviations: deviations.status === 'fulfilled' ? deviations.value : undefined,
      controls: controls.status === 'fulfilled' ? controls.value : undefined,
      areaControls: areaControls.status === 'fulfilled' ? areaControls.value : undefined,
      areas: areas.status === 'fulfilled' ? areas.value : undefined,
      quays: quays.status === 'fulfilled' ? quays.value : undefined,
    }
  })

  useEffect(() => {
    refetch()
  }, [portConfig, refetch])

  return (
    <>
      <CustomDashboard
        activeTenant={activeTenant}
        storageKeys={{
          layout: `${activeTenant.ref}-${hash}-dashboard-layout-pfso`,
          widgets: `${activeTenant.ref}-${hash}-dashboard-widget-constructors-pfso`,
        }}
        defaultWidgetConstructors={defaultWidgetConstructors}
        data={{
          isFetching,
          dos: data?.dos,
          deviations: data?.deviations,
          controls: data?.controls,
          areaControls: data?.areaControls,
          areas: data?.areas,
          quays: data?.quays,
        }}
      >
        {children}
      </CustomDashboard>
    </>
  )
}

export default PfsoDashboard
