import { LocalIdentity } from './dtos/identitiesDto'
import { AxiosInstance } from 'axios'
import qs from 'qs'
import Page from './Page'
import { IUserDto } from './dtos/userDto'
import { Search as S, Infer } from '@app/lib/hooks/useSearch'

export declare namespace IdentityApi {
  export interface Params {
    id?: string[]
    person?: string[]
  }
  export interface Link {
    id: string
    identity: IUserDto
  }
}

export const ParamsSchema = {
  ...Page.schema,
  id: S.array(S.string),
  person: S.array(S.string),
}
export type Params = Infer<typeof ParamsSchema>

export class IdentityApi {
  httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async getTree(tenantRef: string, id: string): Promise<LocalIdentity[]> {
    const encodedId = encodeURIComponent(id)
    const response = await this.httpClient.get(`/ports/${tenantRef}/identities/${encodedId}`)
    return response.data
  }

  async getDetails(tenantRef: string, id: string): Promise<IdentityApi.Link> {
    const encodedId = encodeURIComponent(id)
    const response = await this.httpClient.get(`/ports/${tenantRef}/identities/${encodedId}/details`)
    return response.data
  }

  async getLocalIdentities(
    tenantRef: string,
    filter: IdentityApi.Params,
    paging: Page.Search
  ): Promise<Page<IdentityApi.Link>> {
    const params = {
      ...filter,
      ...paging,
    }

    const response = await this.httpClient.get(`/ports/${tenantRef}/identities`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async setParent(tenantRef: string, id: string, set: { parent: string | null }): Promise<void> {
    await this.httpClient.put(`/ports/${tenantRef}/identities/${id}`, set)
  }
}
