import { Chip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Cancel as CancelIcon } from '@griegconnect/krakentools-react-icons'
import CheckIcon from '@mui/icons-material/Check'
import React, { HTMLProps } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    minWidth: '32px',
    height: '32px',
    borderRadius: '16px',
    backgroundColor: '#FF9A7A',
    color: '#000',
    '& span': {
      padding: 0,
      height: '24px',
    },
    transition: 'none',
  },
  success: {
    minWidth: '32px',
    height: '32px',
    borderRadius: '16px',
    backgroundColor: '#a3d6a6',
    color: '#000',
    '& span': {
      padding: 0,
      height: '24px',
    },
    transition: 'none',
  },
  errorNoChip: {
    color: '#FF9A7A',
  },
  successNoChip: {
    color: '#a3d6a6',
  },
}))

interface IConfirmationStatusIconIconProps extends HTMLProps<HTMLDivElement> {
  status: 'success' | 'error'
  noChip?: boolean
}

const ConfirmationStatusIcon: React.FC<React.PropsWithChildren<IConfirmationStatusIconIconProps>> = (props) => {
  const { status, noChip } = props
  const classes = useStyles()

  return (
    <>
      {status === 'success' && (
        <div>
          {noChip ? (
            <CheckIcon className={classes.successNoChip} />
          ) : (
            <Chip className={classes.success} label={<CheckIcon />} />
          )}
          {/* <CheckCircleIcon style={{color: '#A3D6A6'}} fontSize="large" /> */}
        </div>
      )}
      {status === 'error' && (
        <div>
          {noChip ? (
            <CancelIcon className={classes.errorNoChip} />
          ) : (
            <Chip className={classes.error} label={<CancelIcon />} />
          )}
          {/* <CancelIcon style={{color: '#FF9A7A'}} fontSize="large"/> */}
        </div>
      )}
    </>
  )
}

export default ConfirmationStatusIcon
