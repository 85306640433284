import { FormControl, FormControlProps, InputLabel, MenuItem, Select } from '@mui/material'
import MobileDetect from 'mobile-detect'
import React from 'react'
import { IUiReactSelectOptions } from '@app/lib/temp_types'

interface IUiSelectProps {
  label: string
  margin?: FormControlProps['margin']
  onChange: (value: string | undefined) => void
  required?: boolean
  selectItems: any[]
  unSelect?: boolean
  unSelectText?: string
  value?: string | number
  disabled?: boolean
}

export const UiSelect: React.FunctionComponent<React.PropsWithChildren<IUiSelectProps>> = ({
  label,
  margin,
  onChange,
  required,
  selectItems,
  unSelect,
  value,
  unSelectText,
  disabled,
}) => {
  const selectOptionItems: IUiReactSelectOptions = []
  const isMobileDevice = new MobileDetect(window.navigator.userAgent).mobile() ? true : false // TODO: Burde vi lagt denne inn i en global store?

  const selectItem = (itemValue: string, itemLabel: string) => {
    if (isMobileDevice) {
      return (
        <option key={`${label}-${itemValue}`} value={itemValue}>
          {itemLabel}
        </option>
      )
    } else {
      return (
        <MenuItem key={`${label}-${itemValue}`} value={itemValue}>
          {itemLabel.length > 0 && <>{itemLabel}</>}
          {itemLabel.length === 0 && !unSelectText && <em>None</em>}
          {itemLabel.length === 0 && unSelectText && <em>{unSelectText}</em>}
        </MenuItem>
      )
    }
  }

  for (const selectItemToAdd of selectItems) {
    selectOptionItems.push({
      label: selectItemToAdd.name,
      value: selectItemToAdd.id,
    })
  }
  const options = selectOptionItems
    .filter((option: any) => {
      return option.label === value ? false : true
    })
    .map((option) => selectItem(option.value, option.label))

  if (unSelect) {
    options.unshift(selectItem('', ''))
  }

  const onChangeHandler = (evt) => {
    if (onChange) {
      onChange(evt.target.value)
    }
  }

  return (
    <React.Fragment>
      <FormControl variant="filled" fullWidth={true} margin="normal">
        <InputLabel id={`select-for-${label}`}>{label}</InputLabel>
        <Select
          labelId={`select-for-${label}`}
          value={value ? value : ''}
          label={label}
          onChange={onChangeHandler}
          margin="none"
          native={isMobileDevice}
          disabled={disabled}
        >
          {options}
        </Select>
      </FormControl>
    </React.Fragment>
  )
}
