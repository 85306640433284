import { AxiosInstance } from 'axios'
import { DateTime } from 'luxon'
import qs from 'qs'
import { By } from './By'
import { AreaLink } from './dtos/areaDto'
import { IFileRefDto } from './dtos/fileRefDto'
import Page from './Page'
import { Search as S, Infer } from '@app/lib/hooks/useSearch'

export interface VisitorStats {
  facility: AreaLink
  vehicles: Visitor
  persons: Visitor
}

export declare namespace VisitorEntry {
  export interface Params {
    id?: string[]
    facility?: string[]
    comment?: string[]
  }
}

export interface VisitorEntry {
  id: string
  facility: AreaLink
  vehicles: Visitor
  persons: Visitor
  time: string
  comment: string
  created: By
  files: IFileRefDto[]
}

interface Visitor {
  passed: number
  checked: number
  target: number
}

export interface VisitorForm {
  passed: number
  checked: number
}

export interface VisitorsForm {
  facility: string
  time: DateTime
  vehicles: VisitorForm
  persons: VisitorForm
  comment: string
  files?: string[]
}

export const ParamsSchema = {
  ...Page.schema,
  id: S.array(S.string),
  facility: S.array(S.string),
  comment: S.array(S.string),
}
export type Params = Infer<typeof ParamsSchema>

export class VisitorStatsApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async get(tenantRef: string, from: Date, to: Date): Promise<VisitorStats[]> {
    const response = await this.http.get(`/ports/${tenantRef}/visitorStats/stats`, { params: { from, to } })
    return response.data.visitorStats
  }

  async list(tenantRef: string, search: VisitorEntry.Params, page: Page.Search): Promise<Page<VisitorEntry>> {
    const response = await this.http.get(`/ports/${tenantRef}/visitorStats`, {
      params: { ...search, ...page },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async register(tenantRef: string, form: VisitorsForm): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/visitorStats`, form)
  }

  async update(tenantRef: string, id: string, form: VisitorsForm): Promise<void> {
    await this.http.put(`ports/${tenantRef}/visitorStats/${id}`, form)
  }

  async delete(tenantRef: string, id: string): Promise<void> {
    await this.http.delete(`ports/${tenantRef}/visitorStats/${id}`)
  }
}
