import { useAuth } from '@griegconnect/krakentools-react-kraken-app'
import {
  MapProvider,
  ZoomControl,
  GpsControl,
  LiveView,
  activeAssetLayersSelector,
  AssetsView,
  assetLayersSelector,
  MapButtonPanel,
} from '@griegconnect/krakentools-react-kmap'
import React, { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { useConfig } from '@griegconnect/krakentools-react-kraken-app'
import md5 from 'blueimp-md5'

export const Map: React.FC<React.PropsWithChildren<WithActiveTenant>> = ({ activeTenant }) => {
  const config = useConfig()
  const { getTokenSilently } = useAuth()
  const isMounted = useRef<boolean>(false)
  const user = useAuth()
  const userHash = md5(user.user!.email)
  const mapIdentifierSlug = `${activeTenant.ref}-${userHash}-security-map`
  const allLayers = useRecoilValue(assetLayersSelector(mapIdentifierSlug))
  const [visibleLayers, setVisibleLayers] = useRecoilState(activeAssetLayersSelector(mapIdentifierSlug))

  // Effects
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (allLayers.length > 0) {
      const assets = ['Kamera', 'Havnegjerde', 'HavnegjerdeInngang', 'Kaiområde', 'Havneanlegg']
      const selectedLayers = allLayers.filter((layer) => assets.some((asset) => asset === layer.name)).map((l) => l.key)
      visibleLayers !== selectedLayers && setVisibleLayers(selectedLayers)
    }
  }, [allLayers, setVisibleLayers]) // eslint-disable-line

  return (
    <MapProvider
      mapIdentifierSlug={mapIdentifierSlug}
      monitorApiBaseUrl={config.api.monitorApiBaseUrl}
      activeTenantId={activeTenant.id}
      getToken={getTokenSilently}
    >
      <MapButtonPanel region="right" startElements={[<ZoomControl />]} endElements={[<GpsControl />]} />

      <AssetsView assetApiBaseUrl={config.api.assetApiBaseUrl} tenantId={activeTenant.id} enableDialogs={false} />
      <LiveView
        liveApiBaseUrl={config.api.liveApiUrl}
        tenantId={activeTenant.id}
        enableDialogs={false}
        defaultLayersToVisible={true}
      />
    </MapProvider>
  )
}

export default Map
