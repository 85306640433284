import React from 'react'
import { Navigation, requiresTenant, requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { withServices } from '../withServices'
import { withActiveTenant } from '../WithActiveTenant'
import { Dashboard as IconDashboard } from '@griegconnect/krakentools-react-icons'
import Homepage from '.'
import { AccessDependencies } from '@app/Content'
import { isPort } from '@app/lib/access'
import { Trans } from 'react-i18next'

export const dashboardModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.dashboard.dashboard" />,
    path: PATH_TENANT_BASE + '/',
    page: React.createElement(withServices(withActiveTenant(Homepage))),
    exact: true,
    menu: {
      icon: <IconDashboard />,
    },
    access: (d) => requiresTenant(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.dashboard.passingStats" />,
        path: PATH_TENANT_BASE + '/passingStats',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./passingStats/GateInStats'))))
        ),
        exact: true,
        access: (d) => isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.passing_stats.read, true)(d),
      },
      {
        name: <Trans i18nKey="navigation.dashboard.turnaroundStats" />,
        path: PATH_TENANT_BASE + '/turnaroundStats',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./passingStats/TurnaroundPage'))))
        ),
        exact: true,
        access: (d) => isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.passing_stats.read, true)(d),
      },
      {
        name: <Trans i18nKey="navigation.dashboard.controlStats" />,
        path: PATH_TENANT_BASE + '/controlStats',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./controlReport/ControlReportContainer'))))
        ),
        exact: true,
        access: (d) => isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.visitor_stats.read, true)(d),
      },
    ],
  },
]
