import { useNavigate } from 'react-router-dom'
import { CheckListForm } from '@app/common/ServicesWrapper/apis/CheckListsApi'
import { Services } from '@app/common/ServicesWrapper/types'
import { Page } from '@griegconnect/krakentools-react-ui'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { EditCheckList } from './Edit'
import { useTranslation } from 'react-i18next'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'

interface NewCheckListProps extends WithActiveTenant {
  services: Services
}

export const NewCheckList: React.VFC<NewCheckListProps> = ({ activeTenant, services }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { enqueue } = useAlerts()

  const onSubmit = async (form: CheckListForm) => {
    try {
      await services.checkListsApi.create(activeTenant.ref, form)
      navigate(`/companies/${activeTenant.ref}/settings/checklists`)
      enqueue(t('checks.success.createChecklist'), 'success')
    } catch (error) {
      enqueue(t('checks.errors.createChecklist'), 'error')
    }
  }

  return (
    <Page.Wrapper title={t('settings.labels.newChecklist')} maxWidth="md" showBackButton>
      <EditCheckList onSubmit={onSubmit} />
    </Page.Wrapper>
  )
}
