import { AxiosInstance } from 'axios'
import { DateTime, Interval } from 'luxon'
import { By } from './By'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { AreaLink } from './dtos/areaDto'
import * as Purpose from './PurposesApi'
import Page from '../../ServicesWrapper/apis/Page'
import qs from 'qs'
import { Vessel } from './VesselsApi'
import { IUserDto } from './dtos/userDto'
import { IFileRefDto } from './dtos/fileRefDto'
import { Search as S, Infer } from '../../../lib/hooks/useSearch'

namespace Permits2 {
  export interface Version {
    revision: number
    next: number | null
  }

  export interface Params {
    message?: string[]
    person?: string[]
    vehicle?: string[]
    facility?: string[]
    purpose?: string[]
    valid?: Interval[]
    invalid?: Interval[]
    visiting?: string[]
    vessel?: string[]
    pin?: string[]
    fromCompany?: string[]
    used?: boolean
  }

  export interface Fields {
    email?: string
  }

  export interface Private {
    meta: Meta
    group: string | null
    application: string | null
    by: By
    visiting: Visiting
    visitor: Visitor
    message: string
    pin?: string
    fields?: Fields
  }

  export interface SaltoAccess {
    key?: string
    pin?: string
    mobileType?: 'JustINMobile' | 'JustINmSVN'
  }

  export interface Meta {
    version: Version
    id: string
    revoked: boolean
    usedAt: DateTime | null
  }

  export namespace Visiting {
    export namespace Target {
      export type Form = { type: 'company'; value: { id: string } } | { type: 'vessel'; value: { id: string } }
    }
    export type Target = { type: 'company'; value: { id: string; name: string } } | { type: 'vessel'; value: Vessel }

    export interface Form {
      facility: string
      purpose: string
      valid: string
      oneTime: boolean
      target: Target.Form | null
      visitHandler?: string
    }
  }

  export interface Visiting {
    port: ITenant
    facility: AreaLink
    purpose: Purpose.Link
    valid: string // Interval
    oneTime: boolean
    target: Visiting.Target | null
    visitHandler?: IUserDto
  }

  export namespace Visitor {
    export interface Person {
      mobile: string | null
      files: IFileRefDto[]
      identityChecked: boolean
    }

    export interface Vehicle {
      vehicle: string
      trailer: string | null
      subcontractor: string | null
      files: IFileRefDto[]
    }

    export namespace Form {
      export interface Person {
        mobile: string | null
        files: string[]
        identityChecked: boolean
      }
      export interface Vehicle {
        vehicle: string
        trailer: string | null
        subcontractor: string | null
        files: string[]
      }
    }

    export interface Form {
      owner: string
      company: string | null
      pin: string | null
      person: Form.Person | null
      vehicle: Form.Vehicle | null
    }
  }

  export interface MaxDurationForm {
    startTime?: string
    target?: string
    purpose?: string
    facility?: string
  }

  export interface MaxDuration {
    maxDuration?: string
    defaultDuration?: string
  }

  export interface Visitor {
    owner: IUserDto
    company: string | null
    pin: string | null
    person: Visitor.Person | null
    vehicle: Visitor.Vehicle | null
  }

  export interface Form {
    group: string | null
    visiting: Visiting.Form
    visitor: Visitor.Form
    message: string
    usePin?: boolean
    salto?: SaltoAccess
    fields: Fields
  }

  export interface Details {
    current: Private
    history: Private[]
  }

  export interface Access {
    bewator: number[]
  }

  export interface AllowedTargets {
    id: string
    name: string
  }
}

export const ParamsSchema = {
  ...Page.schema,
  message: S.array(S.string),
  person: S.array(S.string),
  vehicle: S.array(S.string),
  facility: S.array(S.string),
  purpose: S.array(S.string),
  valid: S.array(S.string),
  invalid: S.array(S.string),
  visiting: S.array(S.string),
  vessel: S.array(S.string),
  pin: S.array(S.string),
  fromCompany: S.array(S.string),
  used: S.undefine(S.boolean),
}
export type Params = Infer<typeof ParamsSchema>

export class Permits2Api {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async create(ref: string, form: Permits2.Form): Promise<Permits2.Private> {
    const result = await this.http.post(`/ports/${ref}/permits2`, form)
    return result.data
  }

  async search(ref: string, filter: Permits2.Params, paging: Page.Search): Promise<Page<Permits2.Private>> {
    const params = {
      ...filter,
      ...paging,
      valid: filter.valid?.map((i) => i.toISO()),
      invalid: filter.invalid?.map((i) => i.toISO()),
    }
    const response = await this.http.get(`/ports/${ref}/permits2`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async get(ref: string, id: string): Promise<Permits2.Details> {
    const response = await this.http.get(`/ports/${ref}/permits2/${id}`)
    return response.data
  }

  async revoke(ref: string, id: string): Promise<Permits2.Details> {
    const response = await this.http.delete(`/ports/${ref}/permits2/${id}`)
    return response.data
  }

  async update(ref: string, id: string, form: Permits2.Form): Promise<Permits2.Private> {
    const result = await this.http.put(`/ports/${ref}/permits2/${id}`, form)
    return result.data
  }

  async access(ref: string, permitId: string): Promise<Permits2.Access> {
    const result = await this.http.get(`/ports/${ref}/permits2/${permitId}/access`)
    return result.data
  }

  async maxDuration(ref: string, params: Permits2.MaxDurationForm): Promise<Permits2.MaxDuration> {
    const response = await this.http.get(`/ports/${ref}/permits2/max-duration`, {
      params: {
        startTime: params.startTime && params.startTime.length > 0 ? params.startTime : undefined,
        target: params.target && params.target.length > 0 ? params.target : undefined,
        purpose: params.purpose && params.purpose.length > 0 ? params.purpose : undefined,
        facility: params.facility && params.facility.length > 0 ? params.facility : undefined,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async allowedTargets(ref: string): Promise<Permits2.AllowedTargets[]> {
    const response = await this.http.get(`/ports/${ref}/permits2/allowed-targets`)
    return response.data
  }
}

export default Permits2
