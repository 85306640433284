import { DateTime } from 'luxon'
import { IPortDetailsDto } from 'src/common/ServicesWrapper/apis/SharedDataApi'
import { IFileRefDtoSchema } from 'src/common/ServicesWrapper/apis/dtos/fileRefDto'
import { zDateTime } from 'src/lib/ZodForm'
import * as z from 'zod'
import * as Form from '@griegconnect/krakentools-form'
import { UserApi } from 'src/common/ServicesWrapper/apis/UserApi'
import { SecurityIdentity } from 'src/common/ServicesWrapper/apis/dtos/IdentityAttributes'
import { User } from '@griegconnect/krakentools-react-tenants'

export const VisitingSchema = z
  .object({
    facility: z.string(),
    target: z.string(),
    purpose: z.string(),
    message: z.string().default(''),
    messageRequired: z.boolean().default(false),
  })
  .refine((data) => data.messageRequired === false || data.message.length > 1, {
    message: 'applications.errors.purposeRequiresMessage',
    path: ['message'],
  })

export type VisitingForm = z.infer<typeof VisitingSchema>

export const hasTarget = (form: VisitingForm) => form.target
export const hasFacility = (form: VisitingForm) => form.facility
export const hasPurpose = (form: VisitingForm) => form.purpose

export const TimeSchema = z
  .object({
    timeIn: zDateTime,
    timeOut: zDateTime,
    visitHandler: z.string().nullable(),
    visitHandlerName: z.string(),
  })
  .refine((data) => !data.visitHandler || data.visitHandler.length > 0, {
    message: 'applications.validation.visitHandler',
    path: ['visitHandler'],
  })
  .refine((data) => data.timeIn < data.timeOut, {
    message: 'applications.validation.endTime',
    path: ['timeOut'],
  })
export type TimeForm = z.infer<typeof TimeSchema>

export const VisitorPersonSchema = z
  .object({
    name: z.string(),
    company: z.string().default(''),
    mobile: z.string(),
    email: z.string().email({ message: 'common.validation.email' }),
    locale: z.string(),
    files: z.array(IFileRefDtoSchema),
  })
  .refine((data) => data.name.length > 0, {
    message: 'common.errors.fieldRequired',
    path: ['name'],
  })
  .refine((data) => data.email.length > 0, {
    message: 'common.errors.fieldRequired',
    path: ['email'],
  })
  .refine((data) => Form.validatePhone(data.mobile), {
    message: 'common.errors.phoneNumberInvalid',
    path: ['mobile'],
  })
  .refine((data) => data.files.length > 0, {
    message: 'applications.validation.personFile',
    path: ['files'],
  })

export type VisitorPersonForm = z.infer<typeof VisitorPersonSchema>

export const VisitorVehicleSchema = z
  .object({
    vehicle: z.string(),
    trailer: z.string().optional(),
    subcontractor: z.string().optional(),
    files: z.array(IFileRefDtoSchema),
  })
  .refine((data) => data.vehicle.length > 0, {
    message: 'applications.validation.vehicle',
    path: ['vehicle'],
  })

export type VisitorVehicleForm = z.infer<typeof VisitorVehicleSchema>

const _ApplicationSchema = z.object({
  visiting: VisitingSchema,
  time: TimeSchema,
  visitorPerson: VisitorPersonSchema,
  visitorVehicle: VisitorVehicleSchema,
  confirmations: z.object({
    securityConfirmations: z.array(z.string()),
  }),
  statusMessage: z.string(),
})

export const ConfirmationSchema = (details: IPortDetailsDto) => {
  return _ApplicationSchema.refine(
    (data) => details.securityConfirmations.every((s) => data.confirmations?.securityConfirmations.includes(s.id)),
    {
      message: 'applications.validation.securityConfirmations',
      path: ['securityConfirmations'],
    }
  )
}

export type ApplicationFormData = z.infer<typeof _ApplicationSchema>

export const toFormData = (
  data: ApplicationFormData,
  type: 'company' | 'vessel',
  hasVehicle: boolean,
  port: string
): UserApi.Application.CreateForm => {
  return {
    form: {
      visiting: {
        facility: data.visiting.facility,
        purpose: data.visiting.purpose,
        timeIn: data.time.timeIn.toISO(),
        timeOut: data.time.timeOut.toISO(),
        visitHandler: data.time.visitHandler || null,
        target: data.visiting.target
          ? {
              type: type,
              value: { id: data.visiting.target },
            }
          : null,
      },
      visitor: {
        company: data.visitorPerson.company || '',
        person: {
          mobile: data.visitorPerson.mobile,
          files: data.visitorPerson.files.map((f) => f.id),
        },
        vehicle: hasVehicle
          ? {
              vehicle: data.visitorVehicle.vehicle,
              trailer: data.visitorVehicle.trailer || null,
              subcontractor: data.visitorVehicle.subcontractor || null,
              files: data.visitorVehicle.files.map((f) => f.id),
            }
          : null,
      },
      message: data.visiting.message,
      securityConfirmations: data.confirmations.securityConfirmations,
      integrations: {},
      fields: { email: data.visitorPerson.email },
    },
    port,
  }
}
export const updateFormData = (
  data: ApplicationFormData,
  type: 'company' | 'vessel',
  hasVehicle: boolean
): UserApi.Application.Form => {
  return {
    visiting: {
      facility: data.visiting.facility,
      purpose: data.visiting.purpose,
      timeIn: data.time.timeIn.toISO(),
      timeOut: data.time.timeOut.toISO(),
      visitHandler: data.time.visitHandler,
      target: data.visiting.target
        ? {
            type: type,
            value: { id: data.visiting.target },
          }
        : null,
    },
    visitor: {
      company: data.visitorPerson.company || '',
      person: {
        mobile: data.visitorPerson.mobile,
        files: data.visitorPerson.files.map((f) => f.id),
      },
      vehicle: hasVehicle
        ? {
            vehicle: data.visitorVehicle.vehicle,
            trailer: data.visitorVehicle.trailer || null,
            subcontractor: data.visitorVehicle.subcontractor || null,
            files: data.visitorVehicle.files.map((f) => f.id),
          }
        : null,
    },
    message: data.visiting.message,
    securityConfirmations: data.confirmations.securityConfirmations,
    integrations: {},
    fields: { email: data.visitorPerson.email },
  }
}

const getName = (userDetails?: SecurityIdentity): string => {
  return userDetails?.identity.name &&
    userDetails.identity.mobile &&
    !userDetails.identity.name.startsWith(userDetails.identity.mobile) &&
    userDetails.identity.name !== userDetails.identity.email
    ? userDetails.identity.name
    : ''
}

export const toForm = (
  details: UserApi.Application.Details,
  user: User.Data | null,
  userDetails?: SecurityIdentity
): ApplicationFormData => {
  return {
    visiting: {
      target: details.current.visiting.target.value.id,
      facility: details.current.visiting.facility.id,
      message: details.current.message,
      messageRequired: false,
      purpose: details.current.visiting.purpose.id,
    },
    time: {
      timeIn: details.current.visiting.timeIn ? DateTime.fromISO(details.current.visiting.timeIn) : DateTime.now(),
      timeOut: details.current.visiting.timeOut ? DateTime.fromISO(details.current.visiting.timeOut) : DateTime.now(),
      visitHandler: details.current.visiting.visitHandler?.id || '',
      visitHandlerName: '',
    },
    visitorPerson: {
      name: getName(userDetails),
      locale: user?.metadata.locale || details.current.visitor.person?.mobile?.startsWith('+47') ? 'nb-NO' : 'en-GB',
      company: details.current.visitor.company || userDetails?.attributes.company || '',
      mobile: details.current.visitor.person?.mobile || userDetails?.identity.mobile || '',
      files: details.current.visitor.person?.files || [],
      email: details.current.fields.email || '',
    },
    visitorVehicle: {
      vehicle: details.current.visitor.vehicle?.vehicle || '',
      trailer: details.current.visitor.vehicle?.trailer || '',
      subcontractor: details.current.visitor.vehicle?.subcontractor || '',
      files: details.current.visitor.vehicle?.files || [],
    },
    confirmations: {
      securityConfirmations: details.current.securityConfirmations,
    },
    statusMessage: '',
  }
}

export const emptyApplicationForm = (user: User.Data | null, userDetails?: SecurityIdentity): ApplicationFormData => {
  return {
    visiting: {
      target: '',
      facility: '',
      message: '',
      messageRequired: false,
      purpose: '',
    },
    time: {
      timeIn: DateTime.now(),
      timeOut: DateTime.now().plus({ hours: 1 }),
      visitHandler: '',
      visitHandlerName: '',
    },
    visitorPerson: {
      name: getName(userDetails),
      locale: user?.metadata.locale || user?.mobile?.startsWith('+47') ? 'nb-NO' : 'en-GB',
      company: userDetails?.attributes.company || '',
      mobile: userDetails?.identity.mobile || '',
      email: userDetails && !userDetails.identity.email.includes('@grieg.io') ? userDetails.identity.email : '',
      files: userDetails?.attributes.idDocument ? [userDetails?.attributes.idDocument] : [],
    },
    visitorVehicle: {
      vehicle: '',
      trailer: '',
      subcontractor: '',
      files: [],
    },
    confirmations: {
      securityConfirmations: [],
    },
    statusMessage: '',
  }
}

/*
export const defaultVisitValues = (applicationForm?: VisitDetails
  prefill: IPrefill
  purposes: IPurposeDto[]): Input => {
  const purpose = purposes.find((p) => p.name === prefill.purpose)?.id
  const from = !!applicationForm?.visiting?.timeIn
    ? DateTime.fromISO(applicationForm.visiting.timeIn)
    : prefill.from
    ? DateTime.fromISO(prefill.from)
    : DateTime.now()
  return {
    purpose: applicationForm?.visiting?.purpose || purpose || '',
    from,
    to: from.endOf('day'),
  }
}*/
