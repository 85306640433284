import { AccessDependencies } from '@app/Content'
import { Tenant } from '@griegconnect/krakentools-react-kraken-app'
import { ITenantRoleWithPermissions } from '@griegconnect/krakentools-react-tenants'
import { PERMISSIONS, Permissions } from 'src/constants'

export const usesContracts = (d: AccessDependencies) =>
  (d.portConfig.attributes && d.portConfig.attributes.approvedCompanies) ?? false

export const usesCourses = (d: AccessDependencies) =>
  (d.portConfig.attributes && d.portConfig.attributes.courses) ?? false

export const isPort = (d: AccessDependencies) => d.portConfig.isPort

export function requireGlobalPermission(permission: Tenant.Permission) {
  return (dep: AccessDependencies): boolean =>
    dep.permissions.find((p) => p.domain === permission.domain && p.name === permission.name) ? true : false
}

export function requireAllGlobalPermissions(permissions: Tenant.Permission[]) {
  return (dep: AccessDependencies): boolean =>
    permissions.every((p) => dep.permissions.some((perm) => perm.domain === p.domain && perm.name === p.name))
}

export const accessContracts = (d: AccessDependencies): boolean => d.userDetails.contractOwner.length > 0

export const isVisitHandler = (d: AccessDependencies): boolean => d.userDetails.visitHandler.length > 0

export const rootPath = (): boolean => {
  return window.location.pathname === '/'
}

export const filterChecklistRoles = (roles: ITenantRoleWithPermissions[]) => {
  const acceptablePermissions = [
    ...Permissions.all(PERMISSIONS.checks_facility),
    ...Permissions.all(PERMISSIONS.checks_quay),
    ...Permissions.all(PERMISSIONS.checklists),
    ...Permissions.all(PERMISSIONS.checks_person),
    ...Permissions.all(PERMISSIONS.checks_vehicle),
    ...Permissions.all(PERMISSIONS.checks_vessel),
    ...Permissions.all(PERMISSIONS.quays),
    ...Permissions.all(PERMISSIONS.security_check_result),
  ]
  return roles.filter((r) => r.permissions.every((p) => acceptablePermissions.some((ap) => ap.name === p.name)))
}
