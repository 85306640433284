import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { AxiosInstance } from 'axios'
import { DateTime } from 'luxon'
import { By } from './By'
import { Checkpoints } from './CheckpointApi'

export declare namespace Bewator {
  export interface User {
    email: string
    id: string
    name: string
    createdAt: DateTime
    synchronized: DateTime
    data?: UserData
  }
  export interface UserData {
    PIN: string
    cards: Card[]
  }
  export interface Card{
    Desc: string
    State: number
    CardCode: number
  }
  export interface Credentials {
    username: string
    password: string
  }

  export interface Config {
    gateway: string,
    hostAddress: string,
    hostPort: number,
    integrationKey: string
  }

  export interface Settings {
    visitorPinStart?: number,
    visitorPinEnd?: number,
    syncUsers: string | null,
    data: Object
  }

  export interface Installation{
    id: string
    port: ITenant
    config: Config | null
    settings: Settings
  }

  export interface InstallationForm{
    gateway: string,
    hostAddress: string,
    hostPort: number,
    integrationKey: string
  }

  export namespace Checkpoint{
    export interface Details{
        port: string
        entries: number[]
        checkpoint: Checkpoints.CheckpointLink
    }
    export interface Form{
      entries: number[]
      checkpoint: string
    }
  }

  export namespace AccessSync{
    export interface Query {
      permit?: string
      revision?: number
    }

    export interface Sync {
      id: string
      permit: number | null
      pin_code: number
      created: By
      operation: string
    }
  }

}

export class BewatorApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async updateInstallation(tenantRef: string, form: Bewator.InstallationForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/bewator`, form)
  }
  async updateSettings(tenantRef: string, form: Bewator.Settings): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/bewator/settings`, form)
  }

  async getInstallation(tenantRef: string): Promise<Bewator.Installation> {
    const res = await this.http.get(`/ports/${tenantRef}/bewator`)
    return res.data
  }

  async getCheckpoints(tenantRef: string): Promise<Bewator.Checkpoint.Details[]> {
    const res = await this.http.get(`/ports/${tenantRef}/bewator/checkpoints`)
    return res.data
  }

  async updateCheckpoints(tenantRef: string, form: Bewator.Checkpoint.Form): Promise<Bewator.Checkpoint.Details> {
    const res = await this.http.post(`/ports/${tenantRef}/bewator/checkpoints`, form)
    return res.data
  }

}
