import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPermit = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12 14h2a1 1 0 0 1 1 1v3H9v-3a1 1 0 0 1 1-1h2Z" />,
    <path d="M10 2a1 1 0 0 0-1 1v1H5a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-4V3a1 1 0 0 0-1-1h-4Zm5 4h3v14H6V6h3v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V6Zm-2 0h-2V4h2v2Z" />
  )
export default SvgPermit
