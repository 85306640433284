import { FormControlLabel, FormHelperText, Switch as SwitchMUI } from '@mui/material'
import React from 'react'
import { Control, Controller, get, useFormState } from 'react-hook-form'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'

interface SwitchProps {
  control: Control<any>
  label: string
  name: string
  disabled?: boolean
  helperText?: string
}
export const Switch: React.FC<React.PropsWithChildren<SwitchProps>> = ({
  disabled,
  control,
  label,
  name,
  helperText,
}) => {
  const { errors } = useFormState()
  const error = get(errors, name)
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label={label}
            disabled={disabled ?? false}
            control={<SwitchMUI {...field} checked={field.value} />}
          />
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && error.message && <ErrorHelperText error={error.message} />}
    </>
  )
}
