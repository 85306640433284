import * as Form from '@griegconnect/krakentools-form'
import { fromTranslations, useTranslatable } from '@griegconnect/krakentools-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, FormHelperText, MenuItem, SelectChangeEvent, Stack } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { NextState, StepProps, applicationState, nextStateAtom } from '.'
import FlowHeader from '../Components/FlowHeader'
import FlowNavigation from '../Components/FlowNavigation'
import FlowPage from '../Components/FlowPage'
import { ApplicationFormData, VisitingForm, VisitingSchema, hasFacility, hasPurpose, hasTarget } from '../schema'

const StepVisiting: React.FC<StepProps> = ({ formValues, formType, resetForm }) => {
  const { t } = useTranslation()
  const [applicationFormData, setApplicationFormData] = useRecoilState<ApplicationFormData>(applicationState)
  const [state, setState] = useRecoilState<NextState>(nextStateAtom)
  const translatable = useTranslatable()

  const form = useForm<VisitingForm>({
    defaultValues: applicationFormData.visiting,
    resolver: zodResolver(VisitingSchema),
  })

  const companies = formValues.targets.filter((f) => f.kind === 'company')
  const vessels = formValues.targets.filter((f) => f.kind === 'vessel')
  const formData = form.watch()

  const lookupTargetFacilities = () => formValues.targets.find((t) => t.id === formData.target)?.facilities || []

  const findPurpose = useCallback(() => formValues.purposes.find((p) => p.id === formData.purpose), [
    formData.purpose,
    formValues.purposes,
  ])

  // Set messageRequired based on purpose
  useEffect(() => {
    const purpose = findPurpose()
    form.setValue('messageRequired', purpose?.requireNotes || false)
    if (purpose?.requireNotes === false) {
      form.trigger('message')
    }
  }, [formData.purpose, form, formValues.purposes, findPurpose])

  // Prefill default values if only one option
  useEffect(() => {
    if (state.visitType === 'company') {
      if (formValues.targets.length === 1) {
        form.setValue('target', formValues.targets[0].id)
        if (formValues.targets[0].facilities.length === 1) {
          form.setValue('facility', formValues.targets[0].facilities[0].area.id)
        }
      }
      if (formValues.purposes.length === 1) {
        form.setValue('purpose', formValues.purposes[0].id)
      }
    }
  }, [form, formValues.targets, formValues.purposes, state.visitType])

  const setTarget = (e: SelectChangeEvent<string>) => {
    form.setValue('target', e.target.value)
    const facility = formValues.targets.find((t) => t.id === e.target.value)?.facilities || []
    if (facility.length === 1) {
      form.setValue('facility', facility[0].area.id)
    }
  }

  // const onBack = () => setState((p) => ({ ...p, currentStep: p.currentStep - (state.visitBy === 'walking' ? 2 : 1) }))

  const onComplete = () => {
    setApplicationFormData((p) => ({ ...p, visiting: formData }))
    setState((p) => ({ ...p, currentStep: p.currentStep + 1 }))
  }

  const handleReset = () => resetForm && resetForm()

  return (
    <FormProvider {...form}>
      <Box width={'100%'}>
        <FlowPage>
          <FlowHeader
            i18n={
              state.visitType === 'company'
                ? 'applications.subTitle.selectCompanyFacilityPurpose'
                : 'applications.subTitle.selectVesselFacilityPurpose'
            }
          />
          <>
            <Form.Select
              name="target"
              label={state.visitType === 'company' ? t('security.labels.visitingCompany') : t('settings.labels.vessel')}
              variant="filled"
              onChange={setTarget}
              required
            >
              {state.visitType === 'company'
                ? companies.map((t, i) => (
                    <MenuItem key={t.id + '' + i} value={t.id}>
                      {t.name}
                    </MenuItem>
                  ))
                : vessels.map((t, i) => (
                    <MenuItem key={t.id + '' + i} value={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
            </Form.Select>
          </>
          {hasTarget(formData) && (
            <>
              <Form.Select name="facility" label={t('settings.labels.facility')} variant="filled" required>
                {lookupTargetFacilities().map((t) => (
                  <MenuItem key={t.area.id} value={t.area.id}>
                    {t.area.name}
                  </MenuItem>
                ))}
              </Form.Select>
            </>
          )}
          {hasFacility(formData) && (
            <>
              <Form.Select name="purpose" label={t('settings.labels.purpose')} variant="filled" required>
                {formValues.purposes.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {fromTranslations(translatable.getDefaultLocale(), p.i18n) || p.name}
                  </MenuItem>
                ))}
              </Form.Select>
            </>
          )}
          {hasPurpose(formData) && (
            <>
              <Form.TextInput
                multiline
                name="message"
                control={form.control}
                label={`${t('common.labels.message')}${findPurpose()?.requireNotes ? ' *' : ''}`}
              />
              {findPurpose()?.requireNotes && (
                <FormHelperText>{t('applications.subTitle.requiredMessage')}</FormHelperText>
              )}
            </>
          )}
        </FlowPage>
        <FlowNavigation>
          <Stack direction="row" width="100%">
            {formType === 'new' && (
              <Box display="flex" justifyContent="flex-start" width="100%">
                <Button color="inherit" onClick={handleReset}>
                  {t('applications.actions.reset')}
                </Button>
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end" width="100%">
              {/*  <Button variant="outlined" onClick={onBack} style={{ marginRight: 10 }}>
                {t('common.actions.back')}
              </Button> */}
              <Button
                variant="contained"
                type="submit"
                onClick={form.handleSubmit(onComplete)}
                disabled={!hasTarget(formData) || !hasFacility(formData) || !hasPurpose(formData)}
              >
                {t('common.actions.continue')}
              </Button>
            </Box>
          </Stack>
        </FlowNavigation>
      </Box>
    </FormProvider>
  )
}

export default StepVisiting
