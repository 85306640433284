import { AxiosInstance } from 'axios';
import { SortArrayOfObjectsNested } from '@app/lib/Sort';
import { ISecurityCheckResultDto, ISecurityCheckResultsDto, ISecurityCheckResultForm } from './dtos/securityCheckResultsDto';

export class SecurityCheckResultApi {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getSecurityCheckList(tenantRef: string): Promise<ISecurityCheckResultsDto> {
    const securityChecksResponse = await this.httpClient.get(`/ports/${tenantRef}/securitycheckresults`);
    return securityChecksResponse.data.results.sort(SortArrayOfObjectsNested('severity', 'asc'));
  }

  async postSecurityCheckResult(tenantRef: string, data: ISecurityCheckResultForm): Promise<string> {
    const securityChecksResponse = await this.httpClient.post(`/ports/${tenantRef}/securitycheckresults`, data);
    return securityChecksResponse.data;
  }

  async updateSecurityCheckResult(tenantRef: string, id: string, data: ISecurityCheckResultDto): Promise<string> {
    const securityChecksResponse = await this.httpClient.put(`/ports/${tenantRef}/securitycheckresults/${id}`, data);
    return securityChecksResponse.data;
  }

  async deleteSecurityCheckResult(id: string, tenantRef: string): Promise<ISecurityCheckResultDto> {
    const securityChecksResponse = await this.httpClient.delete(`/ports/${tenantRef}/securitycheckresults/${id}`);
    return securityChecksResponse.data;
  }
}
