import { Navigation, requiresTenant, requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import { GatePassings as IconGatePassings } from '@griegconnect/krakentools-react-icons'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { withServices } from '../withServices'
import { withActiveTenant } from '../WithActiveTenant'
import React from 'react'
import { AccessDependencies } from '@app/Content'
import { isPort } from '@app/lib/access'
import { Trans } from 'react-i18next'

export const passingsModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.passings" />,
    path: PATH_TENANT_BASE + '/passings',
    page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('.'))))),
    exact: true,
    menu: {
      icon: <IconGatePassings />,
    },
    access: (d) => isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.passings.read, true)(d),
  },
]
