import { UserApi } from 'src/common/ServicesWrapper/apis/UserApi'
import { IFileRefDto } from 'src/common/ServicesWrapper/apis/dtos/fileRefDto'

export interface ApplicationForm {
  visiting?: Visiting
  visitDetails?: VisitDetails
  visitor?: Visitor
  confirmations?: Confirmations
}

export interface Visiting {
  visiting: {
    facility: string
    target: VisitingTargetForm
    visitHandler: string | null
  }
}

export interface VisitingTargetForm extends UserApi.Application.VisitingTargetForm {
  value: {
    id: string
    name?: string
  }
}

export interface VisitDetails {
  visiting: {
    purpose: string
    timeIn: string
    timeOut: string | null
  }
}

export interface Visitor {
  visitor: {
    company: string
    person: {
      mobile: string
      email?: string
      files: IFileRefDto[]
    } | null
    vehicle: {
      vehicle: string
      trailer: string | null
      subcontractor: string | null
      files: IFileRefDto[]
    } | null
  }
  integrations: {
    symmetryBlue?: string
  }
  save: {
    company: boolean
    mobile: boolean
    personFile: boolean
    symmetryBlue: boolean
  }
  isPerson: boolean
  isVehicle: boolean
}

export interface Confirmations {
  message: string
  securityConfirmations: string[]
}

export const fromApplication = (application: UserApi.Application.Details): ApplicationForm => {
  return {
    visiting: {
      visiting: {
        facility: application.current.visiting.facility.id,
        target: application.current.visiting.target,
        visitHandler: application.current.visiting.visitHandler?.id || null,
      },
    },
    visitDetails: {
      visiting: {
        purpose: application.current.visiting.purpose.id,
        timeIn: application.current.visiting.timeIn || '',
        timeOut: application.current.visiting.timeOut,
      },
    },
    visitor: {
      visitor: {
        company: application.current.visitor.company || '',
        person: {
          mobile: application.current.visitor.person?.mobile || '',
          files: application.current.visitor.person?.files || [],
        },
        vehicle: {
          vehicle: application.current.visitor.vehicle?.vehicle || '',
          trailer: application.current.visitor.vehicle?.trailer || '',
          subcontractor: application.current.visitor.vehicle?.subcontractor || '',
          files: application.current.visitor.vehicle?.files || [],
        },
      },
      integrations: {
        ...application.current.integrations,
        symmetryBlue: application.current.integrations.symmetryBlue || '',
      },
      isPerson: !!application.current.visitor.person,
      isVehicle: !!application.current.visitor.vehicle,
      save: { company: false, mobile: false, personFile: false, symmetryBlue: false },
    },
    confirmations: {
      message: '',
      securityConfirmations: application.current.securityConfirmations,
    },
  }
}

export const toApplicationForm = (form: ApplicationForm): UserApi.Application.Form => {
  return {
    visiting: {
      ...form.visiting!.visiting,
      ...form.visitDetails!.visiting,
      target: {
        type: form.visiting!.visiting.target.type,
        value: {
          id: form.visiting!.visiting.target.value.id,
        },
      },
    },
    visitor: {
      ...form.visitor!.visitor,
      person: form.visitor!.visitor.person
        ? {
            ...form.visitor!.visitor.person,
            files: form.visitor!.visitor.person.files.map((f) => f.id) || [],
          }
        : null,
      vehicle: form.visitor!.visitor.vehicle
        ? {
            ...form.visitor!.visitor.vehicle,
            files: form.visitor!.visitor.vehicle.files.map((f) => f.id) || [],
          }
        : null,
    },
    ...form.confirmations!,
    integrations: form.visitor!.integrations,
    fields: { email: form.visitor?.visitor.person?.email ?? '' },
  }
}
