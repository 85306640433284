import { AxiosInstance } from 'axios'
import qs from 'qs'
import { IUserDto } from './dtos/userDto'
import Page from './Page'
import { DateTime } from 'luxon'
import { Contracts } from './ContractsApi'

export class InvoicingApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async listInvoices(tenantRef: string, params: Params): Promise<Page<Invoice>> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/invoicing`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async create(tenantRef: string, form: Form): Promise<{ id: string }> {
    const response = await this.httpClient.post(`/ports/${tenantRef}/invoicing`, form)
    return response.data
  }

  async markAccessAsInvoiced(tenantRef: string, form: AccessInvoiceForm): Promise<void> {
    const response = await this.httpClient.post(`/ports/${tenantRef}/invoicing/mark-access-as-invoiced`, form)
    return response.data
  }

  async delete(tenantRef: string, invoiceId: string): Promise<void> {
    const response = await this.httpClient.delete(`/ports/${tenantRef}/invoicing/${invoiceId}`)
    return response.data
  }
}

export interface Params {
  page?: number
  pageSize?: number
  contracts: string[]
}

export interface AccessInvoiceForm {
  openpathUsers?: string[]
  stanleyCardholders?: string[]
  approvedVehicles?: string[]
  contract: string
}

export interface Invoice {
  id: string
  contract: Contracts.Link
  serviceType: string
  identity: Identity
  itemId: string
  duration: string
  serviceLine: BillingServiceLine
}

export interface BillingServiceLine {
  ExternalId: string
  ServiceType: string
  StartTime: DateTime
  FinishTime?: DateTime
  InvoiceText: string
  Categories: { Category1?: string; Category2?: string; Category3?: string }
  Quantity1: number
  Quantity1Measure: string
}
export interface Identity {
  user?: IUserDto
  vehicle?: string[]
}

export interface Form {
  contract: string
  duration: string
  serviceType: string
  userId: string
  itemId: string
  payload: BillingServiceLine
}
