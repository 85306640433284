import React from "react"
import { Box } from "@mui/material"

export type Props = {
    onBottomReached?: () => void
}

export const ScrollEffectWrapper: React.FC<React.PropsWithChildren<Props>> = ({ children, onBottomReached }) => {

    const onScrollHandler = (e: React.UIEvent<HTMLElement>) => {
        const isbottom = (e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === e.currentTarget.clientHeight
        if (isbottom) {
            onBottomReached?.();
        }
    }

    return (
        <Box overflow="auto" onScroll={onScrollHandler}>
            {children}
        </Box>
    )
}

export default ScrollEffectWrapper