import { AxiosInstance } from 'axios'
import Page from '@app/common/ServicesWrapper/apis/Page'
import { By } from './By'
import Permits2 from './Permits2'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { AreaLink } from './dtos/areaDto'
import * as Purpose from './PurposesApi'
import { IUserDto } from './dtos/userDto'
import qs from 'qs'
import { IPurposeDto } from './dtos/purposeDto'
import { ISecurityConfirmationDto } from './dtos/securityConfirmationsDto'
import { Scheduling } from './dtos/schedulingDto'

type VisitingKind = 'company' | 'vessel'

export interface Target {
  id: string
  kind: VisitingKind
  name: string
  facilities: AreaWithSchedule[]
  visitHandlers: VisitHandlerWithSchedules[]
  showVisitHandlers: boolean
}
export interface VisitHandlerWithSchedules {
  visitHandler: IUserDto
  schedules: Scheduling[]
}

export type Reason = 'target_not_available' | 'facility_not_available' | 'handler_not_available'
interface Time {
  preferredArrival: string
  end: string
  reasons: Reason[]
}

export interface AreaWithSchedule {
  area: AreaLink
  handler?: string
  schedule: Scheduling
}

export interface FormValues {
  targets: Target[]
  purposes: IPurposeDto[]
  securityConfirmations: ISecurityConfirmationDto[]
  time: Time
}

export interface FormValuesQuery {
  tenant: string
  arrival?: string
  target?: string
  facility?: string
  purpose?: string
}

namespace Application2 {
  export interface Params {
    page?: number
    pageSize?: number
    facility?: string[]
    purpose?: string[]
    status?: Status[]
    vehicle?: string[]
    fromCompany?: string[]
    vessel?: string[]
    visiting?: string[]
    person?: string[]
  }

  export type Status = 'invited' | 'submitted' | 'request_more_info' | 'declined' | 'approved' | 'expired'

  export interface Meta {
    revision: string
    next: string | null
    id: string
    deleted: boolean
  }

  export interface Visiting {
    port: ITenant
    facility: AreaLink
    purpose: Purpose.Link
    timeIn: string | null
    timeOut: string | null
    target: Permits2.Visiting.Target
    visitHandler?: IUserDto
  }

  export interface Visitor {
    owner: IUserDto
    company: string | null
    person: Permits2.Visitor.Person | null
    vehicle: Permits2.Visitor.Vehicle | null
  }

  export interface Integrations {
    symmetryBlue?: string
  }

  export interface Details {
    current: Application2
    history: Application2[]
  }

  export interface SetStatus {
    status: Status
    message: String
    permit: Permits2.Form | null
    sendInvoiceToPort: boolean
  }

  export type VisitingTargetForm = { type: 'company' | 'customer' | 'vessel'; value: { id: string } }

  export interface InviteForm {
    user: string
    visiting: {
      facility: string
      purpose: string
      target: VisitingTargetForm | null
      visitHandler?: string
    }
  }
}
interface Fields {
  email?: string
}
interface Application2 {
  meta: Application2.Meta
  by: By
  visiting: Application2.Visiting
  securityConfirmations: string[]
  message: string
  integrations: Application2.Integrations
  status: Application2.Status
  statusMessage: string
  visitor: Application2.Visitor
  permits: string[]
  stripeInvoice?: string
  fields: Fields
}

export default Application2

export class Application2Api {
  http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async get(tenantRef: string, id: string): Promise<Application2.Details> {
    const response = await this.http.get(`/ports/${tenantRef}/applications2/${id}`)
    return response.data
  }

  async invite(tenantRef: string, form: Application2.InviteForm): Promise<Application2.Details> {
    const response = await this.http.post(`/ports/${tenantRef}/applications2/invite`, form)
    return response.data
  }

  async update(tenantRef: String, id: string, form: Permits2.Form): Promise<string> {
    try {
      const response = await this.http.put(`/ports/${tenantRef}/applications2/${id}`, form)
      return response.data
    } catch (error) {
      console.error('UPDATE ERROR', error)
      throw new Error('Error updating application')
    }
  }

  async search(tenantRef: string, params: Application2.Params): Promise<Page<Application2>> {
    const response = await this.http.get(`/ports/${tenantRef}/applications2`, {
      params: { ...params },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async setStatus(tenantRef: String, id: string, form: Application2.SetStatus): Promise<string> {
    try {
      const response = await this.http.put(`/ports/${tenantRef}/applications2/${id}/status`, form)
      return response.data
    } catch (error) {
      if (error.response) {
        console.error('SET STATUS ERROR', JSON.stringify(error.response.data, null, 4))
        throw new Error(error.response.data.detail)
      }
      throw error
    }
  }

  async getApplicationValues(params: FormValuesQuery): Promise<FormValues> {
    const q = {
      params: { ...params },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    }
    const response = await this.http.get(`/user/applications2/values`, q)
    return response.data
  }
}
