import LineChart, { IChartData } from './LineChart'
import { WidgetCard, IWidgetCardProps } from '@griegconnect/krakentools-react-ui'

interface ILineChartWidgetProps extends IWidgetCardProps {
  data: IChartData[]
  xAxisLabels: string[] | number[]
  dataLabel?: string
  chartColors?: string[]
}

export const LineChartWidget: React.VFC<ILineChartWidgetProps> = ({
  data,
  xAxisLabels,
  dataLabel,
  chartColors,
  ...props
}) => (
  <WidgetCard {...props}>
    <LineChart data={data} xAxisLabels={xAxisLabels} dataLabel={dataLabel} colors={chartColors} />
  </WidgetCard>
)

export default LineChartWidget
