import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IUiDialogProps {
  title: string
  text?: string
  confirmButton?: string
  cancelButton?: string
  open: boolean
  fullWidth?: boolean
  disabled?: boolean
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
  onCancel?: () => void
  onConfirm: () => void
}

export const UiDialog: React.FunctionComponent<React.PropsWithChildren<IUiDialogProps>> = (props) => {
  const { t } = useTranslation()

  const handleDialogCancel = (evt: any) => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleDialogConfirm = (evt: any) => {
    props.onConfirm()
  }

  return (
    <Dialog fullWidth={props.fullWidth} onClose={handleDialogCancel} open={props.open} maxWidth={props.maxWidth}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        {props.onCancel && (
          <Button variant="text" color="primary" style={{ margin: '16px 8px' }} onClick={handleDialogCancel}>
            {props.cancelButton ? props.cancelButton : t('common.actions.cancel')}
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ margin: '16px 8px' }}
          onClick={handleDialogConfirm}
          disabled={props.disabled ? props.disabled : false}
        >
          {props.confirmButton ? props.confirmButton : t('common.actions.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
