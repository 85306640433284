import { Box } from '@mui/material'
import axios, { AxiosError } from 'axios'
import { useEffect, useMemo, useState } from 'react'

export function isAxiosError(payload?: unknown): payload is AxiosError {
  const result = (payload as AxiosError)?.isAxiosError === true || axios.isCancel(payload)
  return result
}

type VesselImageProps = {
  imo?: number | null
}

export const VesselImage = ({ imo }: VesselImageProps) => {
  const [imageExists, setImageExists] = useState(true)

  const imageUrl = useMemo(() => {
    return imo ? `https://ship-info.com/Vessels/${imo}.jpg` : null
  }, [imo])

  const handleImageError = () => {
    setImageExists(false)
  }

  if (!imageExists || !imageUrl) return null

  return (
    <Box
      sx={{
        height: 'auto',
        width: 'calc(100% + 32px)',
        marginLeft: '-16px',
      }}
    >
      <img
        src={imageUrl}
        alt={`Vessel with IMO ${imo}`}
        style={{
          width: '100%',
          height: 'auto',
        }}
        onError={handleImageError}
      />
    </Box>
  )
}
