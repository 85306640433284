import { Box, Chip, Typography, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { ExtendedFeature } from '@griegconnect/krakentools-react-kmap'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import { PassingDetails } from '@app/common/ServicesWrapper/apis/PassingsApi'
import { QuayApi, Quays } from '@app/common/ServicesWrapper/apis/QuayApi'
import { useState, useEffect } from 'react'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import QuayStatusChip from '@app/common/QuayStatusChip'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(1),
      },
    },
    secured1: {
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
    },
    secured2: {
      backgroundColor: theme.palette.warning.main,
    },
    secured3: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    notSecured: {
      backgroundColor: theme.palette.grey[400],
    },
  })
)

export interface AssetInformationSecurityProps extends WithActiveTenant {
  asset: ExtendedFeature
  facilities: IAreaDto[]
  quays: Quays.Quay[]
  refetchQuays: () => void
  passings: PassingDetails[]
  quayApi: QuayApi
  datatype: 'primary' | 'secondary'
}

export const AssetInformationSecurity = (props: AssetInformationSecurityProps) => {
  const classes = useStyles()
  const { enqueue } = useAlerts()
  const { t } = useTranslation()

  const [secured, setSecured] = useState<boolean | undefined>(undefined)

  const facility = props.facilities.find((f) => f.asset === props.asset.id)
  const quay = props.quays.find((q) => q.data.asset && q.data.asset === props.asset.id)
  const passings = facility ? props.passings.filter((p) => p.passing.facility.id === facility.id) : []
  const visitorsInside = passings.reduce(
    (obj, item) => {
      obj.persons += item.people.length
      obj.vehicles += item.vehicles.length
      return obj
    },
    { persons: 0, vehicles: 0 }
  )

  useEffect(() => {
    quay?.data && quay.data.secured !== undefined && setSecured(quay.data.secured)
  }, [quay]) // eslint-disable-line

  const onStatusChange = async (secured: boolean) => {
    if (quay) {
      try {
        const form: Quays.StatusForm = {
          secured,
        }
        await props.quayApi.setStatus(props.activeTenant.ref, quay.id, form)
        setSecured(secured)
        enqueue(t('settings.success.updateQuay'), 'success')
        props.refetchQuays()
      } catch (error) {
        enqueue(t('settings.errors.update'), 'error')
      }
    } else {
      enqueue(t('settings.errors.update'), 'error')
    }
  }

  return (
    <Grid container justifyContent="space-between" className={classes.container}>
      {props.datatype === 'primary' && facility ? (
        <>
          <Grid item xs={12}>
            <Box pb={2}>
              <Typography variant="body2" color="textSecondary">
                {`${t('security.labels.isps')}:`}
              </Typography>
              <Box m={1} />
              <Chip
                label={t('settings.status.securityLevel', {
                  level: facility.securityLevel,
                })}
                color={'primary'}
                classes={{
                  colorPrimary:
                    facility.securityLevel === 3
                      ? classes.secured3
                      : facility.securityLevel === 2
                      ? classes.secured2
                      : classes.secured1,
                  colorSecondary: classes.notSecured,
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box pb={2}>
              <Typography variant="body2" color="textSecondary">
                {`${t('settings.labels.personsInside')}:`}
              </Typography>
              <Box m={1} />
              <Typography variant="body2" color="textPrimary">
                {`${visitorsInside.persons}`}
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant="body2" color="textSecondary">
                {`${t('settings.labels.vehiclesInside')}:`}
              </Typography>
              <Box m={1} />
              <Typography variant="body2" color="textPrimary">
                {`${visitorsInside.vehicles}`}
              </Typography>
            </Box>
          </Grid>
          {(facility.targets.person || facility.targets.vehicle) && (
            <Grid item>
              {facility.targets.person && (
                <Box pb={2}>
                  <Typography variant="body2" color="textSecondary">
                    {`${t('settings.labels.personCheckTarget')}:`}
                  </Typography>
                  <Box m={1} />
                  <Typography variant="body2" color="textPrimary">
                    {`${facility.targets.person * 100}%`}
                  </Typography>
                </Box>
              )}
              {facility.targets.vehicle && (
                <Box pb={2}>
                  <Typography variant="body2" color="textSecondary">
                    {`${t('settings.labels.vehicleCheckTarget')}:`}
                  </Typography>
                  <Box m={1} />
                  <Typography variant="body2" color="textPrimary">
                    {`${facility.targets.vehicle * 100}%`}
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
          {/* TODO JAKOB facility.openingHours && (
            <Grid item>
              <Box pb={2}>
                <Typography variant="body2" color="textSecondary">
                  {`${t('settings.labels.opening')}:`}
                </Typography>
                <Box m={1} />
                <Typography variant="body2" color="textPrimary">
                  {facility.openingHours.opening}
                </Typography>
              </Box>
              <Box pb={2}>
                <Typography variant="body2" color="textSecondary">
                  {`${t('settings.labels.closing')}:`}
                </Typography>
                <Box m={1} />
                <Typography variant="body2" color="textPrimary">
                  {facility.openingHours.closing}
                </Typography>
              </Box>
            </Grid>
          )*/}
        </>
      ) : props.datatype === 'primary' && quay && secured !== undefined ? (
        <>
          <Grid item xs={12}>
            <Box pb={2}>
              <Typography variant="body2" color="textSecondary">
                {`${t('common.labels.status')}:`}
              </Typography>
              <Box m={1} />
              <QuayStatusChip secured={secured} onChange={onStatusChange} />
            </Box>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default AssetInformationSecurity
