interface IThemeColors {
  light: string[]
  dark: string[]
}

export const chartColors: IThemeColors = {
  light: ['#CC8B8B', '#CCBC8B', '#ABCC8B', '#8BCC9B', '#8BCCCC', '#8B9BCC', '#AB8BCC', '#CC8BBC'],
  dark: ['#F59393', '#F5DC93', '#C4F593', '#93F5AB', '#93F5F5', '#93ABF5', '#C493F5', '#F593DC'],
}

const utils = { colors: chartColors }

export default utils
