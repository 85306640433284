import { Tenant } from '../atoms/tenantsAtom'

type WithTenant = { activeTenant: Tenant.Type | null }

export function requiresTenant<D extends WithTenant>(dep: D): boolean {
  return dep.activeTenant !== null
}

export function requiresNonActiveTenant<D extends WithTenant>(dep: D): boolean {
  return dep.activeTenant === null
}

export function requiresPermission<D extends WithTenant>(
  permission: Tenant.Permission,
  checkBehalfOfCompanies?: boolean
) {
  return (dep: D): boolean => {
    return checkBehalfOfCompanies
      ? (dep.activeTenant &&
          (dep.activeTenant.permissions.some((p) => permission.domain === p.domain && permission.name === p.name) ||
            dep.activeTenant.companies
              .flatMap((c) => c.permissions)
              .some((p) => permission.domain === p.domain && permission.name === p.name))) ??
          false
      : (dep.activeTenant &&
          dep.activeTenant.permissions.some((p) => permission.domain === p.domain && permission.name === p.name)) ??
          false
  }
}
export function requiresOneOfPermissions<D extends WithTenant>(
  permissions: Tenant.Permission[],
  checkBehalfOfCompanies?: boolean
) {
  return (dep: D): boolean => {
    return checkBehalfOfCompanies
      ? (dep.activeTenant &&
          (dep.activeTenant.permissions.some((p) =>
            permissions.some((perm) => perm.domain === p.domain && perm.name === p.name)
          ) ||
            dep.activeTenant.companies
              .flatMap((c) => c.permissions)
              .some((p) => permissions.some((perm) => perm.domain === p.domain && perm.name === p.name)))) ??
          false
      : (dep.activeTenant &&
          dep.activeTenant.permissions.some((p) =>
            permissions.some((perm) => perm.domain === p.domain && perm.name === p.name)
          )) ??
          false
  }
}

export function requiresAllOfPermissions<D extends WithTenant>(
  permissions: Tenant.Permission[],
  checkBehalfOfCompanies?: boolean
) {
  return (dep: D): boolean => {
    return checkBehalfOfCompanies
      ? permissions.every(
          (p) =>
            dep.activeTenant &&
            dep.activeTenant.permissions
              .concat(dep.activeTenant.companies.flatMap((c) => c.permissions))
              .some((perm) => perm.domain === p.domain && perm.name === p.name)
        ) ?? false
      : permissions.every(
          (p) =>
            dep.activeTenant &&
            dep.activeTenant.permissions.some((perm) => perm.domain === p.domain && perm.name === p.name)
        ) ?? false
  }
}
