import { Link } from '@mui/material'
import React from 'react'
import { IFileRefDto } from './ServicesWrapper/apis/dtos/fileRefDto'
import ImageFullScreen from './ImageFullScreen'
import DummyUploadImage from './DummyUploadImage'

interface InlinableImageProps {
  imgButtonStyle?: string
  file: IFileRefDto
  maxHeight?: number
  maxWidth?: number
  hideEyeIcon?: boolean
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
}

export const InlinableImage: React.FunctionComponent<React.PropsWithChildren<InlinableImageProps>> = ({
  file,
  maxHeight,
  maxWidth,
  hideEyeIcon,
  justifyContent,
}) => {
  if (file.resolved) {
    return file.mediatype.startsWith('image/') ? (
      <ImageFullScreen
        imgSrc={file.href}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        hideEyeIcon={hideEyeIcon}
        justifyContent={justifyContent}
      />
    ) : (
      <Link href="#" onClick={() => window.open(file.href)}>
        {file.filename}
      </Link>
    )
  } else {
    return <DummyUploadImage />
  }
}
