import { ContractApplications } from '@app/common/ServicesWrapper/apis/ContractsApi'
import { TFunction } from 'i18next'

export const getChipStatus = (status: ContractApplications.Status, t: TFunction<'translation', undefined>) => {
  switch (status) {
    case 'created':
      return { color: 'default', label: t('applications.status.created') }
    case 'invited':
      return { color: 'default', label: t('applications.status.invited') }
    case 'awaiting_cso':
      return { color: 'warning', label: t('applications.status.awaitingCSO') }
    case 'in_review':
      return { color: 'warning', label: t('applications.status.inReview') }
    case 'require_more_information':
      return { color: 'default', label: t('applications.status.requestingMoreInfo') }
    case 'ready_for_pickup':
      return { color: 'info', label: t('applications.actions.readyForPickup') }
    case 'in_production':
      return { color: 'info', label: t('applications.status.inProduction') }
    case 'completed':
      return { color: 'success', label: t('applications.status.completed') }
    case 'rejected':
      return { color: 'error', label: t('applications.status.rejected') }
    default:
      return { color: 'error', label: 'Status not found' }
  }
}
