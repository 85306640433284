import * as Form from '@griegconnect/krakentools-form'
import { TimeCalendar as TimeCalendarIcon } from '@griegconnect/krakentools-react-icons'
import { Box, FormHelperText, IconButton, InputAdornment } from '@mui/material'
import { DatePicker as DatePickerMUI, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Control, Controller, get, useFormState } from 'react-hook-form'

interface DatePickerProps {
  control: Control<any>
  className?: string | undefined
  required?: boolean
  disabled?: boolean
  name: string
  label: string
  disablePast?: boolean
  clearable?: boolean
  maxDate?: DateTime | null
  minDate?: DateTime | null
  maxDateTime?: DateTime | null
  minDateTime?: DateTime | null
  maxTime?: DateTime | null
  minTime?: DateTime | null
  format?: string
  helperText?: string
  onViewChange?: (view: string) => void
}

export const DatePicker = ({
  control,
  name,
  disabled,
  maxDate,
  label,
  disablePast,
  minDate,
  required,
  minDateTime,
  maxDateTime,
  maxTime,
  minTime,
  format,
  helperText,
  onViewChange,
}: DatePickerProps) => {
  const { errors } = useFormState()
  const error = get(errors, name)

  const [open, setOpen] = useState(false)

  return (
    <Box pb={1}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DatePickerMUI
              value={field.value}
              onViewChange={onViewChange}
              onChange={field.onChange}
              onClose={() => setOpen(false)}
              open={open}
              disabled={disabled ?? false}
              format={format ?? 'dd.MM.yyyy'}
              disablePast={disablePast ?? false}
              maxDate={maxDate ?? undefined}
              minDate={minDate}
              slotProps={{
                textField: {
                  required: required,
                  fullWidth: true,
                  variant: 'filled',
                  label,
                  // style: { marginBottom: 10 },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton size="large" onClick={() => setOpen(true)}>
                          <TimeCalendarIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
      {error && error.message && <Form.ErrorHelperText error={error.message} />}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  )
}
