import { Feature } from 'ol'
import { Coordinate } from 'ol/coordinate'
import { LineString, Point, Polygon } from 'ol/geom'
import { Draw } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { getLength } from 'ol/sphere'
import { Fill, RegularShape, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { useEffect, useMemo, useRef } from 'react'
import { v4 } from 'uuid'

import { Spatial, assertIsStyle, labelStyle } from '@griegconnect/krakentools-kmap'

import { GeometryType, useMapContext } from '../'
import { useMooringContext } from '../MooringPlanContext'
import { formatDistanceInMeters } from '../utils/formatDistanceInMeters'
import { midPoint } from '../utils/geometry'

type DrawMooringLineInteractionProps = {
  onMooringLineCreated: (startPoint: Coordinate, vesselVertex: number, vesselId: string | number, id: string) => void
}

export const selectedMooringLineStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(24, 160, 251, 1)',
    width: 4,
  }),
})

const validDotStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'rgba(24, 160, 251, 1)',
    }),
  }),
})

export const cantDrawMooringLineStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(255, 0, 0, 0.5)',
    width: 2,
    lineDash: [5, 5],
  }),
})

const cantDrawDotStyle = new Style({
  image: new RegularShape({
    stroke: new Stroke({ color: 'rgba(255, 0, 0, 0.5)', width: 2 }),
    points: 4,
    radius: 6,
    radius2: 0,
    angle: Math.PI / 4,
  }),
})

export const DrawMooringLineInteraction = (props: DrawMooringLineInteractionProps) => {
  const { kmapInstance: kmap, mooringLayer } = useMooringContext()
  const { colorPalette } = useMapContext()
  const canDraw = useRef(false)
  const isFirstPointPlaced = useRef(false)
  const labelFeature = useMemo(() => {
    const label = new Feature({
      geometry: new Point([]),
    })
    const style = labelStyle(colorPalette)
    style.getText()?.setOffsetY(16)
    label.setStyle(style)
    mooringLayer.getSource()?.addFeature(label)
    return label
  }, [])

  const getVesselGeometries = () => {
    const features = mooringLayer.getSource()?.getFeatures()
    const vessels = features?.filter((f) => f.get('type') === 'vessel')
    return (
      vessels
        ?.filter((v) => v.get('selectable'))
        .map((v) => ({
          id: v.get('id'),
          geometry: (v.getGeometry() as Polygon)?.getCoordinates()[0],
        })) ?? []
    )
  }

  const updateLabel = (from: Coordinate, to: Coordinate) => {
    const lineStringGeometry = new LineString([from, to])
    const geom = labelFeature.getGeometry()
    if (geom) {
      geom.setCoordinates(midPoint(from, to))
    } else {
      console.warn('No geometry on label feature')
    }
    const angle = Math.atan2(to[1] - from[1], to[0] - from[0])
    const text = assertIsStyle(labelFeature.getStyle()).getText()
    if (text) {
      text.setJustify('center')
      text.setTextBaseline('middle')
      const deg = Spatial.toDegrees(angle)
      text.setRotation(deg > 90 || deg < -90 ? -angle + Math.PI : -angle)
      text.setText(formatDistanceInMeters(getLength(lineStringGeometry)))
    }
  }

  const drawInteraction = useMemo(() => {
    kmap.enableSnap(true)
    let snappedVesselIndexPoint: number = -1
    let snappedToVesselId: number | string = 0

    const interaction = new Draw({
      type: GeometryType.LINE_STRING,
      clickTolerance: 50,
      maxPoints: 2,
      style: () => {
        if (canDraw.current) {
          return [selectedMooringLineStyle, validDotStyle]
        } else {
          return [cantDrawMooringLineStyle, cantDrawDotStyle]
        }
      },
      condition: (event) => {
        if (!canDraw.current) return false
        if (!isFirstPointPlaced.current) {
          isFirstPointPlaced.current = true
          return true
        } else {
          const map = event.map
          const clickedCoord = map.getCoordinateFromPixel(event.pixel)
          const vessels = getVesselGeometries()
          // Need to be outside a vessel
          let insideVessel = false
          vessels.forEach((vessel) => {
            const vesselPolygon = new Polygon([vessel.geometry])
            if (vesselPolygon.intersectsCoordinate(clickedCoord)) {
              insideVessel = true
            }
          })
          return !insideVessel
        }
      },
    })

    interaction.on('drawstart', (e: DrawEvent) => {
      snappedVesselIndexPoint = -1
      kmap.resetSnapCollection()
      kmap.addToSnapCollection(e.feature)

      e.feature.getGeometry()?.on('change', (event) => {
        const line = event.target as LineString
        const coords = line.getCoordinates()
        updateLabel(coords[0], coords[1])
      })

      const vessels = getVesselGeometries()
      if (!vessels) return

      e.feature.getGeometry()?.on('change', (event) => {
        const res = kmap.getViewParams().resolution
        const line = event.target as LineString
        const coords = line.getCoordinates()
        const snapPointCursor = coords[coords.length - 1]
        const limit = 20 * res
        vessels.forEach((vessel) => {
          let snapScore = Infinity
          vessel.geometry?.forEach((point, index) => {
            const score = Math.abs(point[0] - snapPointCursor[0]) + Math.abs(point[1] - snapPointCursor[1])
            if (score < snapScore && score < limit) {
              snapScore = score
              snappedVesselIndexPoint = index
              snappedToVesselId = vessel.id
            }
          })
        })
      })
    })

    interaction.on('drawend', (e: DrawEvent) => {
      if (isFirstPointPlaced.current) {
        const feature = e.feature.getGeometry() as LineString
        props.onMooringLineCreated(
          feature.getLastCoordinate(),
          snappedVesselIndexPoint, // Placeholder for vessel vertex (could be improved to use the actual vertex index)
          snappedToVesselId,
          v4()
        )
      }
    })

    return interaction
  }, [kmap, canDraw])

  useEffect(() => {
    const canDrawToggle = (coordinate: Coordinate) => {
      const vessels = getVesselGeometries()

      if (!vessels) return
      let snappedVesselIndexPoint: number = -1
      const res = kmap.getViewParams().resolution
      const snapPointCursor = coordinate
      const limit = 20 * res
      vessels.forEach((vessel) => {
        let snapScore = Infinity
        vessel.geometry?.forEach((point, index) => {
          const score = Math.abs(point[0] - snapPointCursor[0]) + Math.abs(point[1] - snapPointCursor[1])
          if (score < snapScore && score < limit) {
            snapScore = score
            snappedVesselIndexPoint = index
          }
        })
      })

      const isSnappedToVessel = !isFirstPointPlaced.current && snappedVesselIndexPoint > -1
      canDraw.current = isSnappedToVessel || (isFirstPointPlaced.current && snappedVesselIndexPoint === -1)

      if (isSnappedToVessel) {
        return
      }

      let insideVessel = false
      vessels.forEach((vessel) => {
        const vesselPolygon = new Polygon([vessel.geometry])
        if (vesselPolygon.intersectsCoordinate(coordinate)) {
          insideVessel = true
        }
      })

      canDraw.current = (!isFirstPointPlaced.current && insideVessel) || (isFirstPointPlaced.current && !insideVessel)
    }
    kmap.on('pointermove', canDrawToggle)

    return () => {
      if (kmap && drawInteraction) {
        kmap.un('pointermove')
        drawInteraction.abortDrawing()
        kmap.removeInteraction(drawInteraction)
        kmap.getOlMap().getTargetElement().style.cursor = 'auto'
        mooringLayer.getSource()?.removeFeature(labelFeature)
      }
    }
  }, [])

  useEffect(() => {
    if (drawInteraction) {
      kmap.addInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'none'
    }
  }, [])

  return null
}
