import { GridValidRowModel, GridColDef } from '@mui/x-data-grid-pro'

export function createTableColumn<RowData extends GridValidRowModel>(
  columns: GridColDef<RowData>[]
): GridColDef<RowData>[] {
  const columnDefault: GridColDef<RowData>[] = []

  const capitalize = (word: string) => {
    if (word.length > 3 || word === 'id') {
      return word.charAt(0).toUpperCase() + word.slice(1)
    } else {
      return word.toUpperCase()
    }
  }

  columns.forEach((column) => {
    const headerName = column.headerName ?? capitalize(column.field)
    if (column.type === 'actions') {
      columnDefault.push({
        ...column,
        minWidth: column.minWidth ?? 50,
        maxWidth: column.maxWidth ?? 100,
        align: column.align ?? 'center',
        type: 'actions',
        headerName: column.headerName ?? '',
      })
    } else {
      columnDefault.push({
        ...column,
        disableColumnMenu: column.disableColumnMenu ?? true,
        sortable: column.sortable ?? false,
        minWidth: column.minWidth ?? 150,
        maxWidth: column.maxWidth,
        flex: 1,
        headerAlign: column.headerAlign ?? 'left',
        headerName,
      })
    }
  })

  return columnDefault
}
