import React from 'react'
import { Navigation, requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { withServices } from '../withServices'
import { withActiveTenant } from '../WithActiveTenant'
import { withParam } from '../withParam'
import { AccessDependencies } from '@app/Content'
import { LockOpen } from '@griegconnect/krakentools-react-icons'
import { requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { Trans } from 'react-i18next'

const PATH_SALTO = PATH_TENANT_BASE + '/salto-users'
const PATH_STANLEY = PATH_TENANT_BASE + '/stanley'
const PATH_OPENPATH = PATH_TENANT_BASE + '/openpath-users'
const PATH_WESTEC = PATH_TENANT_BASE + '/westec-users'
const PATH_VISY = PATH_TENANT_BASE + '/visy-vehicles'
const PATH_APPROVED_VEHICLES = PATH_TENANT_BASE + '/approved-vehicles'

const saltoActive = (d: AccessDependencies) => d.portConfig.salto
const stanleyActive = (d: AccessDependencies) => d.portConfig.stanley
const openpathActive = (d: AccessDependencies) => d.portConfig.openpath
const westecActive = (d: AccessDependencies) => d.portConfig.westec
const visyActive = (d: AccessDependencies) => d.portConfig.visy
const approvedVehiclesActive = (d: AccessDependencies) => d.portConfig.vehicles

const saltoAccess = (d: AccessDependencies) =>
  saltoActive(d) && requiresPermission(PERMISSIONS.access_system_data.read, true)(d)
const stanleyAccess = (d: AccessDependencies) =>
  stanleyActive(d) && requiresPermission(PERMISSIONS.access_system_data.read, true)(d)
const openpathAccess = (d: AccessDependencies) =>
  openpathActive(d) && requiresPermission(PERMISSIONS.access_system_data.read, true)(d)
const westecAccess = (d: AccessDependencies) =>
  westecActive(d) && requiresPermission(PERMISSIONS.access_system_data.read, true)(d)
const visyAccess = (d: AccessDependencies) =>
  visyActive(d) && requiresPermission(PERMISSIONS.access_system_data.read, true)(d)
const approvedVehicles = (d: AccessDependencies) =>
  approvedVehiclesActive(d) && requiresPermission(PERMISSIONS.access_system_data.read, true)(d)

export const integrationsModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.access.access" />,
    path: PATH_TENANT_BASE + '/access',
    exact: true,
    menu: {
      icon: <LockOpen />,
    },
    access: (d) => saltoAccess(d) || stanleyAccess(d) || westecAccess(d) || visyAccess(d) || openpathAccess(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.access.salto" />,
        path: PATH_SALTO,
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./SaltoUsers/Users/Users'))))),
        exact: true,
        menu: true,
        access: (d) => saltoAccess(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.access.newSaltoUser" />,
            path: PATH_SALTO + '/user/new',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./SaltoUsers/Users/New')),
                    'userId'
                  )
                )
              )
            ),
            exact: true,
            access: (d) => saltoActive(d) && requiresPermission(PERMISSIONS.access_system_data.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.access.saltoUser" />,
            path: PATH_SALTO + '/user/edit/:userId',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./SaltoUsers/Users/User')),
                    'userId'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              saltoActive(d) &&
              requiresAllOfPermissions(
                [PERMISSIONS.access_system_data.read, PERMISSIONS.access_system_data.update],
                true
              )(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.access.stanley" />,
        path: PATH_STANLEY,
        page: React.createElement(withActiveTenant(React.lazy(() => import('./Stanley/UsersTab')))),
        exact: true,
        menu: true,
        access: (d) => stanleyAccess(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.access.newStanleyUser" />,
            path: PATH_STANLEY + '/newuser',
            page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./Stanley/Users/New'))))),
            exact: true,
            access: (d) => stanleyActive(d) && requiresPermission(PERMISSIONS.access_system_data.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.access.stanleyUser" />,
            path: PATH_STANLEY + '/user/:userId',
            page: React.createElement(
              withActiveTenant(
                withParam(
                  React.lazy(() => import('./Stanley/Users/User')),
                  'userId'
                )
              )
            ),
            exact: true,
            access: (d) =>
              stanleyActive(d) &&
              requiresAllOfPermissions(
                [PERMISSIONS.access_system_data.read, PERMISSIONS.access_system_data.update],
                true
              )(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.access.openpath" />,
        path: PATH_OPENPATH,
        page: React.createElement(withActiveTenant(React.lazy(() => import('./OpenpathUsers/Users/Users')))),
        exact: true,
        menu: true,
        access: (d) => openpathAccess(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.access.openpathUser" />,
            path: PATH_OPENPATH + '/newuser',
            page: React.createElement(
              withServices(withActiveTenant(React.lazy(() => import('./OpenpathUsers/Users/New'))))
            ),
            exact: true,
            access: (d) => openpathAccess(d) && requiresPermission(PERMISSIONS.access_system_data.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.access.openpathUser" />,
            path: PATH_OPENPATH + '/user/:userId',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./OpenpathUsers/Users/User')),
                    'userId'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              openpathAccess(d) &&
              requiresAllOfPermissions(
                [PERMISSIONS.access_system_data.read, PERMISSIONS.access_system_data.update],
                true
              )(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.access.westec" />,
        path: PATH_WESTEC + '/',
        page: React.createElement(withActiveTenant(React.lazy(() => import('./WestecUsers/UserTable')))),
        exact: true,
        menu: true,
        access: (d) => westecAccess(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.access.westec" />,
            path: PATH_WESTEC + '/newuser',
            page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./WestecUsers/New'))))),
            exact: true,
            access: (d) => westecActive(d) && requiresPermission(PERMISSIONS.access_system_data.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.access.westecUser" />,
            path: PATH_WESTEC + '/user/:holderId',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./WestecUsers/View')),
                    'holderId'
                  )
                )
              )
            ),
            exact: true,
            access: (d) => westecActive(d) && requiresPermission(PERMISSIONS.access_system_data.read, true)(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.access.visy" />,
        path: PATH_VISY + '/',
        page: React.createElement(withActiveTenant(React.lazy(() => import('./VisyPermits/Permits')))),
        exact: true,
        menu: true,
        access: (d) => visyAccess(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.access.newVisyPermit" />,
            path: PATH_VISY + '/new',
            page: React.createElement(
              withActiveTenant(
                withParam(
                  React.lazy(() => import('./VisyPermits/New')),
                  'contract'
                )
              )
            ),
            exact: true,
            access: (d) => visyActive(d) && requiresPermission(PERMISSIONS.access_system_data.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.access.editVisyPermit" />,
            path: PATH_VISY + '/edit/:id',
            page: React.createElement(
              withActiveTenant(
                withParam(
                  React.lazy(() => import('./VisyPermits/Permit')),
                  'id'
                )
              )
            ),
            exact: true,
            access: (d) =>
              visyActive(d) &&
              requiresAllOfPermissions(
                [
                  PERMISSIONS.access_system_data.read,
                  PERMISSIONS.access_system_data.update,
                  PERMISSIONS.access_system_data.delete,
                ],
                true
              )(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.access.vehicles" />,
        path: PATH_APPROVED_VEHICLES + '/',
        page: React.createElement(withActiveTenant(React.lazy(() => import('./ApprovedVehicles/View')))),
        exact: true,
        menu: true,
        access: (d) => approvedVehicles(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.access.newVehicle" />,
            path: PATH_APPROVED_VEHICLES + '/new',
            page: React.createElement(
              withServices(withActiveTenant(React.lazy(() => import('./ApprovedVehicles/Form'))))
            ),
            exact: true,
            access: (d) =>
              approvedVehiclesActive(d) && requiresPermission(PERMISSIONS.access_system_data.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.access.editVehicle" />,
            path: PATH_APPROVED_VEHICLES + '/edit/:id',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./ApprovedVehicles/Form')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              approvedVehiclesActive(d) && requiresPermission(PERMISSIONS.access_system_data.update, true)(d),
          },
        ],
      },
    ],
  },
]
