import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCopy = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M4 11a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-8Zm2 1v6h6v-6H6Z" />,
    <path d="M11 4a1 1 0 0 0-1 1v4h2V6h6v6h-3v2h4a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-8Z" />
  )
export default SvgCopy
