import React from 'react'
import { IsoCode } from './lang2lang'
import { getIsoCodes } from './lang2lang/codes'
import { useUser } from '@griegconnect/krakentools-react-auth'

export type ContextType = {
  getDefaultLocale: () => IsoCode
  getSupportedLocales: () => IsoCode[]
}

export const TranslatableContext = React.createContext<ContextType>({
  getDefaultLocale: () => 'en',
  getSupportedLocales: () => getIsoCodes(),
})

interface TranslatableContextProps {
  supportedLocales?: IsoCode[]
  defaultLocale: IsoCode
}

export const TranslatableProvider = (props: TranslatableContextProps & React.PropsWithChildren) => {
  const [locales, setLocales] = React.useState<IsoCode[]>(props.supportedLocales || getIsoCodes())

  React.useEffect(() => {
    setLocales(props.supportedLocales || getIsoCodes())
  }, [props.supportedLocales, setLocales])

  const getDefaultLocale = () => {
    return props.defaultLocale
  }

  const getSupportedLocales = () => {
    return locales
  }

  return (
    <TranslatableContext.Provider value={{ getDefaultLocale, getSupportedLocales }}>
      {props.children}
    </TranslatableContext.Provider>
  )
}

export const useTranslatable = () => React.useContext(TranslatableContext)
