import { Button as MuiButton, Modal as MuiModal } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    padding: '16px',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.default,
  },
}))

interface IButtonProps {
  onChange: (fullscreen: boolean) => void
}

interface IModalProps {
  open: boolean
}

export const Button: React.FC<React.PropsWithChildren<IButtonProps>> = ({ onChange }) => {
  const { t } = useTranslation()

  const fullScreenChange = () => {
    if (document.fullscreenElement === null) {
      onChange(false)
    }
  }

  const onFullScreen = () => {
    document.documentElement.requestFullscreen()
    document.addEventListener('fullscreenchange', fullScreenChange)
    onChange(true)
  }

  return (
    <MuiButton
      onClick={onFullScreen}
      variant="outlined"
      color="primary"
      aria-label="Fullscreen"
    >
      {t('components.dashboard.labels.fullscreen')}
    </MuiButton>
  )
}

export const Modal: React.FC<React.PropsWithChildren<IModalProps>> = ({ open, children }) => {
  const classes = useStyles()

  return (
    <MuiModal open={open}>
      <div className={classes.modalContainer}>{children}</div>
    </MuiModal>
  )
}
