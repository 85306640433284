import { AxiosInstance } from 'axios'

export declare namespace StripeDto {
  export interface Settings {
    requiredApplicationPrices: string[]
    optionalApplicationPrices: string[]
  }

  export interface Installation {
    port: string
    config?: Config
    settings: Settings
  }

  export interface Config {
    apiSecretKey?: string
    public: PublicConfig
  }

  export interface PublicConfig {
    apiPublicKey?: string
  }

  export interface ConfigForm {
    apiSecretKey?: string
    apiPublicKey: string
  }

  export interface Product {
    default_price: {
      id: string
      unit_amount: number
      currency: string
      tax_behavior: "inclusive" | "exclusive" | "unspecified"
    }
    name: string
  }

  export interface VisitorApplicationProducts{
    required: Product[]
    optional: Product[]
  }

  export interface CustomerForm {
    email: string
    name?: string
    address?: {
      country: string
      postal_code?: string
      city?: string
      line1?: string
    }
  }
  export interface SetupIntent {
    id: string
    client_secret: string
    customer: string
    description: string | null
  }

  export interface Invoice {
    id: string
    customer: string
    status: string
    paymentIntent?: string
    lines?: {
      data: Line[]
    }
    subtotal: number
    subtotal_excluding_tax: number
    tax: number
    total: number
    total_excluding_tax: number
    currency: string
  }

  export interface Line {
    id: string
    amount: number
    amount_excluding_tax: number
    currency: string
    description: string
    quantity: number
  }
}

export class StripeApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async updateInstallation(tenantRef: string, form: StripeDto.ConfigForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/integrations/stripe`, form)
  }

  async updateSettings(tenantRef: string, form: StripeDto.Settings): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/integrations/stripe/settings`, form)
  }

  async getInstallation(tenantRef: string): Promise<StripeDto.Installation> {
    const res = await this.http.get(`/ports/${tenantRef}/integrations/stripe`)
    return res.data
  }

  async getPublicConfig(tenantRef: string): Promise<StripeDto.PublicConfig> {
    const res = await this.http.get(`/ports/${tenantRef}/integrations/stripe/public-config`)
    return res.data
  }

  async visitorApplicationProducts(tenantRef: string): Promise<StripeDto.VisitorApplicationProducts> {
    const res = await this.http.get(`/ports/${tenantRef}/integrations/stripe/visitor-application-products`)
    return res.data
  }

  async getProducts(tenantRef: string): Promise<StripeDto.Product[]> {
    const res = await this.http.get(`/ports/${tenantRef}/integrations/stripe/products`)
    return res.data
  }

  async initializeSetupIntent(tenantRef: string, form: StripeDto.CustomerForm): Promise<StripeDto.SetupIntent> {
    const res = await this.http.post(`/ports/${tenantRef}/integrations/stripe/setup-intent`, form)
    return res.data
  }

  async finalizeVisitorApplicationInvoice(tenantRef: string, invoiceId: string): Promise<StripeDto.Invoice> {
    const res = await this.http.post(
      `/ports/${tenantRef}/integrations/stripe/finalize-visitor-application-invoice/${invoiceId}`
    )
    return res.data
  }

  async createVisitorApplicationInvoice(tenantRef: string, customer: string, optionalProducts: string[]): Promise<StripeDto.Invoice> {
    const res = await this.http.post(
      `/ports/${tenantRef}/integrations/stripe/visitor-application-draft-invoice/${customer}`, {optionalProducts: optionalProducts}
    )
    return res.data
  }
}
