import { Typography, Box } from '@mui/material'
import { IWidget, WidgetCard } from '@griegconnect/krakentools-react-ui'
import { Check, Stats } from '@app/common/ServicesWrapper/apis/Stats'
import { SecurityCheckResults } from '@griegconnect/krakentools-react-icons'
import { Widgets } from '../Types'
import * as SkeletonLoader from '../SkeletonLoader'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { Duration } from 'luxon'
import * as TimePeriod from '../../components/TimePeriod'
import { useServices } from '@app/common/ServicesWrapper'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export const Widget: React.VFC<Widgets.InfoWithData<Stats.DeviationsRangeStats[]> & WithActiveTenant> = ({
  widgetConstructor,
  data,
  onRemove,
  onSettings,
  onDetails,
  editing,
  activeTenant,
}) => {
  const { services } = useServices()
  const { t } = useTranslation()

  const timeFrom = widgetConstructor.timeFrom
    ? Duration.fromISO((widgetConstructor.timeFrom as unknown) as string)
    : undefined
  const timeTo = widgetConstructor.timeTo
    ? Duration.fromISO((widgetConstructor.timeTo as unknown) as string)
    : undefined

  const getTotalDeviations = (stats: Stats.DeviationsRangeStats[]) => {
    const filteredDeviations = widgetConstructor.facilities
      ? stats.filter((d) => widgetConstructor.facilities!.find((f) => f === d.facility))
      : stats
    const totalDeviations = widgetConstructor.subVariant
      ? filteredDeviations.reduce((a, c) => a + c[widgetConstructor.subVariant!]?.deviations || a, 0)
      : 0
    return totalDeviations
  }

  const { data: fetchedData } = useQuery<Stats.DeviationsRangeStats[] | undefined>(
    ['fetchedDataDeviations', activeTenant.ref, timeFrom, data],
    async () => {
      if (timeFrom) {
        const period = TimePeriod.getConstructorDates(timeFrom, widgetConstructor.currentPeriod, timeTo)
        const result = await services.statsApi.deviationsRange(activeTenant.ref, {
          from: period.fromDate.toJSDate(),
          to: period.toDate.toJSDate(),
        })
        return result
      }
      return data
    }
  )

  const deviations = fetchedData ? getTotalDeviations(fetchedData) : undefined

  const info: Record<Check, { title: string }> = {
    personChecks: {
      title: t('checks.module.personChecks'),
    },
    vehicleChecks: {
      title: t('checks.module.vehicleChecks'),
    },
    quayChecks: { title: t('checks.module.quayChecks') },
    facilityChecks: { title: t('checks.module.facilityChecks') },
    vesselChecks: { title: t('checks.module.vesselChecks') },
  }

  return deviations !== undefined && widgetConstructor.subVariant ? (
    <WidgetCard
      title={widgetConstructor.name || t('components.dashboard.labels.deviations')}
      description={`${
        widgetConstructor.subVariant
          ? `${info[widgetConstructor.subVariant].title}`
          : t('components.dashboard.labels.all')
      } - ${
        !widgetConstructor.facilities
          ? t('components.dashboard.labels.allFacilities')
          : widgetConstructor.facilities && widgetConstructor.facilities?.length > 3
          ? `${widgetConstructor.facilities?.length} ${t('security.labels.facilities').toLowerCase()}`
          : widgetConstructor.facilities?.map((f, i) => `${i !== 0 ? ', ' : ''}${f}`).join('')
      } - ${TimePeriod.getFilterText(
        t('components.dashboard.labels.allData'),
        timeFrom,
        timeTo,
        widgetConstructor.currentPeriod
      )}`}
      icon={<SecurityCheckResults />}
      onDetails={onDetails}
      editing={editing}
      onRemove={onRemove}
      onSettings={onSettings}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography fontSize={70}>{deviations}</Typography>
      </Box>
    </WidgetCard>
  ) : (
    <SkeletonLoader.Value />
  )
}

export const deviationsStatusWidget = (
  props: Widgets.InfoWithData<Stats.DeviationsRangeStats[]> & WithActiveTenant
): IWidget[] => {
  const { widgetConstructor } = props
  return widgetConstructor.subVariant
    ? [
        {
          id: widgetConstructor.id,
          isResizable: true,
          exactWidth: widgetConstructor.width,
          position: widgetConstructor.position,
          component: <Widget {...props} />,
        },
      ]
    : []
}

export default deviationsStatusWidget
