import React, { Dispatch, SetStateAction } from 'react'
import _ from 'lodash'

const useDebouncedState = <A>(init: A | (() => A), wait: number): [A, Dispatch<SetStateAction<A>>] => {
  const [read, write] = React.useState<A>(init)
  const memo = React.useMemo(() => _.debounce(write, wait), [wait])
  return [read, memo]
}

export default useDebouncedState
