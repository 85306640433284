import { atom } from 'recoil'

export type UserApplicationsAtomType = {
  visitRequests: number
  accessCardRequests: number
}

export const userApplicationsAtom = atom<UserApplicationsAtomType>({
  key: 'userApplicationsState',
  default: {
    visitRequests: 0,
    accessCardRequests: 0,
  },
})
