import React from 'react'
import { Tenant, useTenants } from '@griegconnect/krakentools-react-kraken-app'
import { AppLoader } from '@griegconnect/krakentools-react-ui'

export interface WithActiveTenant {
  activeTenant: Tenant.Type
}

export function withActiveTenant<P extends WithActiveTenant>(
  C: React.ComponentType<React.PropsWithChildren<P>>
): React.ComponentType<React.PropsWithChildren<Omit<P, keyof WithActiveTenant>>> {
  const Wrapped = (props: Omit<P, keyof WithActiveTenant>) => {
    const { activeTenant } = useTenants()
    if (activeTenant) {
      const all = { ...props, activeTenant } as P
      return <C {...all} />
    }
    return <AppLoader />
  }
  return Wrapped
}
