import { useHttp } from '@griegconnect/krakentools-react-http'
import { TenantApi } from '@griegconnect/krakentools-react-tenants'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { configSelector } from '../atoms/configAtom'
import { activeTenantSelector } from '../atoms/tenantsAtom'

export /**
 * This hook can be used without setting tenantRef if it's used inside tenant context. If not init with tenantRef.
 */
const useTenantApi = (tenantRef?: string) => {
  const { httpClient } = useHttp()
  const config = useRecoilValue(configSelector)
  const activeTenant = useRecoilValue(activeTenantSelector)

  if (activeTenant === null && tenantRef === undefined) {
    throw new Error('useTenantApi hook needs to be used inside tenant context, or you can use tenantRef prop')
  }

  const tenantsApi = useMemo(
    () =>
      new TenantApi(httpClient, config.api.tenantApiUrl, tenantRef ? tenantRef : activeTenant ? activeTenant.id : ''),
    [httpClient, config, activeTenant]
  )
  return tenantsApi
}
