import { Typography, TypographyProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  wrapped: {
    whiteSpace: 'normal',
  },
}))

export const Paragraph: React.FC<React.PropsWithChildren<TypographyProps>> = (props) => {
  const classes = useStyles()

  return (
    <Typography {...props} className={classes.wrapped}>
      {props.children}
    </Typography>
  )
}
