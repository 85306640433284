import axios, { AxiosError, AxiosResponse } from 'axios'
import { IConfiguration, IConfig } from '@microsoft/applicationinsights-web'
import { atom, selector } from 'recoil'

export type ApiType = {
  tenantApiUrl: string
  [key: string]: string
}

export type ApiExtendedType = {
  [key: string]: Record<string, string>
}
export type Options = {
  [key: string]: string
}

export type AuthType = {
  audience: string
  clientId: string
  clientIdVisitor?: string
  domain: string
  scope: string
  redirectPath?: string
  connection?: string
  rotatingRefreshTokens?: boolean
  cacheLocation?: 'localstorage' | 'memory'
}

export type ApplicationsType = {
  go?: string
  port?: string
  security?: string
  terminal?: string
  tenants?: string
}

export type CdnType = {
  designHostUrl: string
}

export type SentryConfigType = {
  dsn: string
  tracesSampleRate?: number
  replays?: boolean
  replaysOnErrorSampleRate?: number
  replaysSessionSampleRate?: number
}

export type ApplicationInsightsConfigType = IConfiguration & IConfig

export type ConfigTypeGeneric = Record<string, any> | void
export type ConfigType<T = ConfigTypeGeneric> = {
  initError?: boolean
  initErrorMessage?: string
  api: ApiType
  apiExtended?: ApiExtendedType
  auth: AuthType
  version: string
  forceUpdate?: boolean
  applications?: ApplicationsType
  options?: Options
  cdn: CdnType
  sentry?: SentryConfigType
  insights?: ApplicationInsightsConfigType
  environment?: 'dev' | 'review' | 'test' | 'prod'
} & (T extends undefined ? {} : T)

export const configPathAtom = atom<string>({
  key: 'configPathState',
  default: '/config/config.json',
})

export type ConfigSelectorParameter = {
  configPath?: string
  fetchDateTime?: Date
} | null

declare global {
  interface Window {
    krakenconfig: ConfigType<ConfigTypeGeneric>
  }
}

export const configSelector = selector({
  key: 'configSelector',
  get: async ({ get }) => {
    if (window.krakenconfig && typeof window.krakenconfig === 'object') {
      return window.krakenconfig
    }
    return axios
      .get(get(configPathAtom), {
        baseURL: window.location.origin,
      })
      .then((response: AxiosResponse<ConfigType<ConfigTypeGeneric>>) => {
        return response.data
      })
      .catch((e: Error | AxiosError) => {
        return { initError: true, initErrorMessage: e.message } as ConfigType<ConfigTypeGeneric>
      })
  },
})

export const configUpdatedAtom = atom({
  key: 'configUpdatedState',
  default: false,
})

/**
 *
export type ConfigParameter = {
  configPath?: string
}

export const configAtom = atomFamily<ConfigType, ConfigParameter>({
  key: 'configAtom',
  default: ({ configPath }) => {
    async ({ get }) => {
      return axios
        .get(configPath ?? get(configPathAtom))
        .then((response: AxiosResponse<ConfigType>) => {
          return response.data
        })
        .catch((e: Error | AxiosError) => {
          throw e
        })
    },
  }
})
 */
