import { Stack } from '@mui/material'
export interface FlowPageProps {}

const FlowPage: React.FC<React.PropsWithChildren<FlowPageProps>> = ({ children }) => {
  return (
    <Stack spacing={1} sx={{ marginBottom: { xs: '60px', sm: '10px' } }}>
      {children}
    </Stack>
  )
}

export default FlowPage
