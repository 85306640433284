import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSecurityCheckResults = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M11 8a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1ZM9 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM11 11a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1ZM12 13a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3ZM10 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />,
    <path
      fillRule="evenodd"
      d="m4 5 8-3 8 3v6.09c0 5.05-3.41 9.76-8 10.91-4.59-1.15-8-5.86-8-10.91V5Zm8 14.92c3.45-1.13 6-4.83 6-8.83V6.31l-6-2.12-6 2.12v4.78c0 4.01 2.55 7.7 6 8.83Z"
      clipRule="evenodd"
    />
  )
export default SvgSecurityCheckResults
