import { PhoneNumberUtil } from 'google-libphonenumber'
import * as z from 'zod'

const phoneUtil = PhoneNumberUtil.getInstance()

export const validatePhone = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export const PhoneInputValidate = z.string().refine((s) => {
  try {
    return validatePhone(s as string)
  } catch (e) {
    return false
  }
}, 'common.errors.phoneNumberInvalid')

export const PhoneInputOptionalValidate = z
  .string()
  .optional()
  .refine((s) => {
    if (!s) return true
    else {
      try {
        return validatePhone(s as string)
      } catch (e) {
        return false
      }
    }
  }, 'common.errors.phoneNumberInvalid')
