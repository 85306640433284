import { AxiosInstance } from 'axios';
import { CompanyNotification } from './dtos/companyNotificationDto';

export class CompanyNotificationsApi {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async upsert(port: string, entries: { company: string | null; emails: string[] }[]): Promise<void> {
    await this.httpClient.put(`/ports/${port}/notifications`, { entries });
  }

  async list(port: string): Promise<CompanyNotification[]> {
    const result = await this.httpClient.get(`/ports/${port}/notifications`);
    return result.data;
  }
}
