import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Switch, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useState } from 'react'
import { ISecurityConfirmationDto } from '@app/common/ServicesWrapper/apis/dtos/securityConfirmationsDto'
import { SortArrayOfObjectsNested } from '@app/lib/Sort'
import ConfirmationStatusIcon from '../ConfirmationStatusIcon'
import { FileDownloadButton } from '@app/common/FileDownloadButton'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

export interface IApplicantSecurityConfirmationProps {
  portSecurityConfirmations: ISecurityConfirmationDto[]
  currentUserSecurityConfirmations: string[]
  onConfirmationToggle?: (id: string) => void
  translate?: boolean // temporary avoid translation on pages without it
}

export const ApplicantSecurityConfirmation: React.VFC<IApplicantSecurityConfirmationProps> = ({
  portSecurityConfirmations,
  currentUserSecurityConfirmations,
  onConfirmationToggle,
  translate,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [openConfirmations, setOpenConfirmations] = useState([] as string[])

  const isConfirmedSecurityConfirmation = (id: string): boolean => {
    return currentUserSecurityConfirmations.includes(id)
  }

  const toggleSecurityConfirmation = (evt: any) => {
    const id: string = evt.target.value
    onConfirmationToggle && onConfirmationToggle(id)
  }

  const toggleConfirmationDetails = (id: string) => (evt: any) => {
    const confirmations = [...openConfirmations]
    const existing = confirmations.find((confirmationId) => confirmationId === id)
    if (existing) {
      setOpenConfirmations([])
    } else {
      setOpenConfirmations([id])
    }
  }

  const confirmationSwitch = (securityConfirmation: ISecurityConfirmationDto) => {
    return (
      <Switch
        checked={isConfirmedSecurityConfirmation(securityConfirmation.id)}
        value={securityConfirmation.id}
        color="primary"
        onChange={toggleSecurityConfirmation}
        inputProps={{
          'aria-labelledby': `switch-${
            isConfirmedSecurityConfirmation(securityConfirmation.id) ? 'to-uncomfirm' : 'to-confirm'
          }`,
        }}
      />
    )
  }

  const mainContent = (securityConfirmation: ISecurityConfirmationDto, hasDetails: boolean, open: boolean) => {
    return (
      <>
        <ListItemIcon>
          {isConfirmedSecurityConfirmation(securityConfirmation.id) ? (
            <ConfirmationStatusIcon status="success" />
          ) : (
            <ConfirmationStatusIcon status="error" />
          )}
        </ListItemIcon>
        <ListItemText id="switch-list-label-wifi">
          <Typography component="p">{securityConfirmation.title}</Typography>
        </ListItemText>
        {hasDetails && (
          <ListItemIcon>
            <ExpandMoreIcon
              fontSize="large"
              style={{
                position: 'relative',
                left: '8px',
                transform: `rotate(${open ? -180 : 0}deg)`,
                transition: 'transform 250ms ease-in-out',
              }}
            />
          </ListItemIcon>
        )}
        {onConfirmationToggle && !hasDetails && confirmationSwitch(securityConfirmation)}
      </>
    )
  }

  return (
    <List style={{ width: '100%' }}>
      {portSecurityConfirmations.length > 0 && <Divider style={{ backgroundColor: theme.palette.divider }} />}

      {portSecurityConfirmations.sort(SortArrayOfObjectsNested('rank', 'asc')).map((securityConfirmation, index) => {
        const open = openConfirmations.filter((confirmationId) => confirmationId === securityConfirmation.id).length > 0
        const hasDetails = !!securityConfirmation.details || !!securityConfirmation.document?.href
        return (
          <div key={`security-confirmation-${index}`}>
            {hasDetails && (
              <ListItem button={true} onClick={toggleConfirmationDetails(securityConfirmation.id)}>
                {mainContent(securityConfirmation, hasDetails, open)}
              </ListItem>
            )}
            {!hasDetails && <ListItem>{mainContent(securityConfirmation, hasDetails, open)}</ListItem>}
            {hasDetails && open && (
              <ListItem style={{ paddingTop: '0' }}>
                <ListItemText id="switch-list-label-wifi">
                  <Typography component="p" style={{ padding: '0 8px 16px 56px' }}>
                    {securityConfirmation.details}
                  </Typography>
                  <Box
                    pl={7}
                    display="flex"
                    justifyContent={securityConfirmation.document ? 'space-between' : 'flex-end'}
                  >
                    {securityConfirmation.document && (
                      <FileDownloadButton
                        resolvedFile={securityConfirmation.document}
                        label={translate ? t('common.actions.viewFile') : 'View file'}
                      />
                    )}
                    {onConfirmationToggle && confirmationSwitch(securityConfirmation)}
                  </Box>
                </ListItemText>
              </ListItem>
            )}

            <Divider style={{ backgroundColor: theme.palette.divider }} />
          </div>
        )
      })}
    </List>
  )
}
