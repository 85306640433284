import { AvatarGroup } from '@mui/material';
import React from 'react'
import { ResolvedUser } from './ServicesWrapper/apis/dtos/userDto'
import UserAvatar from './UserAvatar'
import VehicleAvatar from './VehicleAvatar'

interface Props {
  users: ResolvedUser[]
  vehicles: string[][]
}

export default function VisitorAvatarGroup({ users, vehicles }: Props) {
  return (
    <AvatarGroup>
      {users.map((user, index) => (
        <UserAvatar key={`user-avatar-${index + 1}`} user={user} />
      ))}
      {vehicles.map((vehicle, index) => (
        <VehicleAvatar key={`vehicle-avatar-${index + 1}`} vehicle={vehicle} />
      ))}
    </AvatarGroup>
  )
}
