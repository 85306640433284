import { useAuth } from '@griegconnect/krakentools-react-auth'
import { SplashLoader } from '@griegconnect/krakentools-react-kraken-app'
import { lazy, Suspense } from 'react'

type LocaleWrapperProps = {
  children: React.ReactNode
}

const LocaleProvider = lazy(() => import('./LocaleProvider'))

const LocaleWrapper = ({ children }: LocaleWrapperProps) => {
  const { isInitializing } = useAuth()

  return isInitializing ? (
    <SplashLoader message="Loading language…" />
  ) : (
    <Suspense fallback={<SplashLoader message="Loading language…" />}>
      <LocaleProvider>{children}</LocaleProvider>
    </Suspense>
  )
}

export default LocaleWrapper
