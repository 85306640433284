import React from 'react'
import { Navigation, requiresTenant } from '@griegconnect/krakentools-react-kraken-app'
import { withServices } from '../withServices'
import { withParam } from '../withParam'
import { AccessDependencies } from '@app/Content'
import HomeUser from './Home'
import { VisitRequestsBadge, AccessCardRequestsBadge } from '@app/common/Badges'
import { accessContracts, isVisitHandler, rootPath } from '@app/lib/access'
import { SecurityAccessContract } from '@griegconnect/krakentools-react-icons'
import ThreePIcon from '@mui/icons-material/ThreeP'
import { withUserTenant } from '../withUserTenant'
import { Trans } from 'react-i18next'
import { RewriteUri } from '@app/common/Navigation'
import { UserApplicationsAtomType } from '@app/atoms/userApplicationsAtom'
import NewSelector from './Application/PortSelector'
import EditSelector from './Application/EditSelector'
import SelectContext from '../SelectContext'

const USER_PATH = '/user'
const USER_PATH_CONTRACTS = '/user/access-contracts'

export const userModuleNavs = (
  userApplications: UserApplicationsAtomType,
  isVisitDomain: boolean,
  isFullDev: boolean
): Navigation.Item<AccessDependencies>[] => [
  {
    path: '/',
    name: <Trans i18nKey="navigation.user.selectContext" />,
    page: <SelectContext isVisitDomain={isVisitDomain || isFullDev} />,
    exact: true,
  },
  {
    name: <Trans i18nKey="navigation.user.newApplication" />,
    path: USER_PATH + '/application/new',
    page: <NewSelector />,
    exact: true,
    subNavs: [
      {
        name: <Trans i18nKey="navigation.user.newPortApplication" />,
        path: '/user/application/new/:port',
        page: React.createElement(
          withServices(
            withParam(
              React.lazy(() => import('./Application/NewPortSelector')),
              'port'
            )
          )
        ),
        exact: true,
      },
      {
        name: <Trans i18nKey="navigation.user.editApplication" />,
        path: USER_PATH + '/application/edit/:id',
        page: React.createElement(withServices(withParam(EditSelector, 'id'))),
        exact: true,
      },
      {
        name: <Trans i18nKey="navigation.applications.reviewApplication" />,
        path: USER_PATH + '/application/review/:tenantRef/:id',
        page: React.createElement(withUserTenant(React.lazy(() => import('./Application/VisitHandlerReview')))),
        exact: true,
      },
    ],
  },
  {
    name: <Trans i18nKey="navigation.user.accessApplication" />,
    path: USER_PATH + '/fixed-application/:id',
    page: React.createElement(
      withServices(
        withParam(
          React.lazy(() => import('./FixedApplication/FixedApplication')),
          'id'
        )
      )
    ),
    exact: true,
  },

  {
    name: <Trans i18nKey="navigation.user.myApplications" />,
    path: USER_PATH,
    page: <HomeUser />,
    exact: true,
    menu: {
      icon: <ThreePIcon />,
    },
    access: (d) => !requiresTenant(d) && !rootPath() && (isVisitDomain || isFullDev),
  },
  {
    name: <Trans i18nKey="navigation.applications.visitors" />,
    path: USER_PATH + '/visitors',
    exact: true,
    menu: {
      icon: <VisitRequestsBadge />,
      badge: { count: userApplications.visitRequests },
    },
    access: (d) => !requiresTenant(d) && isVisitHandler(d) && !rootPath(),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.user.requests" />,
        path: USER_PATH + '/visitors/requests',
        page: React.createElement(React.lazy(() => import('../Applications/Visitors'))),
        exact: true,
        menu: {
          badge: { count: userApplications.visitRequests },
        },
        access: (d) => !requiresTenant(d) && isVisitHandler(d) && !rootPath(),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.user.request" />,
            path: USER_PATH + '/visitors/requests/:tenantRef/:id/review',
            page: React.createElement(
              withParam(withUserTenant(React.lazy(() => import('./Application/VisitHandlerReview'))), 'id')
            ),
            exact: true,
            menu: false,
            access: (d) => !requiresTenant(d) && isVisitHandler(d) && !rootPath(),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.permits.permits" />,
        path: USER_PATH + '/visitors/permits',
        page: React.createElement(withServices(React.lazy(() => import('../Permits')))),
        exact: true,
        menu: true,
        access: (d) => !requiresTenant(d) && isVisitHandler(d) && !rootPath(),
      },
      {
        name: <Trans i18nKey="navigation.permits.newPermit" />,
        path: USER_PATH + '/visitors/permits/:tenantRef/new',
        page: React.createElement(withUserTenant(React.lazy(() => import('../Permits/NewPermit')))),
        exact: true,
        access: (d) => !requiresTenant(d) && isVisitHandler(d) && !rootPath(),
      },
      {
        name: <Trans i18nKey="navigation.permits.editPermit" />,
        path: USER_PATH + '/visitors/permits/:tenantRef/edit/:id',
        page: React.createElement(
          withUserTenant(
            withParam(
              React.lazy(() => import('../Permits/EditPermit')),
              'id'
            )
          )
        ),
        exact: true,
        access: (d) => !requiresTenant(d) && isVisitHandler(d) && !rootPath(),
      },
    ],
  },
  {
    name: <Trans i18nKey="navigation.user.accessCardRequests" />,
    path: USER_PATH + '/access-card-requests',
    page: React.createElement(React.lazy(() => import('../Applications/Fixed/List'))),
    exact: true,
    menu: {
      icon: <AccessCardRequestsBadge />,
      badge: { count: userApplications.accessCardRequests },
    },
    access: (d) => !requiresTenant(d) && accessContracts(d) && !rootPath(),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.user.accessCard" />,
        path: USER_PATH + '/access-card-requests/:tenantRef/:id',
        page: React.createElement(
          withParam(withUserTenant(React.lazy(() => import('../Applications/Fixed/View'))), 'id')
        ),
        exact: true,
        menu: false,
        access: (d) => !requiresTenant(d) && accessContracts(d) && !rootPath(),
      },
    ],
  },
  {
    name: <Trans i18nKey="navigation.accessContracts.contracts" />,
    path: USER_PATH_CONTRACTS,
    page: React.createElement(React.lazy(() => import('../AccessContracts/List'))),
    exact: true,
    menu: {
      icon: <SecurityAccessContract />,
    },
    access: (d) => !requiresTenant(d) && accessContracts(d) && !rootPath(),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.accessContracts.contract" />,
        path: USER_PATH_CONTRACTS + '/:id',
        page: React.createElement(
          withParam(
            React.lazy(() => import('../AccessContracts/View')),
            'id'
          )
        ),
        exact: true,
        access: (d) => !requiresTenant(d) && accessContracts(d) && !rootPath(),
      },
    ],
  },
  {
    name: 'contracts',
    path: USER_PATH + '/contracts',
    access: (d) => !requiresTenant(d) && accessContracts(d) && !rootPath(),
    page: <RewriteUri search="contracts" replace="access-contracts" />,
  },
]
