import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgVehicleCar = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M18 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM7.5 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />,
    <path d="M6 3a1 1 0 0 0-.962.725L3.818 8H3a1 1 0 1 0 0 2h.246l-.208.725A1 1 0 0 0 3 11v9a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1h12v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-9a.997.997 0 0 0-.038-.275L20.754 10H21a1 1 0 1 0 0-2h-.817l-1.221-4.275A1 1 0 0 0 18 3H6Zm-.674 7 1.428-5h10.492l1.428 5H5.326ZM5 12h14v5H5v-5Z" />
  )
export default SvgVehicleCar
