import { Box, FilledInputProps, FormHelperText, InputBaseComponentProps, TextField } from '@mui/material'
import React from 'react'
import { Control, Controller, get, useFormState } from 'react-hook-form'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'

interface TextInputProps {
  control: Control<any>
  label: string
  name: string
  type?: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  multiline?: boolean
  InputProps?: Partial<FilledInputProps>
  inputProps?: InputBaseComponentProps
  helperText?: string
}
export const TextInput: React.FC<React.PropsWithChildren<TextInputProps>> = ({
  control,
  label,
  name,
  type,
  disabled,
  placeholder,
  InputProps,
  inputProps,
  required,
  multiline,
  helperText,
}) => {
  const { errors } = useFormState()
  const error = get(errors, name)
  return (
    <Box pb={1}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            label={label}
            required={required ?? false}
            variant="filled"
            disabled={disabled ?? false}
            fullWidth={true}
            type={type ?? 'text'}
            multiline={multiline}
            {...field}
            placeholder={placeholder}
            InputProps={InputProps}
            inputProps={inputProps}
            onChange={(e) => {
              const res = Object.assign({}, e, {
                target: { value: type === 'number' ? parseInt(e.target.value) : e.target.value },
              })
              field.onChange(res)
            }}
          />
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && error.message && <ErrorHelperText error={error.message} />}
    </Box>
  )
}
