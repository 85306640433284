import { TrashCan } from '@griegconnect/krakentools-react-icons'
import { CloudDownload } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import { FileDownloadButton } from './FileDownloadButton'
import { FileImageButton } from './FileImageButton'
import { IFileRefDto } from './ServicesWrapper/apis/dtos/fileRefDto'

export interface FileListProp {
  documents: IFileRefDto[]
  onRemove?: (id: string, idx: number) => void
  disabled?: boolean
}

export const FileList: React.FC<React.PropsWithChildren<FileListProp>> = (props) => {
  const removeHandler = (id: string, idx: number) => (evt: any) => {
    if (props.onRemove) {
      props.onRemove(id, idx)
    }
  }
  return (
    <>
      {props.documents.map((document, i) => {
        if (!document.id) {
          return <></>
        }
        return (
          <Box display="flex" alignItems="center" width="100%" flexDirection="row" key={i}>
            <Box flex={1}>
              {document.resolved && document.mediatype.startsWith('image') ? (
                <FileImageButton resolvedFile={document} />
              ) : document.resolved ? (
                <FileDownloadButton resolvedFile={document} />
              ) : (
                <>{/*<Typography>{t('common.files.file.noFilesFound')}</Typography>*/}</>
              )}
            </Box>

            <Box>
              {document.resolved && (
                <IconButton href={document.href} target={'_blank'} download={document.filename} size="large">
                  <CloudDownload />
                </IconButton>
              )}

              {document.resolved && props.onRemove && !props.disabled && (
                <IconButton
                  onClick={removeHandler(document.id, i)}
                  aria-label="delete"
                  style={{ padding: '6px' }}
                  size="large"
                >
                  <TrashCan />
                </IconButton>
              )}
            </Box>
          </Box>
        )
      })}
    </>
  )
}
