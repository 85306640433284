import { Services } from '@app/common/ServicesWrapper/types'
import { useServices } from '@app/common/ServicesWrapper'
import React from 'react'

export interface WithServicesProps {
  services: Services
}

export function withServices<P extends WithServicesProps>(
  Component: React.ComponentType<React.PropsWithChildren<P>>
): React.ComponentType<React.PropsWithChildren<Omit<P, keyof WithServicesProps>>> {
  const Wrapped = (props: Omit<P, keyof WithServicesProps>) => {
    const { services } = useServices()
    const all = { ...props, services } as P
    return <Component {...all} />
  }
  return Wrapped
}
