import { IWidget, WidgetCard } from '@griegconnect/krakentools-react-ui'
import { Widgets } from '../Types'
import MapIcon from '@mui/icons-material/Map'
import Map from '@app/common/Widgets/MapWidget'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { Permissions, PERMISSIONS } from '@app/constants'
import {useTranslation} from "react-i18next";

interface IMapWidgetProps extends Widgets.InfoWithData<{}>, WithActiveTenant {}

export const Widget: React.VFC<IMapWidgetProps> = ({
  widgetConstructor,
  onRemove,
  onSettings,
  onDetails,
  editing,
  activeTenant
}) => {
  const {t} = useTranslation()
  return <WidgetCard
    icon={<MapIcon/>}
    title={widgetConstructor.name ?? t('components.dashboard.module.map')}
    description=""
    editing={editing}
    onRemove={onRemove}
    onSettings={onSettings}
    onDetails={onDetails}
  >
    <Map activeTenant={activeTenant}/>
  </WidgetCard>
}

export const mapWidget = (props: IMapWidgetProps): IWidget[] => {
  const activeTenant = props.activeTenant
  const mapAccess = requiresAllOfPermissions(Permissions.all(PERMISSIONS.maps))({activeTenant})
  //const { t } = useTranslation()

  return mapAccess
    ? [
      {
        component: (<Widget {...props}/>),
        isResizable: true,
        height: props.widgetConstructor.height || [5, 5, 5, 6],
        exactWidth: props.widgetConstructor.width || [6, 4],
        position: props.widgetConstructor.position,
        id: props.widgetConstructor.id,
      },
    ]
    : []
}

export default mapWidget
