import { SortArrayOfObjectsNested } from '@app/lib/Sort'
import { Translations } from '@griegconnect/krakentools-form/TranslatableTextField/lang2lang/lang'
import { AxiosInstance } from 'axios'
import qs from 'qs'
import { IDateRange } from './dtos/IDateRange'
import { IAreaDto } from './dtos/areaDto'

export type Direction = 'in' | 'out' | 'none'
export const Directions: Direction[] = ['in', 'out', 'none']

export type Perimeter = 'true' | 'false' | 'none'
export const Perimeters: Perimeter[] = ['true', 'false', 'none']

export declare namespace Checkpoints {

  export interface Checkpoint {
    id: string
    name: string
    direction: Direction
    area: IAreaDto
    automaticVehicle: boolean
    allow: boolean
    perimeter?: boolean
    visitorInfo: Translations
    visitorInfoUrl?: string
  }

  export interface CheckpointLink {
    id: string
    name: string
    direction?: Direction
  }

  // The form posted to the server
  export interface ApiCheckpointForm {
    name: string
    direction: Direction
    area: string
    automaticVehicle: boolean
    allow: boolean
    perimeter?: boolean
    visitorInfo: Translations
    visitorInfoUrl?: string
  }

  // Introduced because of mismatch between the API form and how to get this to work with Form.Select
  export interface CheckpointForm {
    name: string
    direction: Direction
    area: string
    automaticVehicle: boolean
    allow: boolean
    perimeter: Perimeter
    visitorInfo: Translations
    visitorInfoUrl?: string
  }

  export interface CheckpointAllow {
    vehicles: string[]
    persons: string[]
    valid?: IDateRange
    oneTime?: boolean
    purpose?: string
    company?: string
  }

  export interface CheckpointParams {
    area?: string[]
  }
}

export class CheckpointApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  checkpointFormToApiForm = (form: Checkpoints.CheckpointForm): Checkpoints.ApiCheckpointForm => {
    return {
      name: form.name,
      direction: form.direction,
      area: form.area,
      automaticVehicle: form.automaticVehicle,
      allow: form.allow,
      perimeter: form.perimeter === 'none' ? undefined : form.perimeter === 'true',
      visitorInfo: form.visitorInfo,
      visitorInfoUrl: form.visitorInfoUrl
    }
  }

  checkpointFormFromApiForm: (form: Checkpoints.ApiCheckpointForm) => Checkpoints.CheckpointForm = (form) => {
    return {
      name: form.name,
      direction: form.direction,
      area: form.area,
      automaticVehicle: form.automaticVehicle,
      allow: form.allow,
      perimeter: form.perimeter === undefined || form.perimeter === null ? 'none' : form.perimeter ? 'true' : 'false',
      visitorInfo: form.visitorInfo,
      visitorInfoUrl: form.visitorInfoUrl || ''
    }
  }

  async list(ref: string, search: Checkpoints.CheckpointParams): Promise<Checkpoints.Checkpoint[]> {
    const params = { ...search }
    const response = await this.http.get(`/ports/${ref}/checkpoints`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    const checkpoints = response.data.checkpoints.sort(SortArrayOfObjectsNested('area.name', 'asc'))
    return checkpoints
  }

  async get(ref: string, id: string): Promise<Checkpoints.Checkpoint> {
    const response = await this.http.get(`/ports/${ref}/checkpoints/${id}`)
    return response.data
  }

  async create(ref: string, form: Checkpoints.CheckpointForm): Promise<string> {
    const response = await this.http.post(`/ports/${ref}/checkpoints`, this.checkpointFormToApiForm(form))
    return response.data.id
  }

  async update(ref: string, id: string, form: Checkpoints.CheckpointForm): Promise<void> {
    await this.http.put(`/ports/${ref}/checkpoints/${id}`, this.checkpointFormToApiForm(form))
  }

  async delete(ref: string, id: string): Promise<void> {
    await this.http.delete(`/ports/${ref}/checkpoints/${id}`)
  }

  async allow(ref: string, id: string, form: Checkpoints.CheckpointAllow): Promise<void> {
    await this.http.post(`/ports/${ref}/checkpoints/${id}/allow`, form)
  }
}
