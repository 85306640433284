import { Box, InputAdornment, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import * as Form from '@griegconnect/krakentools-form'
import { LocalIdentity } from '@app/common/ServicesWrapper/apis/dtos/identitiesDto'
import { useTranslation } from 'react-i18next'
import SymmetryBlueGuide from '@app/common/applications/SymmetryBlueGuide'
import { useQuery } from '@tanstack/react-query'
import { useServices } from '@app/common/ServicesWrapper'
import { SecurityIdentity } from '@app/common/ServicesWrapper/apis/dtos/IdentityAttributes'
import * as Stanley from '@app/common/ServicesWrapper/apis/Stanley'

interface Props {
  identities: LocalIdentity[]
  disabled?: boolean
  name: string
  port: string
  facility: string
  me: SecurityIdentity
}

const SymmetryBlueForm: React.VFC<Props> = ({ name, disabled, identities, facility, port, me }) => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const { services } = useServices()
  const cards = useQuery<Stanley.PersistedCard | undefined>(
    ['myAccessCards', facility, me.attributes.symmetryBlue],
    () =>
      services.userApi
        .myAccessCards()
        .then(
          (accessCards) =>
            accessCards
              .find((t) => t.port.ref === port)
              ?.stanley.find((i) => i.info.number === me.attributes.symmetryBlue) || undefined
        ),
    {
      staleTime: 3000,
    }
  )

  if (!cards.isFetched) {
    return <Skeleton variant="text" />
  }
  if (cards.data) {
    return (
      <Box display="flex" alignItems="center">
        <Typography>{t('symmetryBlue.existingCard')}</Typography>
        <SymmetryBlueGuide />
      </Box>
    )
  } else {
    return (
      <Form.TextInput
        required
        disabled={disabled}
        name={name}
        control={control}
        label="Symmetry Blue"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SymmetryBlueGuide />
            </InputAdornment>
          ),
        }}
      />
    )
  }
}

export default SymmetryBlueForm
