import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgTrashCan = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M11 11a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0v-6ZM14 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Z" />,
    <path d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v3h4a1 1 0 1 1 0 2h-.069l-.934 13.071A1 1 0 0 1 18 22H6a1 1 0 0 1-.997-.929L4.069 8H4a1 1 0 0 1 0-2h4V3Zm2 3h4V4h-4v2ZM6.074 8l.857 12H17.07l.857-12H6.074Z" />
  )
export default SvgTrashCan
