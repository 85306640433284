import React, { useState } from 'react'
import { Alert, Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { Page } from '@griegconnect/krakentools-react-ui'
import { WithServicesProps } from '@app/routes/withServices'
import { SecurityIdentity } from '@app/common/ServicesWrapper/apis/dtos/IdentityAttributes'
import StepVisiting from './StepVisiting'
import StepVisitDetails from './StepVisitDetails'
import StepVisitor from './StepVisitor'
import StepConfirmations from './StepConfirmations'
import { StepStripePayment } from './StepStripePayment'
import { IPortDetailsDto } from '@app/common/ServicesWrapper/apis/SharedDataApi'
import { LocalIdentity } from '@app/common/ServicesWrapper/apis/dtos/identitiesDto'
import { OnSite } from '@app/common/ServicesWrapper/apis/CompaniesApi'
import { Vessel } from '@app/common/ServicesWrapper/apis/VesselsApi'
import { useNavigate } from 'react-router-dom'
import { UserApi } from '@app/common/ServicesWrapper/apis/UserApi'
import { useTranslation } from 'react-i18next'
import { useUsersApi, useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import StepSubmitted from './StepSubmitted'
import { StripeDto } from '@app/common/ServicesWrapper/apis/StripeApi'
import {
  ApplicationForm,
  Confirmations,
  VisitDetails,
  Visiting,
  Visitor,
  fromApplication,
  toApplicationForm,
} from '../../common/types'

export interface ApplicationFormData {
  form: Visiting | VisitDetails | Visitor | Confirmations,
  part: 'visiting' | 'visitDetails' | 'visitor' | 'confirmations'
}

interface Props extends WithServicesProps {
  me: SecurityIdentity
  identities: LocalIdentity[]
  details: IPortDetailsDto
  customers: OnSite.Details[]
  vessels?: Vessel[]
  application?: UserApi.Application.Details
  isDone: boolean
  onSubmit: (form: UserApi.Application.Form, invoice?: string) => void
  formLoading: boolean
  setFormLoading: (b: boolean) => void
  mode: 'edit' | 'create'
}

const Flow: React.FC<Props> = ({
  services,
  me,
  identities,
  details,
  customers,
  vessels,
  application,
  onSubmit,
  isDone,
  formLoading,
  setFormLoading,
  mode,
}) => {
  const navigate = useNavigate()
  const { enqueue } = useAlerts()
  const usersApi = useUsersApi()
  const [stripeInvoice, setStripeInvoice] = useState<StripeDto.Invoice | undefined>()
  const [setupIntent, setSetupIntent] = useState<StripeDto.SetupIntent | undefined>()
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [applicationForm, setApplicationForm] = useState<ApplicationForm>(
    application ? fromApplication(application) : {}
  )
  const [activeForm, setActiveForm] = useState<ApplicationFormData | undefined>()
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const onBack = () => {
    activeForm && (updateApplication(activeForm))
    currentStep === 0 ? navigate('/user') : setCurrentStep(currentStep - 1)
  }

  const onCompleted = async (stepForms: ApplicationForm, invoice?: string) => {
    try {
      if (applicationForm.visitor?.save.mobile && applicationForm.visitor?.visitor.person?.mobile) {
        await usersApi.updateCurrentUser(me.identity.name, applicationForm.visitor.visitor.person.mobile)
      }
      if (
        applicationForm.visitor?.save.company ||
        applicationForm.visitor?.save.personFile ||
        applicationForm.visitor?.save.symmetryBlue
      ) {
        const profile = me.attributes
        const idDocument =
          applicationForm.visitor?.isPerson &&
          applicationForm.visitor?.save.personFile &&
          applicationForm.visitor?.visitor.person?.files &&
          applicationForm.visitor?.visitor.person?.files.length > 0
            ? applicationForm.visitor?.visitor.person?.files[0]?.id
            : profile.idDocument?.id
        const company = applicationForm.visitor?.save.company
          ? applicationForm.visitor?.visitor.company
          : profile.company || undefined
        const symmetryBlue = applicationForm.visitor?.save.symmetryBlue
          ? applicationForm.visitor?.integrations.symmetryBlue
          : profile.symmetryBlue
          ? profile.symmetryBlue.toString()
          : null
        await services.userApi.updateProfile(idDocument, company, symmetryBlue || undefined)
      }
    } catch (error) {
      enqueue(t('applications.errors.profileUpdate'), 'error')
    }
    if (stepForms.visiting && stepForms.visitDetails && stepForms.visitor && stepForms.confirmations) {
      const form = toApplicationForm(stepForms)
      if (mode === 'create' && details.integrations.stripe) {
        onSubmit(form, invoice)
      } else {
        onSubmit(form, undefined)
      }
    }
  }

  const updateApplication = async (formData: ApplicationFormData) => {
    const updatedForm: ApplicationForm = { ...applicationForm, [formData.part]: formData.form }
    setApplicationForm(updatedForm)
    return updatedForm
  }

  const updateErrors = (errors: boolean) => {
    setHasErrors(errors)
  }

  const status = application?.current.status
  const applicationMessage = application?.current.statusMessage
  const disabled = status === 'approved' || status === 'declined'

  const statusColor = {
    invited: 'info',
    submitted: 'success',
    request_more_info: 'warning',
    declined: 'error',
  }

  const statusMessage = {
    invited: t(`applications.status.applicationInvited`),
    submitted: t(`applications.status.submitted`),
    request_more_info: applicationMessage || t('applications.status.requestMoreInfo'),
    declined: t('applications.status.applicationRejected'),
  }

  const navButtons = (last?: boolean) => (
    <Box display="flex" justifyContent="flex-end" mt={4}>
      {!isDone && (
        <Button variant="outlined" onClick={onBack} style={{ marginRight: 10 }} disabled={formLoading}>
          {currentStep === 0 ? t('common.actions.cancel') : t('common.actions.back')}
        </Button>
      )}
      {!isDone && (!disabled || currentStep < 3) && (
        <Button variant="contained" type="submit" disabled={hasErrors || formLoading}>
          {last ? t('common.actions.submit') : t('common.actions.next')}
        </Button>
      )}
      {!!isDone && (
        <Button variant="contained" onClick={() => navigate('/user')} disabled={formLoading}>
          {t('common.actions.done')}
        </Button>
      )}
    </Box>
  )

  const steps = () => [
    {
      name: 'Visiting',
      component: () => (
        <StepVisiting
          onNext={(form, part) => {
            updateApplication({form, part})
            setCurrentStep(currentStep + 1)
          }}
          details={details}
          customers={customers}
          vessels={vessels}
          edit={!!application}
          applicationForm={applicationForm.visiting}
          disabled={disabled}
          updateErrors={updateErrors}
        >
          {navButtons()}
        </StepVisiting>
      ),
    },
    {
      name: 'Visit Details',
      component: () => (
        <StepVisitDetails
          onUpdate={(form, part) => {
            setActiveForm({form, part})
          }}
          onNext={() => {
            if (activeForm) {
              updateApplication(activeForm)
              setCurrentStep(currentStep + 1)
            }
          }}
          details={details}
          applicationForm={applicationForm}
          facility={applicationForm.visiting?.visiting?.facility || ''}
          disabled={disabled}
          updateErrors={updateErrors}
        >
          {navButtons()}
        </StepVisitDetails>
      ),
    },
    {
      name: 'Visitor',
      component: () => (
        <StepVisitor
          onUpdate={(form, part) => {
            setActiveForm({form, part})
          }}
          onNext={() => {
            if (activeForm) {
              updateApplication(activeForm)
              setCurrentStep(currentStep + 1)
            }
          }}
          details={details}
          me={me}
          identities={identities}
          applicationForm={applicationForm.visitor}
          facility={applicationForm.visiting?.visiting?.facility || ''}
          disabled={disabled}
          updateErrors={updateErrors}
        >
          {navButtons()}
        </StepVisitor>
      ),
    },
    {
      name: 'Confirmations',
      component: () => (
        <StepConfirmations
          onUpdate={(form, part) => {
            setActiveForm({form, part})
          }}
          onNext={async () => {
             if (activeForm) {
                const formData = await updateApplication(activeForm)
                if (details.integrations.stripe && mode === 'create') setCurrentStep(currentStep + 1)
                else onCompleted(formData)
             }
          }}
          details={details}
          applicationForm={applicationForm.confirmations}
          disabled={disabled}
          application={application}
          updateErrors={updateErrors}
        >
          {navButtons(mode === 'edit' || !details.integrations.stripe ? true : false)}
        </StepConfirmations>
      ),
    },
    ...(mode === 'create' && details.integrations.stripe
      ? [
          {
            name: 'Payment',
            component: () => (
              <StepStripePayment
                me={me}
                port={details.port.id}
                setupIntent={setupIntent}
                setSetupIntent={setSetupIntent}
                invoice={stripeInvoice}
                setInvoice={setStripeInvoice}
                disabled={disabled}
                services={services}
                onBack={onBack}
                onNext={(i) => onCompleted(applicationForm, i)}
                formLoading={formLoading}
                setFormLoading={setFormLoading}
              >
                {navButtons(true)}
              </StepStripePayment>
            ),
          },
        ]
      : []),
  ]

  return (
    <Page.Wrapper title="" maxWidth="md">
      {status && statusMessage[status] && (
        <Alert sx={{ mb: 2 }} severity={statusColor[status]}>
          {statusMessage[status]}
        </Alert>
      )}
      <Page.Paper>
        <Typography variant="h2" gutterBottom>
          {application
            ? application.current.invitedBy
              ? t('applications.actions.application')
              : t('applications.module.edit')
            : t('applications.module.new')}
        </Typography>
        {!isDone ? (
          <>
            <Stepper activeStep={currentStep} key="stepper">
              {steps().map((s, i) => {
                return (
                  <Step key={s.name.toLowerCase()}>
                    <StepLabel />
                  </Step>
                )
              })}
            </Stepper>
            <Box p={2}>
              {steps()
                .filter((s, i) => i === currentStep)
                .map((s, i) => {
                  return <Box key={s.name.toLowerCase()}>{s.component()}</Box>
                })}
            </Box>
          </>
        ) : (
          <StepSubmitted usesPayment={details.integrations.stripe && !application?.current.invitedBy}>
            {navButtons()}
          </StepSubmitted>
        )}
      </Page.Paper>
    </Page.Wrapper>
  )
}

export default Flow
