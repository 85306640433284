import { Chip, Fab, Grid, Modal, Typography, useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1500,
    height: '100vh',
    backgroundColor: 'rgba(0, 90, 160, 0.08)',
    backdropFilter: 'blur(8px) brightness(116%)',
  },
}));

export interface IProcessStep {
  label: string;
  imgSource: string;
}

interface IInstallationGuideProps {
  modalIsOpen: boolean;
  onClose: () => void;
  title: string;
  paragraphs: string[];
  steps: IProcessStep[];
}

export const InstallationGuide: React.FC<React.PropsWithChildren<IInstallationGuideProps>> = ({
  modalIsOpen,
  onClose,
  title,
  paragraphs,
  steps,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Modal open={modalIsOpen} className={classes.root}>
      <>
        <div
          style={{
            outline: 'none',
            overflowY: 'scroll',
            overflowX: 'hidden',
            position: 'fixed',
            top: 0,
            bottom: 0,
          }}
        >
          <Grid container={true} justifyContent="center" style={{ padding: '16px 16px' }}>
            <Grid item={true} xs={12} style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Typography variant={isXs ? 'h5' : 'h4'} component="h1">
                {title}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={8} style={{ marginBottom: '16px' }}>
              {paragraphs.map((paragraph, index) => {
                return (
                  <Typography
                    key={`paragraph-${index + 1}`}
                    variant="body1"
                    style={index < paragraphs.length - 1 ? { marginBottom: '8px' } : {}}
                  >
                    {paragraph}
                  </Typography>
                );
              })}
            </Grid>
            <Grid item={true} xs={12} md={8} style={{ marginBottom: '16px' }}>
              <Grid container={true} spacing={2}>
                {steps.map((step, index) => {
                  return (
                    <Grid key={`process-step-${index + 1}`} item={true} xs={12} sm={6} lg={3}>
                      <Grid
                        container={true}
                        spacing={2}
                        alignItems="center"
                        style={{ marginBottom: '8px', minHeight: '64px' }}
                      >
                        <Grid item={true} style={{ width: '40px' }}>
                          <Chip label={index + 1} color="primary" />
                        </Grid>
                        <Grid item={true} style={{ width: '75%' }}>
                          <Typography variant="body1">{step.label}</Typography>
                        </Grid>
                      </Grid>
                      <img src={step.imgSource} alt="" style={{ width: '100%' }} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Fab
          color="default"
          aria-label="Close"
          size="small"
          onClick={onClose}
          style={{
            position: 'fixed',
            top: '16px',
            right: '16px',
            backgroundColor: 'rgba(104, 104, 104, 0.5)',
            color: 'white',
          }}
        >
          <Close />
        </Fab>
      </>
    </Modal>
  );
};

export default InstallationGuide;
