import React from 'react'
import { Navigation, requiresTenant, requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { withActiveTenant } from '@app/routes/WithActiveTenant'
import { withServices } from '@app/routes/withServices'
import { AccessDependencies } from '@app/Content'
import { PATH_TENANT_BASE, Permissions, PERMISSIONS } from '@app/constants'
import { withParam } from '@app/routes/withParam'
import { isPort } from '@app/lib/access'
import { Trans } from 'react-i18next'
import { Vessel as IconVessel } from '@griegconnect/krakentools-react-icons'

export const vesselsModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.settings.vessels" />,
    path: PATH_TENANT_BASE + '/vessels',
    exact: true,
    menu: {
      icon: <IconVessel />,
    },
    access: (d) =>
      isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.vessels), true)(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.settings.vessels" />,
        path: PATH_TENANT_BASE + '/vessels',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('.'))))),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.vessels), true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.vessel" />,
        path: PATH_TENANT_BASE + '/vessels/new',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./Vessel/New'))))),
        exact: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.vessels), true)(d),
      },
      {
        name: <Trans i18nKey="navigation.settings.vessel" />,
        path: PATH_TENANT_BASE + '/vessels/edit/:id',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Vessel')),
                'id'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.vessels), true)(d),
      },
      {
        name: <Trans i18nKey="security.labels.dos" />,
        path: PATH_TENANT_BASE + '/dos',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./DoS/List'))))),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.dos), true)(d),
      },
      {
        name: <Trans i18nKey="security.labels.dos" />,
        path: PATH_TENANT_BASE + '/dos/new',
        page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./DoS/VesselDosForm'))))),
        exact: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.dos), true)(d),
      },
      {
        name: <Trans i18nKey="security.labels.dos" />,
        path: PATH_TENANT_BASE + '/vessels/dos/:id',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./DoS/DoSEdit')),
                'id'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          isPort(d) &&
          requiresTenant(d) &&
          requiresAllOfPermissions([...Permissions.all(PERMISSIONS.vessels), ...Permissions.all(PERMISSIONS.dos)])(d),
      },
    ],
  },
]
