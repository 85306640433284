import { Box, Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Paragraph } from 'src/common/Paragraph'
import { NextState, StepProps, applicationState, nextStateAtom } from '.'
import { ApplicationFormData } from '../schema'
import { useBreakpointUp } from '@griegconnect/krakentools-react-kraken-app'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { FileDownloadButton } from 'src/common/FileDownloadButton'
import FlowHeader from '../Components/FlowHeader'
import FlowNavigation from '../Components/FlowNavigation'
import FlowPage from '../Components/FlowPage'
import ScrollInfo from '../Components/ScrollInfo'

const StepConfirmations: React.FC<StepProps> = ({ formValues, formType, resetForm, portDetails }) => {
  const { t } = useTranslation()
  const setState = useSetRecoilState<NextState>(nextStateAtom)
  const isBig = useBreakpointUp('sm')
  const [applicationFormData, setApplicationFormData] = useRecoilState<ApplicationFormData>(applicationState)
  const [reachedBottom, setReachedBottom] = useState(false)

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      setReachedBottom(true)
    }
  }, [inView])

  const onNext = () => {
    setApplicationFormData((prev) => ({
      ...prev,
      confirmations: { securityConfirmations: portDetails.securityConfirmations.map((c) => c.id) },
    }))

    setState((p) => ({ ...p, currentStep: p.currentStep + 1 }))
  }

  const handleReset = () => resetForm && resetForm()

  // const onBack = () => setState((p) => ({ ...p, currentStep: p.currentStep - 1 }))

  return (
    <Box width={'100%'} padding={0}>
      <FlowPage>
        <FlowHeader i18n="applications.paragraphs.confirmTerms" />
        <Box mb={'60px'}>
          {portDetails.securityConfirmations.map((c, i) => (
            <Box key={c.id + '' + i} sx={{ padding: 0, marginBottom: { xs: '0.4em', sm: '0.8em' } }}>
              <Paragraph variant="subtitle1" sx={{ fontWeight: 450 }}>
                {c.title}
              </Paragraph>
              <Paragraph variant="body2" m={0} p={0}>
                {c.details}
              </Paragraph>
              {c.document && (
                <Box mt={1}>
                  <FileDownloadButton
                    size={isBig ? 'large' : 'small'}
                    resolvedFile={c.document}
                    label={t('common.actions.attachment')}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <div ref={ref}></div>
      </FlowPage>
      <ScrollInfo reachedBottom={reachedBottom} />
      <FlowNavigation>
        <Stack direction="row" width="100%">
          {formType === 'new' && (
            <Box display="flex" justifyContent="flex-start" width="100%">
              <Button color="inherit" onClick={handleReset}>
                {t('applications.actions.reset')}
              </Button>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end" width="100%">
            {/*  <Button variant="outlined" onClick={onBack} style={{ marginRight: 10 }}>
              {t('common.actions.back')}
            </Button> */}
            <Button
              variant="contained"
              type="submit"
              onClick={onNext}
              disabled={!reachedBottom && applicationFormData.confirmations.securityConfirmations.length === 0}
            >
              {t('applications.actions.acceptTerms')}
            </Button>
          </Box>
        </Stack>
      </FlowNavigation>
    </Box>
  )
}

export default StepConfirmations
