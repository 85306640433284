import React, { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@griegconnect/krakentools-react-ui'
import {
  Box,
  Button,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox as CheckboxMUI,
  FormLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeDto } from '@app/common/ServicesWrapper/apis/StripeApi'
import { loadStripe } from '@stripe/stripe-js'
import { WithServicesProps } from '@app/routes/withServices'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import { SecurityIdentity } from '@app/common/ServicesWrapper/apis/dtos/IdentityAttributes'
import UseQueries from '@app/common/UseQueries'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import * as Form from '@griegconnect/krakentools-form'
import { FormProvider, useForm } from 'react-hook-form'

interface Props extends WithServicesProps {
  apiPublicKey?: string
  me: SecurityIdentity
  port: string
  disabled: boolean
  invoice?: StripeDto.Invoice
  setInvoice: (i: StripeDto.Invoice | undefined) => void
  setSetupIntent: (i: StripeDto.SetupIntent | undefined) => void
  setupIntent?: StripeDto.SetupIntent
  onBack: () => void
  onNext: (invoice: string) => void
  formLoading: boolean
  setFormLoading: (b: boolean) => void
}

export const StepStripePayment: React.FC<React.PropsWithChildren<Props>> = ({
  services,
  port,
  onNext,
  me,
  onBack,
  invoice,
  setSetupIntent,
  setupIntent,
  setInvoice,
  disabled,
  formLoading,
  setFormLoading,
  children,
}) => {
  const { t } = useTranslation()
  const { enqueue } = useAlerts()

  const { data: stripePromise } = useQuery(['stripe-config', port], async () => {
    const installation = await services.stripeApi.getPublicConfig(port)
    if (installation.apiPublicKey) return loadStripe(installation.apiPublicKey)
    else return undefined
  })

  const handleSelectProducts = async (priceIds: string[]) => {
    const customer = {
      name: me.identity.name,
      email: me.identity.email,
      address: { country: 'NO' },
    }

    setFormLoading(true)
    const intent = await services.stripeApi.initializeSetupIntent(port, customer)
    const invoice = await services.stripeApi.createVisitorApplicationInvoice(port, intent.customer, priceIds)
    setInvoice(invoice)
    setSetupIntent(intent)
    setFormLoading(false)
  }

  const handleSetupIntentConfirmed = async () => {
    if (invoice) {
      const finalizedInvoice = await services.stripeApi.finalizeVisitorApplicationInvoice(port, invoice.id)
      onNext(finalizedInvoice.id)
    } else {
      enqueue(t('integrations.errors.invoiceNotFound'), 'error')
    }
  }

  return (
    <>
      <Typography>{t('applications.subTitle.payment')} </Typography>
      <Box pb={2} />

      {invoice && setupIntent && stripePromise ? (
        <>
          <Box>
            {invoice && (
              <InvoiceTable customerId={setupIntent.customer} port={port} services={services} invoice={invoice} />
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" padding={2}>
            <Button onClick={() => setInvoice(undefined)} variant="outlined">
              {t('applications.actions.changeProductSelection')}
            </Button>
          </Box>
          <Elements stripe={stripePromise} options={{ clientSecret: setupIntent.client_secret }}>
            <SetupIntentForm
              disabled={disabled}
              onSetupIntentConfirmed={handleSetupIntentConfirmed}
              onBack={onBack}
              setFormLoading={setFormLoading}
            >
              {children}
            </SetupIntentForm>
          </Elements>
        </>
      ) : (
        <ProductView
          port={port}
          services={services}
          confirmSelectedProducts={handleSelectProducts}
          formLoading={formLoading}
          setFormLoading={setFormLoading}
          onBack={onBack}
        />
      )}
    </>
  )
}

interface SetupProps {
  onSetupIntentConfirmed: () => void
  onBack: () => void
  children?: React.ReactNode
  disabled: boolean
  setFormLoading: (b: boolean) => void
}

const SetupIntentForm: React.VFC<SetupProps> = ({ onSetupIntentConfirmed, children, setFormLoading }) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
  const stripe = useStripe()
  const elements = useElements()
  const { enqueue } = useAlerts()
  const { t } = useTranslation()
  const theme = useTheme()

  elements?.update({ appearance: { theme: theme.palette.mode === 'dark' ? 'night' : 'stripe' } })

  const handleSubmitClick = async (event) => {
    event.preventDefault()
    setConfirmDialogOpen(true)
  }

  const handleStripePayment = async () => {
    if (!stripe || !elements) {
      return
    }
    setFormLoading(true)
    const confirmationResponse = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })

    if (confirmationResponse.error) {
      setFormLoading(false)
      enqueue(t('integrations.errors.paymentFailed'), 'error')
    } else {
      enqueue(t('integrations.success.paymentConfirmed'), 'success')
      onSetupIntentConfirmed()
    }
  }

  return (
    <>
      <form onSubmit={handleSubmitClick}>
        <PaymentElement
          options={{ layout: 'tabs', readOnly: false, wallets: { applePay: 'auto', googlePay: 'auto' } }}
        />
        <Box display="flex" justifyContent="flex-end" mt={4}>
          {children}
        </Box>
      </form>
      <Dialog open={confirmDialogOpen}>
        <DialogTitle>{t('integrations.labels.paymentConfirmation')}</DialogTitle>
        <DialogContent>{t('integrations.paragraphs.paymentConfirmation')}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmDialogOpen(false)}>
            {t('common.actions.decline')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setConfirmDialogOpen(false)
              handleStripePayment()
            }}
          >
            {t('common.actions.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface InvoiceTableProps extends WithServicesProps {
  customerId: string
  port: string
  invoice: StripeDto.Invoice
}

const InvoiceTable: React.VFC<InvoiceTableProps> = ({ invoice }) => {
  const { t } = useTranslation()

  const itemCurrencyFormatter = new Intl.NumberFormat(undefined, {
    style: 'decimal',
    currency: invoice.currency,
    minimumFractionDigits: 2,
  })
  const totalCurrencyFormatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: invoice.currency })

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('integrations.labels.product')}</TableCell>
          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{t('integrations.labels.qty')}</TableCell>
          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
            {t('integrations.labels.excludingTax')}
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{t('integrations.labels.tax')}</TableCell>
          <TableCell>{t('integrations.labels.amount')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoice &&
          invoice.lines &&
          invoice?.lines.data.map((item) => {
            return (
              <TableRow key={item.id}>
                <TableCell>{item.description}</TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{item.quantity}</TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  {itemCurrencyFormatter.format(item.amount_excluding_tax / 100)}
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  {itemCurrencyFormatter.format((item.amount - item.amount_excluding_tax) / 100)}
                </TableCell>
                <TableCell>{itemCurrencyFormatter.format(item.amount / 100)}</TableCell>
              </TableRow>
            )
          })}
      </TableBody>

      <TableHead>
        <TableRow>
          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}></TableCell>
          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}></TableCell>
          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
            {t('integrations.labels.totalExcludingTax')}
          </TableCell>
          <TableCell>{t('integrations.labels.totalTax')}</TableCell>
          <TableCell>{t('integrations.labels.totalAmount')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoice && (
          <TableRow>
            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}></TableCell>
            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}></TableCell>
            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
              {totalCurrencyFormatter.format(invoice.subtotal_excluding_tax / 100)}
            </TableCell>
            <TableCell>{totalCurrencyFormatter.format(invoice.tax / 100)}</TableCell>
            <TableCell>{totalCurrencyFormatter.format(invoice.total / 100)}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

const _Schema = z.object({
  optionalProducts: z.array(z.string()),
})

const Schema = () => _Schema

type Input = z.infer<typeof _Schema>
const defaultProducts = (): Input => ({
  optionalProducts: [],
})

interface ProductViewProps extends WithServicesProps {
  port: string
  onBack: () => void
  formLoading: boolean
  setFormLoading: (b: boolean) => void
  confirmSelectedProducts: (priceIds: string[]) => void
}

const ProductView: React.FC<ProductViewProps> = ({ port, services, confirmSelectedProducts, onBack, formLoading }) => {
  const { t } = useTranslation()
  const form = useForm({
    defaultValues: defaultProducts(),
    resolver: zodResolver(Schema()),
  })

  const products = useQuery<{ fixed: StripeDto.Product[]; optional: StripeDto.Product[] }>(
    [`visitor-application-products-${port}`],
    async () => {
      const data = await services.stripeApi.visitorApplicationProducts(port)
      const fixed = data.required
      const optional = data.optional
      return { fixed, optional }
    }
  )
  const onSubmit = async () => {
    const input = form.getValues()
    confirmSelectedProducts(input.optionalProducts)
  }

  return (
    <>
      <UseQueries queries={{ products }}>
        {({ products: { fixed, optional } }) => {
          const priceCurrencyFormatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: fixed.at(0)?.default_price.currency || optional.at(0)?.default_price.currency || 'nok',
            minimumFractionDigits: 2,
          })
          return (
            <>
              {fixed.map((p) => {
                return (
                  <FormGroup key={p.name}>
                    <FormLabel>{t('integrations.labels.fixedProducts')}</FormLabel>
                    <FormControlLabel
                      control={<CheckboxMUI color="primary" disabled={true} />}
                      checked={true}
                      label={`${p.name} - ${priceCurrencyFormatter.format(p.default_price.unit_amount / 100)} - (${
                        p.default_price.tax_behavior === 'inclusive'
                          ? t('integrations.labels.includingTax')
                          : p.default_price.tax_behavior === 'exclusive'
                          ? t('integrations.labels.excludingTax').toLowerCase()
                          : t('integrations.labels.unspecifiedTaxBehavior')
                      })`}
                    />
                  </FormGroup>
                )
              })}
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <Form.CheckboxMulti
                    label={t('integrations.labels.optionalProducts')}
                    control={form.control}
                    formValues={form.getValues().optionalProducts}
                    name="optionalProducts"
                    values={(optional || []).map((p) => ({
                      id: p.default_price.id,
                      label: `${p.name} - ${priceCurrencyFormatter.format(p.default_price.unit_amount / 100)} - (${
                        p.default_price.tax_behavior === 'inclusive'
                          ? t('integrations.labels.includingTax')
                          : p.default_price.tax_behavior === 'exclusive'
                          ? t('integrations.labels.excludingTax').toLowerCase()
                          : t('integrations.labels.unspecifiedTaxBehavior')
                      })`,
                    }))}
                  />
                  <FormHelperText>{t('applications.subTitle.selectOptionalProducts')}</FormHelperText>
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="outlined" onClick={onBack} style={{ marginRight: 10 }} disabled={formLoading}>
                      {t('common.actions.back')}
                    </Button>
                    <Button variant={'contained'} onClick={onSubmit} disabled={formLoading}>
                      {t('applications.actions.confirmStripeProducts')}
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </>
          )
        }}
      </UseQueries>
    </>
  )
}
export default StepStripePayment
