import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export interface ErrorHelperTextProps {
  error: string
}

export const ErrorHelperText: React.FC<React.PropsWithChildren<ErrorHelperTextProps>> = ({ error }) => {
  const { t } = useTranslation()
  return (
    <Box display="flex" alignItems="center" pt={1}>
      <ErrorOutlineIcon color="error" />
      <Box pr={1} />
      <Typography color="error" variant="caption">
        {t(error)}
      </Typography>
    </Box>
  )
}
