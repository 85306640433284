import { requiresAllOfPermissions, useAuth } from '@griegconnect/krakentools-react-kraken-app'
import { useLocation } from 'react-router-dom'
import { Services } from '@app/common/ServicesWrapper/types'
import { WithActiveTenant } from '../WithActiveTenant'
import { portConfigStore } from '@app/stores/portStore'
import { PortConfig } from '@app/common/ServicesWrapper/apis/SharedDataApi'
import { useRecoilState } from 'recoil'
import { useEffect } from 'react'
import { PERMISSIONS } from '@app/constants'
import { Box, Typography } from '@mui/material'
import PfsoDashboard from './Presets/Pfso'
import CsoDashboard from './Presets/Cso'
import GeneralDashboard from './Presets/General'
import { UiSelect } from '@app/common/UiSelect'
import React from 'react'
import md5 from 'blueimp-md5'
import { useTranslation } from 'react-i18next'

const portPresets = [
  { id: 'p1', name: 'PFSO' },
  { id: 'p2', name: 'General' },
]

const companyPresets = [{ id: 'c1', name: 'CSO' }]

interface Props extends WithActiveTenant {
  services: Services
}

export const Home = ({ activeTenant, services }: Props) => {
  const location = useLocation()
  const user = useAuth()
  const hash = md5(user.user!.email)
  const storedPreset = JSON.parse(localStorage.getItem(`${activeTenant.ref}-${hash}-dashboard-preset`) || '[]')
  const [portConfig, setPortConfig] = useRecoilState<PortConfig>(portConfigStore)
  const [preset, setPreset] = React.useState<string>('')
  const { t } = useTranslation()

  useEffect(() => {
    const go = async () => {
      const config = await services.sharedDataApi.getConfig(activeTenant.ref)
      setPortConfig(config)
    }
    go()
  }, [activeTenant, services, setPortConfig])

  useEffect(() => {
    setPreset(storedPreset?.length > 0 ? storedPreset[0] : portConfig.isPort ? 'p2' : 'c1')
  }, [storedPreset, portConfig.isPort])

  const selectedPreset = [...companyPresets, ...portPresets].find((p) => p.id === preset)

  const changePreset = (value?: string) => {
    if (value) {
      setPreset(value)
      localStorage.setItem(`${activeTenant.ref}-${hash}-dashboard-preset`, JSON.stringify([value]))
    }
  }

  const presetSelect = (type: 'port' | 'company') => (
    <UiSelect
      label={t('components.dashboard.labels.preset')}
      value={preset}
      selectItems={type === 'port' ? portPresets : companyPresets}
      onChange={changePreset}
    />
  )

  return (
    <>
      {location.pathname.indexOf('/companies/') !== -1 &&
      activeTenant &&
      activeTenant.ref &&
      requiresAllOfPermissions([PERMISSIONS.visitor_stats.read, PERMISSIONS.passing_stats.read])({
        activeTenant,
      }) ? (
        portConfig.isPort ? (
          <>
            {selectedPreset?.name === 'PFSO' ? (
              <PfsoDashboard activeTenant={activeTenant}>{presetSelect('port')}</PfsoDashboard>
            ) : (
              <GeneralDashboard activeTenant={activeTenant}>{presetSelect('port')}</GeneralDashboard>
            )}
          </>
        ) : (
          <CsoDashboard activeTenant={activeTenant}>{presetSelect('company')}</CsoDashboard>
        )
      ) : (
        <Box display="flex" mt={15} alignItems="center" justifyContent="center">
          <Typography variant="h4">We have no available data to show.</Typography>
        </Box>
      )}
    </>
  )
}

export default Home
