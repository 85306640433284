import { Box } from '@mui/material'
import { Page as P } from '@griegconnect/krakentools-react-ui'
import React from 'react'
import { Services } from '@app/common/ServicesWrapper/types'
import { Button } from '@griegconnect/krakentools-react-ui'
import SaveIcon from '@mui/icons-material/Save'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { ProfileAttributesForm } from '.'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import { FileUpload } from '../Form'
import { useTranslation } from 'react-i18next'

type ProfileProps = {
  services: Services
  form: UseFormReturn<ProfileAttributesForm.Inputs>
  refresh: () => void
}

const ProfileIdDocument: React.FC<React.PropsWithChildren<ProfileProps>> = ({ services, form, refresh }) => {
  const { enqueue } = useAlerts()
  const { t } = useTranslation()

  const filesArray = useFieldArray({
    control: form.control,
    name: 'idDocument',
  })

  const updateProfile = async () => {
    const inputForm = ProfileAttributesForm.toForm(form.getValues())
    try {
      await services.userApi.updateProfile(
        inputForm.idDocument,
        inputForm.company,
        inputForm.symmetryBlue?.toString() || undefined,
        inputForm.pin,
        inputForm.cardNumbers
      )
      enqueue('ID document updated!', 'success')
      refresh()
    } catch (err) {
      enqueue('Error updating profile', 'error')
    }
  }

  return (
    <P.Wrapper title={t('components.applications.files.personId.title')}>
      <P.Paper>
        <FileUpload
          arrayMethods={filesArray}
          control={form.control}
          name={`idDocument`}
          title={t('components.applications.files.personId.title')}
          kind="photos"
          uploadLabel={t('components.applications.files.personId.upload')}
          modifyLabel={t('components.applications.files.personId.change')}
        />
        <Box m={2} />
        <Button variant="contained" onClick={updateProfile} endIcon={<SaveIcon />}>
          Save
        </Button>
      </P.Paper>
    </P.Wrapper>
  )
}
export default ProfileIdDocument
