import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgBooth = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      fillRule="evenodd"
      d="M22 2a1 1 0 0 0-1.447-.894L6.763 8H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6.382l11.447-5.724A1 1 0 0 0 22 6V2ZM8 10v8H4v-8h4Zm2 .382 2-1V7.618L9.937 8.65c.04.109.063.227.063.35v1.382Zm6-3-2 1V6.618l2-1v1.764Zm2-1 2-1V3.618l-2 1v1.764Z"
      clipRule="evenodd"
    />
  )
export default SvgBooth
