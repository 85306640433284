import { Button, Box, IconButton, Grid } from '@mui/material'
import React from 'react'
import { Page as P } from '@griegconnect/krakentools-react-ui'
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Services } from '@app/common/ServicesWrapper/types'
import { FormProvider, useFieldArray, UseFormReturn } from 'react-hook-form'
import * as Form from '@griegconnect/krakentools-form'
import { ProfileAttributesForm } from '.'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import { IdentityAttributes } from '../ServicesWrapper/apis/dtos/IdentityAttributes'

interface CardNumbersProps {
  services: Services
  refresh: () => void
  attributes?: IdentityAttributes
  form: UseFormReturn<ProfileAttributesForm.Inputs>
}

export const CardNumbersForm: React.FC<React.PropsWithChildren<CardNumbersProps>> = ({
  services,
  form,
  refresh,
  attributes,
}) => {
  const { enqueue } = useAlerts()

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'cardNumbers',
  })

  const disableAddCard = () => {
    return (attributes?.cardNumbers?.length || 0) < fields.length
  }

  const updateProfile = async () => {
    const inputForm = ProfileAttributesForm.toForm(form.getValues())
    try {
      await services.userApi.updateProfile(
        inputForm.idDocument,
        inputForm.company,
        inputForm.symmetryBlue?.toString() || undefined,
        inputForm.pin,
        inputForm.cardNumbers
      )
      enqueue('Card numbers updated', 'success')
    } catch (err) {
      enqueue('Error updating profile', 'error')
    }
    refresh()
  }

  const deleteCard = (cardIndex: number) => {
    remove(cardIndex)
  }

  return (
    <FormProvider {...form}>
      <P.Wrapper title="Card numbers">
        <Box m={1} />
        <P.Paper>
          {fields.map((item, index) => {
            return (
              <>
                <Grid container xs={12} style={{ border: 'solid black 2 px ' }}>
                  <Grid item xs={10}>
                    <Form.TextInput
                      name={`cardNumbers[${index}].cardNumber`}
                      type="number"
                      label="Card number"
                      control={form.control}
                      required
                    />
                  </Grid>
                  <Grid item alignContent="center" alignSelf="center" padding={1}>
                    <IconButton onClick={() => deleteCard(index)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            )
          })}
        </P.Paper>
        <Box m={2} />
        <Box display="flex" flex="1" justifyContent="space-between">
          <Button
            onClick={() => append({ cardNumber: null })}
            variant="outlined"
            disabled={disableAddCard()}
            endIcon={<AddIcon />}
            style={{ marginRight: 8 }}
          >
            Add card
          </Button>
          <Button variant="contained" color="primary" onClick={updateProfile} endIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </P.Wrapper>
    </FormProvider>
  )
}

export default CardNumbersForm
