import * as React from 'react'
import { DateTime } from 'luxon'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField'
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers/models'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

interface IconButtonFieldProps
  extends UseDateFieldProps<DateTime>,
    BaseSingleInputFieldProps<DateTime | null, DateTime, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  sx?: IconButtonProps['sx']
  size?: IconButtonProps['size']
  children: IconButtonProps['size']
}

const IconButtonField = (props: IconButtonFieldProps) => {
  const { setOpen, id, disabled, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props

  return (
    <IconButton
      sx={props.sx}
      size={props.size}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {props.children}
    </IconButton>
  )
}

type IconButtonDatePickerProps = Omit<DatePickerProps<DateTime>, 'open' | 'onOpen' | 'onClose' | 'label'> & {
  size?: IconButtonProps['size']
  sx?: IconButtonProps['sx']
  children: IconButtonProps['children']
}

const IconButtonDatePicker = (props: IconButtonDatePickerProps) => {
  const [open, setOpen] = React.useState(false)

  return (
    <DatePicker
      slots={{ field: IconButtonField, ...props.slots }}
      slotProps={{ field: { setOpen, sx: props.sx, size: props.size, children: props.children } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
}

export default IconButtonDatePicker
