import { TextField } from '@mui/material'
import React from 'react'
import { Control, Controller, get, useFormState } from 'react-hook-form'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'

interface NumberPlateProps {
  name: string
  control: Control<any>
  label: string
  required?: boolean
  disabled?: boolean
}
export const NumberPlate: React.VFC<NumberPlateProps> = ({ name, control, label, required, disabled }) => {
  const { errors } = useFormState()
  const error = get(errors, name)

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            label={label}
            required={required}
            variant="filled"
            disabled={disabled}
            fullWidth={true}
            {...field}
            onChange={(e) => field.onChange(e.target.value.toUpperCase())}
          />
        )}
      />
      {error && error.message && <ErrorHelperText error={error.message} />}
    </>
  )
}
