import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgTime = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M11 12.5a1 1 0 0 0 .553.894l3 1.5a1 1 0 1 0 .894-1.788L13 11.882V8a1 1 0 1 0-2 0v4.5Z" />,
    <path
      fillRule="evenodd"
      d="M2 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12Zm2 0c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8-8 3.59-8 8Z"
      clipRule="evenodd"
    />
  )
export default SvgTime
