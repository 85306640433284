import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CheckCircleOutline } from '@mui/icons-material'

interface Props {
  children?: React.ReactNode
  usesPayment: boolean
}

function StepSubmitted({ children, usesPayment }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" padding={4}>
        <CheckCircleOutline color="success" style={{ fontSize: '40' }} />
        <Box mb={2} />
        <Typography variant="h4">{t('applications.status.submitted')}</Typography>
        <Box mb={1} />
        {usesPayment && (
          <Typography variant="body1" style={{ opacity: '0.6' }}>
            {t('applications.paragraphs.paymentRegistered')}
          </Typography>
        )}
        <Typography variant="body1" style={{ opacity: '0.6' }}>
          {t('applications.paragraphs.submitted')}
        </Typography>
      </Box>
      {children}
    </>
  )
}

export default StepSubmitted
