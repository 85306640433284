import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgAssetCrane = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M13.293 2.293A1 1 0 0 1 14 2h4a1 1 0 0 1 1 1v2h1a1 1 0 1 1 0 2h-1v13a1 1 0 1 1 0 2h-6a1 1 0 1 1 0-2V7H7v5H5V7H4a1 1 0 0 1 0-2h9V3a1 1 0 0 1 .293-.707ZM15 20h2V7h-2v13Zm2-16h-2v1h2V4Z" />,
    <path d="M4 13a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H4Z" />
  )
export default SvgAssetCrane
