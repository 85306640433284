import { TrendWidget, IWidget } from '@griegconnect/krakentools-react-ui'
import { Widgets } from '../Types'
import { DateTime, Duration, Interval } from 'luxon'
import { IChartData } from '@app/common/dashboard/LineChart'
import _ from 'lodash'
import LineChartWidget from '@app/common/dashboard/LineChartWidget'
import * as TimePeriod from '../../components/TimePeriod'
import { VehicleCar as VehicleIcon } from '@griegconnect/krakentools-react-icons'
import { Turnaround } from '@app/common/ServicesWrapper/apis/PassingsApi'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { useServices } from '@app/common/ServicesWrapper'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import * as SkeletonLoader from '../SkeletonLoader'
import { useTranslation } from "react-i18next"

const getMiddleDate = () => {
  return DateTime.now().minus({ day: 1 })
}

interface ITrendWidgetData {
  direction: 'up' | 'down'
  value: string
  change: number
}

interface IChartWidgetData {
  data: IChartData[]
  colors: string[]
}

interface ITurnaroundWidgetProps extends Widgets.InfoWithData<Turnaround>, WithActiveTenant {
  isSM: boolean
  groupings: Widgets.Grouping[]
}

export const Widget: React.VFC<ITurnaroundWidgetProps> = ({
  widgetConstructor,
  data,
  onRemove,
  onSettings,
  onDetails,
  editing,
  isSM,
  groupings,
  activeTenant,
}) => {
  const { services } = useServices()
  const { t } = useTranslation()

  const [trendData, setTrendData] = useState<ITrendWidgetData | undefined>(undefined)
  const [chartData, setChartData] = useState<IChartWidgetData | undefined>(undefined)

  const timeFrom = widgetConstructor.timeFrom
    ? Duration.fromISO((widgetConstructor.timeFrom as unknown) as string)
    : undefined
  const timeTo = widgetConstructor.timeTo
    ? Duration.fromISO((widgetConstructor.timeTo as unknown) as string)
    : undefined

  const { data: fetchedData } = useQuery<Turnaround | undefined>(
    ['fetchedDataTurnaround', activeTenant.ref, timeFrom, data],
    async () => {
      if (timeFrom) {
        const period = TimePeriod.getConstructorDates(timeFrom, widgetConstructor.currentPeriod, timeTo)
        const interval = Interval.fromDateTimes(period.fromDate, period.toDate)
        const result = await services.passingsApi.turnaroundStats(activeTenant.ref, { precision: 'hour', interval })
        return result
      } else {
        return data
      }
    }
  )

  const getTurnaroundChart = (widgetConstructor: Widgets.Constructor) => {
    let arrayLength: number = 24
    const startDate = widgetConstructor ? getMiddleDate() : DateTime.now().set({ year: 2000 }).startOf('year')
    const entries =
      (data?.buckets || [])
        .filter((c) => DateTime.fromISO(c.time) >= startDate)
        .filter((c) =>
          widgetConstructor.grouping
            ? (!c.classified && !!widgetConstructor.grouping.find((g) => g === 'Andre')) ||
              !!widgetConstructor.grouping.find((g) => g === c.classified)
            : true
        )
        .filter((c) =>
          widgetConstructor.facilities ? !!widgetConstructor.facilities.find((f) => f === c.facility.name) : true
        ) ?? []
    const classified = Object.entries(_.groupBy(entries, (c) => c.classified || 'Andre'))
    const chartData = [...classified.map(([name, values]) => ({ name, data: [...values] }))]
    const mergedData: IChartData[] = []
    const chartColors: string[] = []

    for (const info of chartData) {
      const grouping = groupings.find((g) => g.name === info.name)
      grouping?.color && chartColors.push(grouping.color)
      const byFacility = Object.entries(_.groupBy(info.data, (d) => d.facility.name))
      const dataArray: number[] = []
      for (const value of byFacility[0][1]) {
        const valueIndex = byFacility[0][1].indexOf(value)
        let newValue = [0, 0]
        for (const facility of byFacility) {
          if (facility[1][valueIndex]?.avg) {
            newValue[0] += Duration.fromISO(facility[1][valueIndex].avg ?? '').toMillis() / 60000
            newValue[1]++
          }
        }
        newValue[0] /= newValue[1]
        const finalValue = !isNaN(newValue[0]) ? Math.round(newValue[0]) : 0
        dataArray.push(finalValue)
      }
      while (dataArray.length < arrayLength) {
        dataArray.push(0)
      }
      mergedData.push({ name: info.name, data: dataArray })
    }

    const filledData = mergedData.filter((d) => d.data.find((c) => !!c))
    filledData.length === 0 && (mergedData.length = 0)

    // add flat chart lines for groupings not covered in the data
    const emptyDataArray: number[] = []
    while (emptyDataArray.length < arrayLength) {
      emptyDataArray.push(0)
    }
    const emptyGroups = groupings.filter(group => mergedData.find(m => m.name === group.name) === undefined)
    const emptyWidgetGroups = emptyGroups.filter(group => (widgetConstructor.grouping || []).find(g => g === group.name) !== undefined)
    for (const info of emptyWidgetGroups) {
      mergedData.push({ name: info.name, data: emptyDataArray })
      info?.color && chartColors.push(info.color)
    }

    return { data: mergedData, colors: chartColors }
  }

  const getWidgetTurnaround = (widgetConstructor: Widgets.Constructor) => {
    const currentTurnaroundStats = data?.buckets || []

    let turnaroundOld: [number, number] = [0, 0]
    let turnaroundNew: [number, number] = [0, 0]

    for (const stat of currentTurnaroundStats) {
      const currentDate = DateTime.fromISO(stat.time)
      const selectedGrouping = !!widgetConstructor.grouping
        ? (!stat.classified && !!widgetConstructor.grouping.find((g) => g === 'Andre')) ||
          !!widgetConstructor.grouping.find((g) => g === stat.classified)
        : true
      const selectedFacility = !!widgetConstructor.facilities
        ? !!widgetConstructor.facilities.find((f) => f === stat.facility.name)
        : true
      if (selectedGrouping && selectedFacility && currentDate < getMiddleDate()) {
        turnaroundOld[0] += stat.avg ? Duration.fromISO(stat.avg).toMillis() : 0
        stat.count > 0 && (turnaroundOld[1] += 1)
      }
      if (selectedGrouping && selectedFacility && currentDate >= getMiddleDate()) {
        turnaroundNew[0] += stat.avg ? Duration.fromISO(stat.avg).toMillis() : 0
        stat.count > 0 && (turnaroundNew[1] += 1)
      }
    }

    // divide by number of stat values to get average
    turnaroundOld[0] /= turnaroundOld[1]
    turnaroundNew[0] /= turnaroundNew[1]

    let direction: 'up' | 'down' = 'up'
    let valueDifference = `${turnaroundNew[0] - turnaroundOld[0]}`

    if (valueDifference[0] === '-') {
      valueDifference = valueDifference.substr(1)
      direction = 'down'
    }

    const percentDifference =
      turnaroundOld[0] >= 60000
        ? Math.round((+valueDifference / turnaroundOld[0]) * 100)
        : +valueDifference >= 60000
        ? 100
        : isNaN(+valueDifference) && turnaroundNew[0] >= 60000
        ? 100
        : 0

    return {
      direction: direction,
      value: !isNaN(turnaroundNew[0]) ? Duration.fromMillis(turnaroundNew[0]).toFormat('hh:mm') : '00:00',
      change: !isNaN(percentDifference) ? percentDifference : 0,
    }
  }

  useEffect(() => {
    if (fetchedData) {
      const newTrendData = getWidgetTurnaround(widgetConstructor)
      const newChartData = getTurnaroundChart(widgetConstructor)

      setTrendData(newTrendData)
      setChartData(newChartData)
    }
  }, [fetchedData, widgetConstructor]) // eslint-disable-line

  return chartData && widgetConstructor.variant === 'linechart' ? (
    <LineChartWidget
      title={widgetConstructor.name || t('components.dashboard.labels.averageTurnaroundTime') }
      description={`${
        !widgetConstructor.grouping
          ? t('components.dashboard.labels.allAvailableGroupings')
          : widgetConstructor.grouping && widgetConstructor.grouping?.length > 3
          ? `${widgetConstructor.grouping?.length} ${t('components.dashboard.labels.groupings')}`
          : widgetConstructor.grouping?.map((g, i) => `${i !== 0 ? ', ' : ''}${g}`).join('')
      } - ${
        !widgetConstructor.facilities
          ? t('components.dashboard.labels.allFacilities')
          : widgetConstructor.facilities && widgetConstructor.facilities?.length > 3
          ? `${widgetConstructor.facilities?.length} ${t('security.labels.facilities').toLowerCase()}`
          : widgetConstructor.facilities?.map((f, i) => `${i !== 0 ? ', ' : ''}${f}`).join('')
      } - ${TimePeriod.getFilterText(t('components.dashboard.labels.last1Day'), timeFrom, timeTo, widgetConstructor.currentPeriod)}`}
      data={chartData.data}
      xAxisLabels={TimePeriod.getChartLabels('last 24 hours', isSM)}
      dataLabel={'minute'}
      icon={<VehicleIcon />}
      onDetails={onDetails}
      detailsDisabled={chartData.data.filter((cd) => !!cd.data.find((d) => d !== 0)).length === 0}
      editing={editing}
      onRemove={onRemove}
      onSettings={onSettings}
      chartColors={chartData.colors}
    />
  ) : trendData && chartData && widgetConstructor.variant === 'trend' ? (
    <TrendWidget
      title={widgetConstructor.name ||  t('components.dashboard.labels.averageTurnaroundTime')}
      description={`${
        !widgetConstructor.grouping
          ? t('components.dashboard.labels.allAvailableGroupings')
          : widgetConstructor.grouping && widgetConstructor.grouping?.length > 3
          ? `${widgetConstructor.grouping?.length} ${t('components.dashboard.labels.groupings')}`
          : widgetConstructor.grouping?.map((g, i) => `${i !== 0 ? ', ' : ''}${g}`).join('')
      } - ${
        !widgetConstructor.facilities
          ? t('components.dashboard.labels.allFacilities')
          : widgetConstructor.facilities && widgetConstructor.facilities?.length > 3
          ? `${widgetConstructor.facilities?.length} ${t('security.labels.facilities').toLowerCase()}`
          : widgetConstructor.facilities?.map((f, i) => `${i !== 0 ? ', ' : ''}${f}`).join('')
      } - ${TimePeriod.getFilterText(t('components.dashboard.labels.last1Day'), timeFrom, timeTo, widgetConstructor.currentPeriod)}`}
      type="arrow"
      direction={trendData.direction}
      value={trendData.value}
      percentage={trendData.change}
      icon={<VehicleIcon />}
      onDetails={onDetails}
      detailsDisabled={chartData.data.filter((cd) => !!cd.data.find((d) => d !== 0)).length === 0}
      editing={editing}
      onRemove={onRemove}
      onSettings={onSettings}
      positiveValues={widgetConstructor.positiveValue === 'down' ? 'down' : 'up'}
    />
  ) : widgetConstructor.variant === 'trend' ? (
    <SkeletonLoader.Trend />
  ) : (
    <SkeletonLoader.LineChart />
  )
}

export const turnaroundWidget = (props: ITurnaroundWidgetProps): IWidget[] => {
  const { widgetConstructor } = props
  return widgetConstructor.variant === 'linechart'
    ? [
        {
          isResizable: true,
          height: [5],
          width: [1, 2],
          position: widgetConstructor.position,
          id: widgetConstructor.id,
          component: <Widget {...props} />,
        },
      ]
    : [
        {
          height: [4, 3, 3, 3],
          position: widgetConstructor.position,
          id: widgetConstructor.id,
          isResizable: true,
          maxWidth: 'half',
          maxHeight: 4,
          component: <Widget {...props} />,
        },
      ]
}

export default turnaroundWidget
