import { atom } from 'recoil'

export type ChecksAtomType = {
  quay: number
  facility: number
}

export const checksAtom = atom<ChecksAtomType>({
  key: 'checksState',
  default: {
    quay: 0,
    facility: 0
  },
})
