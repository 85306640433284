import { AxiosInstance } from 'axios'
import { By } from './By'
import { CheckListField, CheckListFieldForm } from './CheckListsApi'
import { IAreaDto } from './dtos/areaDto'
import { ISecurityCheckResultDto } from './dtos/securityCheckResultsDto'
import { IUserDto } from './dtos/userDto'
import Page from './Page'
import qs from 'qs'
import { DateTime } from 'luxon'
import { Search as S, Infer } from '@app/lib/hooks/useSearch'

export type Status = 'completed' | 'in_progress' | 'todo'

export declare namespace VehicleCheck {
  export interface Form {
    facility: string
    time: DateTime
    vehicle: string
    company: string
    driver: string
    result: string
    comment: string
    fields: CheckListFieldForm[]
    status: 'completed' | 'todo'
    assignee?: string,
    addPassing: boolean
    checkpoint?: string
  }
  export interface Details {
    vehicleCheck: VehicleCheck
    fields: CheckListField[]
  }
  export interface Params {
    facility?: string[]
    comment?: string[]
    deviations?: boolean
  }
}

export interface Completion {
  status: Status
  assignee?: IUserDto
}

export interface VehicleCheck {
  id: string
  facility: IAreaDto
  vehicle: string
  company: string
  driver: IUserDto
  result: ISecurityCheckResultDto
  comment: string
  modified: By
  negative: number
  time: string
  completion: Completion
}

export const ParamsSchema = {
  ...Page.schema,
  facility: S.array(S.string),
  comment: S.array(S.string),
  deviations: S.undefine(S.boolean),
}
export type Params = Infer<typeof ParamsSchema>

export class VehicleCheckApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async list(tenantRef: string, search: VehicleCheck.Params, pagination: Page.Search): Promise<Page<VehicleCheck>> {
    const params = { ...search, ...pagination }
    const response = await this.http.get(`/ports/${tenantRef}/vehicleChecks`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async create(tenant: string, form: VehicleCheck.Form): Promise<string> {
    const postData = { ...form, time: form.time.toUTC() }
    const response = await this.http.post(`/ports/${tenant}/vehicleChecks`, postData)
    return response.data.id
  }

  async get(tenant: string, id: string): Promise<VehicleCheck.Details> {
    const response = await this.http.get(`/ports/${tenant}/vehicleChecks/${encodeURIComponent(id)}`)
    return response.data
  }

  async delete(tenant: string, id: string): Promise<void> {
    await this.http.delete(`/ports/${tenant}/vehicleChecks/${encodeURIComponent(id)}`)
  }
}
