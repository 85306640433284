import * as z from 'zod'

export const ResolvedFileSchema = z
  .object({
    resolved: z.literal(true),
    id: z.string(),
    href: z.string(),
    mediatype: z.string(),
    category: z.string(),
    filename: z.string(),
  })
  .strict()

export const UnresolvedFileSchema = z
  .object({
    resolved: z.literal(false),
    id: z.string(),
  })
  .strict()

export const IFileRefDtoSchema = z.union([ResolvedFileSchema, UnresolvedFileSchema])

export type UnresolvedFile = z.infer<typeof UnresolvedFileSchema>
export type ResolvedFile = z.infer<typeof ResolvedFileSchema>
export type IFileRefDto = z.infer<typeof IFileRefDtoSchema>
