import { IsoCode } from './codes'

export type Translations = Partial<Record<IsoCode, string>>
export type IsoCodeTranslation = {
  language: Translations
  close: string
  translation: string
}
export type IsoCodeTranslations = Record<IsoCode, IsoCodeTranslation>

const translations: IsoCodeTranslations = {
  da: {
    language: {
      da: 'Dansk',
      en: 'Engelsk',
      fi: 'Finsk',
      no: 'Norsk',
      sv: 'Svensk',
    },
    close: 'Luk',
    translation: 'Oversættelse',
  },
  en: {
    language: {
      da: 'Danish',
      en: 'English',
      fi: 'Finnish',
      no: 'Norwegian',
      sv: 'Swedish',
    },
    close: 'Close',
    translation: 'Translation',
  },
  fi: {
    language: {
      da: 'Tanskaksi',
      en: 'Englantia',
      fi: 'Suomeksi',
      no: 'Norjaksi',
      sv: 'Ruotsiksi',
    },
    close: 'Kiinni',
    translation: 'Käännös',
  },
  no: {
    language: {
      da: 'Dansk',
      en: 'Engelsk',
      fi: 'Finsk',
      no: 'Norsk',
      sv: 'Svensk',
    },
    close: 'Lukk',
    translation: 'Oversettelse',
  },
  sv: {
    language: {
      da: 'Danska',
      en: 'Engelska',
      fi: 'Finska',
      no: 'Norska',
      sv: 'Svenska',
    },
    close: 'Stänga',
    translation: 'Översättning',
  },
}

export const getAllTranslations = (): IsoCodeTranslations => translations

export const translationsFor = (lang: IsoCode): IsoCodeTranslation => (lang ? translations[lang] : translations['en'])

export const languageAsString = (lang: IsoCode, code: IsoCode): string | undefined => translations[lang].language[code]
