import { headerAtom, tenantsAtom, useAuth } from '@griegconnect/krakentools-react-kraken-app'
import { Box, Button, Container, List, ListItemButton, ListItemText } from '@mui/material'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      width: '100%',
    },
    paper: {
      width: '100%',
      padding: theme.spacing(3, 2),
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(1),
    },
    description: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    lastListItem: {
      borderBottom: 'none',
    },
    refreshButtonLoading: {
      marginTop: theme.spacing(1.5),
    },
    refreshButton: {
      margin: `${theme.spacing(2)} auto 0px`,
      display: 'block',
    },
    dialog: {
      zIndex: (`${theme.zIndex.appBar - 1} !important` as unknown) as number,
      top: `${theme.mixins.toolbar.minHeight}px !important`,
    },
    hover: {
      cursor: 'pointer',
    },
  })
)

export interface SelectContextProps {
  isVisitDomain: boolean
}

export const SelectContext: React.FC<SelectContextProps> = ({ isVisitDomain }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const tenants = useRecoilValue(tenantsAtom)
  const [headerState, setHeaderState] = useRecoilState(headerAtom)
  const [isLoading, setIsLoading] = useState(true)
  const [showRedirectPage, setShowRedirectPage] = useState(false)
  const { isAuthenticated, isInitializing } = useAuth()

  const { t } = useTranslation()

  const redirectToVisit = () => {
    const n = new URL(window.location.href)
    n.hostname = window.location.hostname.replace('security', 'visit')
    n.pathname = '/user'
    window.location.href = n.href
  }
  const navigateToUser = () => {
    const defaultMode = window.location.port !== '3000'

    if (defaultMode) {
      if (isVisitDomain) {
        if (!window.location.pathname.includes('/user')) navigate('/user')
      } else {
        if (isAuthenticated) {
          setShowRedirectPage(true)
        } else {
          redirectToVisit()
        }
      }
    }
  }

  const enterAsUser = () => {
    return (
      <Paper className={`${classes.paper} ${classes.hover}`} elevation={3} style={{ padding: 0 }}>
        <Button
          variant="text"
          fullWidth
          style={{ textTransform: 'initial', padding: 30 }}
          onClick={() => redirectToVisit()}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h2" className={classes.title}>
              {t('navigation.user.enterAsUser')}
            </Typography>
            <Typography
              gutterBottom={false}
              style={{ marginBottom: 0 }}
              color="textSecondary"
              variant="subtitle1"
              component="div"
              className={classes.description}
            >
              {t(`navigation.user.enterAsUserDesc`)}
            </Typography>
          </Box>
        </Button>
      </Paper>
    )
  }

  useEffect(() => {
    if (!isInitializing) {
      // Redirect if no tenants
      if (tenants.length === 0) {
        navigateToUser()
      } else {
        setIsLoading(false)
      }

      setHeaderState({ ...headerState, hideTenantSelector: true })
      return () => setHeaderState({ ...headerState, hideTenantSelector: false })
      // eslint-disable-next-line
    }
  }, [isInitializing]) // eslint-disable-line react-hooks/exhaustive-deps

  if (showRedirectPage) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        {enterAsUser()}
      </Container>
    )
  }

  if (isLoading) {
    return <></>
  }
  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      {enterAsUser()}
      <Box mt={4} />
      <Paper className={classes.paper} elevation={3}>
        <>
          <Typography variant="h2" className={classes.title}>
            {t(`navigation.user.enterAsCompany`)}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" component="div" className={classes.description}>
            {tenants.length > 0 && <p>{t(`navigation.user.enterAsCompanyDesc`)}</p>}
          </Typography>
          {tenants.length > 0 && (
            <List>
              {tenants.map((tenant, index) => (
                <ListItemButton
                  key={`${index}-${tenant.ref}`}
                  className={index === tenants.length - 1 ? classes.lastListItem : ''}
                  onClick={() => navigate('/companies/' + tenant.ref)}
                  divider={true}
                >
                  <ListItemText>{tenant.name}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          )}
        </>
      </Paper>
    </Container>
  )
}

export default SelectContext
