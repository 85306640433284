import { IWidget, ProgressWidget } from '@griegconnect/krakentools-react-ui'
import { Widgets } from '../Types'
import { IReport } from '../../controlReport/ControlReportContainer'
import { Person as PersonIcon } from '@griegconnect/krakentools-react-icons'
import { VehicleCar as VehicleIcon } from '@griegconnect/krakentools-react-icons'
import { SecurityCheck as ControlIcon } from '@griegconnect/krakentools-react-icons'
import { VisitorStats } from '@app/common/ServicesWrapper/apis/VisitorStatsApi'
import { useEffect, useState } from 'react'
import { useServices } from '@app/common/ServicesWrapper'
import { useQuery } from '@tanstack/react-query'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { DateTime, Duration } from 'luxon'
import * as TimePeriod from '../../components/TimePeriod'
import * as SkeletonLoader from '../SkeletonLoader'
import { useTranslation } from "react-i18next"

const getPercentage = (value: number, total: number) => {
  const percent = (value / total) * 100
  return isFinite(percent) ? percent : 100
}

interface IProgressData {
  personPercent: number
  vehiclePercent: number
  resolvedPersons: boolean
  resolvedVehicles: boolean
}

export const Widget: React.VFC<Widgets.InfoWithData<VisitorStats[]> & WithActiveTenant> = ({
  widgetConstructor,
  data,
  onRemove,
  onSettings,
  onDetails,
  editing,
  activeTenant,
}) => {
  const { services } = useServices()
  const { t } = useTranslation()

  const [progressData, setProgressData] = useState<IProgressData | undefined>(undefined)

  const timeFrom = widgetConstructor.timeFrom
    ? Duration.fromISO((widgetConstructor.timeFrom as unknown) as string)
    : undefined
  const timeTo = widgetConstructor.timeTo
    ? Duration.fromISO((widgetConstructor.timeTo as unknown) as string)
    : undefined

  const { data: fetchedData } = useQuery<VisitorStats[] | undefined>(
    ['fetchedDataControls', activeTenant.ref, timeFrom, data],
    async () => {
      if (timeFrom) {
        const period = TimePeriod.getConstructorDates(timeFrom, widgetConstructor.currentPeriod, timeTo)
        const result = await services.visitorStatsApi.get(
          activeTenant.ref,
          period.fromDate.startOf('day').toJSDate(),
          period.toDate.endOf('day').toJSDate()
        )
        return result
      } else {
        return data
      }
    }
  )

  useEffect(() => {
    if (fetchedData) {
      const reports: IReport[] = fetchedData.map((d) => {
        const area = d.facility
        return {
          id: area.id,
          name: area.name,
          passingsPerson: d.persons.passed >= 0 ? d.persons.passed : 0,
          passingsVehicle: d.vehicles.passed >= 0 ? d.vehicles.passed : 0,
          controlsPerson: d.persons.checked >= 0 ? d.persons.checked : 0,
          controlsVehicle: d.vehicles.checked >= 0 ? d.vehicles.checked : 0,
          targetPerson: d.persons.target,
          targetVehicle: d.vehicles.target,
        }
      })

      const summaryReport: IReport = {
        passingsPerson: 0,
        passingsVehicle: 0,
        controlsPerson: 0,
        controlsVehicle: 0,
      }

      let resolvedPersons = true
      let resolvedVehicles = true

      const filteredReports = reports.filter((r) =>
        widgetConstructor?.facilities ? widgetConstructor.facilities.find((f) => f === r.name) : true
      )

      if (filteredReports) {
        for (const report of filteredReports) {
          summaryReport.passingsPerson += report.passingsPerson
          summaryReport.passingsVehicle += report.passingsVehicle
          summaryReport.controlsPerson += report.controlsPerson
          summaryReport.controlsVehicle += report.controlsVehicle
          const personTarget = report.targetPerson ? report.targetPerson * 100 : 0
          const vehicleTarget = report.targetVehicle ? report.targetVehicle * 100 : 0
          const personPercent = getPercentage(report.controlsPerson, report.passingsPerson)
          const vehiclePercent = getPercentage(report.controlsVehicle, report.passingsVehicle)
          if (personTarget && personPercent < personTarget) {
            resolvedPersons = false
          }
          if (vehicleTarget && vehiclePercent < vehicleTarget) {
            resolvedVehicles = false
          }
        }
      }

      const totalPersonPercent = getPercentage(summaryReport.controlsPerson, summaryReport.passingsPerson)
      const totalVehiclePercent = getPercentage(summaryReport.controlsVehicle, summaryReport.passingsVehicle)

      setProgressData({
        personPercent: totalPersonPercent,
        vehiclePercent: totalVehiclePercent,
        resolvedPersons,
        resolvedVehicles,
      })
    }
  }, [widgetConstructor.facilities, fetchedData])

  const info = {
    persons: {
      title: t('security.labels.persons'),
    },
    vehicles: {
      title: t('security.labels.vehicles'),
    },
    quays: {
      title: t('security.labels.quays'),
    },
    facilities: {
      title: t('security.labels.facilities'),
    }
  }

  return progressData && widgetConstructor.subVariant ? (
    <ProgressWidget
      title={widgetConstructor.name || t('components.dashboard.labels.controlPercentage')}
      description={`${info[widgetConstructor.subVariant].title} - ${
        !widgetConstructor.facilities
          ? t('components.dashboard.labels.allFacilities')
          : widgetConstructor.facilities && widgetConstructor.facilities?.length > 3
          ? `${widgetConstructor.facilities?.length} facilities`
          : widgetConstructor.facilities?.map((f, i) => `${i !== 0 ? ', ' : ''}${f}`).join('')
      } - ${TimePeriod.getFilterText(
        `from ${DateTime.now().setLocale('en-gb').startOf('year').toLocaleString({ month: 'long', day: '2-digit' })}`,
        timeFrom,
        timeTo,
        widgetConstructor.currentPeriod
      )}`}
      value={widgetConstructor.subVariant === 'persons' ? progressData.personPercent : progressData.vehiclePercent}
      resolved={
        widgetConstructor.subVariant === 'persons' ? progressData.resolvedPersons : progressData.resolvedVehicles
      }
      icon={<ControlIcon />}
      onDetails={onDetails}
      detailsDisabled={
        (!progressData.vehiclePercent && !progressData.personPercent) ||
        (progressData.vehiclePercent === 100 && progressData.personPercent === 100)
      }
      progressIcon={widgetConstructor.subVariant === 'persons' ? <PersonIcon /> : <VehicleIcon />}
      editing={editing}
      onRemove={onRemove}
      onSettings={onSettings}
    />
  ) : (
    <SkeletonLoader.Progress />
  )
}

export const controlReportVisitorWidget = (
  props: Widgets.InfoWithData<VisitorStats[]> & WithActiveTenant
): IWidget[] => {
  const { widgetConstructor } = props
  return widgetConstructor.subVariant
    ? [
        {
          height: [5],
          position: widgetConstructor.position,
          id: widgetConstructor.id,
          component: <Widget {...props} />,
        },
      ]
    : []
}

export default controlReportVisitorWidget
