import { Chip } from '@mui/material'
import { IApplicationStatus } from '@app/common/ServicesWrapper/apis/dtos/applicationDto'
import { useTranslation } from 'react-i18next'
interface Props {
  status: IApplicationStatus
}

export const UserApplicationStatus = ({ status }: Props) => {
  const { t } = useTranslation()
  switch (status) {
    case 'draft':
      return <Chip label={t('applications.status.draft')} variant="filled" color="info" />
    case 'invited':
      return <Chip label={t('applications.status.invited')} variant="filled" color="info" />
    case 'submitted':
      return <Chip label={t('applications.status.submitted')} variant="filled" color="success" />
    case 'suspended':
      return <Chip label={t('applications.status.pendingInfo')} color="warning" />
    case 'closed':
      return <Chip label={t('applications.status.closed')} variant="filled" color="info" />
  }
}

export default UserApplicationStatus
