import React from 'react'
import { VehicleCar } from '@griegconnect/krakentools-react-icons'
import { Avatar } from '@mui/material'

interface Props {
  vehicle: string[]
}

export default function VehicleAvatar({ vehicle }: Props) {
  return (
    <Avatar>
      <VehicleCar style={{ color: 'rgba(255, 255, 255, 0.87)' }} />
    </Avatar>
  )
}
