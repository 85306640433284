import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgEdit = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M16.96 3.29a1.001 1.001 0 0 1 1.41 0l2.34 2.34c.39.39.39 1.02 0 1.41l-1.83 1.83-3.75-3.75 1.83-1.83Z" />,
    <path
      fillRule="evenodd"
      d="M3 17.25 14.06 6.19l3.75 3.75L6.75 21H3v-3.75Zm11.98-7.31-.92-.92L5 18.08V19h.92l9.06-9.06Z"
      clipRule="evenodd"
    />,
    <path d="M14 19h-3l-2 2h5v-2ZM15 19h3v2h-3v-2ZM21 19h-2v2h2v-2Z" />
  )
export default SvgEdit
