import { AxiosInstance } from 'axios'
import { SortArrayOfObjectsNested } from '@app/lib/Sort'
import { Interval } from 'luxon'
import {
  IAreaDataDto,
  IAreaDataDtoWithDefaultAccesses,
  IAreaDto,
  IAreaDtoWithDefaultAccesses,
  IAssetDto,
} from './dtos/areaDto'
import { convertToScheduling, convertToTScheduling } from './dtos/schedulingDto'

export class AreasApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async get(tenantRef: string, area: string): Promise<IAreaDto> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/areas/${area}`)
    return response.data
  }

  async getExtended(tenantRef: string, area: string): Promise<IAreaDtoWithDefaultAccesses> {
    try {
      const response = await this.httpClient.get(`/ports/${tenantRef}/areas/${area}`)
      const accesses = await this.httpClient.get(`/ports/${tenantRef}/areas/${area}/default-accesses`)
      const schedules = await this.httpClient.get(`/ports/${tenantRef}/areas/${area}/schedules`)
      if (response.data && accesses.data && schedules.data) {
        return { ...response.data, defaultAccesses: accesses.data, schedule: convertToScheduling(schedules.data) }
      } else {
        throw new Error('Failed to fetch area with default accesses')
      }
    } catch (error) {
      return error
    }
  }

  async getAreas(tenantRef: string): Promise<IAreaDto[]> {
    const areasResponse = await this.httpClient.get(`/ports/${tenantRef}/areas`)
    return areasResponse.data.areas.sort(SortArrayOfObjectsNested('name', 'asc'))
  }

  async postArea(tenantRef: string, form: IAreaDataDtoWithDefaultAccesses): Promise<string> {
    try {
      const res = await this.httpClient.post(`/ports/${tenantRef}/areas`, {
        name: form.name,
        securityLevel: form.securityLevel,
        pin: form.pin,
        asset: form.asset,
        files: form.files,
        data: form.data,
      } as IAreaDataDto)
      await this.httpClient.put(`/ports/${tenantRef}/areas/${res.data.id}/default-accesses`, form.defaultAccesses)
      await this.httpClient.put(`/ports/${tenantRef}/areas/${res.data.id}/schedules`, form.schedule)
      return res.data.id
    } catch (error) {
      return error
    }
  }

  async updateArea(tenantRef: string, id: string, form: IAreaDataDtoWithDefaultAccesses): Promise<void> {
    const converted = convertToTScheduling(form.schedule)
    await Promise.all([
      this.httpClient.put(`/ports/${tenantRef}/areas/${id}`, {
        name: form.name,
        securityLevel: form.securityLevel,
        pin: form.pin,
        asset: form.asset,
        files: form.files,
        data: form.data,
      } as IAreaDataDto),
      this.httpClient.put(`/ports/${tenantRef}/areas/${id}/default-accesses`, form.defaultAccesses),
      this.httpClient.put(`/ports/${tenantRef}/areas/${id}/schedules`, converted),
    ])
  }

  async deleteArea(id: string, tenantRef: string): Promise<void> {
    await this.httpClient.delete(`/ports/${tenantRef}/areas/${id}`)
  }

  async getAssets(tenantRef: string): Promise<IAssetDto[]> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/areas/assets`)
    return response.data
  }

  async getAsset(tenantRef: string, assetId: string): Promise<IAssetDto> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/areas/assets/${assetId}`)
    return response.data
  }

  async validPin(
    tenantRef: String,
    id: string,
    interval: Interval,
    existing: string | null,
    permit: string | null
  ): Promise<string> {
    const params = { interval: interval.toISO(), existing, permit }
    const response = await this.httpClient.get(`/ports/${tenantRef}/areas/${id}/pin`, { params })
    return response.data.pin
  }
}
