// import

import { LanguageTag } from '@griegconnect/krakentools-react-ui'
import { useUser } from './useUser'
import { useApplicationOptions } from '../ApplicationOptionsContext'

type UseLocalization = {
  language: LanguageTag
  languages: LanguageTag[]
}

export const useLocalization = (): UseLocalization => {
  const { i18n } = useApplicationOptions()
  const user = useUser()

  const language = (): LanguageTag => {
    if (i18n?.languages) {
      if (user?.metadata.locale && i18n.languages.includes(user.metadata.locale as LanguageTag)) {
        return user.metadata.locale as LanguageTag
      } else {
        return i18n.languages[0]
      }
    }
    return 'en-GB'
  }

  const languages = (): LanguageTag[] => {
    return i18n?.languages ?? ['en-GB']
  }

  return { language: language(), languages: languages() }
}
