import { AxiosInstance } from 'axios'
import { Checkpoints } from './CheckpointApi'
import { IPurposeDto } from './dtos/purposeDto'
import { IUserDto } from './dtos/userDto'
import { Interval } from 'luxon'
import Page from './Page'
import qs from 'qs'
import { By } from './By'
import { Contracts } from './ContractsApi'

export interface VisyDetails {
  installation: VisyInstallation
  checkpoints: VisyCheckpointDetails[]
}

export interface VisyCredentials {
  username: string
  password: string
}

export interface VisyClientConfig {
  uri: string
  credentials: VisyCredentials | null
}

export interface VisyConfiguration {
  timezone: string
  ovt: string
  client: VisyClientConfig | null
  apiKey: string | null
}

export interface VisyInstallation {
  id: string
  config: VisyConfiguration | null
}

export interface VisyData {
  allowedPermitGroups: PermitGroup[]
}

export interface VisyInstallationForm {
  ovt: string
  timezone: string
  clientConfig: VisyClientConfig | null
  apiKey: string | null
}

export interface VisyCheckpointDetails {
  visy: VisyCheckpoint
  permitGroupRules: PermitGroupRule[]
}

export interface VisyCheckpoint {
  id: number
  checkpoint: Checkpoints.CheckpointLink
  permitGroup: number | null
  requireOk: boolean
}

export interface UpsertVisyCheckpoint {
  id: number
  checkpoint: string
  permitGroup: number | null
  permitGroupRules: string[]
  requireOk: boolean
}

interface IResolvedID {
  id: string
  resolved: boolean
}

export interface PermitGroup {
  id: number
  name: string
}

export interface PermitGroupRule {
  id: string
  port: IResolvedID
  permitGroupId: number
  name: string
  automaticAllow: boolean
  purpose?: IPurposeDto
  company?: IResolvedID
}

export interface PermitGroupRuleForm {
  permitGroupId: number
  name: string
  automaticAllow: boolean
  purpose?: string
  company?: string
}

export interface VisyPermit {
  id: number
  modifiedAt: string
  modifiedBy: IUserDto
  checkpoints: number[]
  permitGroupId: number
  oneTime: boolean
  validFrom: string
  validTo: string
  vehicles: string[]
  driver: IUserDto | null
  payload: VisyPayload
  contract: Contracts.Link | null
}
interface VisyPayload {
  company: string
}

export interface VisyPermitForm {
  company: string
  checkpoints: number[]
  permitGroupId: number
  oneTime: boolean
  validFrom: string
  validTo: string
  vehicles: string[]
  driver: IUserDto | null
  contract: string | null
}

export interface PermitQuery {
  valid?: Interval[]
  permitGroup?: number[]
  licensePlate?: string[]
  driver?: string[]
  company?: string[]
  contract?: string[]
}

export interface AccessSyncQuery {
  permit?: string
  revision?: number
}

interface VisyAccessSyncCompleted {
  error: number
  id: number
  at: string
}

export interface VisyAccessSync {
  id: string
  permit: number | null
  created: By
  completed: VisyAccessSyncCompleted | null
  operation: string
}

export interface SetPermitContractBulk {
  contract: string
  permits: number[]
}

export class VisyApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async get(tenantRef: string): Promise<VisyDetails | null> {
    try {
      const response = await this.http.get(`/ports/${tenantRef}/visy`)
      return response.data
    } catch (error) {
      if (error.response.status === 404) {
        return null
      } else {
        throw error
      }
    }
  }

  async setClientConfiguration(tenantRef: string, form: VisyClientConfig) {
    await this.http.post(`/ports/${tenantRef}/visy/client-configuration`, form)
  }

  async upsertInstallation(tenantRef: string, form: VisyInstallationForm) {
    await this.http.post(`/ports/${tenantRef}/visy`, form)
  }

  async deleteInstallation(tenantRef: string) {
    await this.http.delete(`/ports/${tenantRef}/visy`)
  }

  async upsertCheckpoint(tenantRef: string, form: UpsertVisyCheckpoint) {
    await this.http.post(`/ports/${tenantRef}/visy/checkpoints`, form)
  }

  async deleteCheckpoint(tenantRef: string, id: number) {
    await this.http.delete(`/ports/${tenantRef}/visy/checkpoints/${id}`)
  }

  async checkpoints(tenantRef: string): Promise<VisyCheckpoint[]> {
    const response = await this.http.get(`/ports/${tenantRef}/visy/checkpoints`)
    return response.data.checkpoints
  }

  async getData(tenantRef: string): Promise<VisyData> {
    const response = await this.http.get(`/ports/${tenantRef}/visy/data`)

    try {
      return response.data
    } catch (e) {
      if (e.response.status === 404) {
        return { allowedPermitGroups: [] }
      } else throw e
    }
  }

  async permitGroupsRules(tenantRef: string): Promise<PermitGroupRule[]> {
    const response = await this.http.get(`/ports/${tenantRef}/visy/permit-groups`)
    return response.data.permitGroups
  }

  async addPermitGroupRule(tenantRef: string, form: PermitGroupRuleForm) {
    await this.http.post(`/ports/${tenantRef}/visy/permit-groups`, form)
  }

  async deletePermitGroupRule(tenantRef: string, id: string) {
    await this.http.delete(`/ports/${tenantRef}/visy/permit-groups/${id}`)
  }

  async getPermitGroupRuleMappings(tenantRef: string, checkpointID: string): Promise<PermitGroupRule[]> {
    const response = await this.http.get(`/ports/${tenantRef}/visy/checkpoints/${checkpointID}/permit-group`)
    return response.data.permitGroup
  }

  async addPermitGroupRuleMapping(tenantRef: string, checkpointID: string, ruleId: string) {
    await this.http.post(`/ports/${tenantRef}/visy/checkpoints/${checkpointID}/permit-group`, { ruleId })
  }

  async deletePermitGroupRuleMapping(tenantRef: string, checkpointID: string, groupId: string) {
    await this.http.delete(`/ports/${tenantRef}/visy/checkpoints/${checkpointID}/permit-group/${groupId}`)
  }

  async permits(tenantRef: string, query: PermitQuery, page: Page.Search): Promise<Page<VisyPermit>> {
    const valid = query.valid?.map((i) => i.mapEndpoints((dt) => dt.toUTC()).toISO())
    const params = { ...page, ...query, valid }
    const response = await this.http.get(`/ports/${tenantRef}/visy/permits`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })

    return response.data
  }

  async accessSync(tenant: string, query: AccessSyncQuery, page: Page.Search): Promise<Page<VisyAccessSync>> {
    const params = { ...page, ...query }
    const response = await this.http.get(`/ports/${tenant}/visy/access-sync`, { params })
    return response.data
  }

  async getPermit(tenantRef: string, id: number): Promise<VisyPermit> {
    const response = await this.http.get(`/ports/${tenantRef}/visy/permits/${id}`)
    return response.data
  }

  async createPermit(tenantRef: string, permit: VisyPermitForm) {
    const response = await this.http.post(`/ports/${tenantRef}/visy/permits`, permit)
    return response.data.id
  }

  async updatePermit(tenantRef: string, id: number, permit: VisyPermitForm) {
    await this.http.put(`/ports/${tenantRef}/visy/permits/${id}`, permit)
  }

  async deletePermit(tenantRef: string, id: number) {
    await this.http.delete(`/ports/${tenantRef}/visy/permits/${id}`)
  }

  async setContractBulk(tenantRef: string, form: SetPermitContractBulk): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/visy/permits/set-contract`, form)
  }
}
