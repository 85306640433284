import { ErrorHelperText, PhoneInputValidate, validatePhone } from '@griegconnect/krakentools-form'
import { useServices } from '@app/common/ServicesWrapper'
import { Vessel as VesselType } from '@app/common/ServicesWrapper/apis/VesselsApi'
import { IUserDto, IUserSchema } from '@app/common/ServicesWrapper/apis/dtos/userDto'
import { initials } from '@app/lib/Initials'
import useDebouncedState from '@app/lib/hooks/useDebouncedState'
import { validateEmail } from '@app/lib/util'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { Edit, Email } from '@griegconnect/krakentools-react-icons'
import { Tenant, useUsersApi } from '@griegconnect/krakentools-react-kraken-app'
import { AlertDialog, FileDropZone } from '@griegconnect/krakentools-react-ui'
import { CloudUpload, Smartphone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Autocomplete as AutocompleteMUI,
  Avatar,
  Box,
  Button,
  Chip,
  FormLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { Vessel as VesselIcon } from '@griegconnect/krakentools-react-icons'
import React, { useState } from 'react'
import { Control, Controller, UseFieldArrayReturn, get, useFormContext, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { FileList } from './FileList'
import { CheckListValues } from './FormValues'
import { InlinableImage } from './InlinableImage'
import { IFileRefDto, ResolvedFile } from './ServicesWrapper/apis/dtos/fileRefDto'
import DummyUploadImage from './DummyUploadImage'

interface Props extends WithActiveTenant {
  value: IUserDto | null
  onChange: (user: IUserDto | null) => void
  disabled?: boolean
  required?: boolean
  label?: string
  variant?: PersonInputVariant
}

const PersonField: React.FC<Props> = ({
  activeTenant,
  value,
  onChange,
  disabled = false,
  required = false,
  label,
  variant,
}) => {
  const [userInput, setUserInput] = useDebouncedState('', 300)
  const { services } = useServices()
  const { t } = useTranslation()
  const usersApi = useUsersApi()

  const [currentUser, setCurrentUser] = React.useState<User[]>(value ? [{ type: 'id', person: value }] : [])
  const [nameInput, setNameInput] = useState<string>('')

  React.useEffect(() => {
    setCurrentUser(value ? [{ type: 'id', person: value }] : [])
  }, [value])

  const checkPhone = variant === 'phone-email'

  const users = useQuery<IUserDto[], Error>(
    ['users', activeTenant.ref, userInput],
    async () => {
      const isEmail = validateEmail(userInput)
      const isPhone = validatePhone(userInput)
      if (userInput.length > 0 && (isEmail || (isPhone && checkPhone))) {
        const lookup = await services.sharedDataApi.lookup(activeTenant.ref, {
          email: isEmail ? userInput.toLowerCase() : undefined,
          phoneNumber: variant === 'phone-email' && isPhone ? userInput : undefined,
        })
        return lookup
      } else {
        return []
      }
    },
    { keepPreviousData: true }
  )
  const getPersonName = (u: ResolvedPerson) => (u.person.resolved ? u.person.name : u.person.id)

  const userLabel = (u: User) => {
    switch (u.type) {
      case 'id':
        return u.person.resolved ? u.person.name : u.person.id
      case 'create':
        return u.name
      case 'create-mobile':
        return u.name || ''
      case 'invite':
        return u.email
      default:
        return ''
    }
  }

  const userId = (u: User) => {
    switch (u.type) {
      case 'id':
        return u.person.id
      case 'create':
        return u.name
      case 'create-mobile':
        return u.name
      case 'invite':
        return u.email
    }
  }
  const userWith = (a: User, b: User) => a.type === b.type && userId(a) === userId(b)

  const lookup = users.data || []
  const all: User[] = lookup.map((u) => ({ person: u, type: 'id' }))
  const options: User[] = _.uniqWith([...all, ...currentUser], userWith)
  const actionLabel = (u: User) => {
    switch (u.type) {
      case 'create':
        return `Create '${u.name}'`
      case 'create-mobile':
        return `Create unverified user for ${u.mobile}`
      case 'invite':
        return `Invite '${u.email}'`
      case 'id':
        return getPersonName(u)
    }
  }

  const avatar = (u: User) => {
    switch (u.type) {
      case 'create':
        return <Edit />
      case 'create-mobile':
        return <Smartphone />
      case 'invite':
        return <Email />
      case 'id':
        return u.person.resolved && u.person.picture ? (
          <Avatar src={u.person.picture} />
        ) : (
          <Avatar>{initials(getPersonName(u))}</Avatar>
        )
    }
  }

  const onCreate = async (create: CreatePerson) => {
    const created = await services.portUserApi.create(activeTenant.ref, { name: create.name })
    onChange(created)
  }

  const onCreateMobile = async (create: CreateMobile) => {
    const created = await services.portUserApi.create(activeTenant.ref, {
      name: nameInput,
      mobile: create.mobile,
    })
    onChange(created)
  }

  const onInvite = async (invite: InvitePerson) => {
    const invited = await usersApi.inviteUser(invite.email)
    const resolved = await services.portUserApi.get(activeTenant.ref, invited.id)
    onChange(resolved)
  }

  const processChange = (change: User[]) => {
    if (change.length > 0 && change[0].type === 'id' && change[0].person.id !== value?.id) {
      onChange(change[0].person)
    } else if (change.length === 0 && value !== null) {
      onChange(null)
    } else {
      setCurrentUser(change)
    }
  }
  const renderDialog = () => {
    const current = currentUser[0]
    const onComplete = (current) => {
      if (current.type === 'create') {
        onCreate(current)
      } else if (current.type === 'create-mobile') {
        onCreateMobile(current)
      } else if (current.type === 'invite') {
        onInvite(current)
      }
    }
    const action = current.type === 'create' ? t('common.actions.create') : t('common.actions.invite')
    return current.type === 'create-mobile' ? (
      <AlertDialog
        open={current.type === 'create-mobile'}
        title={'Create security identity'}
        onClose={() => setCurrentUser([])}
        onComplete={() => onComplete(current)}
        nextButtonName={t('common.actions.create')}
        closeButtonName={t('common.actions.cancel')}
        disableNext={nameInput.length === 0}
        maxWidth="xs"
      >
        <Alert severity="warning">
          <AlertTitle>{t('common.labels.important')}</AlertTitle>
          {t('security.paragraphs.warnPhoneNumber')}
        </Alert>
        <TextField
          variant="standard"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          name="name"
          label={t('common.labels.name')}
          sx={{ mt: 4 }}
          fullWidth
        />
      </AlertDialog>
    ) : (
      <AlertDialog
        open={current.type !== 'id'}
        title={action}
        onClose={() => setCurrentUser([])}
        onComplete={() => onComplete(current)}
        nextButtonName={t('common.actions.yes')}
        closeButtonName={t('common.actions.no')}
      >
        <Typography component="h1" variant="body1">
          {t('security.paragraphs.actionConfirm', { action: action.toLowerCase(), type: 'user' })}
        </Typography>
      </AlertDialog>
    )
  }

  const selectedUserEmails = currentUser
    .map((p) => (p.type === 'id' && p.person.resolved && p.person.email) || '')
    .filter((p) => p.length > 0)

  let translatedLabel = ''
  switch (variant) {
    case 'name-email':
      translatedLabel = t('security.labels.nameOrEmail')
      break
    case 'phone-email':
      translatedLabel = t('security.labels.emailOrPhone')
      break
    case 'email':
      translatedLabel = t('security.labels.email')
      break
  }

  return (
    <>
      <Tooltip title={selectedUserEmails.join(', ')}>
        <Autocomplete
          multiple
          autoHighlight
          autoSelect
          options={options}
          disabled={disabled}
          value={currentUser}
          filterOptions={(options, filterState) => {
            // 'soft disable' ved kun å tilby valgt person som option
            if (currentUser.length > 0) {
              return currentUser
            }
            const filtered = options

            if (filterState.inputValue.length > 0) {
              if (validateEmail(filterState.inputValue)) {
                // bare vis `invite` om bruker med epost ikke finnes
                if (
                  !filtered.find(
                    (u) => u.type === 'id' && u.person.resolved && u.person.email === filterState.inputValue
                  )
                ) {
                  filtered.push({ type: 'invite', email: filterState.inputValue })
                }
              } else if (variant === 'phone-email' && validatePhone(filterState.inputValue)) {
                if (
                  !filtered.find(
                    (u) => u.type === 'id' && u.person.resolved && u.person.mobile === filterState.inputValue
                  )
                ) {
                  filtered.push({ type: 'create-mobile', mobile: filterState.inputValue, name: '' })
                }
              } else if (variant === 'name-email') {
                filtered.push({ type: 'create', name: filterState.inputValue })
              }
            }
            return filtered
          }}
          renderInput={(params) => (
            <TextField {...params} label={translatedLabel} required={required} variant="filled" />
          )}
          renderTags={(values, getTagProps) =>
            values.map((option, index) => {
              return <Chip label={actionLabel(option)} {...getTagProps({ index })} avatar={avatar(option)} />
            })
          }
          isOptionEqualToValue={userWith}
          getOptionLabel={userLabel}
          renderOption={(props, option) => (
            <li {...props}>
              {avatar(option)}
              <Box m={1}>{actionLabel(option)}</Box>
            </li>
          )}
          onChange={(_, values) => processChange(values)}
          onInputChange={(_, newInputValue) => setUserInput(newInputValue)}
        />
      </Tooltip>
      {currentUser.length > 0 && renderDialog()}
    </>
  )
}

const CreatePersonSchema = z
  .object({
    type: z.literal('create'),
    name: z.string(),
  })
  .strict()
type CreatePerson = z.infer<typeof CreatePersonSchema>

const CreateMobileSchema = z
  .object({
    type: z.literal('create-mobile'),
    mobile: PhoneInputValidate,
    name: z.string(),
  })
  .strict()
type CreateMobile = z.infer<typeof CreateMobileSchema>

const InvitePersonSchema = z
  .object({
    type: z.literal('invite'),
    email: z.string().email(),
  })
  .strict()

type InvitePerson = z.infer<typeof InvitePersonSchema>

const ResolvedPersonSchema = z
  .object({
    type: z.literal('id'),
    person: IUserSchema,
  })
  .strict()

const UserSchema = z.union([ResolvedPersonSchema, CreatePersonSchema, CreateMobileSchema, InvitePersonSchema])

type ResolvedPerson = z.infer<typeof ResolvedPersonSchema>
type User = z.infer<typeof UserSchema>

export type PersonInputVariant = 'email' | 'name-email' | 'phone-email'

interface PersonProps {
  activeTenant: Tenant.Type
  name: string
  required?: boolean
  disabled?: boolean
  label?: string
  variant?: PersonInputVariant
  onChange?: (user: IUserDto | null) => void
}

export const PersonInput: React.VFC<PersonProps> = ({
  activeTenant,
  disabled,
  name,
  required,
  label,
  variant = 'name-email',
  onChange,
}) => {
  const { control } = useFormContext()
  const { errors } = useFormState()
  const error = get(errors, name)

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <PersonField
            activeTenant={activeTenant}
            variant={variant}
            value={field.value}
            onChange={(value) => {
              field.onChange(value)
              onChange && onChange(value)
            }}
            disabled={disabled}
            required={required}
            label={label}
          />
        )}
      />
      {error && <ErrorHelperText error={error.message} />}
    </>
  )
}

export const zVesselSchema = z.union([
  z.object({
    type: z.literal('vessel'),
    id: z.string(),
    name: z.string(),
    mmsi: z.string().optional(),
    imo: z.string().optional(),
  }),
  z.object({ type: z.literal('create'), name: z.string() }),
])

export type zVessel = z.infer<typeof zVesselSchema>

interface VesselProps {
  control: Control<any>
  name: string
  label: string
  tenant: string
  required?: boolean
  disabled?: boolean
}
export const VesselInput: React.VFC<VesselProps> = ({ tenant, control, name, label, required, disabled }) => {
  const { errors } = useFormState()
  const error = get(errors, name)
  const { services } = useServices()
  const { t } = useTranslation()

  const [vesselName, setVesselName] = useDebouncedState('', 300)

  const vessels = useQuery<VesselType[], Error>(
    ['vessels', tenant, vesselName],
    async () => (vesselName ? await services.vesselApi.list(tenant, { name: vesselName }) : []),
    { keepPreviousData: true }
  )

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: zVessel) => {
    const identifier = option.type === 'vessel' && (option.imo || option.mmsi)

    return (
      <ListItem {...props} key={`${option.name}-${props.id}`} dense={true} style={{ width: '100%' }}>
        <ListItemIcon>
          <VesselIcon />
        </ListItemIcon>
        <ListItemText primary={option.name} secondary={identifier} />
      </ListItem>
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...field } }) => {
        const current = value ? (value as zVessel) : null
        const fetched: zVessel[] = (vessels.data || []).map<zVessel>((value) => ({
          type: 'vessel',
          ...value,
          imo: value.imo || undefined,
          mmsi: value.mmsi || undefined,
        }))
        const options =
          current && !fetched.some((o) => o.type === current.type && o.name === current.name)
            ? [...fetched, current]
            : fetched
        return (
          <AutocompleteMUI
            {...field}
            value={current}
            disabled={disabled}
            autoHighlight
            autoSelect
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            filterOptions={(opts, state) => {
              if (state.inputValue.length > 0 && !opts.some((o) => o.name === state.inputValue)) {
                opts.push({ type: 'create', name: state.inputValue })
              }
              return opts
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(opt, current) => opt.type === current.type && opt.name === current.name}
            renderInput={(params) => (
              <>
                <TextField {...params} required={required} label={label} variant="filled" />
                {error && error.message && <ErrorHelperText error={error.message} />}
              </>
            )}
            onInputChange={(e, value) => {
              if (e?.type === 'change') {
                setVesselName(value)
              }
            }}
            onChange={(e, value) => onChange(value)}
            renderOption={renderOption}
            noOptionsText={t('common.paragraphs.noOptions')}
          />
        )
      }}
    />
  )
}

export interface IGCFile {
  file?: File
  id?: string
  name: string
  status: IGFileAction
  href?: string
  filename?: string
  resolved?: boolean
}
type IGFileAction = 'upload' | 'delete' | 'persisting'

interface FileUploadProps {
  control: Control<any> | undefined
  arrayMethods: UseFieldArrayReturn<any, any, 'id'>
  multiple?: boolean
  uploadLabel: string
  modifyLabel: string
  name: string
  title: string
  errors?: string[]
  disabled?: boolean
  showLinks?: boolean
  acceptContent?: string
  downloadImage?: boolean
  kind: 'photos' | 'files'
}

export const FileUpload = ({
  multiple,
  uploadLabel,
  modifyLabel,
  title,
  arrayMethods,
  name,
  disabled,
  acceptContent,
  kind,
}: FileUploadProps) => {
  const fileUploadElement: React.RefObject<HTMLInputElement> = React.createRef()
  const { services } = useServices()
  const { errors } = useFormState()
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const error = get(errors, name)

  const renameFile = (file: File) => new File([file], _.deburr(file.name), { type: file.type })

  const updateFiles = async (newFiles: File[]) => {
    if (!multiple && arrayMethods.fields.length >= 1) {
      removeAllDocuments()
    }

    if (multiple) {
      for (const newFile of newFiles) {
        const resolvedFile = await services.fileApi.uploadUserFile(renameFile(newFile), 'identityDocument')
        arrayMethods.append(resolvedFile)
      }
    } else {
      const resolvedFile = await services.fileApi.uploadUserFile(renameFile(newFiles[0]), 'identityDocument')
      arrayMethods.append(resolvedFile)
    }
  }

  const onChangeHandler = async (e: any) => {
    if (e.target.files) {
      const files = e.target.files as File[]
      updateFiles(files)
    } else {
      removeAllDocuments()
    }

    // Clear the input
    if (fileUploadElement.current) {
      fileUploadElement.current.value = ''
    }
  }

  const onDropHandler = (files: FileList) => updateFiles((files as any) as File[])

  const removeAllDocuments = () => arrayMethods.fields.forEach((f, i) => arrayMethods.remove(i))

  const renderPhotos = () => {
    if (kind !== 'photos' || multiple) {
      // We do not support multiple photos right now
      return null
    }
    if (arrayMethods.fields.length === 0) {
      return <DummyUploadImage />
    }
    return (
      <InlinableImage file={arrayMethods.fields[0] as ResolvedFile} maxHeight={100} maxWidth={100} hideEyeIcon={true} />
    )
  }

  const renderFileList = () => {
    if (kind !== 'files' || arrayMethods.fields.length === 0) {
      return null
    }
    return (
      <Box pb={2}>
        <FileList
          disabled={disabled}
          documents={arrayMethods.fields as IFileRefDto[]}
          onRemove={(id, i) => arrayMethods.remove(i)}
        />
      </Box>
    )
  }

  const buttonText = () => {
    if (multiple && kind === 'files') {
      return uploadLabel
    }
    return arrayMethods.fields.length > 0 ? modifyLabel : uploadLabel
  }

  return (
    <Box pt={2} width={'100%'}>
      <FormLabel error={false}>
        <Typography variant="caption" component="div">
          {title}
        </Typography>
      </FormLabel>

      {renderPhotos()}
      {renderFileList()}

      {
        <>
          <Stack spacing={2}>
            <input
              accept={acceptContent || 'image/*,application/pdf'}
              id="contained-button-file"
              multiple={multiple ?? false}
              type="file"
              name={`file-${name}`}
              onChange={onChangeHandler}
              style={{ display: 'none' }}
              ref={fileUploadElement}
            />
            {!disabled && (
              <>
                {isSm ? (
                  <Button
                    variant="outlined"
                    endIcon={<CloudUpload />}
                    onClick={() => fileUploadElement.current!.click()}
                    fullWidth={true}
                  >
                    {buttonText()}
                  </Button>
                ) : (
                  <FileDropZone
                    fullWidth={true}
                    onFilesDropped={onDropHandler}
                    dropText={t('common.paragraphs.dropFiles')}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      sx={{ p: 2, border: '1px dashed grey' }}
                    >
                      <Button
                        variant="outlined"
                        endIcon={<CloudUpload />}
                        onClick={() => fileUploadElement.current!.click()}
                        fullWidth={true}
                      >
                        {buttonText()}
                      </Button>
                      <Box pt={1} />
                      <Typography variant="body1">({t('common.paragraphs.dragAndDropFiles')})</Typography>
                    </Box>
                  </FileDropZone>
                )}
              </>
            )}
          </Stack>
          {error && <ErrorHelperText error={error.message} />}
        </>
      }
    </Box>
  )
}

interface CheckListValuesProps {
  name: string
  control: Control<any>
  minControlled?: number
  onFile: (files: IGCFile[]) => Promise<ResolvedFile[]>
  removeTopSpacing?: boolean
  hideDescriptions?: boolean
  nonEditable?: boolean
}

export const CheckListFields: React.VFC<CheckListValuesProps> = ({
  name,
  control,
  minControlled,
  onFile,
  removeTopSpacing,
  hideDescriptions,
  nonEditable,
}) => {
  const { errors } = useFormState()
  const error = get(errors, name)

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CheckListValues
            fields={field.value}
            onChange={(value) => field.onChange(value)}
            minControlled={minControlled}
            onFile={onFile}
            removeTopSpacing={removeTopSpacing}
            hideDescriptions={hideDescriptions}
            nonEditable={nonEditable}
          />
        )}
      />
      {error && <ErrorHelperText error={error.message} />}
    </>
  )
}
