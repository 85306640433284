import { AxiosInstance } from 'axios'
import Page from '@app/common/ServicesWrapper/apis/Page'
import qs from 'qs'
import { Courses } from './CoursesApi'


export class AuthenticatedApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async listCourses(params: Courses.Params, page: Page.Search): Promise<Page<Courses.Summary>> {
    const response = await this.httpClient.get(`/courses`, {
      params: { ...params, ...page },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async getCourse(id: string): Promise<Courses.Summary> {
    const response = await this.httpClient.get(`/courses/${id}`)
    return response.data
  }


}

export default AuthenticatedApi
