import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPermitApplication = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M17 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />,
    <path d="M15 8h-2a1 1 0 0 0-1 1v1h6V9a1 1 0 0 0-1-1h-2Z" />,
    <path
      fillRule="evenodd"
      d="M10 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10Zm1 10V3h8v8h-8Z"
      clipRule="evenodd"
    />,
    <path d="M16 14v2H6v-2h10ZM8 11H6v2h2v-2ZM6 8h2v2H6V8ZM16 19v-2H6v2h10Z" />,
    <path d="M4 3h4v2H5v16h12v-7h2v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z" />
  )
export default SvgPermitApplication
