import React from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Map from './Map'
import { Box } from '@mui/material'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapwrapper: {
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  })
)
export const Module: React.FC<React.PropsWithChildren<WithActiveTenant>> = ({ activeTenant }) => {
  const classes = useStyles()
  return (
    <Box position="relative" height="100%" width="100%">
      <div className={`${classes.mapwrapper} nonDraggable`}>
        <Map activeTenant={activeTenant} />
      </div>
    </Box>
  )
}

export default Module
