import { DateTime } from 'luxon'
import * as z from 'zod'

export type ParseResult<A> =
  | {
    success: true
    data: A
  }
  | {
    success: false
    error: z.ZodError
  }

export const zodArrayFlatten = (error: z.ZodError, index: number): z.ZodError => {
  const filtered: z.ZodIssue[] = error.errors
    .filter(({ path }) => path[0] === index)
    .map((i) => {
      return { ...i, path: i.path.slice(1) }
    })
  return z.ZodError.create(filtered)
}

export const zDateTime = z
  .custom<DateTime>(
    (s) => {
      switch (typeof s) {
        case 'string':
          return true
        case 'object':
          return s instanceof DateTime
        default:
          return false
      }
    },
    { message: 'common.validation.required' }
  )
  .transform((s: string | DateTime) => (typeof s === 'string' ? DateTime.fromISO(s) : s))
  .refine((d) => d.isValid, 'common.validation.required')
