import { z } from 'zod'
import { DateTime } from 'luxon'
import { t } from 'i18next'

const zDateTime = z
  .custom<DateTime>(
    (s) => {
      switch (typeof s) {
        case 'string':
          return true
        case 'object':
          return s instanceof DateTime
        default:
          return false
      }
    },
    { message: 'common.validation.required' }
  )
  .transform((s: string | DateTime) => (typeof s === 'string' ? DateTime.fromISO(s) : s))
  .refine((d) => d.isValid, 'common.validation.required')

// Zod schemas

export const SchedulingSlotSchema = z
  .object({
    startTime: zDateTime,
    endTime: zDateTime,
    startDaysOfWeek: z.string().array(),
  })
  .refine((data) => data.startTime < data.endTime, {
    path: ['endTime'],
    message: t('contracts.errors.timeAfter') || 'End time must be after start time.',
  })
  .refine((data) => data.startDaysOfWeek.length > 0, {
    path: ['startDaysOfWeek'],
    message: t('contracts.errors.weekdayRequired') || 'At least one weekday must be selected.',
  })

export const SchedulingSchema = z.object({
  slots: SchedulingSlotSchema.array(),
})

export const SchedulingSchemaWithAttributes = z.object({
  schedule: SchedulingSchema,
  attributes: z.record(z.string()),
})

// Inferred types

export type SchedulingSlot = z.infer<typeof SchedulingSlotSchema>
export type Scheduling = z.infer<typeof SchedulingSchema>
export type SchedulingWithAttributes = z.infer<typeof SchedulingSchemaWithAttributes>

// Transfer types for http requests

export type TSlot = {
  startTime: string
  endTime: string
  startDaysOfWeek: string[]
}
export type TScheduling = {
  slots: TSlot[]
}

// Converters

export const convertToSchedulingSlot = (data: TSlot): SchedulingSlot => {
  let e = DateTime.fromISO(data.endTime)
  const s = DateTime.fromISO(data.startTime)
  if ((e.hour === 23 && e.minute === 59) || (e.hour === 0 && e.minute === 0)) {
    e.set({ second: 59, millisecond: 999 })
  }
  if (s.hour === 0 && s.minute === 0) {
    e.set({ second: 0, millisecond: 0 })
  }
  return {
    startTime: s,
    endTime: e,
    startDaysOfWeek: data.startDaysOfWeek,
  }
}

export const convertToScheduling = (data: TScheduling): Scheduling => {
  return {
    slots: data.slots?.map((slot) => convertToSchedulingSlot(slot) as SchedulingSlot) || [],
  }
}

export const convertToTSlot = (slot: SchedulingSlot): TSlot => {
  const isCtm = (time: DateTime) => time.hour === 23 && time.minute === 59
  const isZero =
    slot.startTime.hour === 0 && slot.startTime.minute === 0 && slot.endTime.hour === 0 && slot.endTime.minute === 0
  return {
    startTime: slot.startTime.toFormat('HH:mm:ss.SSS'),
    endTime: isZero
      ? '23:59:59.999'
      : slot.endTime
          .set({ second: isCtm(slot.endTime) ? 59 : 0, millisecond: isCtm(slot.endTime) ? 999 : 0 })
          .toFormat('HH:mm:ss.SSS'),
    startDaysOfWeek: slot.startDaysOfWeek,
  }
}

export const convertToTScheduling = (data: Scheduling): TScheduling => {
  return {
    slots: data.slots.map((slot) => convertToTSlot(slot)),
  }
}
