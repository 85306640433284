import { Button, Stack } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import { CheckList } from '@app/common/ServicesWrapper/apis/CheckListsApi'
import { Services } from '@app/common/ServicesWrapper/types'
import { Page } from '@griegconnect/krakentools-react-ui'
import { Add } from '@griegconnect/krakentools-react-icons'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { createTableColumn } from '@app/lib/createTableColumn'
import SecurityDataGridPro from '@app/common/SecurityDataGridPro'

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    marginLeft: theme.spacing(3),
  },
}))

interface Props extends WithActiveTenant {
  services: Services
}

export const CheckLists: React.VFC<Props> = ({ activeTenant, services }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const columns = createTableColumn<CheckList>([
    {
      field: 'title',
      headerName: t('common.labels.title'),
      valueGetter: (params) => params.row.name,
    },
    {
      field: 'description',
      headerName: t('settings.labels.description'),
      valueGetter: (params) => params.row.description,
    },
  ])

  const { data: checklists, isLoading } = useQuery<CheckList[]>(
    ['checklists', activeTenant.ref, services.checkListsApi],
    async () => {
      const result = await services.checkListsApi.list(activeTenant.ref)
      return result
    }
  )

  const onAdd = () => {
    navigate(`/companies/${activeTenant.ref}/settings/checklists/new`)
  }

  const onClickHandler = (list: CheckList) => {
    navigate(`/companies/${activeTenant.ref}/settings/checklists/${list.id}`)
  }

  return (
    <Page.Wrapper
      title={t('settings.module.checklists')}
      flexbox
      buttons={
        <Stack direction="row" flex={1} justifyContent={'flex-end'} width={'100%'}>
          <Button
            onClick={onAdd}
            variant="contained"
            color="primary"
            aria-label={'Add'}
            startIcon={<Add />}
            className={classes.addButton}
          >
            {t('settings.labels.newChecklist')}
          </Button>
        </Stack>
      }
    >
      <SecurityDataGridPro
        rows={checklists || []}
        columns={columns}
        loading={isLoading}
        onRowClick={(params) => onClickHandler(params.row)}
        getRowId={(row) => row.id}
      />
    </Page.Wrapper>
  )
}
