import React from 'react'
import {
  Navigation,
  requiresTenant,
  requiresOneOfPermissions,
  requiresPermission,
} from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, Permissions, PERMISSIONS } from '@app/constants'
import { withServices } from '../withServices'
import { withActiveTenant } from '../WithActiveTenant'
import { withParam } from '../withParam'
import { AccessDependencies } from '@app/Content'
import { isPort } from '@app/lib/access'
import { ChecksBadge } from 'src/common/Badges'
import { Trans } from 'react-i18next'
import { ChecksAtomType } from 'src/atoms/checksAtom'

export const checksModuleNavs = (checks: ChecksAtomType): Navigation.Item<AccessDependencies>[] => [
  {
    name: <Trans i18nKey="navigation.checks.checks" />,
    path: PATH_TENANT_BASE + '/checks',
    exact: true,
    menu: {
      icon: <ChecksBadge />,
      badge: { count: checks.facility + checks.quay },
    },
    access: (d) =>
      isPort(d) &&
      requiresTenant(d) &&
      requiresOneOfPermissions(
        [
          ...Permissions.all(PERMISSIONS.checks_facility),
          ...Permissions.all(PERMISSIONS.checks_person),
          ...Permissions.all(PERMISSIONS.checks_quay),
          ...Permissions.all(PERMISSIONS.checks_vehicle),
          ...Permissions.all(PERMISSIONS.checks_vessel),
        ],
        true
      )(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.checks.quayChecks" />,
        path: PATH_TENANT_BASE + '/quayChecks',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./QuayChecks/ListQuayChecks'))))
        ),
        exact: true,
        menu: {
          badge: { count: checks.quay },
        },
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresOneOfPermissions(Object.values(PERMISSIONS.checks_quay), true)(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.checks.newQuayCheck" />,
            path: PATH_TENANT_BASE + '/quayChecks/new',
            page: React.createElement(
              withServices(withActiveTenant(React.lazy(() => import('./QuayChecks/NewQuayCheck'))))
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_quay.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.checks.showQuayCheck" />,
            path: PATH_TENANT_BASE + '/quayChecks/:id',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./QuayChecks/ViewQuayCheck')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) => isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_quay.read, true)(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.checks.facilityChecks" />,
        path: PATH_TENANT_BASE + '/facilityChecks',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./FacilityChecks/ListFacilityChecks'))))
        ),
        exact: true,
        menu: {
          badge: { count: checks.facility },
        },
        access: (d) =>
          isPort(d) &&
          requiresTenant(d) &&
          requiresOneOfPermissions(Object.values(PERMISSIONS.checks_facility), true)(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.checks.newFacilityCheck" />,
            path: PATH_TENANT_BASE + '/facilityChecks/new',
            page: React.createElement(
              withServices(withActiveTenant(React.lazy(() => import('./FacilityChecks/NewFacilityCheck'))))
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_facility.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.checks.showFacilityCheck" />,
            path: PATH_TENANT_BASE + '/facilityChecks/:id',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./FacilityChecks/ViewFacilityCheck')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_facility.read, true)(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.checks.vesselChecks" />,
        path: PATH_TENANT_BASE + '/vesselChecks',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./VesselChecks/ListVesselChecks'))))
        ),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresOneOfPermissions(Object.values(PERMISSIONS.checks_vessel), true)(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.checks.newVesselCheck" />,
            path: PATH_TENANT_BASE + '/vesselChecks/new',
            page: React.createElement(
              withServices(withActiveTenant(React.lazy(() => import('./VesselChecks/NewVesselCheck'))))
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_vessel.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.checks.showVesselCheck" />,
            path: PATH_TENANT_BASE + '/vesselChecks/:id',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./VesselChecks/ViewVesselCheck')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_vessel.read, true)(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.checks.vehicleChecks" />,
        path: PATH_TENANT_BASE + '/vehicleChecks',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./VehicleChecks/ListVehicleChecks'))))
        ),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) &&
          requiresTenant(d) &&
          requiresOneOfPermissions(Object.values(PERMISSIONS.checks_vehicle), true)(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.checks.newVehicleCheck" />,
            path: PATH_TENANT_BASE + '/vehicleChecks/new',
            page: React.createElement(
              withServices(withActiveTenant(React.lazy(() => import('./VehicleChecks/NewVehicleCheck'))))
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_vehicle.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.checks.showVehicleCheck" />,
            path: PATH_TENANT_BASE + '/vehicleChecks/:id',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./VehicleChecks/ViewVehicleCheck')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_vehicle.read, true)(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.checks.personChecks" />,
        path: PATH_TENANT_BASE + '/personChecks',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./PersonChecks/ListPersonChecks'))))
        ),
        exact: true,
        menu: true,
        access: (d) =>
          isPort(d) && requiresTenant(d) && requiresOneOfPermissions(Object.values(PERMISSIONS.checks_person), true)(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.checks.newPersonCheck" />,
            path: PATH_TENANT_BASE + '/personChecks/new',
            page: React.createElement(
              withServices(withActiveTenant(React.lazy(() => import('./PersonChecks/NewPersonCheck'))))
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_person.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.checks.showPersonCheck" />,
            path: PATH_TENANT_BASE + '/personChecks/:id',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./PersonChecks/ViewPersonCheck')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.checks_person.read, true)(d),
          },
        ],
      },
      {
        name: <Trans i18nKey="navigation.checks.controlRegistrations" />,
        path: PATH_TENANT_BASE + '/control-registrations',
        page: React.createElement(
          withServices(withActiveTenant(React.lazy(() => import('./controlRegistrations/ListControlRegistrations'))))
        ),
        exact: true,
        menu: true,
        access: (d) => isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.visitor_stats.read, true)(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.checks.newControlRegistration" />,
            path: PATH_TENANT_BASE + '/control-registrations/new',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./controlRegistrations/NewControlRegistration')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.visitor_stats.create, true)(d),
          },
          {
            name: <Trans i18nKey="navigation.checks.editControlRegistration" />,
            path: PATH_TENANT_BASE + '/control-registrations/:id/edit',
            page: React.createElement(
              withServices(
                withActiveTenant(
                  withParam(
                    React.lazy(() => import('./controlRegistrations/NewControlRegistration')),
                    'id'
                  )
                )
              )
            ),
            exact: true,
            access: (d) =>
              isPort(d) &&
              requiresTenant(d) &&
              requiresOneOfPermissions([PERMISSIONS.visitor_stats.read, PERMISSIONS.visitor_stats.update], true)(d),
          },
        ],
      },
    ],
  },
]
