import React, { ReactElement } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper, { PopperPlacementType, PopperProps as MuiPopperProps } from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

type PopperItemBaseProps = {
  name: string
  icon?: ReactElement
}

/**
 * @deprecated item without onClick is deprecated
 */
export type PopperMenuAction = PopperItemBaseProps
export type PopperMenuItemProps = PopperItemBaseProps & {
  id?: string
  /**
   * This will only display the hotkey in the menu
   * You need to setup the keyboard event yourself.
   */
  hotkey?: string
  action?: React.ReactNode
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  /**
   * `variant` defaults to `'standard'` if no value is defined.
   */
  variant?: 'standard' | 'header'
}

type PopperBaseProps = {
  sx?: MuiPopperProps['sx']
  onClose: (event: MouseEvent | TouchEvent | React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  open: boolean
  anchorEl: any
  placement?: PopperPlacementType
  /**
   * Defaults to `true`
   */
  disablePortal?: boolean
  /**
   * @deprecated onClick is deprecated please use PopperMenuAction callback
   */
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, action: PopperMenuAction) => void
  className?: string
}

/**
 * @deprecated onClick is deprecated please use PopperMenuAction callback
 */
type PopperPropsDeprecated = PopperBaseProps & {
  /**
   * @deprecated onClick is deprecated please use PopperMenuAction callback
   */
  actions: PopperMenuAction[]
}

type PopperPropsNew = Omit<PopperBaseProps, 'onClick'> & {
  items: PopperMenuItemProps[]
  autoClose?: boolean
}

type PopperProps = PopperPropsNew | PopperPropsDeprecated

let warnDeprecated = false

const PopperMenu = (props: PopperProps) => {
  if (('onClick' in props || 'actions' in props) && !warnDeprecated) {
    console.warn(`[react-ui]: PopperMenu's actions and onClick props are deprecated, please use items prop instead.`)
    warnDeprecated = true
  }

  return (
    <Popper
      className={props.className}
      open={props.open}
      anchorEl={props.anchorEl}
      role={undefined}
      transition
      disablePortal={props.disablePortal ?? true}
      sx={(theme) => {
        const base = { zIndex: theme.zIndex.appBar + 1 }
        if (props.sx) {
          return Object.assign({ ...base, ...props.sx })
        }
        return { ...base }
      }}
      placement={props.placement}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement.indexOf('bottom') > -1 ? 'center top' : 'center bottom',
          }}
        >
          <Paper elevation={3}>
            <ClickAwayListener onClickAway={props.onClose}>
              <MenuList autoFocusItem={props.open}>
                {(props as PopperPropsDeprecated).actions &&
                  (props as PopperPropsDeprecated).actions.map((action, index) => {
                    return (
                      <MenuItem
                        key={action.name + index}
                        onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                          event.stopPropagation()
                          if ('onClick' in props && props.onClick) {
                            props.onClick(event, action)
                          } else {
                            console.warn(
                              `[react-ui]: Please upgrade to the new PopperMenu's items type PopperMenuItemProps`
                            )
                          }
                        }}
                      >
                        {action.icon && <ListItemIcon>{action.icon && action.icon}</ListItemIcon>}
                        <ListItemText>{action.name}</ListItemText>
                      </MenuItem>
                    )
                  })}
                {(props as PopperPropsNew).items &&
                  (props as PopperPropsNew).items.map((action) => (
                    <MenuItem
                      key={action.id ? action.id : action.name}
                      disabled={action.disabled || action.variant === 'header'}
                      onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                        event.stopPropagation()
                        if ('autoClose' in props && props.autoClose) {
                          props.onClose(event)
                        }
                        if (action.onClick) {
                          action.onClick(event)
                        }
                      }}
                    >
                      {action.icon && <ListItemIcon>{action.icon && action.icon}</ListItemIcon>}
                      <ListItemText>
                        <Typography variant={action.variant === 'header' ? 'body2' : 'body1'}>{action.name}</Typography>
                      </ListItemText>
                      {action.action && <Box sx={{ ml: 4 }}>{action.action}</Box>}
                      {action.hotkey && <Typography sx={{ color: 'text.hint', ml: 4 }}>{action.hotkey}</Typography>}
                    </MenuItem>
                  ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default PopperMenu
