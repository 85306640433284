import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import clsx from 'clsx'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useState } from 'react'
import {
  CheckListField,
  CheckListOk,
  CheckListProblem,
  CheckListValue,
} from '@app/common/ServicesWrapper/apis/CheckListsApi'
import { ResolvedFile } from '@app/common/ServicesWrapper/apis/dtos/fileRefDto'
import { FileList } from '@app/common/FileList'
import UiFileUpload, { IGCFile } from '@app/common/UiFileUpload'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  up: {
    backgroundColor: `${theme.palette.success.main} !important`,
    color: '#000 !important',
  },
  pass: {
    backgroundColor: `${theme.palette.grey[300]} !important`,
    color: '#000 !important',
  },
  down: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: '#000 !important',
  },
  na: {
    padding: '2px 0px',
    fontSize: '0.8rem',
  },
  iconButton: {
    padding: '4px',
    margin: '8px',
  },
  descriptionTextField: {
    width: '100%',
  },
  validationMessage: {
    marginLeft: theme.spacing(2),
  },
  rejectInfo: {
    marginBottom: '8px',
  },
  visible: {
    opacity: '1 !important',
  },
  lineChange: {
    whiteSpace: 'pre-line',
  },
  comment: {
    whiteSpace: 'pre-line',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
    borderRadius: 16,
  },
}))

interface CheckListValuesProps {
  fields: CheckListField[]
  minControlled?: number
  onChange?: (values: CheckListField[]) => void
  onFile: (files: IGCFile[]) => Promise<ResolvedFile[]>
  removeTopSpacing?: boolean
  hideDescriptions?: boolean
  nonEditable?: boolean
}

export const CheckListValues: React.VFC<CheckListValuesProps> = ({
  fields,
  minControlled,
  onChange,
  onFile,
  removeTopSpacing,
  hideDescriptions,
  nonEditable,
}: CheckListValuesProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isXs = useMediaQuery(theme.breakpoints.down('md'))
  const open = !hideDescriptions

  const [collapseFields, setCollapseFields] = useState<{ id: string; collapsed: boolean }[]>(
    fields.map((f) => {
      return { id: f.item.id, collapsed: false }
    })
  )

  const toggleCollapse = (id: string) => {
    const newCollapseFields = collapseFields.map((f) => {
      if (f.id === id) return { id: f.id, collapsed: !f.collapsed }
      else return f
    })
    setCollapseFields(newCollapseFields)
  }

  const expandOnlyOne = (id: string) => {
    const newCollapseFields = collapseFields.map((i) =>
      i.id === id ? { ...i, collapsed: false } : { ...i, collapsed: true }
    )
    setCollapseFields(newCollapseFields)
  }

  const render = (field: CheckListField, idx: number) => {
    const { item, value } = field
    const problem: CheckListProblem =
      value && !value.ok ? value : { ok: false, description: '', documents: [], tags: [] }
    const ok: CheckListOk = value && !!value.ok ? value : { ok: true, description: '' }

    const update = (value?: CheckListValue) => {
      onChange && onChange([...fields.slice(0, idx), { ...field, value }, ...fields.slice(idx + 1)])
      expandOnlyOne(field.item.id)
    }

    const up = () => update(ok)

    const pass = () => update()

    const down = () => update(problem)

    const onDescriptionProblem = (description: string) => update({ ...problem, description })

    const onDescriptionOk = (description: string) => update({ ...ok, description })

    const onDocument = async (files: IGCFile[]) => {
      const documents = await onFile(files)
      update({ ...problem, documents })
    }

    const onTags = (tags: string[]) => update({ ...problem, tags })

    const collapsableContent = () =>
      !collapseFields.find((f) => f.id === field.item.id)?.collapsed && (
        <>
          {value && !!value.ok && (!nonEditable || value.description?.length > 0) && (
            <Box p={2} pt={1}>
              {!nonEditable && (
                <TextField
                  label={t('common.labels.comment')}
                  value={value.description}
                  onChange={(evt) => onDescriptionOk(evt.target.value)}
                  variant="outlined"
                  rows={3}
                  multiline={true}
                  className={classes.descriptionTextField}
                />
              )}
              {nonEditable && <Typography className={classes.comment}>{value.description}</Typography>}
            </Box>
          )}
          {value && !value.ok && (
            <ListItem className={classes.rejectInfo}>
              <Stack spacing={1} width="100%" direction="column">
                <Grid
                  container={true}
                  spacing={1}
                  item={true}
                  xs={12}
                  style={
                    !nonEditable || value.description.length > 0 || value.documents.length > 0
                      ? { marginBottom: '8px' }
                      : {}
                  }
                >
                  {item.tags.map((tag, index) => {
                    const selected = value.tags.includes(tag)
                    const onClick = () =>
                      onTags(selected ? value.tags.filter((value) => value !== tag) : [...value.tags, tag])
                    return (
                      <Grid item={true} key={`tag-${index + 1}`}>
                        <Chip
                          className={selected ? classes.visible : undefined}
                          label={tag}
                          color={selected ? 'primary' : 'default'}
                          onClick={onClick}
                          disabled={nonEditable}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
                {(!nonEditable || value.description.length > 0) && (
                  <Grid item={true} xs={12}>
                    {!nonEditable && (
                      <TextField
                        label={t('common.labels.description')}
                        value={value.description}
                        onChange={(evt) => onDescriptionProblem(evt.target.value)}
                        variant="outlined"
                        rows={3}
                        multiline={true}
                        className={classes.descriptionTextField}
                      />
                    )}
                    {nonEditable && <Typography className={classes.comment}>{value.description}</Typography>}
                  </Grid>
                )}
                {value.documents.length > 0 && (
                  <Grid item={true} xs={12}>
                    <FileList documents={value.documents} />
                  </Grid>
                )}
                {!nonEditable && (
                  <Grid item={true} xs={12}>
                    <UiFileUpload
                      onChange={onDocument}
                      uploadButtonlabel={
                        value.documents.length > 0
                          ? t('checks.files.document.change')
                          : t('checks.files.document.upload')
                      }
                      title={t('checks.files.document.title')}
                      value={[]}
                      multiple={true}
                      showLinks={true}
                    />
                  </Grid>
                )}
              </Stack>
            </ListItem>
          )}
        </>
      )

    return (
      <React.Fragment key={item.id}>
        <Grid container={true} justifyContent="space-between">
          <Grid item={true} xs={12}>
            <ListItem style={{ padding: isXs ? '8px 0px' : '8px 16px' }}>
              {
                <IconButton onClick={() => toggleCollapse(field.item.id)}>
                  <ExpandMoreIcon
                    fontSize="medium"
                    style={{
                      position: 'relative',
                      transform: `rotate(${
                        !collapseFields.find((f) => f.id === field.item.id)?.collapsed ? -180 : 0
                      }deg)`,
                      transition: 'transform 250ms ease-in-out',
                    }}
                  />
                </IconButton>
              }
              <ListItemText primary={item.name + (item.required ? ' *' : '')} />
              <ListItemSecondaryAction
                style={{
                  height: '51px',
                  padding: '0 8px',
                  position: 'absolute',
                  right: isXs ? '-16px' : '0px',
                }}
              >
                {!field.item.required && (
                  <IconButton
                    aria-label="n/a"
                    onClick={pass}
                    disabled={nonEditable}
                    className={`${clsx({
                      [classes.pass]: Boolean(!value),
                    })} ${classes.iconButton}`}
                    size="large"
                  >
                    <Typography className={classes.na}>{t('common.labels.na')}</Typography>
                  </IconButton>
                )}
                <IconButton
                  aria-label="problem"
                  onClick={down}
                  disabled={nonEditable}
                  className={`${clsx({
                    [classes.down]: Boolean(value && !value.ok),
                  })} ${classes.iconButton}`}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
                <IconButton
                  aria-label="ok"
                  onClick={up}
                  disabled={nonEditable}
                  className={`${clsx({ [classes.up]: Boolean(value && value.ok) })} ${classes.iconButton}`}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Grid>
        </Grid>
        {open && item.description.length > 0 && (
          <ListItem>
            <Grid container={true}>
              <Grid item={true}>
                <ListItemText className={classes.lineChange} secondary={item.description} />
              </Grid>
            </Grid>
          </ListItem>
        )}
        {collapsableContent()}
      </React.Fragment>
    )
  }

  return (
    <>
      {minControlled && (
        <>
          <Typography className={classes.validationMessage}>
            {minControlled > 1
              ? t('checks.paragraphs.minControlledPlural', { number: minControlled })
              : t('checks.paragraphs.minControlledSingilar')}
          </Typography>
          <Box m={2} />
        </>
      )}
      <List
        style={{
          paddingBottom: '16px',
          paddingTop: removeTopSpacing ? '0' : '16px',
        }}
      >
        {intersperse(fields.map(render), (idx) => (
          <Divider key={idx} component="li" />
        ))}
      </List>
    </>
  )
}

function intersperse<A>(as: A[], value: (idx: number) => A): A[] {
  return as.reduce<A[]>((a, v, idx) => a.concat(v, value(idx)), []).slice(0, -1)
}
