import React from 'react'
import { Navigation, requiresTenant, requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { SecurityAccessContract } from '@griegconnect/krakentools-react-icons'
import { withActiveTenant } from '../WithActiveTenant'
import { AccessDependencies } from '@app/Content'
import { withParam } from '../withParam'
import { isPort, usesContracts } from '@app/lib/access'
import { Trans } from 'react-i18next'
import { RewriteUri } from 'src/common/Navigation'

const PATH_CONTRACTS = PATH_TENANT_BASE + '/access-contracts'

export const accessContractsModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.accessContracts.contracts" />,
    path: PATH_CONTRACTS,
    page: React.createElement(withActiveTenant(React.lazy(() => import('./List')))),
    exact: true,
    menu: {
      icon: <SecurityAccessContract />,
    },
    access: (d) =>
      requiresTenant(d) && isPort(d) && usesContracts(d) && requiresPermission(PERMISSIONS.dos_contract.read)(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.accessContracts.contract" />,
        path: PATH_CONTRACTS + '/:id',
        page: React.createElement(
          withActiveTenant(
            withParam(
              React.lazy(() => import('./View')),
              'id'
            )
          )
        ),
        exact: true,
        access: (d) =>
          requiresTenant(d) && isPort(d) && usesContracts(d) && requiresPermission(PERMISSIONS.dos_contract.read)(d),
      },
      {
        name: <Trans i18nKey="navigation.accessContracts.companySelect" />,
        path: PATH_CONTRACTS + '/new',
        page: React.createElement(withActiveTenant(React.lazy(() => import('./New')))),
        exact: true,
        access: (d) =>
          requiresTenant(d) && isPort(d) && usesContracts(d) && requiresPermission(PERMISSIONS.dos_contract.create)(d),
      },
    ],
  },
  {
    name: 'contracts',
    path: PATH_TENANT_BASE + '/contracts',
    access: (d) =>
      requiresTenant(d) && isPort(d) && usesContracts(d) && requiresPermission(PERMISSIONS.dos_contract.read)(d),
    page: <RewriteUri search="contracts" replace="access-contracts" />,
  },
]
