import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLockOpen = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M19.243 2.1a5 5 0 0 0-7.071 0l-1.708 1.708a1 1 0 1 0 1.415 1.414l1.707-1.707a3 3 0 1 1 4.242 4.242L15.586 10H5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1h-.586l.829-.828a5 5 0 0 0 0-7.071ZM6 12h12v8H6v-8Z" />,
    <path d="M14 16a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
  )
export default SvgLockOpen
