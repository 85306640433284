import { AxiosInstance } from 'axios'
import qs from 'qs'
import { By } from './By'
import Page from './Page'
import * as z from 'zod'
import { Contracts } from './ContractsApi'

export const _SchemaData = z.object({
  manufacturer: z.string().optional(),
  model: z.string().optional(),
  color: z.string().optional(),
})

export const _Schema = z.object({
  contract: z.string(),
  numberPlate: z.string().refine((nr) => nr.trim().length > 0, { message: 'integrations.validations.numberPlate' }),
  data: _SchemaData,
})

export const Schema = () => _Schema

export type Inputs = z.infer<typeof _Schema>
export type Data = z.infer<typeof _SchemaData>

export class ApprovedVehiclesApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async list(tenantRef: string, params: ApprovedVehicles.Params): Promise<Page<ApprovedVehicles.Summary>> {
    const response = await this.httpClient.get(`/ports/${tenantRef}/approvedVehicles`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async create(tenantRef: string, form: ApprovedVehicles.Form): Promise<{ id: string }> {
    const response = await this.httpClient.post(`/ports/${tenantRef}/approvedVehicles`, form)
    return response.data
  }

  async update(tenantRef: string, id: string, form: ApprovedVehicles.Form): Promise<{ id: string }> {
    const response = await this.httpClient.put(`/ports/${tenantRef}/approvedVehicles/${id}`, form)
    return response.data
  }

  async delete(tenantRef: string, id: string): Promise<{ id: string }> {
    const response = await this.httpClient.delete(`/ports/${tenantRef}/approvedVehicles/${id}`)
    return response.data
  }
}

export declare namespace ApprovedVehicles {
  export interface Summary {
    id: string
    port: string
    contract?: Contracts.Link
    numberPlate: string
    created: By
    modified?: By
    data: Data
  }

  export interface Params {
    page?: number
    pageSize?: number
    numberPlates?: string[]
    onContract?: boolean
    ids?: string[]
    contracts?: string[]
  }

  export interface Form {
    contract?: string
    numberPlate: string
    data: Data
  }
}
