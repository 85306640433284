import React from 'react'
import { Navigation, requiresTenant, requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, Permissions, PERMISSIONS } from '@app/constants'
import { withActiveTenant } from '../WithActiveTenant'
import { Map as MapIcon } from '@griegconnect/krakentools-react-icons'
import Map from './'
import { AccessDependencies } from '@app/Content'
import { Trans } from 'react-i18next'

export const mapModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.map" />,
    path: PATH_TENANT_BASE + '/map',
    page: React.createElement(withActiveTenant(Map)),
    exact: true,
    menu: {
      icon: <MapIcon />,
    },
    access: (d) => requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.maps), true)(d),
  },
]
