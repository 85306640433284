import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { TimePicker as TimePickerMUI } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTime } from 'luxon'
import { Control, Controller, get, useFormState } from 'react-hook-form'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'
import { Time as TimeIcon } from '@griegconnect/krakentools-react-icons'
import { IconButton, InputAdornment, FormControl } from '@mui/material'

import { useState } from 'react'

interface TimePickerProps {
  control: Control<any>
  className?: string | undefined
  required?: boolean
  disabled?: boolean
  fullWidth?: boolean
  name: string
  label: string
  disablePast?: boolean
  disableErrorMessages?: boolean
  maxTime?: DateTime | null
  minTime?: DateTime | null
  onViewChange?: (view: string) => void
}

export const TimePicker = ({
  control,
  name,
  disabled,
  label,
  disablePast,
  required,
  maxTime,
  minTime,
  disableErrorMessages,
  fullWidth,
  onViewChange,
}: TimePickerProps) => {
  const { errors } = useFormState()
  const error = get(errors, name)

  const [open, setOpen] = useState(false)

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth={fullWidth}>
              <TimePickerMUI
                value={field.value}
                onViewChange={onViewChange}
                onChange={field.onChange}
                onClose={() => setOpen(false)}
                open={open}
                disabled={disabled ?? false}
                ampm={false}
                format="HH:mm"
                disablePast={disablePast ?? false}
                minTime={minTime}
                maxTime={maxTime}
                error={error}
                slotProps={{
                  textField: {
                    required: required,
                    variant: 'filled',
                    label,
                    style: { marginBottom: 10 },
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setOpen(true)}>
                            <TimeIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                }}
              />
            </FormControl>
          )}
          />
      </LocalizationProvider>
        {disableErrorMessages === undefined ||
          (disableErrorMessages === false && error && error.message && <ErrorHelperText error={error.message} />)}
    </>
  )
}
