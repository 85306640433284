import { AppLoader } from '@griegconnect/krakentools-react-ui'
import { useNavigate } from 'react-router-dom'
import { CheckListDetails, CheckListForm } from '@app/common/ServicesWrapper/apis/CheckListsApi'
import { Services } from '@app/common/ServicesWrapper/types'
import { Page } from '@griegconnect/krakentools-react-ui'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { EditCheckList } from './Edit'
import { useQuery } from '@tanstack/react-query'
import { FailedToRetrieveData } from '@app/common/StatusTexts'
import { useTranslation } from 'react-i18next'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'

type ViewCheckListProps = {
  services: Services
  id: string
} & WithActiveTenant

export const ViewCheckList: React.VFC<ViewCheckListProps> = ({ services, id, activeTenant }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { enqueue } = useAlerts()

  const onSubmit = async (form: CheckListForm) => {
    await services.checkListsApi.update(activeTenant.ref, id, form)
    navigate(`/companies/${activeTenant.ref}/settings/checklists`)
    try {
      await services.checkListsApi.update(activeTenant.ref, id, form)
      navigate(`/companies/${activeTenant.ref}/settings/checklists`)
      enqueue(t('checks.success.updateChecklist'), 'success')
    } catch (error) {
      enqueue(t('checks.errors.updateChecklist'), 'error')
    }
  }

  const { data: checkList, isLoading, isFetching, isError } = useQuery<CheckListDetails>(
    ['checkList', services.checkListsApi, activeTenant, id],
    () => services.checkListsApi.get(activeTenant.ref, id)
  )

  if (isError) {
    return <FailedToRetrieveData />
  }

  return isLoading || isFetching || !checkList ? (
    <div id="apploader">
      <AppLoader />
    </div>
  ) : (
    <Page.Wrapper title={t('settings.module.checklist')} maxWidth="md" showBackButton>
      <EditCheckList checkList={{ ...checkList.checklist, items: checkList.items }} onSubmit={onSubmit} />
    </Page.Wrapper>
  )
}
