import { fromTranslations, useTranslatable } from '@griegconnect/krakentools-form'
import { useAlerts, useBreakpointDown, useUser, useUsersApi } from '@griegconnect/krakentools-react-kraken-app'
import { Box, Button, CircularProgress, Grid, Link, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { useServices } from '@app/common/ServicesWrapper'
import { NextState, StepProps, applicationState, defaultNextState, nextStateAtom } from '.'
import { ApplicationFormData, emptyApplicationForm, toFormData, updateFormData } from '../schema'
import FlowNavigation from '../Components/FlowNavigation'
import FlowPage from '../Components/FlowPage'
import FlowHeader from '../Components/FlowHeader'
import { useNavigate } from 'react-router-dom'
import { InlinableImage } from '@app/common/InlinableImage'

const StepSubmit: React.FC<StepProps> = ({
  formValues,
  children,
  portDetails,
  formType,
  application,
  setModalOpen,
  resetForm,
  onBack,
  userDetails,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [state, setState] = useRecoilState<NextState>(nextStateAtom)
  const [applicationFormData, setApplicationFormData] = useRecoilState<ApplicationFormData>(applicationState)

  const translatable = useTranslatable()
  const { services } = useServices()
  const { enqueue } = useAlerts()
  const { t } = useTranslation()
  const user = useUser()
  const navigate = useNavigate()
  const isDevice = useBreakpointDown('sm')
  const usersApi = useUsersApi()

  // const onBack = () => setState((p) => ({ ...p, currentStep: p.currentStep - 1 }))

  const handleReset = () => resetForm && resetForm()
  const handleOnBack = () => onBack && onBack()

  const onSubmit = async () => {
    const companyChanged = applicationFormData.visitorPerson.company && applicationFormData.visitorPerson.company !== userDetails?.attributes.company
    const mobileChanged = applicationFormData.visitorPerson.mobile && applicationFormData.visitorPerson.mobile !== userDetails?.identity.mobile
    const personFileChanged = applicationFormData.visitorPerson.files.length > 0 && applicationFormData.visitorPerson.files[0].id !== userDetails?.attributes.idDocument?.id

    try {
      if (userDetails && mobileChanged && applicationFormData.visitorPerson.mobile) {
        await usersApi.updateCurrentUser(userDetails.identity.name, applicationFormData.visitorPerson.mobile)
      }
      if (userDetails && (companyChanged || personFileChanged)) {
        const profile = userDetails.attributes
        const idDocument =
          personFileChanged &&
          applicationFormData.visitorPerson.files &&
          applicationFormData.visitorPerson.files.length > 0
            ? applicationFormData.visitorPerson.files[0]?.id
            : profile.idDocument?.id
        const company =
          companyChanged &&
          applicationFormData.visitorPerson.company
            ? applicationFormData.visitorPerson.company
            : profile.company || undefined
        const symmetryBlue = profile.symmetryBlue
          ? profile.symmetryBlue.toString()
          : null
        await services.userApi.updateProfile(idDocument, company, symmetryBlue || undefined)
      }
    } catch (error) {
      enqueue(t('applications.errors.profileUpdate'), 'error')
    }
    try {
      setSubmitting(true)
      if (formType === 'edit' && application && setModalOpen) {
        const updateFormPayload = updateFormData(applicationFormData, state.visitType!, state.visitBy === 'driving')
        await services.userApi.updateApplication(application.current.meta.id, updateFormPayload)
        navigate('/user')
        setModalOpen(false)
        setState(defaultNextState)
        enqueue(t('applications.success.edit'), 'success')
      } else {
        const payload = toFormData(
          applicationFormData,
          state.visitType!,
          state.visitBy === 'driving',
          portDetails.port.id
        )
        await services.userApi.createApplication(payload)
        setState((p) => ({ ...p, currentStep: p.currentStep + 1 }))
      }
      setApplicationFormData(emptyApplicationForm(user))
      setSubmitting(false)
    } catch (e) {
      enqueue(t('applications.errors.failedToSubmit'), 'error')
    }
  }

  const target = formValues.targets.find((t) => t.id === applicationFormData.visiting.target)
  const facility = target && target.facilities.find((f) => f.area.id === applicationFormData.visiting.facility)
  const purpose = formValues.purposes.find((p) => p.id === applicationFormData.visiting.purpose)
  const visitHandler = applicationFormData.time.visitHandlerName

  const applicationTrailerInput =
    portDetails && portDetails.attributes && portDetails.attributes.applicationTrailerInput
  const applicationSubcontractorInput =
    portDetails && portDetails.attributes && portDetails.attributes.applicationSubcontractorInput

  if (submitting) {
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <Stack direction="column" width="100%" mt={isDevice ? 4 : 0}>
      <FlowPage>
        <FlowHeader i18n="applications.paragraphs.summarySubmit" />
        <Grid container spacing={2}>
          {applicationFormData.visitorPerson.files && (
            <Grid item xs={12} md={6}>
              <Typography variant="caption">
                {portDetails.attributes.nextGenUserPhotoOverId
                  ? `${t('components.applications.files.personPhoto.title')} *`
                  : `${t('components.applications.files.personId.title')} *`}
              </Typography>
              <Box display="flex" width="100%" alignItems="flex-start" justifyContent="flex-start" mt={2} mb={2}>
                <InlinableImage
                  file={applicationFormData.visitorPerson.files[0]}
                  maxHeight={100}
                  maxWidth={100}
                  hideEyeIcon={true}
                  justifyContent="flex-start"
                />
              </Box>
            </Grid>
          )}

          {applicationFormData.visitorPerson.name && (
            <TextWithLabel title={t('security.labels.fullName')} text={applicationFormData.visitorPerson.name || ''} />
          )}
          {applicationFormData.visitorPerson.email && (
            <TextWithLabel title={t('security.labels.email')} text={applicationFormData.visitorPerson.email || ''} />
          )}
          {applicationFormData.visitorPerson.mobile && (
            <TextWithLabel
              title={t('security.labels.phone')}
              text={applicationFormData.visitorPerson.mobile || ''}
            />
          )}
          {applicationFormData.visitorPerson.company && (
            <TextWithLabel
              title={t('components.applications.fields.labels.visitorCompany')}
              text={applicationFormData.visitorPerson.company || ''}
            />
          )}
          {target && <TextWithLabel title={t('applications.subTitle.visiting')} text={target.name || ''} />}
          {facility && <TextWithLabel title={t('settings.labels.facilityOrVessel')} text={facility.area.name || ''} />}
          {purpose?.id && (
            <TextWithLabel
              title={t('settings.labels.purpose')}
              text={fromTranslations(translatable.getDefaultLocale(), purpose.i18n) || purpose.name}
            />
          )}
          {visitHandler && <TextWithLabel title={t('security.labels.visitHandler')} text={visitHandler} />}
          {applicationFormData.time.timeIn && (
            <TextWithLabel
              title={t('invoices.labels.startTime')}
              text={applicationFormData.time.timeIn.toFormat('dd.MM.yyyy HH:mm')}
            />
          )}
          {applicationFormData.time.timeOut && (
            <TextWithLabel
              title={t('invoices.labels.endTime')}
              text={applicationFormData.time.timeOut.toFormat('dd.MM.yyyy HH:mm')}
            />
          )}
          {user?.name && (
            <TextWithLabel title={t('common.labels.name')} text={applicationFormData?.visitorPerson.name} />
          )}
          {applicationFormData.visitorPerson.mobile && (
            <TextWithLabel title={t('security.labels.mobile')} text={applicationFormData.visitorPerson.mobile} />
          )}
          {applicationFormData.visitorPerson.company && (
            <TextWithLabel
              title={t('security.labels.visitingCompany')}
              text={applicationFormData.visitorPerson.company}
            />
          )}

          {applicationFormData.visitorVehicle.vehicle && (
            <TextWithLabel title={t('security.labels.vehicle')} text={applicationFormData.visitorVehicle.vehicle} />
          )}
          {applicationTrailerInput && applicationFormData.visitorVehicle.trailer && (
            <TextWithLabel
              title={t('components.applications.fields.labels.trailer')}
              text={applicationFormData.visitorVehicle.trailer || ''}
            />
          )}
          {applicationSubcontractorInput && applicationFormData.visitorVehicle.subcontractor && (
            <TextWithLabel
              title={t('applications.subTitle.subContractor')}
              text={applicationFormData.visitorVehicle.subcontractor || ''}
            />
          )}
          {applicationFormData.visiting.message.length > 0 ? (
            <Grid item xs={12}>
              <TextWithLabel
                title={t('applications.subTitle.visitingMessage')}
                text={applicationFormData.visiting.message}
              />
            </Grid>
          ) : null}
          {applicationFormData.visitorVehicle.files.some((file) => file.resolved) && (
            <TextWithLabel
              files
              title={t('components.applications.files.vehicle.title')}
              content={
                <>
                  {applicationFormData.visitorVehicle.files.map((f) =>
                    f.resolved ? (
                      <Link key={f.id} target="_blank" href={f.href}>
                        {f.filename}
                      </Link>
                    ) : null
                  )}
                </>
              }
            />
          )}
        </Grid>
      </FlowPage>
      <FlowNavigation>
        <Stack direction="row" width="100%" justifyContent="space-between">
          {/*  {formType === 'new' && (
            <Button color="inherit" onClick={handleReset}>
              {t('applications.actions.reset')}
            </Button>
          )} */}
          <Box display="flex" justifyContent="flex-end" width="100%">
            {formType === 'new' && (
              <Button variant="outlined" onClick={handleReset} sx={{ mr: 2 }}>
                {t('common.actions.notSend')}
              </Button>
            )}
            {application?.current.status === 'declined' && (
              <Button variant="outlined" onClick={handleOnBack}>
                {t('common.actions.back')}
              </Button>
            )}
            {application?.current.status !== 'declined' && (
              <Button variant="contained" type="submit" onClick={onSubmit} disabled={false}>
                {formType === 'edit' ? t('common.actions.update') : t('common.actions.yesSend')}
              </Button>
            )}
          </Box>
        </Stack>
      </FlowNavigation>
    </Stack>
  )
}

interface TextWithLabelProps {
  title: string
  text?: string
  content?: React.ReactNode
  files?: boolean
}
const TextWithLabel = ({ title, text, content, files }: TextWithLabelProps) => (
  <Grid item xs={12} sm={files ? 12 : 6}>
    <Stack direction="column">
      <Typography variant="caption">{title}</Typography>
      {text && <Typography variant="body1">{text}</Typography>}
      {content && content}
    </Stack>
  </Grid>
)

export default StepSubmit
