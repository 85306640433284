export const initials = (name: string, secondNameIndex?: number) => {
  const nameSplitted: string[] = name.split(' ');
  let initials: string = '';
  if (nameSplitted.length > 1) {
    const first: string = nameSplitted[0].substring(0, 1).toUpperCase();
    const second: string = nameSplitted[secondNameIndex ? secondNameIndex : nameSplitted.length - 1]
      .substring(0, 1)
      .toUpperCase();
    initials = first.concat(second);
  } else if (nameSplitted.length === 1) {
    initials = nameSplitted[0].substring(0, 2).toUpperCase();
  }
  return initials;
};
