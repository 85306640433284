import { AxiosInstance } from 'axios'
import { MuiColor } from '@griegconnect/krakentools-react-ui'
import { DateTime } from 'luxon'
import qs from 'qs'
import { IFileRefDto } from './dtos/fileRefDto'
import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { Vessel } from './VesselsApi'
import { By } from './By'
import { t } from 'i18next'

export namespace DoS {
  export type Status = 'created' | 'signed' | 'revoked' | 'valid'

  export interface Params {
    vessels?: string[]
    status?: Status[]
    ids?: string[]
    port?: string
    contactEmail?: string[]
  }

  export interface Summary {
    id: string
    port: ITenant
    contactEmail: string
    validFrom: string | null
    validTo: string | null
    status: Status
    document?: IFileRefDto
    vessel: Vessel
    by: By
  }

  export interface Form {
    contactEmail: string
    vessel: string
    validFrom: DateTime | null
    validTo: DateTime | null
    status: DoS.Status
    document: string
  }

  export interface ChipData {
    color: MuiColor
    label: string
  }

  export const getChipData = (status: DoS.Status): ChipData => {
    switch (status) {
      case 'signed':
        return { color: 'warning', label: t('common.status.signed') } as ChipData
      case 'created':
        return { color: 'default', label: t('common.status.created') } as ChipData
      case 'valid':
        return { color: 'success', label: t('common.status.valid') } as ChipData
      case 'revoked':
        return { color: 'error', label: t('common.status.revoked') } as ChipData
    }
  }
}

export class DoSApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async create(tenantRef: string, form: DoS.Form): Promise<void> {
    const response = await this.http.post(`/ports/${tenantRef}/dos`, form)
    return response.data
  }

  async update(tenantRef: string, dosId: string, form: DoS.Form): Promise<void> {
    const response = await this.http.put(`/ports/${tenantRef}/dos/${dosId}`, form)
    return response.data
  }

  async list(tenantRef: string, params: DoS.Params): Promise<DoS.Summary[]> {
    const response = await this.http.get(`/ports/${tenantRef}/dos`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async get(tenantRef: string, id: string): Promise<{ current: DoS.Summary; history: DoS.Summary[] }> {
    const response = await this.http.get(`/ports/${tenantRef}/dos/${id}`)
    return response.data
  }

  async revoke(tenantRef: string, id: string): Promise<void> {
    const response = await this.http.put(`/ports/${tenantRef}/dos/${id}/revoke`)
    return response.data
  }

  async approve(tenantRef: string, id: string): Promise<void> {
    const response = await this.http.put(`/ports/${tenantRef}/dos/${id}/approve`)
    return response.data
  }
}
