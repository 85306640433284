import { AxiosInstance } from 'axios'
import { Checkpoints } from './CheckpointApi'
import Page from './Page'
import qs from 'qs'
import { IFileRefDto } from './dtos/fileRefDto'
import { ChipProps } from '@mui/material'

type AttributeTypeName = 'Text' | 'Selection List'

export namespace Westec {
  export interface Settings {
    data: JsonConfig
    vehicleTypeId: number | null
    accessSyncEnabled: boolean | undefined
    syncUsers: string | null
  }

  export interface JsonConfig {
    idHolderTypes?: {
      vehicleType?: HolderType
      fixedType?: HolderType
      visitingType?: HolderType
    }
    cardTypes?: {
      pin?: CardType
    }
    pinLength?: number
  }

  export interface CardType {
    id: number
    name: string
  }

  export interface Credentials {
    uri: string
    apiKey: string
    certificate?: string
  }

  export interface Data {
    readers: Reader[]
    attributeTypes: AttributeTypes
    idHolderTypes: HolderType[]
    cardTypes: CardType[]
    accessGroups: Group[]
    facilities: FacilityAccess[]
  }

  export interface Installation {
    settings: Westec.Settings
    credentials?: Credentials
  }

  export interface CheckpointReaders {
    checkpoint: Checkpoints.CheckpointLink
    readers: number[]
  }

  export interface Reader {
    id: number
    name: string
  }

  export interface Group {
    id: number
    name: string
  }

  export interface FacilityAccess {
    facility: {
      id: string
      name: string
    }
    accessGroups: number[]
  }

  export interface FacilityAccessForm {
    accessGroups: number[]
  }

  export interface HolderType {
    id: number
    name: string
  }

  export interface AttributeTypes {
    [key: string]: AttributeType[]
  }

  export interface AttributeType {
    id: number
    description: string
    minLength: number | null
    maxLength: number | null
    typeName: AttributeTypeName
    values: AttributeValue[] | null
  }

  export interface AttributeValues {
    [key: string]: string
  }

  export interface AttributeValue {
    id: number
    description: string
  }

  export interface CardholderWithCards {
    cardholder: CardHolderWithAvatar
    cards: Card[]
  }
  export interface CardHolderWithAvatar {
    holder: CardHolder
    avatar: IFileRefDto | null
    accessGroups: number[]
    contract: string | null
    id: string
    installation: string
    remoteFaceUri: string
  }

  export interface CardHolder {
    holderId: number
    type: HolderType
    title: string | null
    firstName: string | null
    middleName: string | null
    surName: string | null
    attributes: AttributeValues
  }
  export interface ContractLink {
    id: string
    name: string
  }

  export interface CardHolderQuery {
    contract?: string[]
    name?: string[]
    type?: number
    page?: number
    pageSize?: number
  }

  export interface Card {
    id: number
    number: string
    state: number
    valid: string
    type: CardType
    physicalId: string
  }

  export interface CardHolderDetails {
    cardholder: CardHolder
    faceImage: IFileRefDto | null
    cards: Card[]
    accessGroups: Group[]
    contract?: ContractLink
  }

  export interface Type {
    id: number
    name: string
  }

  export interface CardHolderForm {
    type: Type
    title: string
    firstName: string
    middleName?: string
    surName: string
    pin?: string
    attributes: AttributeValues
    faceImage?: File
  }

  export interface EditableCardHolder {
    holderId: number
    type: Type
    title?: string
    firstName?: string
    middleName?: string
    surName?: string
    attributes: AttributeValues
  }

  export interface EditableImage {
    mediaType: string
    data: File
  }

  export interface CardHolderUpdateForm {
    holder: EditableCardHolder
    image?: EditableImage
  }

  export interface GroupForm {
    accessGroups: Group[]
  }
  export interface CardState {
    id: number
    label: string
    allowed: boolean
    color: ChipProps['color']
  }

  export const CardStates: CardState[] = [
    {
      id: 1,
      label: 'Activate', // ValidInUse
      allowed: true,
      color: 'success',
    },
    {
      id: 9,
      label: 'ValidBanned',
      allowed: false,
      color: 'success',
    },
    {
      id: 17,
      label: 'ValidLost',
      allowed: false,
      color: 'success',
    },
    {
      id: 33,
      label: 'ValidSuspended',
      allowed: false,
      color: 'success',
    },
    {
      id: 2,
      label: 'NotYetValidInUse',
      allowed: false,
      color: 'warning',
    },
    {
      id: 10,
      label: 'NotYetValidBanned',
      allowed: false,
      color: 'warning',
    },
    {
      id: 18,
      label: 'NotYetValidLost',
      allowed: false,
      color: 'warning',
    },
    {
      id: 34,
      label: 'NotYetValidSuspended',
      allowed: false,
      color: 'warning',
    },
    {
      id: 4,
      label: 'ExpiredInUse',
      allowed: false,
      color: 'warning',
    },
    {
      id: 12,
      label: 'ExpiredBanned',
      allowed: false,
      color: 'warning',
    },
    {
      id: 20,
      label: 'ExpiredLost',
      allowed: false,
      color: 'warning',
    },
    {
      id: 36,
      label: 'Deactivate', // ExpiredSuspended
      allowed: true,
      color: 'default',
    },
  ]
}

export const getCardState = (state: number): Westec.CardState => {
  return (
    Westec.CardStates.find((s) => s.id === state) || {
      id: 0,
      label: 'Unknown',
      allowed: false,
      color: 'default',
    }
  )
}

export class WestecApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async get(tenantRef: string): Promise<Westec.Installation | null> {
    try {
      const res = await this.http.get(`/ports/${tenantRef}/westec`)
      return res.data
    } catch (error) {
      if (error.response.status === 404) {
        return null
      } else {
        throw error
      }
    }
  }
  async getSettings(tenantRef: string): Promise<Westec.Settings | null> {
    try {
      const res = await this.http.get(`/ports/${tenantRef}/westec/settings`)
      return res.data
    } catch (error) {
      if (error.response.status === 404) {
        return null
      } else {
        throw error
      }
    }
  }

  async setSettings(tenantRef: string, config: Westec.Settings): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/westec/settings`, config)
  }

  async setCredentials(tenantRef: string, config: Westec.Credentials): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/westec/`, config)
  }

  async getCheckpointMapping(tenantRef: string): Promise<Westec.CheckpointReaders[]> {
    try {
      const res = await this.http.get(`/ports/${tenantRef}/westec/checkpoints`)
      return res.data.checkpoints
    } catch (error) {
      if (error.response.status === 404) {
        return []
      } else {
        throw error
      }
    }
  }

  async setCheckpointMapping(tenantRef: string, mapping: Westec.CheckpointReaders): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/westec/checkpoints`, mapping)
  }

  async getData(tenantRef: string): Promise<Westec.Data> {
    try {
      const res = await this.http.get(`/ports/${tenantRef}/westec/data`)
      return res.data
    } catch (error) {
      if (error.response.status === 404) {
        return {
          readers: [],
          idHolderTypes: [],
          cardTypes: [],
          attributeTypes: {},
          accessGroups: [],
          facilities: [],
        }
      } else {
        throw error
      }
    }
  }

  async cardHolders(tenantRef: string, query: Westec.CardHolderQuery): Promise<Page<Westec.CardholderWithCards>> {
    const params = { ...query }
    const response = await this.http.get(`/ports/${tenantRef}/westec/cardholders`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })

    return response.data
  }

  async cardHolder(tenantRef: string, id: string): Promise<Westec.CardHolder | null> {
    try {
      const response = await this.http.get(`/ports/${tenantRef}/westec/cardholders/${id}`)
      return response.data
    } catch (e) {
      if (e.response.status === 404) return null
      throw e
    }
  }

  async cardHolderDetails(tenantRef: string, id: string): Promise<Westec.CardHolderDetails | null> {
    try {
      const response = await this.http.get(`/ports/${tenantRef}/westec/cardholders/${id}/details`)
      return response.data
    } catch (e) {
      if (e.response.status === 404) return null
      throw e
    }
  }

  async addCardHolder(tenantRef: string, form: Westec.CardHolderForm): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/westec/cardholders`, form)
    return response.data.local
  }

  async updateCardHolder(tenantRef: string, id: string, form: Westec.CardHolderUpdateForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/westec/cardholders/${id}/edit`, form)
  }

  async refreshUser(tenantRef: string, id: string): Promise<void> {
    await this.http.get(`/ports/${tenantRef}/westec/cardholders/${id}/refresh`)
  }

  async updateCardHolderImageFile(tenantRef: string, id: string, file: File): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/westec/cardholders/${id}/image`, file)
  }

  async setAccessGroups(tenantRef: string, id: string, form: Westec.GroupForm): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/westec/cardholders/${id}/accessGroups`, form)
    return response.data.id
  }

  async setContract(tenantRef: string, id: string, contract?: string): Promise<string> {
    const response = await this.http.put(`/ports/${tenantRef}/westec/cardholders/${id}/contract`, {
      contract: contract ? contract : null,
    })
    return response.data.id
  }

  async deleteUser(tenantRef: string, userId: string): Promise<void> {
    await this.http.delete(`/ports/${tenantRef}/westec/cardholders/${userId}`)
  }

  async updateCard(tenantRef: string, cardHolder: number, cardId: number, card: Westec.Card): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/westec/cardholders/${cardHolder}/cards/${cardId}`, card)
  }

  async updateFacility(tenantRef: string, facility: string, updateFacility: Westec.FacilityAccessForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/westec/facilities/${facility}`, updateFacility)
  }
}
