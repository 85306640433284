import { FileUpload } from '@app/common/Form'
import * as Form from '@griegconnect/krakentools-form'
import { useUser, useUsersApi } from '@griegconnect/krakentools-react-kraken-app'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, MenuItem } from '@mui/material'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { NextState, StepProps, applicationState, nextStateAtom } from '.'
import { ApplicationFormData, VisitorPersonForm, VisitorPersonSchema } from '../schema'
import { supportedLanguagesTranslated } from '@griegconnect/krakentools-react-ui'
import { useEffect } from 'react'
import FlowNavigation from '../Components/FlowNavigation'
import FlowPage from '../Components/FlowPage'
import FlowHeader from '../Components/FlowHeader'

const StepVisitor: React.FC<StepProps> = ({ portDetails, formType, application }) => {
  const { t, i18n } = useTranslation()
  const usersApi = useUsersApi()
  const user = useUser()
  const [applicationFormData, setApplicationFormData] = useRecoilState<ApplicationFormData>(applicationState)
  const setState = useSetRecoilState<NextState>(nextStateAtom)

  const disabled = application?.current.status === 'declined'

  const form = useForm<VisitorPersonForm>({
    defaultValues: applicationFormData.visitorPerson,
    resolver: zodResolver(VisitorPersonSchema),
  })
  const locale = form.watch('locale')

  useEffect(() => {
    if (locale && locale !== i18n.language) {
      i18n.changeLanguage(locale)
    }
  }, [locale, i18n])

  const personFileArray = useFieldArray({
    control: form.control,
    name: 'files',
  })

  const onSubmit = (data: VisitorPersonForm) => {
    const go = async () => {
      try {
        await usersApi.updateCurrentUserMetadata({ theme: user?.metadata.theme ?? '', locale: data.locale })
        await usersApi.updateCurrentUser(data.name, user?.mobile ?? '')
      } catch (e) {
        console.log("Error updating user's metadata", e)
      }
    }
    go()
    setApplicationFormData((p) => ({ ...p, visitorPerson: data }))
    setState((p) => ({ ...p, currentStep: p.currentStep + 1 }))
  }
  const hasEmailInProfile = (user?.email && user.email_verified) || false
  const hasPhoneInProfile = (user?.mobile && user.mobile_verified) || false
  const validPhone = Form.validatePhone(applicationFormData.visitorPerson.mobile)

  return (
    <FormProvider {...form}>
      <Box width={'100%'}>
        <FlowPage>
          <FlowHeader
            i18n={
              portDetails.attributes.nextGenUserPhotoOverId
                ? 'applications.subTitle.fillDetailsAndUploadPhoto'
                : 'applications.subTitle.fillDetailsAndUploadID'
            }
          />
          <Form.TextInput name="name" label={t('security.labels.fullName')} required disabled={disabled} />
          {(!hasPhoneInProfile || !validPhone) && (
            <Form.TextInput
              name="mobile"
              label={t('security.labels.phone')}
              disabled={(hasPhoneInProfile && validPhone) || disabled}
              required
            />
          )}
          {!hasEmailInProfile && (
            <Form.TextInput
              name="email"
              label={t('security.labels.email')}
              disabled={hasEmailInProfile || disabled}
              type="email"
              required
            />
          )}
          <Form.TextInput name="company" label={t('components.applications.fields.labels.visitorCompany')} disabled={disabled} />
          <Form.Select name="locale" label={t('security.labels.language')} required>
            {Object.entries(supportedLanguagesTranslated).map(([langTag, langName]) => (
              <MenuItem value={langTag} key={langTag}>
                {langName}
              </MenuItem>
            ))}
          </Form.Select>
          <FileUpload
            arrayMethods={personFileArray}
            control={form.control}
            multiple={false}
            kind="photos"
            name={`files`}
            title={
              portDetails.attributes.nextGenUserPhotoOverId
                ? `${t('components.applications.files.personPhoto.title')} *`
                : `${t('components.applications.files.personId.title')} *`
            }
            uploadLabel={
              portDetails.attributes.nextGenUserPhotoOverId
                ? t('components.applications.files.personPhoto.upload')
                : t('components.applications.files.personId.upload')
            }
            modifyLabel={
              portDetails.attributes.nextGenUserPhotoOverId
                ? t('components.applications.files.personPhoto.change')
                : t('components.applications.files.personId.change')
            }
            disabled={disabled}
          />
        </FlowPage>
        <FlowNavigation>
          <Button variant="contained" type="submit" onClick={form.handleSubmit(onSubmit, (err) => console.error(err))}>
            {t('common.actions.continue')}
          </Button>
        </FlowNavigation>
      </Box>
    </FormProvider>
  )
}

export default StepVisitor
