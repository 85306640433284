import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type RedirectProps = {
  href: string
}
export const Redirect = ({ href }: RedirectProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(href, { replace: true })
  })
  return <></>
}

type RewriteUriProps = {
  search: string
  replace: string
}
export const RewriteUri = ({ search, replace }: RewriteUriProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    navigate(location.pathname.replace(search, replace), { replace: true })
  })
  return <></>
}
