import { useLocalization } from '@griegconnect/krakentools-react-kraken-app'
import { Page } from '@griegconnect/krakentools-react-ui'
import { Box, Chip, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import humanizeDuration from 'humanize-duration'
import { DateTime, Interval } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useServices } from 'src/common/ServicesWrapper'
import { UserApi } from '../../../common/ServicesWrapper/apis/UserApi'
import VisitorAvatarGroup from '../../../common/VisitorAvatarGroup'

interface IPermitProps {
  permit: UserApi.Permit
}

const PermitCard = ({ permit }: IPermitProps) => {
  const { t } = useTranslation()
  const { language } = useLocalization()
  const { services } = useServices()

  const getValidStatus = (timeIn: DateTime, timeOut: DateTime): string => {
    const duration = timeOut.diff(now, ['days', 'hours', 'minutes'])

    const langCodes = {
      'fi-FI': 'fi',
      'nb-NO': 'no',
      'en-GB': 'en',
    }

    return timeIn > now
      ? t('applications.status.notValid')
      : now > timeOut
      ? t('applications.status.expired')
      : t('applications.status.validDuration', {
          duration: humanizeDuration(duration.toMillis(), {
            language: langCodes[language],
            fallbacks: ['en'],
            largest: 1,
            round: true,
            delimiter: ', ',
          }),
        })
  }
  const pinQuery = useQuery<string>(['pin', permit.pin, permit.meta.id], () =>
    services.sharedDataApi
      .getPortDetails(permit.visiting.port.ref)
      .then((res) =>
        res.attributes.pinFormat && permit.pin ? res.attributes.pinFormat.replace('%s', permit.pin) : permit.pin || ''
      )
  )

  const now = DateTime.now()
  const dateIn = Interval.fromISO(permit.visiting.valid).start
  const dateOut = Interval.fromISO(permit.visiting.valid).end
  const status = permit.meta.revoked ? t('applications.status.revoked') : getValidStatus(dateIn, dateOut)
  const active = !permit.meta.revoked && dateIn < now && now < dateOut

  return (
    <Page.Paper style={{ height: '100%' }}>
      <Box display="flex" flexDirection="row" alignItems="flex-start">
        <Box flexDirection="column" flex={1} style={{ textOverflow: 'ellipsis' }}>
          <Typography variant="h5">{permit.visiting.port.name}</Typography>
          <Typography variant="subtitle2">{permit.visiting.facility.name}</Typography>
          <Box mt={1} />
          <Typography variant="body1">
            {t('common.labels.timeFrom')}: {dateIn.toLocaleString(DateTime.DATETIME_SHORT)}
          </Typography>
          <Typography variant="body1">
            {t('common.labels.timeTo')}: {dateOut.toLocaleString(DateTime.DATETIME_SHORT)}
          </Typography>
          {permit.pin !== null && (
            <Box mt={2}>
              <Typography key={`permit-pin-${permit.pin}`} variant="h6">
                PIN: {pinQuery.data}
              </Typography>
            </Box>
          )}
          <Grid container spacing={2}>
            {permit.visitor.vehicle && (
              <Grid item xs={12} sm={6} key={`vehicle`}>
                <Box display="flex" flexDirection="row" my={2} alignItems="center">
                  <VisitorAvatarGroup users={[]} vehicles={[[`${permit.visitor.vehicle.vehicle}`]]} />
                  <Typography variant="body1" ml={1}>
                    {permit.visitor.vehicle.vehicle}
                  </Typography>
                </Box>
                {permit.visitor.pin && <Typography variant="h6">{`Pin: ${permit.visitor.pin}`}</Typography>}
              </Grid>
            )}
          </Grid>
        </Box>
        <Box display="flex" alignItems="flex-start" justifyContent="center" pl={2}>
          <Chip color={active ? 'success' : 'warning'} label={status} />
        </Box>
      </Box>
    </Page.Paper>
  )
}
export default PermitCard
