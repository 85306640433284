import {AxiosInstance} from 'axios'
import * as Vehicles from './types/Vehicles'

export class VehicleApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async get(regno: string): Promise<Vehicles.Kjoretoydata | null> {
    try {
      const response = await this.http.get(`/vehicle?regno=${regno}`)
      return response.data
    } catch (error) {
      return null
    }
  }
}
