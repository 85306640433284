import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { TranslatableTextField, TranslatableTextFieldProps } from './TranslatableTextField'

interface TranslatableFormInputProps<TField extends FieldValues> extends TranslatableTextFieldProps {
  control: Control<TField>
  name: Path<TField>
}

export const TranslatableFormInput = <TField extends FieldValues>(props: TranslatableFormInputProps<TField>) => {
  const { control, ...rest } = props
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => (
        <TranslatableTextField onChange={(e) => field.onChange(e)} value={field.value} {...rest} />
      )}
    />
  )
}
