import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { AxiosInstance } from 'axios'
import qs from 'qs'
import { AreaLink } from './dtos/areaDto'
import { IUserDto } from './dtos/userDto'

export declare namespace Port {
  export interface Organisation {
    organisationId: number,
    companyId: string | null
    name: string
    organisationNumber: string
    statusCode: string
    organisationGroupCode: string
    countryCode: string
    languageCode: string
    customerLedgerId: string
    payTermCode: string
    invoiceIntervalCode: string
    hasAgreementGiro: boolean
    hasCurrencyCost: boolean
    hasInvoiceCost: boolean
    fsaTransp: boolean
    invoiceDeliveryMethodCode: string
    collectionFee: boolean
    groupCargoInvoice: boolean
    groupServiceInvoice: boolean
    externalUse: boolean
    separateServicePerVehicle: boolean
    invoiceSecurityPassings: boolean
  }
  export interface Form {
    companyId?: string
    name?: string
    organisationNumber?: string
    countryCode?: string
    tenant?: string
    createTenantWithInvite?: string
  }

  export interface LinkForm {
    organisationId: number
    tenant?: string,
    admin?: string
  }
  export interface Params{
    query?: string
    pageSize: number
    page: number
  }

}
export declare namespace Tenants {
  export interface Params {
    ref?: string
    id?: string
  }
}

export declare namespace OnSite {

  export interface Details {
    name: string
    contract: string
    enforceOneTimeVisit: boolean
    facilities: AreaLink[]
    visitHandlers: IUserDto[]
  }
}


export class CompaniesApi {
  private readonly http: AxiosInstance
  constructor(http: AxiosInstance) {
    this.http = http
  }

  async searchPortCustomer(ref: string, params: Port.Params | null): Promise<Port.Organisation[]> {
    const qp = qs.stringify(params)
    const url = params ? `/ports/${ref}/companies/port?${qp}`: `/ports/${ref}/companies/port`
    const response = await this.http.get(url)
    return response.data
  }

  async searchTenant(ref: string, params: Tenants.Params): Promise<ITenant | null> {
    const qp = qs.stringify(params)
    const response = await this.http.get(`/ports/${ref}/companies/tenants?${qp}`)
    return response.data
  }

  async companiesOnSite(ref: string): Promise<OnSite.Details[]> {
    const response = await this.http.get(`/ports/${ref}/companies/on_site`)
    return response.data
  }
}
