import React, { useState } from 'react'
import { Button } from '@mui/material'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { ResolvedFile } from './ServicesWrapper/apis/dtos/fileRefDto'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@griegconnect/krakentools-react-ui'
import { useTranslation } from 'react-i18next'

export interface FileDownloadButtonProps {
  resolvedFile: ResolvedFile
  label?: string
}

export const FileImageButton: React.FC<React.PropsWithChildren<FileDownloadButtonProps>> = ({
  resolvedFile,
  label,
}) => {
  const { t } = useTranslation()

  const [showDialog, setShowDialog] = useState<boolean>(false)

  const { href, filename } = resolvedFile

  const cappedFilename = () => {
    if (!label && filename.length > 40) {
      return `${filename.substring(0, 40)}...`
    } else {
      return filename
    }
  }
  const showImage = () => {
    setShowDialog(true)
  }

  return (
    <div>
      <Button color="primary" endIcon={<AttachmentIcon />} onClick={showImage}>
        {label ? label : cappedFilename()}
      </Button>
      <Dialog onClose={() => setShowDialog(false)} open={showDialog}>
        <DialogTitle>{resolvedFile.filename}</DialogTitle>
        <DialogContent>
          <img src={href} alt={resolvedFile.filename} style={{ maxWidth: 800, maxHeight: 400 }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary" autoFocus>
            {t('common.actions.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
