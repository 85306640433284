import { Autocomplete as AutocompleteMUI, TextField, CircularProgress } from '@mui/material'
import { Control, Controller, FieldValues, Path, useFormState, get } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'

interface ControllerAutoCompleteProps<O extends { id: string; label: string }, TField extends FieldValues> {
  control: Control<TField>
  label: string
  name: Path<TField>
  options: O[]
  multiple?: boolean
  noOptionsText?: string
  onInputChange?: (value: number | string) => void
  autoHighlight?: boolean
  fullWidth?: boolean
  disabled?: boolean
  required?: boolean
  loading?: boolean
  autoFocus?: boolean
  sx?: AutocompleteMUI['sx']
  defaultValue?: O | null
}

export const AutoComplete = <O extends { id: string; label: string }, TField extends FieldValues>(
  props: ControllerAutoCompleteProps<O, TField>
) => {
  const {
    sx,
    control,
    defaultValue,
    label,
    name,
    multiple = false,
    options,
    onInputChange,
    autoHighlight = true,
    noOptionsText,
    loading,
    autoFocus,
    fullWidth = true,
    disabled = false,
    required = false,
  } = props
  const { errors } = useFormState()
  const error = get(errors, name)
  const { t } = useTranslation()

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const { value, onChange, ref } = field
          return (
            <AutocompleteMUI
              sx={sx}
              defaultValue={defaultValue}
              getOptionLabel={(option) => {
                return option.label ?? ''
              }}
              onChange={(_, newValue) => {
                const setVal = newValue
                  ? Array.isArray(newValue)
                    ? newValue.map((v) => v.id)
                    : (newValue as O)?.id
                  : null
                onChange(setVal)
              }}
              loading={loading}
              id={name}
              options={options}
              renderInput={(params) => (
                <TextField
                  key={params.id}
                  {...params}
                  variant="filled"
                  label={label}
                  ref={ref}
                  required={required}
                  autoFocus={autoFocus}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={15} sx={{ mb: 2 }} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              )}
              fullWidth={fullWidth}
              noOptionsText={noOptionsText || t('common.paragraphs.noOptions')}
              onInputChange={(e, v) => {
                onInputChange && onInputChange(v)
              }}
              autoHighlight={autoHighlight}
              isOptionEqualToValue={(o, v) => {
                return o.id === v.id
              }}
              disabled={disabled}
              multiple={multiple}
            />
          )
        }}
      />
      {error && error.message && <ErrorHelperText error={error.message} />}
    </>
  )
}
