import { useRecoilState } from 'recoil'
import { NextState, StepProps, WalkingOrDrivingType, nextStateAtom } from '.'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import { VehicleTruck } from '@griegconnect/krakentools-react-icons'
import FlowHeader from '../Components/FlowHeader'
import { Box, Button, Grid, Stack } from '@mui/material'
import WizardButton from '../Components/WizardButton'
import { useTranslation } from 'react-i18next'
import FlowPage from '../Components/FlowPage'
import FlowNavigation from '../Components/FlowNavigation'

export const StepWalkingOrDriving: React.FC<StepProps> = ({ formType, resetForm }) => {
  const [state, setState] = useRecoilState<NextState>(nextStateAtom)
  const { t } = useTranslation()

  const handleDone = (type: WalkingOrDrivingType) => {
    setState((prevState) => ({
      ...prevState,
      mode: 'schema',
      visitBy: type,
      currentStep: prevState.currentStep + (type === 'walking' ? 2 : 1),
    }))
  }

  const handleReset = () => resetForm && resetForm()

  // const onBack = () => setState((p) => ({ ...p, currentStep: p.currentStep - 1 }))

  if (state.visitBy === undefined) {
    return (
      <>
        <FlowHeader i18n="applications.subTitle.walkingOrDriving" />
        <Grid container spacing={1} mt={2}>
          <Grid item xs={6}>
            <WizardButton
              icon={DirectionsWalkIcon}
              onClick={() => handleDone('walking')}
              buttonText={t('applications.actions.walking')}
            />
          </Grid>
          <Grid item xs={6}>
            <WizardButton
              icon={VehicleTruck}
              onClick={() => handleDone('driving')}
              buttonText={t('applications.actions.byVehicle')}
            />
          </Grid>
        </Grid>
      </>
    )
  } else {
    return (
      <Box width="100%">
        <FlowPage>
          <Stack direction="column" spacing={2} justifyContent="center" alignItems="flex-start">
            <FlowHeader i18n="applications.subTitle.visitingWhat" />
            <Stack direction="row" spacing={2} width="100%">
              <WizardButton
                icon={DirectionsWalkIcon}
                onClick={() => handleDone('walking')}
                buttonText={t('applications.actions.walking')}
                selected={state.visitBy === 'walking'}
              />
              <WizardButton
                icon={VehicleTruck}
                onClick={() => handleDone('driving')}
                buttonText={t('applications.actions.byVehicle')}
                selected={state.visitBy === 'driving'}
              />
            </Stack>
          </Stack>
        </FlowPage>
        <FlowNavigation>
          <Stack direction="row" width="100%" justifyContent="space-between">
            {formType === 'new' && (
              <Button color="inherit" onClick={handleReset}>
                {t('applications.actions.reset')}
              </Button>
            )}
            <Box display="flex" justifyContent="flex-end" width="100%">
              {/* <Button variant="outlined" onClick={onBack} style={{ marginRight: 10 }}>
                {t('common.actions.back')}
              </Button> */}
              <Button
                variant="contained"
                type="submit"
                onClick={() =>
                  setState((p) => ({ ...p, currentStep: p.currentStep + (state.visitBy === 'walking' ? 2 : 1) }))
                }
              >
                {t('common.actions.continue')}
              </Button>
            </Box>
          </Stack>
        </FlowNavigation>
      </Box>
    )
  }
}
export default StepWalkingOrDriving
