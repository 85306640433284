import { ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@mui/icons-material'
import { useRecoilState } from 'recoil'
import { useMemo } from 'react'
import clsx from 'clsx'
import { useMapContext, visibleLayersSelector, LayerMenuItem } from '@griegconnect/krakentools-react-kmap'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      paddingLeft: theme.spacing(1.5),
      opacity: 0.6,
    },
    button: {
      padding: theme.spacing(0.5),
    },
    actions: {
      right: theme.spacing(1),
    },
    active: {
      opacity: 0.87,
    },
  })
)

export type LayerListItemProps = {
  layer: LayerMenuItem
}

export const LayerListItem = (props: LayerListItemProps) => {
  const { kmapInstance: instance } = useMapContext()
  const classes = useStyles()
  const [visibleLayers, setVisibleLayers] = useRecoilState(visibleLayersSelector(instance.mapElementId))

  const onCheckVisibilityHandler = () => {
    setVisibleLayers((prev) => {
      if (prev.find((l) => l === props.layer.key)) {
        return prev.filter((l) => l !== props.layer.key)
      } else {
        return prev.concat(props.layer.key)
      }
    })
  }

  const isVisible = useMemo(() => {
    return visibleLayers.find((l) => l === props.layer.key) ? true : false
  }, [visibleLayers]) // eslint-disable-line

  return (
    <ListItem>
      <ListItemText
        className={clsx(classes.text, isVisible ? classes.active : undefined)}
        primaryTypographyProps={{
          variant: 'body2',
          color: 'textPrimary',
          component: 'div',
        }}
      >
        <Typography>{props.layer.name}</Typography>
      </ListItemText>
      <ListItemSecondaryAction className={classes.actions}>
        <IconButton className={classes.button} onClick={onCheckVisibilityHandler} size="large">
          {isVisible ? <VisibilityOutlinedIcon color="primary" /> : <VisibilityOffOutlinedIcon color="disabled" />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default LayerListItem
