export const isValidUrl = (url: string) => {
  try {
    // eslint-disable-next-line
    const test = new URL(url)
    return true
  } catch (error) {
    return false
  }
}

export const addQueryParms = (
  params: { [key: string]: string | string[] },
  ignoreEmpty: boolean = true,
  url?: string
): string => {
  const dummyUrl = 'https://dummy.placeholder.com'
  const isValid = url ? isValidUrl(url) : false
  const newUrl = new URL(url && isValid ? url : url ? `${dummyUrl}${url}` : dummyUrl)
  for (const [itemKey, itemValue] of Object.entries(params)) {
    if (Array.isArray(itemValue)) {
      for (const arrayItem of itemValue) {
        if ((arrayItem && arrayItem.length > 0 && ignoreEmpty) || ignoreEmpty === false) {
          newUrl.searchParams.append(itemKey, arrayItem.toString().trim())
        }
      }
    } else {
      const cleanValue = itemValue.trim()
      if ((cleanValue && cleanValue.length > 0 && ignoreEmpty) || ignoreEmpty === false) {
        newUrl.searchParams.append(itemKey, cleanValue)
      }
    }
  }
  const newUrlAsString = url && isValid ? newUrl.toString() : newUrl.toString().replace(dummyUrl, '')
  return newUrlAsString
}

// copied from haulier
export const validateEmail = (email: string) => {
  const expression = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/
  return expression.test(String(email).toLowerCase())
}
