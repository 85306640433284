import { PortData } from '@app/common/ServicesWrapper/apis/SharedDataApi'
import { useServices } from '@app/common/ServicesWrapper/index'
import useSearch, { Search as S } from '@app/lib/hooks/useSearch'
import { Page } from '@griegconnect/krakentools-react-ui'
import { List, ListItem, ListItemText } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const NewSelector: React.FC = () => {
  const { services } = useServices()
  const { t } = useTranslation()
  const [search] = useSearch({ port: S.string })
  const ports = useQuery<PortData[], Error>(['getPorts', search.port], () =>
    services.sharedDataApi.getPorts(search.port ? { id: search.port } : { applications: true })
  )
  return (
    <Page.Wrapper title={t('applications.module.portSelect')} showBackButton={true} maxWidth="md">
      <Page.Paper>
        <List>
          {(ports.data || []).map((port) => (
            <ListItem key={port.tenant.id} button component={Link} to={`/user/application/new/${port.tenant.ref}`}>
              <ListItemText>{port.tenant.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Page.Paper>
    </Page.Wrapper>
  )
}

export default NewSelector
