import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgUploadCloud = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M13 16v-4.586l1.293 1.293a1 1 0 0 0 1.414-1.414l-3-3a1 1 0 0 0-1.414 0l-3 3a1 1 0 1 0 1.414 1.414L11 11.414V16a1 1 0 1 0 2 0Z" />,
    <path d="M5.35 8.04A7.496 7.496 0 0 1 12 4a7.49 7.49 0 0 1 7.35 6.04c2.6.18 4.65 2.32 4.65 4.96 0 2.76-2.24 5-5 5H6c-3.31 0-6-2.69-6-6 0-3.09 2.34-5.64 5.35-5.96Zm12.04 2.39A5.503 5.503 0 0 0 12 6C9.94 6 8.08 7.14 7.13 8.97l-.25.476a1 1 0 0 1-.783.529l-.537.055A3.973 3.973 0 0 0 2 14c0 2.21 1.79 4 4 4h13c1.65 0 3-1.35 3-3a2.98 2.98 0 0 0-2.78-2.96l-.77-.055a1 1 0 0 1-.91-.802l-.15-.753Z" />
  )
export default SvgUploadCloud
