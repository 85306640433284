import { AxiosInstance } from 'axios'
import { By } from './By'
import { CheckListField, CheckListFieldForm } from './CheckListsApi'
import { IAreaDto } from './dtos/areaDto'
import { Vessel } from './VesselsApi'
import qs from 'qs'
import Page from './Page'
import { IUserDto } from './dtos/userDto'
import { DateTime } from 'luxon'
import { Search as S, Infer } from '@app/lib/hooks/useSearch'

export type Status = 'completed' | 'in_progress' | 'todo'

export interface Completion {
  status: Status
  assigned?: IUserDto
}

export interface IVesselCheck {
  id: string;
  facility: IAreaDto
  vessel: Vessel
  summary: string
  modified: By
  negative: number
  time: string
  completion: Completion
}

export interface IVesselCheckParams {
  facility?: string[]
  summary?: string[]
  deviations?: boolean
}

export interface IVesselCheckDetails {
  vesselCheck: IVesselCheck
  fields: CheckListField[]
}

export interface IVesselCheckForm {
  facility: string
  time: DateTime
  vessel: string
  fields: CheckListFieldForm[]
  summary: string
  status: 'completed' | 'todo'
  assignee?: string
}

export const ParamsSchema = {
  ...Page.schema,
  facility: S.array(S.string),
  summary: S.array(S.string),
  deviations: S.undefine(S.boolean),
}
export type Params = Infer<typeof ParamsSchema>

export class VesselCheckApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async list(tenantRef: string, search: IVesselCheckParams, pagination: Page.Search): Promise<Page<IVesselCheck>> {
    const params = { ...search, ...pagination }
    const response = await this.http.get(`/ports/${tenantRef}/vesselChecks`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async get(tenantRef: string, id: string): Promise<IVesselCheckDetails> {
    const response = await this.http.get(`/ports/${tenantRef}/vesselChecks/${id}`)
    return response.data
  }

  async create(tenantRef: string, form: IVesselCheckForm): Promise<string> {
    const postData = { ...form, time: form.time.toUTC() }
    const response = await this.http.post(`/ports/${tenantRef}/vesselChecks`, postData)
    return response.data.id;
  }

  async update(tenantRef: string, id: string, form: IVesselCheckForm): Promise<void> {
    const postData = { ...form, time: form.time.toUTC() }
    await this.http.put(`/ports/${tenantRef}/vesselChecks/${id}`, postData)
  }

  async delete(tenantRef: string, id: string): Promise<string> {
    const response = await this.http.delete(`/ports/${tenantRef}/vesselChecks/${id}`)
    return response.data.id
  }
}
