import React from 'react'
import { Navigation } from '@griegconnect/krakentools-react-kraken-app'
import { withActiveTenant } from '@app/routes/WithActiveTenant'
import { withServices } from '@app/routes/withServices'
import { PATH_TENANT_BASE, Permissions, PERMISSIONS } from '@app/constants'
import { withParam } from '@app/routes/withParam'
import { AccessDependencies } from '@app/Content'
import { requireAllGlobalPermissions } from '@app/lib/access'
import { Trans } from 'react-i18next'

const PATH = PATH_TENANT_BASE + '/admin/openpath'

const openpathActive = (d: AccessDependencies) => d.portConfig.openpath

export const openpathModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: 'Openpath',
    path: PATH + '/',
    page: React.createElement(
      withServices(
        withActiveTenant(
          withParam(
            React.lazy(() => import('./settings')),
            'settings'
          )
        )
      )
    ),
    exact: true,
    menu: true,
    access: (d) =>
      openpathActive(d) && requireAllGlobalPermissions(Permissions.all(PERMISSIONS.global_access_system))(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.global.settings" />,
        path: PATH + '/:settings',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./settings')),
                'settings'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          openpathActive(d) && requireAllGlobalPermissions(Permissions.all(PERMISSIONS.global_access_system))(d),
      },
      {
        name: <Trans i18nKey="navigation.openpath.newOpenpathGroup" />,
        path: PATH + '/group/new',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Group')),
                'groupId'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          openpathActive(d) && requireAllGlobalPermissions(Permissions.all(PERMISSIONS.global_access_system))(d),
      },
      {
        name: <Trans i18nKey="navigation.openpath.openpathGroup" />,
        path: PATH + '/group/edit/:groupId',
        page: React.createElement(
          withServices(
            withActiveTenant(
              withParam(
                React.lazy(() => import('./Group')),
                'groupId'
              )
            )
          )
        ),
        exact: true,
        access: (d) =>
          openpathActive(d) && requireAllGlobalPermissions(Permissions.all(PERMISSIONS.global_access_system))(d),
      },
    ],
  },
]
