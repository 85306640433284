import { AxiosInstance } from 'axios'
import { IUserDto } from './dtos/userDto'

export interface SearchParams {
  q: string
}

export interface CreatePortUser {
  name: string
  mobile?: string
}

export class PortUserApi {
  private readonly http: AxiosInstance
  constructor(http: AxiosInstance) {
    this.http = http
  }

  async get(tenantRef: string, id: string): Promise<IUserDto> {
    const response = await this.http.get(`/ports/${tenantRef}/users/${encodeURIComponent(id)}`)
    return response.data
  }

  async create(tenantRef: string, form: CreatePortUser): Promise<IUserDto> {
    const response = await this.http.post(`/ports/${tenantRef}/users/`, form)
    return response.data
  }

  async search(tenantRef: string, params: SearchParams): Promise<IUserDto[]> {
    const response = await this.http.get(`/ports/${tenantRef}/users`, { params })
    return response.data
  }
}
