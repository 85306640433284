import { Typography, Box } from '@mui/material'
import { IWidget, WidgetCard } from '@griegconnect/krakentools-react-ui'
import { Port } from '@griegconnect/krakentools-react-icons'
import { Widgets } from '../Types'
import * as SkeletonLoader from '../SkeletonLoader'
import { Quays } from '@app/common/ServicesWrapper/apis/QuayApi'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from "react-i18next"

export const Widget: React.VFC<Widgets.InfoWithData<Quays.Quay[]>> = ({
  widgetConstructor,
  data,
  onRemove,
  onDetails,
  onSettings,
  editing,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const quay = widgetConstructor.quay ? data?.find((d) => d.name === widgetConstructor.quay) : undefined
  return quay ? (
    <WidgetCard
      title={widgetConstructor.name || t('components.dashboard.labels.quayStatus')}
      description={`${widgetConstructor.quay || ''} - ${quay.facility.name}`}
      icon={<Port />}
      onDetails={onDetails}
      editing={editing}
      onRemove={onRemove}
      onSettings={onSettings}
    >
      <Box display="flex" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Typography
          fontSize={40}
          style={{
            color: !!quay.data.secured ? theme.palette.success.main : theme.palette.error.main,
          }}
        >
          {!!quay.data.secured ? t('components.quayStatus.status.secured') : t('components.quayStatus.status.notSecured')}
        </Typography>
      </Box>
    </WidgetCard>
  ) : (
    <SkeletonLoader.Value />
  )
}

export const quayStatusWidget = (props: Widgets.InfoWithData<Quays.Quay[]>): IWidget[] => {
  const { widgetConstructor } = props
  return widgetConstructor.quay
    ? [
        {
          id: widgetConstructor.id,
          isResizable: true,
          exactWidth: widgetConstructor.width,
          position: widgetConstructor.position,
          component: <Widget {...props} />,
        },
      ]
    : []
}

export default quayStatusWidget
