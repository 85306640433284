import { useAuth } from '@griegconnect/krakentools-react-kraken-app'
import {
  MapProvider,
  FullscreenControl,
  ZoomControl,
  GpsControl,
  SpotlightControl,
  LiveView,
  activeAssetLayersSelector,
  mapZoomLevelSelector,
  mapCenterSelector,
  assetLayersSelector,
  MapButtonPanel,
  LayerControl,
} from '@griegconnect/krakentools-react-kmap'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useConfig } from '@griegconnect/krakentools-react-kraken-app'
import { WithActiveTenant } from '../WithActiveTenant'
import md5 from 'blueimp-md5'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import { useQuery } from '@tanstack/react-query'
import { useServices } from '@app/common/ServicesWrapper'
import { PassingDetails } from '@app/common/ServicesWrapper/apis/PassingsApi'
import SecurityView from './SecurityView'
import { Quays } from '@app/common/ServicesWrapper/apis/QuayApi'
import LayerList from './Layers/LayerList'

export const Map: React.FC<React.PropsWithChildren<WithActiveTenant>> = ({ activeTenant }) => {
  const { services } = useServices()
  const location = useLocation()
  const navigate = useNavigate()
  const config = useConfig()
  const { getTokenSilently } = useAuth()
  const [mmsi, setMmsi] = useState<number | undefined>(undefined)
  const user = useAuth()
  const userHash = md5(user.user!.email)
  const mapIdentifierSlug = `${activeTenant.ref}-${userHash}-security-map`
  const allLayers = useRecoilValue(assetLayersSelector(mapIdentifierSlug))
  const [visibleLayers, setVisibleLayers] = useRecoilState(activeAssetLayersSelector(mapIdentifierSlug))

  const assets = ['Havneanlegg', 'Havnegjerde', 'HavnegjerdeInngang', 'Kaiområde', 'Kamera']

  const zoomLevel = useRecoilValue(mapZoomLevelSelector(mapIdentifierSlug))
  const center = useRecoilValue(mapCenterSelector(mapIdentifierSlug))

  const quays = useQuery<Quays.Quay[]>(['quays', activeTenant.ref, services.quayApi], () =>
    services.quayApi.list(activeTenant.ref, {})
  )

  const map = useQuery<{ passings: PassingDetails[]; areas: IAreaDto[] }>(
    ['map', activeTenant.ref, services.passingsApi, services.areasApi],
    async () => {
      const [passings, areas] = await Promise.all([
        services.passingsApi.search(activeTenant.ref, { inside: true }, { page: -1, pageSize: -1 }),
        services.areasApi.getAreas(activeTenant.ref),
      ])
      return { passings: passings.values, areas }
    }
  )

  // Effects
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const mmsi = params.get('mmsi')
    if (mmsi && !isNaN(Number(mmsi))) {
      setMmsi(Number(mmsi))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (allLayers.length > 0) {
      const selectedLayers = allLayers.filter((layer) => assets.some((asset) => asset === layer.name)).map((l) => l.key)
      visibleLayers !== selectedLayers && setVisibleLayers(selectedLayers)
    }
  }, [allLayers, setVisibleLayers]) // eslint-disable-line

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('lon', center.lon.toString())
    searchParams.set('lat', center.lat.toString())
    searchParams.set('zoom', zoomLevel.toString())
    navigate(
      {
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      },
      { replace: true }
    )
    // eslint-disable-next-line
  }, [zoomLevel, center])

  return (
    <>
      {!map.isLoading && !quays.isLoading && (
        <MapProvider
          mapIdentifierSlug={mapIdentifierSlug}
          monitorApiBaseUrl={config.api.monitorApiBaseUrl}
          activeTenantId={activeTenant.id}
          getToken={getTokenSilently}
        >
          <MapButtonPanel
            region="right"
            endElements={[<FullscreenControl />]}
            startElements={[<ZoomControl />, <GpsControl />]}
          />
          <MapButtonPanel
            region="left"
            startElements={[<LayerControl controls={['maps']} customControls={[<LayerList layers={assets} />]} />]}
          />

          <MapButtonPanel
            region="top"
            startElements={[
              <SpotlightControl
                isAbsolute={false}
                searchitApiUrl={config.api.searchApiBaseUrl}
                activeTenantId={activeTenant.id}
                width={350}
              />,
            ]}
          />

          <SecurityView
            assetApiBaseUrl={config.api.assetApiBaseUrl}
            tenantId={activeTenant?.id ?? null}
            enableDialogs={true}
            facilities={map.data?.areas || []}
            quays={quays.data || []}
            refetchQuays={quays.refetch}
            passings={map.data?.passings || []}
            activeTenant={activeTenant}
            quayApi={services.quayApi}
          />
          <LiveView
            liveApiBaseUrl={config.api.liveApiUrl}
            tenantId={activeTenant.id}
            enableDialogs={true}
            vesselMMSI={mmsi}
            defaultLayersToVisible={true}
          />
        </MapProvider>
      )}
    </>
  )
}

export default Map
