import { atom } from 'recoil'
import { PortConfig } from '@app/common/ServicesWrapper/apis/SharedDataApi'

export const portConfigStore = atom<PortConfig>({
  key: 'portConfig',
  default: {
    passingsRetention: null,
    applications: true,
    attributes: { invoiceDate: null },
    isPort: false,
    visy: false,
    salto: false,
    stanley: false,
    westec: false,
    openpath: false,
    vehicles: false,
    ava: false,
    bewator: false,
    stripe: false,
    vehicleCategories: []
  },
})
