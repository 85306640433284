import * as Form from '@griegconnect/krakentools-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useServices } from 'src/common/ServicesWrapper'
import { Reason, VisitHandlerWithSchedules } from 'src/common/ServicesWrapper/apis/Application2'
import { NextState, StepProps, applicationState, nextStateAtom } from '.'
import FlowNavigation from '../Components/FlowNavigation'
import FlowPage from '../Components/FlowPage'
import { ApplicationFormData, TimeForm, TimeSchema } from '../schema'

const StepTime: React.FC<StepProps> = ({ portDetails, formValues, formType, resetForm }) => {
  const { services } = useServices()
  const { t } = useTranslation()
  const [applicationFormData, setApplicationFormData] = useRecoilState<ApplicationFormData>(applicationState)
  const setState = useSetRecoilState<NextState>(nextStateAtom)
  const [reasons, setReasons] = useState<Reason[]>([])
  const [showVisitHandlers, setShowVisitHandlers] = useState<boolean>(false)
  const [minDate, setMinDate] = useState<DateTime | null>(null)
  const [availableHandlers, setAvailableHandlers] = useState<VisitHandlerWithSchedules[]>([])

  const form = useForm<TimeForm>({
    defaultValues: applicationFormData.time,
    resolver: zodResolver(TimeSchema),
  })
  const { watch, setValue } = form

  const timeForm = watch()

  //update time out when time in changes
  useEffect(() => {
    const go = async () => {
      const values = await services.applications2Api.getApplicationValues({
        tenant: portDetails.port.id,
        facility: applicationFormData.visiting.facility,
        purpose: applicationFormData.visiting.purpose,
        target: applicationFormData.visiting.target,
        arrival: timeForm.timeIn.toISO(),
      })

      //set visit handler if only one is available
      const target = values.targets.find((t) => t.id === applicationFormData.visiting.target)
      const handlers = target?.visitHandlers
      if (handlers && handlers.length === 1) {
        setValue('visitHandler', handlers[0].visitHandler.id)
      } else {
        setValue('visitHandler', '')
      }
      setAvailableHandlers(handlers || [])
      setValue('timeOut', DateTime.fromISO(values.time.end))
      setReasons(values.time.reasons)
      setMinDate(DateTime.fromISO(values.time.preferredArrival))
      setShowVisitHandlers(target?.showVisitHandlers || false)
    }
    go()
  }, [
    setValue,
    services.applications2Api,
    applicationFormData.visiting.facility,
    applicationFormData.visiting.purpose,
    applicationFormData.visiting.target,
    applicationFormData.time.timeIn,
    portDetails.port.id,
    timeForm.timeIn,
  ])

  const target = formValues.targets.find((t) => t.id === applicationFormData.visiting.target)

  const prepareDescription = () => {
    const facility = formValues.targets
      .map((t) => t.facilities)
      .flat()
      .find((f) => f.area.id === applicationFormData.visiting.facility)
    return (
      <Box pb={2}>
        <Typography variant="subtitle1">
          {t('applications.subTitle.selectDuration1')} <strong>{facility?.area.name}</strong>.{' '}
          {t('applications.paragraphs.weHaveFilledNearest')}
        </Typography>
      </Box>
    )
  }

  const handleReset = () => resetForm && resetForm()

  const onSubmit = async (data: TimeForm) => {
    const visitHandler = target?.visitHandlers.find((v) => v.visitHandler.id === data.visitHandler)
    const vhName = visitHandler?.visitHandler.resolved ? visitHandler.visitHandler.name : ''
    setApplicationFormData((p) => ({
      ...p,
      time: {
        timeIn: data.timeIn,
        timeOut: data.timeOut,
        visitHandler: data.visitHandler,
        visitHandlerName: vhName,
      },
    }))
    setState((p) => ({ ...p, currentStep: p.currentStep + 1 }))
  }

  const facilityUnavailable = reasons.some((r) => r === 'facility_not_available')
  const visitHandlerUnavailable = reasons.some((r) => r === 'handler_not_available')

  const nearestAvailableTime = minDate?.toFormat('dd.MM.yyyy HH:mm')

  return (
    <FormProvider {...form}>
      <Box width={'100%'}>
        <FlowPage>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <div>{prepareDescription()}</div>
            <Form.DateTimePicker label={t('invoices.labels.startTime')} control={form.control} name="timeIn" required />
            <Form.DateTimePicker
              label={t('invoices.labels.endTime')}
              control={form.control}
              name="timeOut"
              minDate={timeForm.timeIn}
              maxDate={timeForm.timeOut}
              required
            />
            {showVisitHandlers && (
              <Form.Select
                label={t('security.labels.visitHandler')}
                control={form.control}
                name="visitHandler"
                required
              >
                {availableHandlers.map((handler, index) =>
                  handler.visitHandler.resolved ? (
                    <MenuItem key={handler.visitHandler.id + '-' + index} value={handler.visitHandler.id}>
                      {handler.visitHandler.name}
                    </MenuItem>
                  ) : null
                )}
              </Form.Select>
            )}
            {facilityUnavailable && (
              <>
                <Typography color="error" variant="subtitle2" mb={1}>
                  {t('security.title.facilityNotOpen')}
                </Typography>

                <Typography variant="body1" color="error">
                  {t('applications.paragraphs.nearestAvailableTimeIs')} {nearestAvailableTime}.
                </Typography>
                <Button
                  variant="text"
                  onClick={() => form.setValue('timeIn', minDate || DateTime.now())}
                  sx={{ mt: 1 }}
                >
                  {t('applications.actions.useNearestTime')}
                </Button>
              </>
            )}
            {!facilityUnavailable && visitHandlerUnavailable && (
              <Typography color="error" variant="subtitle2" mb={1}>
                {t('applications.subTitle.noHandlersAvailable')}
              </Typography>
            )}
          </LocalizationProvider>
        </FlowPage>
        <FlowNavigation>
          <Stack direction="row" width="100%">
            {formType === 'new' && (
              <Box display="flex" justifyContent="flex-start" width="100%">
                <Button color="inherit" onClick={handleReset}>
                  {t('applications.actions.reset')}
                </Button>
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button variant="contained" type="submit" onClick={form.handleSubmit(onSubmit)}>
                {t('common.actions.continue')}
              </Button>
            </Box>
          </Stack>
        </FlowNavigation>
      </Box>
    </FormProvider>
  )
}

export default StepTime
