import React from 'react'
import { Navigation } from '@griegconnect/krakentools-react-kraken-app'
import { withActiveTenant } from '@app/routes/WithActiveTenant'
import { withServices } from '@app/routes/withServices'
import { PATH_TENANT_BASE, Permissions, PERMISSIONS } from '@app/constants'
import { AccessDependencies } from '@app/Content'
import { requireAllGlobalPermissions } from '@app/lib/access'

const PATH = PATH_TENANT_BASE + '/settings/westec'
const westecActive = (d: AccessDependencies) => d.portConfig.westec

export const westecModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: 'Westec',
    path: PATH + '/',
    page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('./installation'))))),
    exact: true,
    menu: true,
    access: (d) => westecActive(d) && requireAllGlobalPermissions(Permissions.all(PERMISSIONS.global_access_system))(d),
  },
]
