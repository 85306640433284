import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLicensePlate = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M5 10h2v5H5v-5ZM11 15v-5H9v5h2ZM15 10v5h-2v-5h2ZM19 10h-2v5h2v-5Z" />,
    <path
      fillRule="evenodd"
      d="M2 6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H2Zm1 10V8h6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1h6v8H3Z"
      clipRule="evenodd"
    />
  )
export default SvgLicensePlate
