import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { useAuth } from '@griegconnect/krakentools-react-kraken-app'
import md5 from 'blueimp-md5'
import { useQuery } from '@tanstack/react-query'
import { useServices } from '@app/common/ServicesWrapper'
import { v4 } from 'uuid'
import { Widgets } from '../../CustomDashboard/Types'
import CustomDashboard from '../../CustomDashboard'
import { PassingIn } from '@app/common/ServicesWrapper/apis/PassingsApi'
import { DateTime, Interval } from 'luxon'
import { Turnaround } from '@app/common/ServicesWrapper/apis/PassingsApi'
import { VisitorStats } from '@app/common/ServicesWrapper/apis/VisitorStatsApi'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import { useRecoilValue } from 'recoil'
import { portConfigStore } from '@app/stores/portStore'
import { Stats } from '@app/common/ServicesWrapper/apis/Stats'
import { Quays } from '@app/common/ServicesWrapper/apis/QuayApi'
import { PermitGroup } from '@app/common/ServicesWrapper/apis/VisyApi'

const defaultWidgetConstructors: Widgets.Constructor[] = [
  {
    id: v4(),
    type: 'passings',
    variant: 'trend',
    grouping: ['Andre'],
  },
  {
    id: v4(),
    type: 'passings',
    variant: 'trend',
    grouping: ['GOS3'],
  },
  {
    id: v4(),
    type: 'turnaround',
    variant: 'trend',
    grouping: ['Andre'],
    position: [undefined, undefined, undefined, { x: 0, y: 3 }],
  },
  {
    id: v4(),
    type: 'turnaround',
    variant: 'trend',
    grouping: ['GOS3'],
    position: [undefined, undefined, undefined, { x: 4, y: 3 }],
  },
  {
    id: v4(),
    type: 'map',
    variant: 'map',
    position: [undefined, undefined, undefined, { x: 8, y: 0 }],
  },
  {
    id: v4(),
    type: 'controls',
    variant: 'progress',
    subVariant: 'persons',
    position: [undefined, undefined, { x: 0, y: 11 }, { x: 8, y: 6 }],
  },
  {
    id: v4(),
    type: 'controls',
    variant: 'progress',
    subVariant: 'vehicles',
    position: [undefined, undefined, { x: 3, y: 11 }, { x: 8, y: 11 }],
  },
  {
    id: v4(),
    type: 'passings',
    variant: 'linechart',
    position: [undefined, undefined, { x: 0, y: 16 }, { x: 0, y: 6 }],
  },
  {
    id: v4(),
    type: 'turnaround',
    variant: 'linechart',
    position: [undefined, undefined, { x: 3, y: 16 }, { x: 0, y: 11 }],
  },
]

const GeneralDashboard: React.FC<React.PropsWithChildren<WithActiveTenant>> = ({ activeTenant, children }) => {
  const { services } = useServices()
  const user = useAuth()
  const hash = md5(user.user!.email)
  const portConfig = useRecoilValue(portConfigStore)

  const { data, isLoading: isFetching } = useQuery<{
    passings: PassingIn | undefined
    turnaround: Turnaround | undefined
    controls: VisitorStats[] | undefined
    areaControls: Stats.CompletedRangeStats[] | undefined
    deviations: Stats.DeviationsRangeStats[] | undefined
    areas: IAreaDto[] | undefined
    quays: Quays.Quay[] | undefined
    visyGroups: PermitGroup[] | undefined
  }>(['', activeTenant.ref, portConfig.isPort], async () => {
    const now = DateTime.now()
    const interval = Interval.fromDateTimes(now.minus({ day: 2 }), now)
    const [
      passings,
      turnaround,
      controls,
      areaControls,
      deviations,
      areas,
      quays,
      visyGroups,
    ] = await Promise.allSettled([
      services.passingsApi.passingIn(activeTenant.ref, { precision: 'hour', interval }),
      services.passingsApi.turnaroundStats(activeTenant.ref, { precision: 'hour', interval }),
      services.visitorStatsApi.get(
        activeTenant.ref,
        now.startOf('year').startOf('day').toJSDate(),
        now.endOf('day').toJSDate()
      ),
      services.statsApi.completedChecksRange(activeTenant.ref, {
        from: now.startOf('day').toJSDate(),
        to: now.endOf('day').toJSDate(),
      }),
      services.statsApi.deviationsRange(activeTenant.ref, {}),
      services.areasApi.getAreas(activeTenant.ref),
      services.quayApi.list(activeTenant.ref, {}) || undefined,
      services.visyApi.getData(activeTenant.ref) || undefined,
    ])
    return {
      passings: passings.status === 'fulfilled' ? passings.value : undefined,
      turnaround: turnaround.status === 'fulfilled' ? turnaround.value : undefined,
      controls: controls.status === 'fulfilled' ? controls.value : undefined,
      areaControls: areaControls.status === 'fulfilled' ? areaControls.value : undefined,
      deviations: deviations.status === 'fulfilled' ? deviations.value : undefined,
      areas: areas.status === 'fulfilled' ? areas.value : undefined,
      quays: quays.status === 'fulfilled' ? quays.value : undefined,
      visyGroups: visyGroups.status === 'fulfilled' ? visyGroups.value.allowedPermitGroups : undefined,
    }
  })

  return (
    <CustomDashboard
      activeTenant={activeTenant}
      storageKeys={{
        layout: `${activeTenant.ref}-${hash}-dashboard-layout-general`,
        widgets: `${activeTenant.ref}-${hash}-dashboard-widget-constructors-general`,
      }}
      defaultWidgetConstructors={defaultWidgetConstructors}
      data={{
        isFetching,
        passings: data?.passings,
        turnaround: data?.turnaround,
        controls: data?.controls,
        areaControls: data?.areaControls,
        deviations: data?.deviations,
        areas: data?.areas,
        quays: data?.quays,
        visyGroups: data?.visyGroups,
      }}
    >
      {children}
    </CustomDashboard>
  )
}

export default GeneralDashboard
