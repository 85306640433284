import { Box, Checkbox as CheckboxMUI, FormControlLabel, FormGroup, SxProps } from '@mui/material'
import React, { useState } from 'react'
import { Control, Controller, get, useFormState, ControllerRenderProps, FieldValues } from 'react-hook-form'
import 'react-international-phone/style.css'
import { ErrorHelperText } from './ErrorHelper'

interface CheckboxProps {
  control: Control<any>
  label: string
  name: string
  defaultChecked?: boolean
  value?: string
  disabled?: boolean
  size?: "small" | "medium" | "large"
  sx: SxProps
}
export const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  disabled,
  control,
  label,
  name,
  value,
  defaultChecked,
  size,
  sx
}) => {
  const { errors } = useFormState()
  const error = get(errors, name)
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <FormControlLabel
            control={
              <CheckboxMUI
                {...rest}
                disabled={disabled ?? false}
                name={name}
                color="primary"
                defaultChecked={defaultChecked}
                size={size}
                sx={sx}
              />
            }
            label={label}
            checked={rest.value}
            ref={ref}
            
          />
        )}
      />
      {error && error.message && <ErrorHelperText error={error.message} />}
    </>
  )
}

interface CheckboxMultiValues {
  id: string
  label: string
}
interface CheckboxMultiProps {
  control: Control<any>
  values: CheckboxMultiValues[]
  formValues: string[]
  disabled?: boolean
  name: string
  label?: string
}
export const CheckboxMulti: React.FC<React.PropsWithChildren<CheckboxMultiProps>> = ({
  control,
  values,
  name,
  label,
  formValues,
  disabled,
}) => {
  const { errors } = useFormState()
  const error = get(errors, name)
  const [isChecked, setIsChecked] = useState<string[]>(formValues ||[])

  const toggleCheckboxValue = (
    field: ControllerRenderProps<FieldValues, string>,
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      field.onChange([...field.value, event.target.value])
      setIsChecked([...isChecked, event.target.value])
    } else {
      field.onChange(field.value.filter((value) => value !== event.target.value))
      setIsChecked([...isChecked.filter((c) => c !== event.target.value)])
    }
  }

  return (
    <Box py={3}>
       <FormGroup>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
                {values.map((option) => (
                    <FormControlLabel
                      key={`${option.id}-form-control`}
                      control={
                        <CheckboxMUI
                          name={name}
                          color="primary"
                          value={option.id}
                          disabled={disabled}
                          onChange={(event, checked) => toggleCheckboxValue(field, event, checked)}
                        />
                      }
                      checked={isChecked.some((c) => c === option.id)}
                      label={option.label ?? label}
                      sx={{ ml: 2 }}
                    />
                ))}
              {error && <ErrorHelperText error={error.message} />}
            </>
          )}
        />
      </FormGroup>
    </Box>
  )
}
