import { AxiosInstance } from 'axios'
import qs from 'qs'
import { By } from './By'
import { CheckListField, CheckListFieldForm } from './CheckListsApi'
import { IAreaDto } from './dtos/areaDto'
import Page from './Page'
import { IUserDto } from './dtos/userDto'
import { Quays } from './QuayApi'
import { DateTime } from 'luxon'
import { Search as S, Infer } from '@app/lib/hooks/useSearch'

export type Status = 'completed' | 'in_progress' | 'todo'

export declare namespace QuayCheck {
  export interface Details {
    quayCheck: QuayCheck
    fields: CheckListField[]
  }
  export interface Form {
    facility: string
    time: DateTime
    fields: CheckListFieldForm[]
    summary: string
    status: Status
    assignee?: string
    quay?: string
  }
  export interface Params {
    facility?: string[]
    summary?: string[]
    deviations?: boolean
    status?: Status[]
  }
}

export interface Completion {
  status: Status
  assignee?: IUserDto
}

export interface QuayCheck {
  id: string
  facility: IAreaDto
  summary: string
  modified: By
  negative: number
  time: string
  completion: Completion
  quay?: Quays.Link
}

export const ParamsSchema = {
  ...Page.schema,
  facility: S.array(S.string),
  summary: S.array(S.string),
  deviations: S.undefine(S.boolean),
  status: S.array(S.string),
}
export type Params = Infer<typeof ParamsSchema>

export class QuayCheckApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async list(tenantRef: string, search: QuayCheck.Params, pagination: Page.Search): Promise<Page<QuayCheck>> {
    const params = { ...search, ...pagination }
    const response = await this.http.get(`/ports/${tenantRef}/quayChecks`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async get(tenantRef: string, id: string): Promise<QuayCheck.Details> {
    const response = await this.http.get(`/ports/${tenantRef}/quayChecks/${id}`)
    return response.data
  }

  async create(tenantRef: string, form: QuayCheck.Form): Promise<string> {
    const postData = { ...form, time: form.time.toUTC() }
    const response = await this.http.post(`/ports/${tenantRef}/quayChecks`, postData)
    return response.data.id
  }

  async update(tenantRef: string, id: string, form: QuayCheck.Form): Promise<void> {
    const postData = { ...form, time: form.time.toUTC() }
    await this.http.put(`/ports/${tenantRef}/quayChecks/${id}`, postData)
  }

  async delete(tenantRef: string, id: string): Promise<string> {
    const response = await this.http.delete(`/ports/${tenantRef}/quayChecks/${id}`)
    return response.data.id
  }
}
