import { Page } from '@griegconnect/krakentools-react-ui'
import { LinearProgress } from '@mui/material'
import { DataGridPro, DataGridProProps, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'

type FooterProps = {
  page: number
  hasNext: boolean
  onChange: (page: number) => void
}

type SecurityDataGridProProps = Pick<
  DataGridProProps,
  | 'columnVisibilityModel'
  | 'rows'
  | 'columns'
  | 'getRowId'
  | 'initialState'
  | 'rowHeight'
  | 'slots'
  | 'slotProps'
  | 'onRowClick'
  | 'disableRowSelectionOnClick'
  | 'autosizeOptions'
  | 'loading'
  | 'getRowHeight'
> & {
  footerProps?: FooterProps
}

export const SecurityDataGridPro = (props: SecurityDataGridProProps) => {
  const apiRef = useGridApiRef()
  return (
    <DataGridPro
      apiRef={apiRef}
      rows={props.rows}
      columns={props.columns}
      getRowId={props.getRowId}
      sx={{
        '&.MuiDataGrid-root': {
          padding: '0px !important',
        },
        '& .MuiDataGrid-row': {
          cursor: props.onRowClick ? 'pointer' : 'auto',
        },
        '& .MuiDataGrid-pinnedColumns': {
          boxShadow: 'none',
          backgroundImage: 'none',
        },
        '& .MuiDataGrid-pinnedColumnHeaders': {
          boxShadow: 'none',
          backgroundImage: 'none',
        },
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
          py: 1,
        },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '10px',
        },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
          py: '12px',
        },
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
      }}
      initialState={
        props.initialState ?? {
          pinnedColumns: {
            right: ['actions'],
          },
        }
      }
      columnVisibilityModel={props.columnVisibilityModel}
      onRowClick={props.onRowClick}
      disableRowSelectionOnClick={props.disableRowSelectionOnClick ?? true}
      rowHeight={props.rowHeight ?? 60}
      slots={{
        ...props.slots,
        footer: () => props.footerProps && <Page.Pagination {...props.footerProps} />,
        loadingOverlay: props.loading ? LinearProgress : undefined,
      }}
      slotProps={props.slotProps}
      autosizeOptions={
        props.autosizeOptions ?? {
          expand: true,
          includeHeaders: true,
        }
      }
      autosizeOnMount={true}
      loading={props.loading}
      getRowHeight={props.getRowHeight}
    />
  )
}
export default SecurityDataGridPro
