import { AxiosInstance } from "axios"
import { ContractApplications } from "./ContractsApi"
import qs from 'qs'

export declare namespace Stats {
  type ContractStatusStats = {
      [key in ContractApplications.Status]: number
  }

  interface DeviationsInterval {
    lastHour?: number
    lastDay?: number
    lastWeek?: number
    lastYear?: number
  }

  interface DeviationsStats {
    facility: string
    interval: DeviationsInterval
  }

  interface RangeParams {
    from?: Date
    to?: Date
  }

  interface DeviationsRange {
    checks: number,
    deviations: number
  }

  interface DeviationsRangeStats {
    facility: string,
    facilityChecks?: DeviationsRange
    personChecks?: DeviationsRange
    quayChecks?: DeviationsRange
    vehicleChecks?: DeviationsRange
    vesselChecks?: DeviationsRange
  }

  interface LatestChecks {
    completed: string,
    deviation: number
  }

  interface LatestChecksStats {
    facility: string,
    facilityChecks?: LatestChecks
    personChecks?: LatestChecks
    quayChecks?: LatestChecks
    vehicleChecks?: LatestChecks
    vesselChecks?: LatestChecks
  }

  interface CompletedChecks {
    completedChecks: number,
  }

  interface CompletedRangeStats {
    facility: string,
    facilityChecks?: CompletedChecks
    personChecks?: CompletedChecks
    quayChecks?: CompletedChecks
    vehicleChecks?: CompletedChecks
    vesselChecks?: CompletedChecks
  }
}

export type Check =
    'facilityChecks'
  | 'personChecks'
  | 'quayChecks'
  | 'vehicleChecks'
  | 'vesselChecks'

export class StatsApi {
    private httpClient: AxiosInstance

    constructor(httpClient: AxiosInstance) {
      this.httpClient = httpClient
    }

    async contractApplicationStatuses(tenantRef: string): Promise<Stats.ContractStatusStats> {
      const response = await this.httpClient.get(`/ports/${tenantRef}/stats/dos/status`)
      return response.data
    }

    async deviations(tenantRef: string): Promise<Stats.DeviationsStats[]> {
      const response = await this.httpClient.get(`/ports/${tenantRef}/stats/checks/deviations`)
      return response.data
    }

    async deviationsRange(tenantRef: string, params: Stats.RangeParams): Promise<Stats.DeviationsRangeStats[]> {
      const response = await this.httpClient.get(`/ports/${tenantRef}/stats/checks/deviationsRange`, {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      return response.data
    }

    async latestChecks(tenantRef: string): Promise<Stats.LatestChecksStats[]> {
      const response = await this.httpClient.get(`/ports/${tenantRef}/stats/checks/latest`)
      return response.data
    }

    async completedChecksRange(tenantRef: string, params: Stats.RangeParams): Promise<Stats.CompletedRangeStats[]> {
      const response = await this.httpClient.get(`/ports/${tenantRef}/stats/checks/completedRange`, {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      return response.data
    }
  }

  export default StatsApi
