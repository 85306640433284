import { UserApi } from 'src/common/ServicesWrapper/apis/UserApi'
import { IUserDto } from '@app/common/ServicesWrapper/apis/dtos/userDto'

export type Message =
  | { side: 'applicant'; by: IUserDto; at: string; message: string }
  | { side: 'reviewer'; at: string; message: string }

export const getMessages = (details: UserApi.Application.Details) =>
  [...details.history, details.current].reduce<Message[]>((messages, c, idx, array) => {
    const previous = idx ? array[idx - 1] : null
    if (c.message && c.message !== previous?.message) {
      return [...messages, { side: 'applicant', by: c.visitor.owner, at: c.modified, message: c.message }]
    } else if (c.statusMessage && c.statusMessage !== previous?.statusMessage) {
      return [...messages, { side: 'reviewer', at: c.modified, message: c.statusMessage }]
    } else {
      return messages
    }
  }, [])
