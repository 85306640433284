import { FileUpload } from '@app/common/Form'
import * as Form from '@griegconnect/krakentools-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Stack } from '@mui/material'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { NextState, StepProps, applicationState, nextStateAtom } from '.'
import FlowHeader from '../Components/FlowHeader'
import FlowNavigation from '../Components/FlowNavigation'
import FlowPage from '../Components/FlowPage'
import { ApplicationFormData, VisitorVehicleForm, VisitorVehicleSchema } from '../schema'

const StepVehicle: React.FC<StepProps> = ({ portDetails, formType, resetForm }) => {
  const { t } = useTranslation()
  const [applicationFormData, setApplicationFormData] = useRecoilState<ApplicationFormData>(applicationState)
  const [state, setState] = useRecoilState<NextState>(nextStateAtom)
  const form = useForm<VisitorVehicleForm>({
    defaultValues: applicationFormData.visitorVehicle,
    resolver: zodResolver(VisitorVehicleSchema),
  })

  const vehicleFileArray = useFieldArray({
    control: form.control,
    name: 'files',
  })

  const applicationTrailerInput =
    portDetails && portDetails.attributes && portDetails.attributes.applicationTrailerInput

  const applicationSubcontractorInput =
    portDetails && portDetails.attributes && portDetails.attributes.applicationSubcontractorInput

  // const onBack = () => setState((p) => ({ ...p, currentStep: p.currentStep - 1 }))

  const onSubmit = (data: VisitorVehicleForm) => {
    setApplicationFormData((p) => ({ ...p, visitorVehicle: data }))
    setState((p) => ({ ...p, currentStep: p.currentStep + 1 }))
  }

  const onNoVehicle = () => {
    setState((prevState) => ({
      ...prevState,
      visitBy: 'walking',
      currentStep: prevState.currentStep + 1,
    }))
  }

  const handleReset = () => resetForm && resetForm()

  return (
    <FormProvider {...form}>
      <Box width={'100%'}>
        <FlowPage>
          {state.visitBy === 'driving' && (
            <>
              <FlowHeader i18n="applications.subTitle.filloutVehicleDetails" />
              <Form.TextInput name={'vehicle'} label={t('security.labels.licensePlateNumber')} required />
              {applicationTrailerInput && (
                <Form.TextInput name={'trailer'} label={t('components.applications.fields.labels.trailerPlate')} />
              )}
              {applicationSubcontractorInput && (
                <Form.TextInput
                  name={'subcontractor'}
                  label={t('components.applications.fields.labels.subContractor')}
                />
              )}

              <FileUpload
                arrayMethods={vehicleFileArray}
                control={form.control}
                name={`files`}
                title={`${t('components.applications.files.vehicle.title')}`}
                kind="files"
                multiple={true}
                uploadLabel={t('components.applications.files.vehicle.upload')}
                modifyLabel={t('components.applications.files.vehicle.change')}
              />
            </>
          )}
        </FlowPage>
        <FlowNavigation>
          <Stack direction="row" width="100%">
            {formType === 'new' && (
              <Box display="flex" justifyContent="flex-start" width="100%">
                <Button color="inherit" onClick={handleReset}>
                  {t('applications.actions.reset')}
                </Button>
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end" width="100%">
              {/* <Button variant="outlined" onClick={onBack} sx={{ mr: 2 }}>
                {t('common.actions.back')}
              </Button> */}
              <Button
                variant="contained"
                type="submit"
                onClick={state.visitBy === 'driving' ? form.handleSubmit(onSubmit) : onNoVehicle}
              >
                {t('common.actions.continue')}
              </Button>
            </Box>
          </Stack>
        </FlowNavigation>
      </Box>
    </FormProvider>
  )
}

export default StepVehicle
