import { AxiosInstance } from 'axios';
import { PortCompany } from './dtos/companyDto';

export class PortCompaniesApi {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async create(port: string, company: string, facility: string): Promise<void> {
    await this.httpClient.put(`/ports/${port}/companies/${company}/facilities/${facility}`);
  }

  async delete(port: string, company: string, facility: string): Promise<void> {
    await this.httpClient.delete(`/ports/${port}/companies/${company}/facilities/${facility}`);
  }

  async list(port: string): Promise<PortCompany[]> {
    const response = await this.httpClient.get(`/ports/${port}/companies`);
    return response.data.portCompanies;
  }
}
