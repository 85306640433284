import React, { useRef, useState } from 'react'
import { Chip, ChipProps, CircularProgress, CircularProgressProps } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { PopperMenu } from '../popper-menu'
import { useTheme } from '@mui/material/styles'

export interface Props<A, V> {
  actions: A[]
  value: V
  onClick: (action: A) => void
  loading?: boolean
  disabled?: boolean
}
export interface ActionBase {
  id: string
  label: string
}

export interface Value {
  label: string
  color?: ChipProps['color']
}

const StatusChip = <A extends ActionBase, V extends Value>({
  actions,
  value,
  onClick,
  loading = false,
  disabled = false,
}: Props<A, V>) => {
  const [open, setOpen] = useState<boolean>(false)
  const actionAnchorRef = useRef(null)
  const theme = useTheme()
  const color = value.color || 'default'
  const loadingColor: CircularProgressProps['color'] = color === 'default' ? undefined : color
  const textColorDarkMode = color !== 'default' ? theme.palette.common.black : theme.palette.common.white
  const textColorLightMode = theme.palette.common.black
  const textColor = theme.palette.mode === 'light' ? textColorLightMode : textColorDarkMode
  return (
    <>
      <Chip
        label={value.label}
        variant="filled"
        color={color}
        sx={{ color: textColor }}
        deleteIcon={
          !disabled ? (
            !loading ? (
              <ArrowDropDownIcon style={{ color: textColor }} />
            ) : (
              <CircularProgress size={20} color={loadingColor} style={{ color: textColor }} />
            )
          ) : (
            <></>
          )
        }
        onDelete={() => !loading && !disabled && setOpen(!open)}
        ref={actionAnchorRef}
      />
      <PopperMenu
        items={actions.map((action) => ({
          id: action.id,
          name: action.label,
          onClick: () => {
            setOpen(!open)
            onClick(action)
          },
        }))}
        sx={{ textAlign: 'left' }}
        anchorEl={actionAnchorRef.current}
        open={open}
        onClose={() => setOpen(!open)}
        placement={'bottom'}
      />
    </>
  )
}
export default StatusChip
