import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSecurityAccessContract = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M13 2H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.89 2 1.99 2H17c1.1 0 2-.9 2-2l-2-1v1H5V4h7v5h5v5.5l2 1.053V8l-6-6Z" />,
    <path d="M12.84 15.013a1 1 0 0 1 .867.28l1.426 1.425 1.32-.863a1 1 0 0 1 1.094 0L19.297 17H21a1 1 0 1 1 0 2h-2a1 1 0 0 1-.547-.163L17 17.887l-1.453.95a1 1 0 0 1-1.254-.13l-1.02-1.019-.379.76a1 1 0 0 1-1.788-.895l1-2a1 1 0 0 1 .734-.54ZM11 7H6v2h5V7ZM6 11h10v2H6v-2ZM11 15H6v2h4l1-2Z" />
  )
export default SvgSecurityAccessContract
