import { AxiosInstance } from 'axios'
import qs from 'qs'
import { By } from './By'
import { AreaLink } from './dtos/areaDto'
import { DefaultAccess } from './dtos/defaultAccessDto'

export declare namespace Quays {
  export interface Data {
    secured?: boolean
    asset?: string
  }

  export interface Quay {
    portObjectCode?: string
    data: Data
    facility: AreaLink
    id: string
    modified: By
    name: string
    port: string
    accessGroups?: DefaultAccess.IDefaultAccesses
  }

  export interface QuayForm {
    name: string
    facility: string
    data: Data
    portObjectCode?: string
    accessGroups?: DefaultAccess.IDefaultAccesses
  }

  export interface StatusForm {
    secured: boolean
  }

  export interface Params {
    id?: string[]
    facility?: string[]
  }

  export interface Link {
    id: string
    name: string
  }

  export interface Update {
    modified: By
    status?: StatusForm
  }

  export interface PortObjectQuay {
    physicalObjectCode: string
    parentObjectCode: string
    linked: boolean
    descriptions: {
      norwegian: string
      english: string
    }
  }
  export interface IAssetMetaDto {
    at: string
    by: string
    id: string
  }

  export interface IAssetDto {
    meta: IAssetMetaDto
    tenant: string
    name: string
    description: string
    primarySource: string
    data: {
      sfkb: {
        properties: {
          kaiId: string
          kainavn: string
        }
      }
    }
  }
}

export class QuayApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async list(ref: string, search: Quays.Params): Promise<Quays.Quay[]> {
    const params = { ...search }
    const response = await this.http.get(`/ports/${ref}/quays`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async listPortObjectQuays(ref: string): Promise<Quays.PortObjectQuay[]> {
    const response = await this.http.get(`/ports/${ref}/quays/portObjectQuays`)
    return response.data
  }

  async getAssetsAll(ref: string): Promise<Quays.IAssetDto[]> {
    const response = await this.http.get(`/ports/${ref}/quays/assets/all`)
    return response.data
  }

  async getAssetsUnlinked(ref: string): Promise<Quays.IAssetDto[]> {
    const response = await this.http.get(`/ports/${ref}/quays/assets/unlinked`)
    return response.data
  }

  async getAsset(ref: string, assetId: string): Promise<Quays.IAssetDto> {
    const response = await this.http.get(`/ports/${ref}/quays/assets/${assetId}`)
    return response.data
  }

  async create(ref: string, form: Quays.QuayForm): Promise<string> {
    const response = await this.http.post(`/ports/${ref}/quays`, form)
    return response.data.id
  }

  async get(ref: string, id: string): Promise<Quays.Quay> {
    const response = await this.http.get(`/ports/${ref}/quays/${id}`)
    return response.data
  }

  async update(ref: string, id: string, form: Quays.QuayForm): Promise<void> {
    await this.http.put(`/ports/${ref}/quays/${id}`, form)
  }

  async delete(ref: string, id: string): Promise<void> {
    await this.http.delete(`/ports/${ref}/quays/${id}`)
  }

  async setStatus(ref: string, id: string, form: Quays.StatusForm): Promise<void> {
    await this.http.put(`/ports/${ref}/quays/${id}/status`, form)
  }

  async getStatusHistory(ref: string, id: string): Promise<Quays.Update[]> {
    const response = await this.http.get(`/ports/${ref}/quays/${id}/status/history`)
    return response.data
  }
}
