import { AssetsView as AssetsViewClass, IStyleTemplate } from '@griegconnect/krakentools-kmap'
import { Box, Hidden, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import axios, { CancelTokenSource } from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  AssetsApi,
  allAssetLayersVisibleSelector,
  kindsAtom,
  hightlightedAssetAtom,
  selectedAssetSelector,
  visibleAssetLayersSelector,
  editAssetSelector,
  createAssetSelector,
  styleTemplatesAtom,
  useMapContext,
  MapDialog,
  MapDialogMobile,
  ExtendedFeature,
} from '@griegconnect/krakentools-react-kmap'
import AssetInformationSecurity from './AssetInformationSecurity'
import { IAreaDto } from '@app/common/ServicesWrapper/apis/dtos/areaDto'
import { PassingDetails } from '@app/common/ServicesWrapper/apis/PassingsApi'
import { QuayApi, Quays } from '@app/common/ServicesWrapper/apis/QuayApi'
import { WithActiveTenant } from '@app/routes/WithActiveTenant'

type InitConfigProps = {
  showAllLayers?: boolean
}

export interface SecurityViewProps extends WithActiveTenant {
  assetApiBaseUrl: string
  tenantId: string | null
  enableDialogs?: boolean
  initConfig?: InitConfigProps
  facilities: IAreaDto[]
  quays: Quays.Quay[]
  refetchQuays: () => void
  passings: PassingDetails[]
  quayApi: QuayApi
}

/**
 * @param {SecurityViewProps.assetApiBaseUrl} props.assetApiBaseUrl The HTTPS protocol base URL of the asset API
 */
export const SecurityView = (props: SecurityViewProps) => {
  const { kmapInstance: instance, getToken } = useMapContext()
  const theme = useTheme()
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('xl'))
  const [view, setView] = useState<AssetsViewClass | null>(null)

  const visibleLayers = useRecoilValue(visibleAssetLayersSelector(instance.mapElementId))
  const highlightedAsset = useRecoilValue(hightlightedAssetAtom(instance.mapElementId))
  const resetHightlightedAsset = useResetRecoilState(hightlightedAssetAtom(instance.mapElementId))
  const [selectedAsset, setSelectedAsset] = useRecoilState(selectedAssetSelector(instance.mapElementId))
  const [assetTitle, setAssetTitle] = useState<string | undefined>(undefined)
  const [assetSubTitle, setAssetSubTitle] = useState<string | undefined>(undefined)
  const resetSelectedAsset = useResetRecoilState(selectedAssetSelector(instance.mapElementId))
  const showAllAssetLayers = useSetRecoilState(allAssetLayersVisibleSelector(instance.mapElementId))
  const [kinds, setKinds] = useRecoilState(kindsAtom(instance.mapElementId))
  const setStyleTemplates = useSetRecoilState(styleTemplatesAtom(instance.mapElementId))
  const editAsset = useRecoilValue(editAssetSelector(instance.mapElementId))
  const createAsset = useRecoilValue(createAssetSelector(instance.mapElementId))

  const isMounted = useRef<boolean>(false)
  const signal = useRef<CancelTokenSource>()
  const assetDialog = useRef<HTMLDivElement>(null)

  // Memoized values
  const baseTileUrl = useMemo(() => {
    if (props.tenantId) {
      return `${props.assetApiBaseUrl}/tenants/${props.tenantId}`
    } else {
      return props.assetApiBaseUrl
    }
  }, [props.assetApiBaseUrl, props.tenantId])

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: baseTileUrl,
    })
    return new AssetsApi(instance, getToken)
  }, [baseTileUrl, getToken])

  /**
   *
   * Mounting & initialization effects
   *
   */

  useEffect(() => {
    signal.current = axios.CancelToken.source()
    isMounted.current = true
    return () => {
      signal.current?.cancel()
      isMounted.current = false
      resetSelectedAsset()
      resetHightlightedAsset()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const assetColored = (facility: IStyleTemplate, subTypeName: string, color: string) => ({
      colorPalette: facility.colorPalette,
      name: subTypeName, // use name of asset subtype when available
      style: {
        ...facility.style,
        fill: `${color}52`,
      },
      highlightedStyle: {
        ...facility.style,
        fill: `${color}52`,
        stroke: {
          color: `${color}A3`,
          width: facility.highlightedStyle?.stroke?.width || 2,
        },
      },
      selectedStyle: {
        ...facility.style,
        fill: `${color}7A`,
        stroke: {
          color: `${color}A3`,
          width: facility.highlightedStyle?.stroke?.width || 2,
        },
      },
    })
    if (api) {
      api
        .getKinds(signal.current?.token)
        .then((types) => {
          if (isMounted.current) {
            setKinds(types)
            const templates: IStyleTemplate[] = []
            types.forEach((type) => {
              if (type.style?.dark && type.style?.light) {
                templates.push({
                  ...type.style.dark,
                  colorPalette: 'dark',
                  name: type.name,
                })
                templates.push({
                  ...type.style.light,
                  colorPalette: 'light',
                  name: type.name,
                })
              }
            })
            /*
              - change color based on security level
            */
            const facilities = templates.filter((t) => t.name === 'Havneanlegg')
            facilities.forEach((facility) => {
              facility.subtypes = [
                assetColored(facility, facility.name, `${theme.palette.success.main}`),
                assetColored(facility, facility.name, `${theme.palette.warning.main}`),
                assetColored(facility, facility.name, `${theme.palette.error.main}`),
              ]
            })
            setStyleTemplates(templates)
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
    return () => {}
  }, [api]) // eslint-disable-line

  useEffect(() => {
    if (props.initConfig?.showAllLayers) {
      showAllAssetLayers(true)
    }
  }, [props.initConfig, kinds, showAllAssetLayers])

  useEffect(() => {
    if (props.tenantId) {
      const initializedView = new AssetsViewClass(instance, getToken, baseTileUrl, props.tenantId)
      setView(initializedView)
      return () => {
        initializedView.destroy()
      }
    } else {
      console.debug('SecurityView: No active tenant')
      setView(null)
    }
  }, [instance, props.assetApiBaseUrl, props.tenantId]) // eslint-disable-line

  useEffect(() => {
    if (view) {
      view.updateAssetTileLayers(visibleLayers)
      view.recreateVectorTileLayers()
    }
  }, [view, visibleLayers])

  /**
   *
   * Asset & map interactions
   *
   */

  useEffect(() => {
    if (view) {
      const assetClickListener = view.on('asset-selected', (data: ExtendedFeature | null) => {
        const facility = props.facilities.find((f) => f.asset === data?.id)
        const quay = props.quays.find((q) => q.data.asset && q.data.asset === data?.id)
        const quayTitle =
          data?.properties?.assettypename === 'Kaiområde'
            ? data?.properties?.primaryproperties.kainavn
            : data?.properties?.name
        setAssetTitle(quayTitle || data?.properties?.name)
        setAssetSubTitle(facility?.name || quay?.name || data?.properties?.assettypename)
        if (
          data?.properties?.assettypename === 'Havneanlegg' ||
          data?.properties?.assettypename === 'Kaiområde' ||
          data === null
        ) {
          setSelectedAsset(data)
        }
      })
      return () => {
        instance.un(assetClickListener)
      }
    }
  }, [view, setSelectedAsset, props.facilities, props.quays]) // eslint-disable-line

  useEffect(() => {
    if (view && props.enableDialogs && selectedAsset && assetDialog.current && !isMobileOrTablet) {
      const position = 'bottom-center'
      const offset: [number, number] = [0, -35]
      const cursor = instance.cursorPosition
      const dialogId = instance.placeHTMLElement(
        assetDialog.current,
        cursor?.coordinates ?? null,
        position,
        offset,
        true
      )
      return () => {
        instance.removeOverlayById(dialogId)
      }
    }
  }, [view, props.enableDialogs, instance, selectedAsset, isMobileOrTablet])

  useEffect(() => {
    if (view) {
      view.setHighlightedFeature(highlightedAsset?.id.toString() ?? null)
    }
  }, [view, highlightedAsset])

  useEffect(() => {
    if (view && editAsset) {
      view.editFeature(editAsset.assetId, editAsset.assetGeometry, editAsset.callback, true, 4326)
      return () => {
        view.stopEditFeature()
      }
    }
  }, [view, editAsset])

  useEffect(() => {
    if (view && createAsset) {
      view.drawAsset(createAsset.geometry).then((feature) => {
        createAsset.callback?.(feature)
      })
      return () => {
        view.stopAndCleanDrawing()
      }
    }
  }, [view, createAsset])

  return (
    <>
      {selectedAsset && assetTitle && assetSubTitle && props.enableDialogs ? (
        <>
          <Box display="none">
            <MapDialog
              title={assetTitle}
              subtitle={assetSubTitle}
              onClose={resetSelectedAsset}
              ref={assetDialog}
              primary={
                <AssetInformationSecurity
                  asset={selectedAsset}
                  passings={props.passings}
                  facilities={props.facilities}
                  quays={props.quays}
                  refetchQuays={props.refetchQuays}
                  activeTenant={props.activeTenant}
                  quayApi={props.quayApi}
                  datatype="primary"
                />
              }
            />
          </Box>
          <Hidden lgUp>
            <MapDialogMobile
              title={assetTitle}
              subtitle={assetSubTitle}
              onClose={resetSelectedAsset}
              primary={
                <AssetInformationSecurity
                  asset={selectedAsset}
                  passings={props.passings}
                  facilities={props.facilities}
                  quays={props.quays}
                  refetchQuays={props.refetchQuays}
                  activeTenant={props.activeTenant}
                  quayApi={props.quayApi}
                  datatype="primary"
                />
              }
            />
          </Hidden>
        </>
      ) : null}
    </>
  )
}

export default SecurityView
