import { Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import InstallationGuide, { IProcessStep } from '@app/common/InstallationGuide'
import { useTranslation } from 'react-i18next'

interface ISymmetryBlueGuideProps {
  // props for when new rendering after window resize closes modal
  modalIsOpen?: boolean
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export const SymmetryBlueGuide: React.FC<React.PropsWithChildren<ISymmetryBlueGuideProps>> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  const { t } = useTranslation()
  const [guideIsOpen, setGuideIsOpen] = useState<boolean>(false)

  const toggleModal = () => {
    if (modalIsOpen !== undefined && setModalIsOpen !== undefined) {
      setModalIsOpen(!modalIsOpen)
    } else {
      setGuideIsOpen(!guideIsOpen)
    }
  }

  const paragraphs: string[] = [t('symmetryBlue.paragraphs.1'), t('symmetryBlue.paragraphs.2')]

  const steps: IProcessStep[] = [
    {
      label: t('symmetryBlue.steps.1'),
      imgSource: '/images/symmetry-blue1.jpg',
    },
    {
      label: t('symmetryBlue.steps.2'),
      imgSource: '/images/symmetry-blue2.jpg',
    },
    {
      label: t('symmetryBlue.steps.3'),
      imgSource: '/images/symmetry-blue3.jpg',
    },
    {
      label: t('symmetryBlue.steps.4'),
      imgSource: '/images/symmetry-blue4.jpg',
    },
  ]

  return (
    <>
      <Typography variant="body1">
        <Button variant="text" color="primary" onClick={toggleModal}>
          {t('symmetryBlue.button')}
        </Button>
      </Typography>
      <InstallationGuide
        modalIsOpen={modalIsOpen !== undefined ? modalIsOpen : guideIsOpen}
        onClose={toggleModal}
        title="Symmetry blue"
        paragraphs={paragraphs}
        steps={steps}
      />
    </>
  )
}

export default SymmetryBlueGuide
