import { Grid, Button, Stack, Typography, Box } from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { useQuery } from '@tanstack/react-query'
import { useServices } from '@app/common/ServicesWrapper'
import { UserApi } from '@app/common/ServicesWrapper/apis/UserApi'
import Paged from '@app/common/ServicesWrapper/apis/Page'
import { Add } from '@griegconnect/krakentools-react-icons'
import UseQueries from '@app/common/UseQueries'
import PermitCard from './PermitCard'
import { useNavigate } from 'react-router-dom'
import ApplicationCard from './ApplicationCard'
import { ContractApplications } from '@app/common/ServicesWrapper/apis/ContractsApi'
import { useTranslation } from 'react-i18next'
import { Page } from '@griegconnect/krakentools-react-ui'
import { useBreakpointDown } from '@griegconnect/krakentools-react-kraken-app'
import UserApplicationStatus from '@app/common/applications/ApplicationStatus'

export const Home = () => {
  const { services } = useServices()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isDevice = useBreakpointDown('sm')

  const applicationPermits = useQuery<{
    fixedApplications: ContractApplications.Summary[]
    applications: Paged<UserApi.Application>
    permits: Paged<UserApi.Permit>
    accessCards: UserApi.AccessCards[]
  }>(
    ['userApplicationsAndPermits'],
    async () => {
      const [_applicationsAll, _permitsAll, fixedApplications, accessCards] = await Promise.all([
        services.userApi.applications(),
        services.userApi.myPermits(),
        services.userApi.myFixedApplications(),
        services.userApi.myAccessCards(),
      ])
      const _applications: Paged<UserApi.Application> = {
        ..._applicationsAll,
        values: _applicationsAll.values
          .filter(
            (application) =>
              application.status === 'submitted' ||
              application.status === 'request_more_info' ||
              application.status === 'invited'
          )
          .sort((a, b) => {
            if (a.visiting.timeIn && b.visiting.timeIn) {
              return DateTime.fromISO(a.visiting.timeIn) > DateTime.fromISO(b.visiting.timeIn) ? 1 : -1
            } else if (a.visiting.timeIn && !b.visiting.timeIn) {
              return -1
            } else if (!a.visiting.timeIn && b.visiting.timeIn) {
              return 1
            } else return 0
          }),
      }
      const _permits: Paged<UserApi.Permit> = {
        ..._permitsAll,
        values: _permitsAll.values
          .filter((p) => DateTime.now() < Interval.fromISO(p.visiting.valid).end.plus({ days: 1 }))
          .sort((a, b) => {
            return DateTime.fromISO(Interval.fromISO(a.visiting.valid).start.toISO()) >
              DateTime.fromISO(Interval.fromISO(b.visiting.valid).start.toISO())
              ? 1
              : -1
          }),
      }
      return {
        fixedApplications,
        applications: _applications,
        permits: _permits,
        accessCards,
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: 10000,
    }
  )

  const status = {
    invited: 'invited' as const,
    created: 'invited' as const,
    require_more_information: 'suspended' as const,
  }

  const now = DateTime.now()

  const applicationTitle =
    applicationPermits.data && (applicationPermits.data.applications.values || []).length === 0
      ? t('applications.module.noApplications')
      : t('applications.module.userApplications')

  return (
    <UseQueries queries={{ applicationPermits }}>
      {({ applicationPermits }) => {
        const applicationsAwaiting = applicationPermits.fixedApplications.filter(
          (a) =>
            a.application.status === 'invited' ||
            a.application.status === 'created' ||
            a.application.status === 'require_more_information'
        )
        return (
          <Page.Wrapper
            title={applicationTitle}
            buttons={
              <Stack flex={1} direction="row" justifyContent="flex-end" width="100%">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => navigate('/user/application/new')}
                >
                  {isDevice ? t('common.actions.new') : t('applications.actions.newApplication')}
                </Button>
              </Stack>
            }
          >
            {applicationsAwaiting.length > 0 && (
              <Grid container spacing={2} mt={4} mb={4}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t('applications.module.myAccessApplications')}</Typography>
                </Grid>
                {applicationsAwaiting.map((app) => (
                  <Grid key={app.application.id} item xs={12} md={6} lg={4}>
                    <Page.Paper>
                      <Stack direction="column" spacing={2}>
                        <Stack direction="row" width="100%" spacing={2} justifyContent="space-between" pb={2}>
                          <Box>
                            <Typography variant="h6">{t('applications.subTitle.actionsRequired')}</Typography>
                            <Typography variant="subtitle2">{app.application.port.name}</Typography>
                          </Box>
                          <UserApplicationStatus status={status[app.application.status]} />
                        </Stack>
                        <Typography variant="body2" pb={2}>
                          {t('applications.module.applicationWaiting')}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate(`/user/fixed-application/${app.application.id}`)}
                        >
                          {t('applications.module.applyForAccess')}
                        </Button>
                      </Stack>
                    </Page.Paper>
                  </Grid>
                ))}
              </Grid>
            )}
            {applicationPermits.permits.values.length > 0 && (
              <Grid container spacing={2} mt={4} mb={4}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t('applications.subTitle.myPermits')}</Typography>
                </Grid>
                {applicationPermits.permits.values.map((permit) => {
                  return (
                    <Grid key={permit.meta.id} item xs={12} md={6} lg={4}>
                      <PermitCard permit={permit} key={`${now.toString()}-${permit.meta.version.revision}`} />
                    </Grid>
                  )
                })}
              </Grid>
            )}
            {(applicationPermits.applications.values || []).length === 0 ? null : (
              <Grid container spacing={2} mt={4} mb={4}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t('applications.subTitle.myApplications')}</Typography>
                </Grid>
                {applicationPermits.applications.values.map((application) => {
                  return (
                    <Grid key={application.meta.id} item xs={12} md={6} lg={4}>
                      <ApplicationCard
                        key={`${now.toString()}-${application.meta.revision}`}
                        application={application}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )}
          </Page.Wrapper>
        )
      }}
    </UseQueries>
  )
}

export default Home
