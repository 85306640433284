import { useBreakpointDown } from '@griegconnect/krakentools-react-kraken-app'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface ScrollInfoProps {
  reachedBottom: boolean
}

const ScrollInfo: React.FC<React.PropsWithChildren<ScrollInfoProps>> = ({ children, reachedBottom }) => {
  const isSmall = useBreakpointDown('sm')
  const { t } = useTranslation()
  const theme = useTheme()

  if (isSmall && !reachedBottom) {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '85px',
          width: '50%',
          padding: '0.2em',
          backgroundColor: 'rgba(146, 146, 146, 0.8)',
          color: theme.palette.primary.contrastText,
          left: '50%',
          height: '30px',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          boxShadow: theme.shadows[4],
          borderRadius: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {t('common.actions.scrollDown')}
          <KeyboardDoubleArrowDownIcon sx={{ width: '20px', color: theme.palette.primary.contrastText }} />
        </div>
      </div>
    )
  }

  return <></>
}

export default ScrollInfo
