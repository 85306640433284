import { AxiosInstance } from 'axios'
import { DateTime } from 'luxon'

export interface Vessel {
  vesselId: number
  name: string
  vesselTypeCode: string
  imoNumber: number | null
  mmsi: number | null
  statusCode: string
  flagCode: string
  updated: DateTime
}

export class PortApi {
  private readonly http: AxiosInstance
  constructor(http: AxiosInstance) {
    this.http = http
  }

  async getAllVessels(tenantRef: string): Promise<Vessel[]> {
    const response = await this.http.get(`/gateway/companies/${tenantRef}/vessels/all`)
    return response.data
  }
  async getVessel(tenantRef: string, id: string): Promise<Vessel> {
    const response = await this.http.get(`/gateway/companies/${tenantRef}/vessels/${id}`)
    return response.data
  }
}
