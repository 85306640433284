import { AxiosInstance, AxiosResponse } from 'axios'
import { SortArrayOfObjectsNested } from '@app/lib/Sort'
import { IPurposeDto } from './dtos/purposeDto'
import { Translations } from '@griegconnect/krakentools-form/TranslatableTextField/lang2lang/lang'

export interface Link {
  id: string
  name: string
  i18n: Translations | null
}

export interface Form {
  value: string
  requireNotes: boolean
  maxDuration: string | null
  i18n?: Translations
}

export class PurposesApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async list(tenantRef: string): Promise<IPurposeDto[]> {
    const purposesResponse: AxiosResponse<{ purposes: IPurposeDto[] }> = await this.httpClient.get(
      `/ports/${tenantRef}/purposes`
    )
    return purposesResponse.data.purposes.sort(SortArrayOfObjectsNested('name', 'asc'))
  }

  async get(tenantRef: string, id: string): Promise<IPurposeDto> {
    const purposesResponse: AxiosResponse<IPurposeDto> = await this.httpClient.get(`/ports/${tenantRef}/purposes/${id}`)
    return purposesResponse.data
  }

  async create(tenantRef: string, form: Form): Promise<string> {
    const purposesResponse = await this.httpClient.post(`/ports/${tenantRef}/purposes`, form)
    return purposesResponse.data
  }

  async update(tenantRef: string, id: string, form: Form): Promise<string> {
    const purposesResponse = await this.httpClient.put(`/ports/${tenantRef}/purposes/${id}`, form)
    return purposesResponse.data
  }

  async delete(id: string, tenantRef: string): Promise<IPurposeDto> {
    const purposesResponse: AxiosResponse<IPurposeDto> = await this.httpClient.delete(
      `/ports/${tenantRef}/purposes/${id}`
    )
    return purposesResponse.data
  }
}
