import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAuth, useHttp, useConfig, SplashLoader } from '@griegconnect/krakentools-react-kraken-app'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { AreasApi } from './apis/AreasApi'
import { CheckListsApi } from './apis/CheckListsApi'
import { CheckpointApi } from './apis/CheckpointApi'
import { CompanyNotificationsApi } from './apis/CompanyNotificationsApi'
import { FacilityCheckApi } from './apis/FacilityCheckApi'
import { FileApi } from './apis/FileApi'
import { PassingsApi } from './apis/PassingsApi'
import { Permits2Api } from './apis/Permits2'
import { PersonCheckApi } from './apis/PersonCheckApi'
import { PortCompaniesApi } from './apis/PortCompaniesApi'
import { PortUserApi } from './apis/PortUserApi'
import { PurposesApi } from './apis/PurposesApi'
import { QuayCheckApi } from './apis/QuayCheckApi'
import { SecurityCheckApi } from './apis/SecurityCheckApi'
import { SecurityCheckResultApi } from './apis/SecurityCheckResultApi'
import { SecurityConfirmationApi } from './apis/SecurityConfirmationApi'
import { SecurityLevelApi } from './apis/SecurityLevelApi'
import { SharedDataApi } from './apis/SharedDataApi'
import { StanleyApi } from './apis/StanleyApi'
import { UserApi } from './apis/UserApi'
import { VehicleCheckApi } from './apis/VehicleCheckApi'
import { VesselCheckApi } from './apis/VesselCheckApi'
import { IdentityApi } from './apis/IdentityApi'
import { VesselApi } from './apis/VesselsApi'
import { DoSApi } from './apis/dosApi'
import { VisitorStatsApi } from './apis/VisitorStatsApi'
import { VisyApi } from './apis/VisyApi'
import { Services } from './types'
import { SaltoApi } from './apis/SaltoApi'
import { WestecApi } from './apis/WestecApi'
import { Application2Api } from './apis/Application2'
import ContractsApi from './apis/ContractsApi'
import { ContractsSchedulesApi } from './apis/ContractsApi'
import { InvoicingApi } from './apis/InvoicingApi'
import { CoursesApi } from './apis/CoursesApi'
import { OpenpathApi } from './apis/OpenpathApi'
import { CourseAttendeesApi } from './apis/CourseAttendeesApi'
import { VehicleApi } from '../../../../../libs/apis/VehicleApi'
import { SearchApi } from './apis/SearchApi'
import StatsApi from './apis/Stats'
import AuthenticatedApi from './apis/AuthenticatedApi'
import { CompaniesApi } from './apis/CompaniesApi'
import { AvaApi } from './apis/AvaApi'
import { ApprovedVehiclesApi } from './apis/ApprovedVehiclesApi'
import { QuayApi } from './apis/QuayApi'
import { BewatorApi } from './apis/BewatorApi'
import { StripeApi } from './apis/StripeApi'
import { SearchitApi } from '@griegconnect/krakentools-react-kmap'
import { PortApi } from './apis/Port'

interface IServicesContextValues {
  services: Services
}

export const ServicesContext = React.createContext<IServicesContextValues | null>(null)
export const useServices = () => useContext(ServicesContext)!

export const ServicesWrapper: React.FC<React.PropsWithChildren> = (props) => {
  const [services, setServices] = useState<Services>({} as Services)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getTokenSilently } = useAuth()
  const config = useConfig()
  const { httpClient } = useHttp()
  const getHttpClient = useMemo(() => httpClient, [httpClient])

  useEffect(() => {
    ;(async () => {
      const baseUrl = window.location.protocol + '//' + window.location.hostname + '/api'
      const isDev = baseUrl.includes('localhost')
      const securityApi = isDev ? config!.api.securityApi : baseUrl

      const token = await getTokenSilently()
      const eventClient = (url: string): EventSource => {
        const base = config!.api.securityApi
        return new EventSourcePolyfill(url.startsWith('/') ? `${base}${url}` : `/${base}/${url}`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
      }
      const api = getHttpClient(securityApi)
      const vehicleApi = getHttpClient(config!.api.vehicleApiUrl)
      const searchitApi = getHttpClient(config!.api.searchApiBaseUrl)
      const portApi = getHttpClient(config!.api.portApiBaseUrl)
      const apis = {
        applications2Api: new Application2Api(api),
        contractsApi: new ContractsApi(api),
        ContractsSchedulesApi: new ContractsSchedulesApi(api),
        invoicingApi: new InvoicingApi(api),
        areasApi: new AreasApi(api),
        checkListsApi: new CheckListsApi(api),
        checkpointApi: new CheckpointApi(api),
        statsApi: new StatsApi(api),
        companyNotificationsApi: new CompanyNotificationsApi(api),
        coursesApi: new CoursesApi(api),
        courseAttendeesApi: new CourseAttendeesApi(api),
        facilityCheckApi: new FacilityCheckApi(api),
        fileApi: new FileApi(api),
        searchApi: new SearchApi(api),
        searchitApi: new SearchitApi(searchitApi),
        identityApi: new IdentityApi(api),
        passingsApi: new PassingsApi(api, eventClient),
        permits2Api: new Permits2Api(api),
        personCheckApi: new PersonCheckApi(api),
        portCompaniesApi: new PortCompaniesApi(api),
        portUserApi: new PortUserApi(api),
        purposesApi: new PurposesApi(api),
        quayCheckApi: new QuayCheckApi(api),
        quayApi: new QuayApi(api),
        saltoApi: new SaltoApi(api),
        openpathApi: new OpenpathApi(api),
        securityCheckApi: new SecurityCheckApi(api),
        securityCheckResultApi: new SecurityCheckResultApi(api),
        securityConfirmationApi: new SecurityConfirmationApi(api),
        securityLevelApi: new SecurityLevelApi(api),
        sharedDataApi: new SharedDataApi(api),
        stanleyApi: new StanleyApi(api),
        userApi: new UserApi(api),
        vehicleCheckApi: new VehicleCheckApi(api),
        vesselApi: new VesselApi(api),
        dosApi: new DoSApi(api),
        vesselCheckApi: new VesselCheckApi(api),
        approvedVehiclesApi: new ApprovedVehiclesApi(api),
        visitorStatsApi: new VisitorStatsApi(api),
        visyApi: new VisyApi(api),
        westecApi: new WestecApi(api),
        avaApi: new AvaApi(api),
        bewatorApi: new BewatorApi(api),
        stripeApi: new StripeApi(api),
        vehicleApi: new VehicleApi(vehicleApi),
        authenticatedApi: new AuthenticatedApi(api),
        companiesApi: new CompaniesApi(api),
        portApi: new PortApi(portApi),
      }
      setServices(apis)
      setIsLoading(false)
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ServicesContext.Provider
      value={{
        services,
      }}
    >
      {isLoading ? <SplashLoader /> : props.children}
    </ServicesContext.Provider>
  )
}

export default ServicesWrapper
