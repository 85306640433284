import { Breakpoint, Theme } from '@mui/material'
import useMuiMediaQuery from '@mui/material/useMediaQuery'

export /**
 * Matches screen widths less than the given breakpoint (exclusive)
 */
const useBreakpointDown = (breakpoint: Breakpoint) => {
  return useMuiMediaQuery<Theme>((theme) => theme.breakpoints.down(breakpoint))
}

export /**
 * Matches screen widths greater than the given breakpoint (inclusive)
 */ const useBreakpointUp = (breakpoint: Breakpoint) => {
  return useMuiMediaQuery<Theme>((theme) => theme.breakpoints.up(breakpoint))
}
