import { Typography, Grid, Button, Box } from '@mui/material'
import { Page as P } from '@griegconnect/krakentools-react-ui'
import React from 'react'
import { useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import SaveIcon from '@mui/icons-material/Save'
import { Services } from '@app/common/ServicesWrapper/types'
import SymmetryBlueGuide from '@app/common/applications/SymmetryBlueGuide'
import { UseFormReturn } from 'react-hook-form'
import { ProfileAttributesForm } from '.'
import * as Form from '@griegconnect/krakentools-form'

interface SymmetryProps {
  services: Services
  form: UseFormReturn<ProfileAttributesForm.Inputs>
  refresh: () => void
  symmetryBlueId?: string
  symmetryBlueRequired?: boolean
}

export const SymmetryBlue: React.FC<React.PropsWithChildren<SymmetryProps>> = ({
  services,
  form,
  refresh,
  symmetryBlueRequired,
}) => {
  const { enqueue } = useAlerts()

  const updateProfile = async () => {
    const inputForm = ProfileAttributesForm.toForm(form.getValues())
    try {
      await services.userApi.updateProfile(
        inputForm.idDocument,
        inputForm.company,
        inputForm.symmetryBlue?.toString() || undefined,
        inputForm.pin,
        inputForm.cardNumbers
      )
      enqueue('Symmetryblue ID updated!', 'success')
      refresh()
    } catch (err) {
      enqueue('Error updating profile', 'error')
    }
  }

  return (
    <P.Wrapper title="Symmetry blue">
      <P.Paper>
        <Grid item>
          <SymmetryBlueGuide />
          {symmetryBlueRequired && <Typography variant="subtitle1" component="p"></Typography>}
        </Grid>
        <Grid item xs={12}>
          <Form.TextInput control={form.control} type="number" name="symmetryBlue" label="Your symmetry blue ID" />
        </Grid>
        <Box m={2} />
        <Box display="flex" flex="1" justifyContent="flex-end">
          <Button variant="contained" endIcon={<SaveIcon />} onClick={updateProfile}>
            Save
          </Button>
        </Box>
      </P.Paper>
    </P.Wrapper>
  )
}

export default SymmetryBlue
