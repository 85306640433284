import { Search as S } from '../../../lib/hooks/useSearch'

interface Page<A> {
  view: Page.View
  values: A[]
}

namespace Page {
  export const empty: Page<never> = {
    view: { current: 1, size: 0, next: null },
    values: [],
  }
  export interface View {
    current: number
    size: number
    next: number | null
  }
  export interface Search {
    page?: number
    pageSize?: number
  }

  export const schema = { page: S.undefine(S.int), pageSize: S.undefine(S.int) }
}

export default Page
