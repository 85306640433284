import React from 'react'
import {
  Navigation,
  requiresTenant,
  requiresPermission,
  requiresOneOfPermissions,
} from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, PERMISSIONS } from '@app/constants'
import { withActiveTenant } from '../WithActiveTenant'
import { withParam } from '../withParam'
import { AccessDependencies } from '@app/Content'
import { ApplicationsBadge } from 'src/common/Badges'
import { usesContracts, isPort } from '@app/lib/access'
import { withUserTenant } from '../withUserTenant'
import { Trans } from 'react-i18next'
import { ApplicationsAtomType } from 'src/atoms/applicationsAtom'

const PATH = PATH_TENANT_BASE + '/applications'

export const applicationsModuleNavs = (applications: ApplicationsAtomType): Navigation.Item<AccessDependencies>[] => [
  {
    name: <Trans i18nKey="navigation.applications.applications" />,
    path: PATH + '/applications',
    exact: true,
    menu: {
      icon: <ApplicationsBadge />,
      badge: { count: applications.fixed + applications.visitor },
    },
    access: (d) =>
      requiresTenant(d) &&
      usesContracts(d) &&
      requiresOneOfPermissions([PERMISSIONS.dos_application.read, PERMISSIONS.applications.read], true)(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.applications.visitors" />,
        path: PATH + '/visitors',
        page: React.createElement(withActiveTenant(React.lazy(() => import('./Visitors')))),
        exact: true,
        menu: {
          badge: { count: applications.visitor },
        },
        access: (d) => requiresTenant(d) && requiresPermission(PERMISSIONS.applications.read, true)(d),
      },
      {
        name: <Trans i18nKey="navigation.applications.reviewApplication" />,
        path: PATH + '/visitors/:id/review',
        page: React.createElement(
          withUserTenant(
            withParam(
              React.lazy(() => import('./Visitors/Review')),
              'id'
            )
          )
        ),
        exact: true,
      },
      {
        name: <Trans i18nKey="navigation.applications.fixed" />,
        path: PATH + '/fixed',
        page: React.createElement(withActiveTenant(React.lazy(() => import('./Fixed/List')))),
        exact: true,
        menu: {
          badge: { count: applications.fixed },
        },
        access: (d) => requiresTenant(d) && requiresPermission(PERMISSIONS.dos_application.read, true)(d),
        subNavs: [
          {
            name: <Trans i18nKey="navigation.applications.fixedPersonApplication" />,
            path: PATH + '/fixed/:id',
            page: React.createElement(
              withActiveTenant(
                withParam(
                  React.lazy(() => import('./Fixed/View')),
                  'id'
                )
              )
            ),
            exact: true,
            menu: false,
            access: (d) => requiresTenant(d) && requiresPermission(PERMISSIONS.dos_application.read, true)(d),
          },
        ],
      },
    ],
  },
  {
    name: <Trans i18nKey="navigation.applications.applications" />,
    path: PATH + '/',
    page: React.createElement(withActiveTenant(React.lazy(() => import('./Visitors')))),
    exact: true,
    menu: {
      icon: <ApplicationsBadge />,
      badge: { count: applications.fixed + applications.visitor },
    },
    access: (d) =>
      isPort(d) && !usesContracts(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.applications.read, true)(d),
    subNavs: [
      {
        name: <Trans i18nKey="navigation.applications.reviewApplication" />,
        path: PATH + '/:id/review',
        page: React.createElement(
          withUserTenant(
            withParam(
              React.lazy(() => import('./Visitors/Review')),
              'id'
            )
          )
        ),
        exact: true,
      },
    ],
  },
  {
    name: <Trans i18nKey="navigation.applications.applications" />,
    path: PATH + '/fixed-client',
    page: React.createElement(withActiveTenant(React.lazy(() => import('./Fixed/List')))),
    exact: true,
    menu: {
      icon: <ApplicationsBadge />,
      badge: { count: applications.fixed + applications.visitor },
    },
    access: (d) => !isPort(d) && requiresTenant(d) && requiresPermission(PERMISSIONS.dos_application.read, true)(d),
  },
]
