import { useNavigate, useLocation } from 'react-router-dom'
import React from 'react'

export interface Type<A> {
  read: (params: URLSearchParams, name: string) => A
  write: (params: URLSearchParams, name: string, value: A) => void
}

export interface Primitive<A> extends Type<A | null> {
  get: (value: string) => A | null
  set: (value: A) => string
}

export namespace Search {
  export const primitive = <A>(get: (s: string) => A | null, set: (a: A) => string): Primitive<A> => ({
    read: (params, name) => {
      const s = params.get(name)
      return s === null ? null : get(s)
    },
    write: (params, name, value) => {
      if (value === null) {
        params.delete(name)
      } else {
        params.set(name, set(value))
      }
    },
    get,
    set,
  })

  export const array = <A>(tpe: Primitive<A>): Type<A[]> => ({
    read: (params, name) => params.getAll(name).map(tpe.get).filter((a):a is A => a !== null),
    write: (params, name, value) => {
      const vs = value.map(tpe.set)
      params.delete(name)
      vs.forEach((v) => params.append(name, v))
    },
  })

  export const withDefault = <A>(tpe: Type<A | null>, getNull: A): Type<A> => ({
    read: (params, name) => {
      const value = tpe.read(params, name)
      return value === null ? getNull : value
    },
    write: tpe.write,
  })

  export const xmap = <A, B>(tpe: Type<A>, f: (a: A) => B, g: (b: B) => A): Type<B> => ({
    read: (params, name) => f(tpe.read(params, name)),
    write: (params, name, value) => tpe.write(params, name, g(value)),
  })

  export const string = primitive(
    (s) => s,
    (s) => s
  )

  export const int = primitive(
    (s) => {
      const num = Number.parseInt(s)
      return Number.isNaN(num) ? null : num
    }, (n) => n.toString())
  export const number = primitive(
    (s) => Number(s),
    (n) => n.toString()
  )
  export const boolean = primitive(
    (value) => value !== 'false',
    (b) => b.toString()
  )

  // oneOf(string, ['a', 'b']):Type<string>
  // oneOf(string, ['a', 'b'] as const):Type<'a' | 'b'>
  export const oneOf = <A, T extends readonly A[]>(tpe:Primitive<A>, values:T):Primitive<{[K in keyof T]: T[K] }[Exclude<keyof T, keyof []>]> =>
    primitive(
      (value) => {
        const parsed = tpe.get(value)
        return parsed !== null && values.includes(parsed) ? parsed as any  : null
      },
      (value) => tpe.set(value as any)
    )

  export const undefine = <A>(tpe: Type<A | null>) =>
    xmap(
      tpe,
      (a) => (a === null ? undefined : a),
      (a) => (a === undefined ? null : a)
    )

  export const query =
    <T extends { [K in keyof T]: Type<any> }>(schema: T) =>
    (values: Infer<T>) => {
      const params = new URLSearchParams()
      Object.entries(values).forEach(([name, value]) => schema[name].write(params, name, value))
      return params.toString()
    }
}

type Types<A extends { [K in keyof A]:Type<any> }> =
  { [K in keyof A]: A[K] extends Type<infer T> ? T : never};

type Required<A> = {
  [K in keyof A]: A[K] extends Exclude<A[K], undefined> ? K : never
}[keyof A]

// typescript skiller mellom undefined og optional
// {a?:X} betyr at property er optional
// {a: X | undefined } betyr at property er required, men har en optional verdi
// Her gjør vi alle required properties med optional verdi om til optional properties, så vi kan bruke de som 'forventet'
export type Infer<A extends { [K in keyof A]:Type<any> }> =
  Partial<Types<A>> & Pick<Types<A>, Required<Types<A>>>

type Options = {
  replace?: boolean
  mutate?: boolean
}

export const useSearch = <A extends {[K in keyof A]:Type<any>}>(schema:A, defaults?:Options):[Infer<A>, (params:Infer<A>) => void] => {
  const location = useLocation()
  const navigate = useNavigate()

  const defined = <X,>(...xs:X[]) =>
    xs.find(x => x !== undefined)

  const get = React.useMemo(() => {
    const r = new URLSearchParams(location.search)
    const data = {} as Infer<A>
    Object.entries<Type<any>>(schema).forEach(([name, tpe]) => Object.assign(data, { [name]: tpe.read(r, name) }))
    return data
  }, [location.search, schema])

  const set = (values: Infer<A>, options?:Options) => {
    const mutate = defined(defaults?.mutate, options?.mutate, true)
    const w = mutate ? new URLSearchParams(location.search) : new URLSearchParams()
    Object.entries<Type<any>>(schema).forEach(([name, tpe]) => tpe.write(w, name, values[name]))
    const search = w.toString()
    navigate({ search }, { replace: defined(options?.replace, defaults?.replace, true) })
  }

  return [get, set]
}

export default useSearch
