import { ITenant } from '@griegconnect/krakentools-react-tenants'
import { AxiosInstance } from 'axios'
import { Checkpoints } from './CheckpointApi'

export declare namespace Ava {
  export interface Credentials {
    username: string
    password: string
  }

  export interface Config {
    uri: string
    credentials: Credentials
    webhookCredentials: Credentials
  }

  export interface Installation{
    id: string
    config: Config | null
    port: ITenant
  }
  export interface InstallationForm{
    uri: string
    username: string
    password: string
    webhookUsername: string
    webhookPassword: string
  }
  export namespace Checkpoint{
    export interface Details{
        id: string
        installation: string
        entries: Device[]
        checkpoint: Checkpoints.CheckpointLink
    }
    export interface Form{
      entries: string[]
      checkpoint: string
    }
  }
  export interface Device {
    guid: string,
    name: string,
    model: string,
    active: boolean,
    address: string,
    port: number,
    manufacturer: string,

  }
}
export class AvaApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async updateInstallation(tenantRef: string, form: Ava.InstallationForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/ava`, form)
  }

  async getInstallation(tenantRef: string): Promise<Ava.Installation> {
    const res = await this.http.get(`/ports/${tenantRef}/ava`)
    return res.data
  }

  async getDevices(tenantRef: string): Promise<Ava.Device[]> {
    const res = await this.http.get(`/ports/${tenantRef}/ava/devices`)
    return res.data
  }

  async getCheckpoints(tenantRef: string): Promise<Ava.Checkpoint.Details[]> {
    const res = await this.http.get(`/ports/${tenantRef}/ava/checkpoints`)
    return res.data
  }

  async updateCheckpoints(tenantRef: string, form: Ava.Checkpoint.Form): Promise<Ava.Checkpoint.Details> {
    const res = await this.http.post(`/ports/${tenantRef}/ava/checkpoints`, form)
    return res.data
  }

}
