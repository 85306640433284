import React, { useMemo } from 'react'
import {
  SecurityCheckResults as IconSecurityCheckResults,
  PermitApplication as IconPermitApplication,
} from '@griegconnect/krakentools-react-icons'
import { useQuery } from '@tanstack/react-query'
import { useHttp, useConfig, useTenants } from '@griegconnect/krakentools-react-kraken-app'
import { AxiosInstance } from 'axios'
import { Application2Api } from '@app/common/ServicesWrapper/apis/Application2'
import { useRecoilState, useRecoilValue } from 'recoil'
import { portConfigStore } from '@app/stores/portStore'
import ContractsApi from './ServicesWrapper/apis/ContractsApi'
import { UserApi } from './ServicesWrapper/apis/UserApi'
import { applicationsAtom } from 'src/atoms/applicationsAtom'
import { userApplicationsAtom } from 'src/atoms/userApplicationsAtom'
import { QuayCheckApi } from '@app/common/ServicesWrapper/apis/QuayCheckApi'
import { FacilityCheckApi } from '@app/common/ServicesWrapper/apis/FacilityCheckApi'
import { checksAtom } from 'src/atoms/checksAtom'
import { requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import { PERMISSIONS } from '@app/constants'
import BadgeIcon from '@mui/icons-material/Badge'

export const ChecksBadge: React.FC = () => {
  const { activeTenant } = useTenants()
  const config = useConfig()
  const { httpClient } = useHttp()
  const getHttpClient = useMemo(() => httpClient, [httpClient])
  const api = getHttpClient(config!.api.securityApi) as AxiosInstance
  const quayCheckApi = new QuayCheckApi(api)
  const facilityCheckApi = new FacilityCheckApi(api)
  const [checks, setChecks] = useRecoilState(checksAtom)

  const quayChecksAccess = requiresPermission(PERMISSIONS.checks_quay.read, true)({ activeTenant })
  const facilityChecksAccess = requiresPermission(PERMISSIONS.checks_facility.read, true)({ activeTenant })

  useQuery<number>(
    ['todo-checks-quay', quayCheckApi, activeTenant],
    async () => {
      if (activeTenant && quayChecksAccess) {
        const quayChecks = await quayCheckApi
          .list(activeTenant!.ref, { status: ['todo'] }, { page: -1, pageSize: -1 })
          .then((res) => res.values.length)
        quayChecks !== checks.quay && setChecks((value) => ({ ...value, quay: quayChecks }))
        return quayChecks
      }
      return 0
    },
    {
      refetchInterval: quayChecksAccess ? 5000 : false,
    }
  )

  useQuery<number>(
    ['todo-checks-facility', facilityCheckApi, activeTenant],
    async () => {
      if (activeTenant && facilityChecksAccess) {
        const facilityChecks = await facilityCheckApi
          .list(activeTenant!.ref, { status: ['todo'] }, { page: -1, pageSize: -1 })
          .then((res) => res.values.length)
        facilityChecks !== checks.facility && setChecks((value) => ({ ...value, facility: facilityChecks }))
        return facilityChecks
      }
      return 0
    },
    {
      refetchInterval: facilityChecksAccess ? 5000 : false,
    }
  )

  return <IconSecurityCheckResults />
}

export const ApplicationsBadge: React.FC = () => {
  const { activeTenant } = useTenants()
  const config = useConfig()
  const { httpClient } = useHttp()
  const getHttpClient = useMemo(() => httpClient, [httpClient])
  const api = getHttpClient(config!.api.securityApi) as AxiosInstance
  const portConfig = useRecoilValue(portConfigStore)
  const applicationsApi = new Application2Api(api)
  const contractsApi = new ContractsApi(api)
  const [applications, setApplications] = useRecoilState(applicationsAtom)

  const visitorApplicationsAccess = requiresPermission(PERMISSIONS.applications.read, true)({ activeTenant })
  const fixedApplicationsAccess = requiresPermission(PERMISSIONS.dos_application.read, true)({ activeTenant })

  useQuery<number>(
    ['todo-applications-visitor', applicationsApi, activeTenant],
    async () => {
      if (activeTenant && visitorApplicationsAccess) {
        const visitorCount = await applicationsApi
          .search(activeTenant!.ref, {
            status: ['submitted'],
            page: -1,
            pageSize: -1,
          })
          .then((res) => res.values.length)
        visitorCount !== applications.visitor && setApplications((value) => ({ ...value, visitor: visitorCount }))
        return visitorCount
      }
      return 0
    },
    {
      refetchInterval: visitorApplicationsAccess ? 5000 : false,
    }
  )

  useQuery<number>(
    ['todo-applications-fixed', contractsApi, activeTenant],
    async () => {
      if (activeTenant && fixedApplicationsAccess) {
        const fixedCount = portConfig.attributes?.approvedCompanies
          ? await contractsApi
              .listApplications(activeTenant!.ref, { status: ['in_review'], page: -1, pageSize: -1 })
              .then((res) => res.values.length)
          : 0
        fixedCount !== applications.fixed && setApplications((value) => ({ ...value, fixed: fixedCount }))
        return fixedCount
      }
      return 0
    },
    {
      refetchInterval: fixedApplicationsAccess ? 5000 : false,
    }
  )

  return <IconPermitApplication />
}

export const VisitRequestsBadge: React.FC = () => {
  const config = useConfig()
  const { httpClient } = useHttp()
  const getHttpClient = useMemo(() => httpClient, [httpClient])
  const api = getHttpClient(config!.api.securityApi) as AxiosInstance
  const userApi = new UserApi(api)
  const [userApplications, setUserApplications] = useRecoilState(userApplicationsAtom)

  useQuery<number>(
    ['todo-visit-requests', userApi],
    async () => {
      const requests = await userApi
        .visitRequests({ status: ['submitted'], page: -1, pageSize: -1 })
        .then((res) => res.values.length)
      requests !== userApplications.visitRequests &&
        setUserApplications((value) => ({ ...value, visitRequests: requests }))
      return requests
    },
    {
      refetchInterval: 5000,
    }
  )

  return <IconPermitApplication />
}

export const AccessCardRequestsBadge: React.FC = () => {
  const config = useConfig()
  const { httpClient } = useHttp()
  const getHttpClient = useMemo(() => httpClient, [httpClient])
  const api = getHttpClient(config!.api.securityApi) as AxiosInstance
  const userApi = new UserApi(api)
  const [userApplications, setUserApplications] = useRecoilState(userApplicationsAtom)

  useQuery<number>(
    ['todo-access-card-requests', userApi],
    async () => {
      const requests = await userApi
        .accessRequests({ status: ['awaiting_cso'], page: -1, pageSize: -1 })
        .then((res) => res.values.length)
      requests !== userApplications.accessCardRequests &&
        setUserApplications((value) => ({ ...value, accessCardRequests: requests }))
      return requests
    },
    {
      refetchInterval: 5000,
    }
  )

  return <BadgeIcon />
}
