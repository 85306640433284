import React from 'react'
import { Navigation, requiresTenant, requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { PATH_TENANT_BASE, Permissions, PERMISSIONS } from '@app/constants'
import { withServices } from '../withServices'
import { withActiveTenant } from '../WithActiveTenant'
import { AccessDependencies } from '@app/Content'
import { withParam } from '../withParam'
import { isPort } from '@app/lib/access'
import { Trans } from 'react-i18next'
import RecentActorsIcon from '@mui/icons-material/RecentActors'

const PATH = PATH_TENANT_BASE + '/identities'

export const identitiesModuleNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: <Trans i18nKey="navigation.identities.identities" />,
    path: PATH + '/',
    menu: {
      icon: <RecentActorsIcon />,
    },
    page: React.createElement(withServices(withActiveTenant(React.lazy(() => import('.'))))),
    exact: true,
    access: (d) =>
      isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.identities))(d),
  },
  {
    name: <Trans i18nKey="navigation.identities.identity" />,
    path: PATH + '/edit/:id',
    page: React.createElement(
      withActiveTenant(
        withParam(
          React.lazy(() => import('./Identity')),
          'id'
        )
      )
    ),
    exact: true,
    access: (d) =>
      isPort(d) && requiresTenant(d) && requiresAllOfPermissions(Permissions.all(PERMISSIONS.identities))(d),
  },
]
