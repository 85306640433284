import React from 'react'
import { Button } from '@mui/material'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { ResolvedFile } from './ServicesWrapper/apis/dtos/fileRefDto'

type SizeT = 'small' | 'medium' | 'large'
export interface FileDownloadButtonProps {
  resolvedFile: ResolvedFile
  openNewTab?: boolean
  label?: string
  size?: SizeT
}

export const FileDownloadButton: React.FC<React.PropsWithChildren<FileDownloadButtonProps>> = ({
  resolvedFile,
  openNewTab = true,
  label,
  size,
}) => {
  const cappedFilename = () => {
    if (!label && resolvedFile.filename.length > 40) {
      return `${resolvedFile.filename.substring(0, 40)}...`
    } else {
      return resolvedFile.filename
    }
  }
  return (
    <Button
      color="primary"
      endIcon={<AttachmentIcon />}
      href={resolvedFile.href}
      download={resolvedFile.filename}
      target={openNewTab ? '_blank' : '_self'}
      size={size}
    >
      {label ? label : cappedFilename()}
    </Button>
  )
}
