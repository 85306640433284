import { AxiosInstance } from 'axios'
import { IFileRefDto, IFileRefDtoSchema } from './dtos/fileRefDto'
import * as z from 'zod'

export const FieldOkSchema = z.object({
  ok: z.literal(true),
  description: z.string(),
}).strict()

export const FieldProblemSchema = z.object({
  ok: z.literal(false),
  description: z.string(),
  documents: z.array(IFileRefDtoSchema),
  tags: z.array(z.string())
}).strict()

export const ICheckListFieldSchema = z.object({
  item: z.object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    tags: z.array(z.string()).optional(),
    required: z.boolean()
  }),
  value: z.union([FieldOkSchema, FieldProblemSchema]).optional()
})

export interface CheckList {
  id: string
  name: string
  description: string
  minControlled?: number
}

export interface CheckListDetails {
  checklist: CheckList
  items: CheckListItem[]
}

export interface CheckListForm {
  name: string
  description: string
  minControlled?: number
  items: CheckListItem[]
}

export interface CheckListItem {
  id: string
  name: string
  description: string
  tags: string[]
  required: boolean
}

export interface CheckListProblem {
  ok: false
  description: string
  documents: IFileRefDto[]
  tags: string[]
}

export interface CheckListOk {
  ok: true
  description: string
}

export type CheckListValue = CheckListOk | CheckListProblem

export interface CheckListField {
  item: CheckListItem
  value?: CheckListValue
}

export interface CheckListFieldForm {
  item: string
  value?: CheckListValue
}

export class CheckListsApi {
  private http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async list(tenant: string): Promise<CheckList[]> {
    const response = await this.http.get(`/ports/${tenant}/checklists`)
    return response.data.checklists
  }

  async get(tenant: string, id: string): Promise<CheckListDetails> {
    const response = await this.http.get(`/ports/${tenant}/checklists/${id}`)
    return response.data
  }

  async remove(tenantRef: string, id: string): Promise<void> {
    await this.http.delete(`/ports/${tenantRef}/checklists/${id}`)
  }

  async update(tenantRef: string, id: string, form: CheckListForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/checklists/${id}`, form)
  }

  async create(tenantRef: string, form: CheckListForm): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/checklists`, form)
    return response.data.id
  }
}
