import { AxiosInstance } from 'axios'
import * as Stanley from './Stanley'
import Page from './Page'
import qs from 'qs'

export class StanleyApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  async get(tenantRef: string): Promise<Stanley.InstallationDetails> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley`)
    return response.data
  }

  async upsert(tenantRef: string): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/stanley`)
    return response.data.id
  }

  async delete(tenantRef: string): Promise<void> {
    await this.http.delete(`/ports/${tenantRef}/stanley`)
  }

  async updateCredentials(tenantRef: string, form: Stanley.UpdateCredentials) {
    await this.http.put(`/ports/${tenantRef}/stanley/credentials`, form)
  }

  async deleteCredentials(tenantRef: string) {
    await this.http.delete(`/ports/${tenantRef}/stanley/credentials`)
  }

  async resolveCardHolder(id: string, tenantRef: string): Promise<Stanley.User[]> {
    const encodedId = encodeURIComponent(id)
    const res = await this.http.get(`/ports/${tenantRef}/stanley/resolved-card-holders/${encodedId}`)
    return res.data.resolved
  }

  async accessGroups(tenantRef: string): Promise<Stanley.AccessGroup[]> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/accessGroups`)
    return response.data.accessGroups
  }

  async accessSync(
    tenantRef: string,
    params: Stanley.AccessSyncParams,
    page: Page.Search
  ): Promise<Page<Stanley.AccessSync>> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/accessSync`, {
      params: { ...params, ...page },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async data(tenantRef: String): Promise<Stanley.InstallationData> {
    const result = await this.http.get(`/ports/${tenantRef}/stanley/data`)
    return result.data
  }

  async cardholders(tenantRef: string, params: Stanley.CardHolderParams): Promise<Page<Stanley.User>> {
    const result = await this.http.get(`ports/${tenantRef}/stanley/cardholders`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return result.data
  }

  async cards(tenant: string, params: Stanley.CardParams): Promise<Page<Stanley.Card>> {
    const result = await this.http.get(`/ports/${tenant}/stanley/cards`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    return result.data
  }

  async searchCompanies(tenantRef: string, q: string): Promise<Stanley.CardHolderCompany[]> {
    const result = await this.http.get(`ports/${tenantRef}/stanley/cardholderCompanies`, { params: { q } })
    return result.data.companies
  }

  async updateFacility(tenantRef: string, facility: string, updateFacility: Stanley.UpdateFacility): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/stanley/facilities/${facility}`, updateFacility)
  }

  async registerCard(tenantRef: string, userId: string, data: Stanley.NewSymmetryBlueUser): Promise<void> {
    const encodedId = encodeURIComponent(userId)
    await this.http.post(`/ports/${tenantRef}/stanley/register-card/${encodedId}`, data)
  }

  async checkpoints(tenantRef: string): Promise<Stanley.CheckpointReader[]> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/checkpoints`)
    return response.data.checkpoints
  }

  async updateCheckpoint(
    tenantRef: string,
    checkpoint: string,
    updateCheckpoint: Stanley.UpdateCheckpointReader
  ): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/stanley/checkpoints/${checkpoint}`, updateCheckpoint)
  }

  async updateSettings(tenantRef: string, form: Stanley.SettingsForm): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/stanley/settings`, form)
  }

  async getCardHolder(tenantRef: string, id: string): Promise<Stanley.CardHolder> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/cardholders/${id}`)
    return response.data
  }

  async getCardHolderImage(tenantRef: string, id: string): Promise<string> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/cardholders/${id}/image`, {
      responseType: 'arraybuffer',
    })
    const image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
    return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`
  }

  async addCardHolder(tenantRef: string, form: Stanley.NewCardForm): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/stanley/cardholders`, form)
    return response.data.local
  }

  async updateCardHolder(tenantRef: string, id: number, form: Stanley.CardHolderForm): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/stanley/cardholders/${id}`, form)
  }

  async refreshCardholder(tenantRef: string, id: number): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/stanley/cardholders/${id}/refresh`)
  }

  async updateCardHolderImageFile(tenantRef: string, id: number, file: File): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/stanley/cardholders/${id}/image`, file)
  }

  async setAccessGroups(tenantRef: string, id: number, form: Stanley.AccessGroupsForm): Promise<string> {
    const response = await this.http.post(`/ports/${tenantRef}/stanley/cardholders/${id}/accessGroups`, form)
    return response.data.id
  }

  async setContract(tenantRef: string, id: number, form: Stanley.SetCardHolderContract): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/stanley/cardholders/${id}/contract`, form)
  }

  async setContractBulk(tenantRef: string, form: Stanley.SetCardHolderContractBulk): Promise<void> {
    await this.http.put(`/ports/${tenantRef}/stanley/cardholders/set-contract`, form)
  }

  async getContract(tenantRef: string, id: number): Promise<Stanley.CardHolderContract> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/cardholders/${id}/contract`)
    return response.data
  }

  async setStatus(tenantRef: string, id: number, form: Stanley.StatusForm): Promise<void> {
    await this.http.post(`/ports/${tenantRef}/stanley/cardholders/${id}/card-status`, form)
  }

  async personalDataTitles(tenantRef: string): Promise<Stanley.PersonalDataTitle[]> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/personalDataTitles`)
    return response.data.personalDataTitles
  }

  async badgeFormats(tenantRef: string): Promise<Stanley.BadgeFormat[]> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/badgeFormats`)
    return response.data.badgeformats
  }

  async companies(tenantRef: string): Promise<Stanley.Company[]> {
    const response = await this.http.get(`/ports/${tenantRef}/stanley/companies`)
    return response.data.companies
  }
}
