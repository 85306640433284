import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon'

interface WizardButtonProps extends ButtonProps {
  icon?: React.ElementType<SvgIconProps>
  buttonText: string
  selected?: boolean
}

const WizardButton: React.FC<WizardButtonProps> = ({ icon: Icon, onClick, selected, buttonText, ...buttonProps }) => {
  return (
    <Button
      {...buttonProps}
      variant={selected ? 'contained' : 'outlined'}
      size="large"
      fullWidth
      startIcon={Icon && <Icon />}
      onClick={onClick}
      sx={{
        flexDirection: 'column',
        textTransform: 'none',
        '& .MuiButton-label': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '30px !important',
        },
        '& .MuiButton-startIcon': {
          margin: 0,
          marginBottom: '4px',
        },
      }}
    >
      {buttonText}
    </Button>
  )
}

export default WizardButton
