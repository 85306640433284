import { AxiosResponse } from 'axios';
import { AxiosInstance } from 'axios';
import { NewSecurityConfirmation } from '@app/lib/securityConfirmation';
import { SortArrayOfObjectsNested } from '@app/lib/Sort';
import { ISecurityConfirmationDto, ISecurityConfirmationsDataResponse } from './dtos/securityConfirmationsDto';

export class SecurityConfirmationApi {
  private httpClient: AxiosInstance;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  async getSecurityConfirmations(tenantRef: string): Promise<ISecurityConfirmationDto[]> {
    const securityConfirmationsResponse: AxiosResponse<ISecurityConfirmationsDataResponse> = await this.httpClient.get(
      `/ports/${tenantRef}/securityConfirmations`
    );
    return securityConfirmationsResponse.data.securityConfirmations.sort(SortArrayOfObjectsNested('rank', 'asc'));
  }

  async postSecurityConfirmation(tenantRef: string, data: NewSecurityConfirmation): Promise<string> {
    const securityChecksResponse = await this.httpClient.post(`/ports/${tenantRef}/securityConfirmations`, data);
    return securityChecksResponse.data;
  }

  async putSecurityConfirmation(tenantRef: string, data: ISecurityConfirmationDto): Promise<string> {
    const securityChecksResponse = await this.httpClient.put(`/ports/${tenantRef}/securityConfirmations/${data.id}`, {
      ...data,
      // remove resolved document as it will mess up the json parsing
      document: data.document?.id,
    });
    return securityChecksResponse.data;
  }

  async deleteSecurityConfirmation(id: string, tenantRef: string): Promise<ISecurityConfirmationDto> {
    const securityChecksResponse = await this.httpClient.delete(`/ports/${tenantRef}/securityConfirmations/${id}`);
    return securityChecksResponse.data;
  }
}
