import { Box, Chip, IconButton, MenuItem, Menu } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useState } from 'react'
import { MoreVertical as MoreVerticalIcon } from '@griegconnect/krakentools-react-icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secured1: {
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
    },
    secured2: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    notSecured: {
      backgroundColor: theme.palette.grey[400],
    },
  })
)

export interface QuayStatusChipProps {
  secured: boolean
  onChange: (secured: boolean) => void
}

export const QuayStatusChip: React.VFC<QuayStatusChipProps> = ({ secured, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const setStatusOption = async (value: string) => {
    const isSecured = value === '1' ? false : true
    onChange(isSecured)
    setAnchorEl(null)
  }

  const openStatusMenu = () => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeStatusMenu = () => {
    setAnchorEl(null)
  }

  const statusOptions = [
    { id: '1', name: t('components.quayStatus.actions.notSecured') },
    { id: '2', name: t('components.quayStatus.actions.secured') },
  ]

  return (
    <Box display="flex" alignItems="center">
      <Chip
        label={secured ? t('components.quayStatus.status.secured') : t('components.quayStatus.status.notSecured')}
        color={'primary'}
        classes={{
          colorPrimary: secured ? classes.secured1 : classes.secured2,
          colorSecondary: classes.notSecured,
        }}
      />
      <Box mr={1} />
      <IconButton onClick={openStatusMenu()} size="large">
        <MoreVerticalIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeStatusMenu}>
        {statusOptions.map((option) => (
          <MenuItem key={option.id} onClick={() => setStatusOption(option.id)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default QuayStatusChip
