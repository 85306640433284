import { WithActiveTenant } from '@app/routes/WithActiveTenant'
import { useAuth } from '@griegconnect/krakentools-react-kraken-app'
import md5 from 'blueimp-md5'
import { useQuery } from '@tanstack/react-query'
import { useServices } from '@app/common/ServicesWrapper'
import { Stats } from '@app/common/ServicesWrapper/apis/Stats'
import { v4 } from 'uuid'
import { ContractApplications } from '@app/common/ServicesWrapper/apis/ContractsApi'
import { Widgets } from '../../CustomDashboard/Types'
import CustomDashboard from '../../CustomDashboard'
import { useTheme } from '@mui/material/styles'
import { useRecoilValue } from 'recoil'
import { portConfigStore } from '@app/stores/portStore'
import { useTranslation } from "react-i18next"

const CsoDashboard: React.FC<React.PropsWithChildren<WithActiveTenant>> = ({ activeTenant, children }) => {
  const { services } = useServices()
  const user = useAuth()
  const hash = md5(user.user!.email)
  const portConfig = useRecoilValue(portConfigStore)
  const theme = useTheme()
  const warning = theme.palette.warning.main
  const { t } = useTranslation()

  const defaultWidgetConstructors: Widgets.Constructor[] = [
    {
      id: v4(),
      type: 'dos',
      variant: 'value',
      name: t('components.dashboard.labels.cardApplications'),
      subVariant: 'awaiting_cso' as ContractApplications.Status,
      position: [undefined, undefined, undefined, { x: 0, y: 0 }],
      width: [3, 3],
      color: warning,
    },
    {
      id: v4(),
      type: 'dos',
      variant: 'value',
      name: t('components.dashboard.labels.cardApplications'),
      subVariant: 'require_more_information' as ContractApplications.Status,
      position: [undefined, undefined, undefined, { x: 3, y: 0 }],
      width: [3, 3],
      color: warning,
    },
    {
      id: v4(),
      type: 'dos',
      variant: 'value',
      name: t('components.dashboard.labels.cardApplications'),
      subVariant: 'in_review' as ContractApplications.Status,
      position: [undefined, undefined, undefined, { x: 6, y: 0 }],
      width: [3, 3],
    },
    {
      id: v4(),
      type: 'dos',
      variant: 'value',
      name: t('components.dashboard.labels.cardApplications'),
      subVariant: 'created' as ContractApplications.Status,
      position: [undefined, undefined, undefined, { x: 9, y: 0 }],
      width: [3, 3],
    },
  ]

  const { data, isLoading: isFetching } = useQuery<Stats.ContractStatusStats>(['', activeTenant.ref, portConfig], () =>
    services.statsApi.contractApplicationStatuses(activeTenant.ref)
  )

  return (
    <CustomDashboard
      activeTenant={activeTenant}
      storageKeys={{
        layout: `${activeTenant.ref}-${hash}-dashboard-layout-cso`,
        widgets: `${activeTenant.ref}-${hash}-dashboard-widget-constructors-cso`,
      }}
      defaultWidgetConstructors={defaultWidgetConstructors}
      data={{
        isFetching,
        dos: data,
      }}
      disableMap={true}
    >
      {children}
    </CustomDashboard>
  )
}

export default CsoDashboard
