import { AxiosInstance } from 'axios'
import qs from 'qs'

export interface Vessel {
  id: string
  name: string
  contactEmail?: string
  mmsi?: string
  imo?: string
}

export interface Params {
  id?: string[]
  name?: string
  mmsi?: string[]
  imo?: string[]
}

export interface VesselForm {
  name: string
  contactEmail?: string
  mmsi?: string
  imo?: string
}

export class VesselApi {
  http: AxiosInstance

  constructor(http: any) {
    this.http = http
  }

  async list(tenant: string, params?: Params): Promise<Vessel[]> {
    const response = await this.http.get(`/ports/${tenant}/vessels`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data.vessels
  }

  async get(tenant: string, vessel: string): Promise<Vessel> {
    const response = await this.http.get(`/ports/${tenant}/vessels/${vessel}`)
    return response.data
  }

  async create(tenant: string, form: VesselForm): Promise<{ id: string }> {
    const response = await this.http.post(`ports/${tenant}/vessels`, form)
    return response.data
  }

  async update(tenant: string, id: string, form: VesselForm): Promise<void> {
    await this.http.put(`ports/${tenant}/vessels/${id}`, form)
  }

  async delete(tenant: string, id: string): Promise<void> {
    await this.http.delete(`ports/${tenant}/vessels/${id}`)
  }
}
