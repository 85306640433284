import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgLockOpening = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M12 1a5 5 0 0 0-5 5v1a1 1 0 0 0 2 0V6a3 3 0 1 1 6 0v4H5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1h-2V6a5 5 0 0 0-5-5ZM6 12h12v8H6v-8Z" />,
    <path d="M14 16a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
  )
export default SvgLockOpening
